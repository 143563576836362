import React, { useContext, useMemo } from 'react'
import { Dropdown, MenuProps, Space } from 'antd'
import { rolodex_batch_delete } from '../../../api/useFDEBackend'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { LifeExtensionOptionsTableData } from '../RecommendationsInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import { SiteContext } from '../../../contextProviders/siteContextProvider'

interface LifeExtensionMenuProps {
  lifeExtension: LifeExtensionOptionsTableData
  allActions: boolean
  refetchCallBack?: () => void
}

export const LifeExtensionMenu: React.FC<LifeExtensionMenuProps> = ({
  lifeExtension,
  refetchCallBack,
  allActions,
}) => {
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const lifeExtensionSlug = useMemo(() => {
    return lifeExtension.id
  }, [])

  const menuPropsMap: Record<string, () => void> = {
    // Options for Plan Recs
    Remove: () => {
      rolodex_batch_delete(auth0, [lifeExtensionSlug]).then(() => {
        refetchCallBack?.()
      })
    },
    Tentative: () => {
      RolodexPatchBatch(
        [
          {
            id: lifeExtensionSlug,
            type: RolodexEntryType.PLAN_REC,
            data: {
              is_tentative: true,
            },
          },
        ],
        auth0,
        siteData
      ).then(() => {
        refetchCallBack?.()
      })
    },
    Planned: () => {
      RolodexPatchBatch(
        [
          {
            id: lifeExtensionSlug,
            type: RolodexEntryType.PLAN_REC,
            data: {
              is_tentative: false,
            },
          },
        ],
        auth0,
        siteData
      ).then(() => {
        refetchCallBack?.()
      })
    },

    // Options for All-actions menu
    Delete: () => {
      rolodex_batch_delete(auth0, [lifeExtensionSlug]).then(() => {
        refetchCallBack?.()
      })
    },
    None: () => {
      void 0
    },
  }

  const planMenuOnClick: MenuProps['onClick'] = ({ key }) => {
    menuPropsMap[key]()
  }

  const customPlanMenuItems: MenuProps['items'] = [
    {
      label: 'Remove from plan',
      key: 'Remove',
    },
  ].concat(
    lifeExtension.is_tentative
      ? {
          label: 'Unmark tentative',
          key: 'Planned',
        }
      : {
          label: 'Mark tentative',
          key: 'Tentative',
        }
  )

  const allActionsMenuItems: MenuProps['items'] = [
    lifeExtension.editable
      ? {
          label: 'Delete Action',
          key: 'Delete',
        }
      : {
          label: 'No Options',
          key: 'None',
        },
  ]

  return (
    <Dropdown
      menu={{
        items: allActions ? allActionsMenuItems : customPlanMenuItems,
        onClick: planMenuOnClick,
      }}
      trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>...</Space>
      </a>
    </Dropdown>
  )
}
