import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import styled from 'styled-components'
import { getMapMarkerSVG } from './MapMarker'
import { asset_manager_theme } from '../../assets/themes'
import { useRolodexEntries } from '../../api/useRolodex'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { fileFetcher } from '../../api/fetchers'
mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2Vja29yb2JvdGljcyIsImEiOiJjbGwycmE0dmMxaTg5M2xsczByYzdhd296In0.dhEv6ff1JBIRG_Uvq9GseA'
import { useAuthInfo, AuthInfo } from '../../contextProviders/useAuthInfo'
import { downloadEntryFile } from '../../util/RolodexInterface'

export interface Location {
  longitude: number
  latitude: number
  zoom: number
}

export interface MarkerData {
  id?: string
  longitude: number
  latitude: number
  title: string
  color?: string // Optional, customized color for the marker.
  size?: number // Optional, size for the SVG element.
  outlineColor?: string // Optional, outline color for custom styling.
}

interface MapProps {
  center: Location
  markers: MarkerData[]
  addingAsset?: boolean
  tempMarker?: mapboxgl.Marker | null
  setTempMarker?: Dispatch<SetStateAction<mapboxgl.Marker | null>>
  initializeTempMarker?: boolean
  setAddAssetModalVisible?: Dispatch<SetStateAction<boolean>>
  setBaseballCardFdeCustomerUnitSlug?: (id: string) => void
  style?: {
    height?: string
    width?: string
    borderRadius?: string
  }
}

type MapContainerProps = {
  height?: string
  width?: string
  borderRadius?: string
}

const MapContainer = styled.div<MapContainerProps>`
  height: ${(props) => props.height || '90%'};
  width: ${(props) => props.width || '90%'};
  border-radius: ${(props) => props.borderRadius || '0.5rem'};
`

const RadarModule: FC<MapProps> = ({
  center,
  markers,
  addingAsset,
  tempMarker,
  initializeTempMarker,
  setTempMarker,
  setAddAssetModalVisible,
  setBaseballCardFdeCustomerUnitSlug,
  style,
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const markerRefs = useRef<Map<string, mapboxgl.Marker>>(new Map())
  const authInfo = useAuthInfo()
  const orthomosaicOverlayQuery = useRolodexEntries({
    type: RolodexEntryType.RADAR_ORTHOMOSAIC,
  })

  const [orthomosaicLayers, setOrthomosaicLayers] = useState<
    | {
        id: string
        data?: Record<string, any>
        url: string
      }[]
    | undefined
  >([])

  const getOrthomosaicLayerURls = async (id: string, authInfo: AuthInfo) => {
    const signedUrl = await downloadEntryFile(id, 'orthomosaic.png', authInfo)
    const fetchedData = await fileFetcher(signedUrl, authInfo)
    if (!fetchedData) {
      return ''
    }
    return URL.createObjectURL(fetchedData)
  }

  useEffect(() => {
    const getOrthomosaicLayers = async () => {
      const orthomosaicData =
        orthomosaicOverlayQuery.data?.map((orthoEntry) => {
          return {
            id: orthoEntry.entry.id,
            data: orthoEntry.entry.data,
          }
        }) ?? []

      const processedOrthoData = await Promise.all(
        orthomosaicData.map(async (d) => {
          const url = await getOrthomosaicLayerURls(d.id, authInfo)
          return {
            id: d.id,
            data: d.data,
            url: url,
          }
        })
      )
      setOrthomosaicLayers(processedOrthoData)
    }
    getOrthomosaicLayers()
  }, [orthomosaicOverlayQuery.data, authInfo])

  const openSelectAssetModal = useCallback(() => {
    setAddAssetModalVisible?.(true)
  }, [setAddAssetModalVisible])

  const onMapClick = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (!addingAsset) return // Only proceed if adding asset mode is active

      if (tempMarker) {
        tempMarker.remove()
        setTempMarker?.(null)
      }

      const el = document.createElement('div')
      // Assuming getMapMarkerSVG returns an SVG or appropriate HTML string for the marker's visuals
      el.innerHTML = getMapMarkerSVG(
        asset_manager_theme.background.background_color5, // Example: Specific color for temporary marker
        35, // Size could be standard or specific for temp markers
        'black' // Outline color if needed
      )
      el.className = 'temp-marker' // You can keep this class for additional CSS if needed

      const newMarker = new mapboxgl.Marker(el)
        .setLngLat(e.lngLat)
        .addTo(map.current as mapboxgl.Map)

      setTempMarker?.(newMarker)
      openSelectAssetModal()
    },
    [addingAsset, tempMarker, setTempMarker, openSelectAssetModal, map]
  )

  useEffect(() => {
    if (map.current) {
      if (addingAsset) {
        map.current.on('click', onMapClick)
      } else {
        map.current.off('click', onMapClick)
        if (tempMarker) {
          tempMarker.remove()
          setTempMarker?.(null)
        }
      }
    }

    return () => {
      if (map.current) {
        map.current.off('click', onMapClick)
      }
    }
  }, [addingAsset, onMapClick, setTempMarker, tempMarker?.getLngLat()])

  useEffect(() => {
    if (initializeTempMarker && tempMarker && map.current instanceof mapboxgl.Map) {
      tempMarker.addTo(map.current)
    }
  }, [initializeTempMarker, tempMarker])

  useEffect(() => {
    if (tempMarker) {
      setTempMarker?.(tempMarker)
    }
  }, [tempMarker?.getLngLat(), setTempMarker])

  const updateMarkers = useCallback(() => {
    // Clear existing markers first
    markerRefs.current.forEach((marker) => marker.remove())
    markerRefs.current.clear()

    if (!markers.length) {
      return // Exit after clearing markers if there are none to add.
    }

    // Add new markers, if there are any.
    markers.forEach((markerData) => {
      const el = document.createElement('div')
      el.className = 'marker'
      el.innerHTML = getMapMarkerSVG(
        markerData.color || asset_manager_theme.background.high_vis,
        markerData.size || 35,
        markerData.outlineColor || asset_manager_theme.background.background_color9
      )
      el.addEventListener('click', () => {
        if (markerData.id && setBaseballCardFdeCustomerUnitSlug) {
          setBaseballCardFdeCustomerUnitSlug(markerData.id)
        }
      })

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
      }).setHTML(`<div style="color: black;">${markerData.title}</div>`)

      if (map.current) {
        const marker = new mapboxgl.Marker(el)
          .setLngLat([markerData.longitude, markerData.latitude])
          .setPopup(popup)
          .addTo(map.current)
        // Mouse enter event
        marker.getElement().addEventListener('mouseenter', () => {
          if (map.current) {
            popup.addTo(map.current)
          }
        })

        // Mouse leave event
        marker.getElement().addEventListener('mouseleave', () => {
          popup.remove()
        })

        if (markerData.id) {
          markerRefs.current.set(markerData.id, marker)
        }
      }
    })
  }, [markers, setBaseballCardFdeCustomerUnitSlug])

  // Effect to run when markers change
  useEffect(() => {
    if (map.current) {
      updateMarkers()
    }
  }, [markers, updateMarkers])

  // Initializing the map
  useEffect(() => {
    if (!map.current && mapContainerRef.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [center.longitude, center.latitude],
        zoom: center.zoom,
      })

      map.current.on('load', () => {
        map.current?.on('click', onMapClick)
        updateMarkers()
      })

      if (initializeTempMarker && tempMarker) {
        tempMarker.addTo(map.current)
      }
    }
  }, [center, initializeTempMarker, onMapClick, tempMarker, updateMarkers])

  useEffect(() => {
    if (orthomosaicLayers?.length && orthomosaicLayers?.length > 0 && map.current) {
      orthomosaicLayers?.forEach((layer) => {
        map.current?.addSource(layer.id, {
          type: 'image',
          url: layer.url,
          coordinates: layer.data?.coordinates,
        })
        map.current?.addLayer({
          id: layer.id,
          type: 'raster',
          source: layer.id,
          paint: {
            'raster-fade-duration': 0,
          },
        })
      })
    }
  }, [orthomosaicLayers?.length])

  return (
    <MapContainer
      height={style?.height}
      width={style?.width}
      borderRadius={style?.borderRadius}
      ref={mapContainerRef}
    />
  )
}
export default RadarModule
