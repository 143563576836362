// Compliance Filter Types
export type OVERVIEW_FILTER_TYPES = 'NONCOMPLIANT' | 'SINGLE' | 'BOTH'

// Inspection Filter Types
export type OVERVIEW_INSP_FILTER_TYPES = 'INTERNAL' | 'EXTERNAL'

export const OVERVIEW_BAR_COMPLIANT_LABEL = 'Fully Compliant'
export const OVERVIEW_BAR_EXT_NON_COMPLIANT_LABEL = 'Externally Non-Compliant'
export const OVERVIEW_BAR_INT_NON_COMPLIANT_LABEL = 'Internally Non-Compliant'
export const OVERVIEW_BAR_NON_COMPLIANT_LABEL = 'Fully Non-Compliant'

//  Data Rolodex Keys
export const ROLODEX_KEY = 'capex_optimizer'
