import React, { useContext, useState, useEffect } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useDebounce } from 'react-use'

interface PlanRecCheckboxInputProps {
  checkboxValue: boolean | undefined
  planRecId: string
  field: string
  disabled?: boolean
  refetchData?: () => void
  rolodexType: string
}

export const PlanRecCheckboxInput: React.FC<PlanRecCheckboxInputProps> = ({
  checkboxValue,
  planRecId,
  field,
  disabled,
  refetchData,
  rolodexType,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<boolean>(checkboxValue || false)

  useEffect(() => {
    if (typeof checkboxValue === 'boolean') {
      setEditedValue(checkboxValue)
    }
  }, [checkboxValue])

  useDebounce(
    () => {
      if (editedValue !== checkboxValue && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: planRecId,
          type: rolodexType,
          data: {
            [field]: editedValue,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    300,
    [editedValue]
  )

  return (
    <Checkbox
      disabled={disabled}
      checked={editedValue}
      onChange={(value: CheckboxChangeEvent) => {
        setEditedValue(value.target.checked)
      }}
    />
  )
}
