// The code within this comment block is to support different data structures returned from the InspectionData endpoint
// for Due and Unscheduled, Scheduled Inspections, and Inspection History tabs. We need to make this data structure
// consistent and remove this.

import { SWRResponse } from 'swr'
import {
  InspectionGraphCategoryData,
  DueGraphData,
} from '../InspectionSchedulingInterface'

type ScheduledAndInspectionHistoryInspectionData = {
  external?: Record<string, number>
  internal?: Record<string, number>
}
// Function to get the maximum combined value of internal and external data on any specific date.
export function getStackedMaxValue(
  data: ScheduledAndInspectionHistoryInspectionData
): number {
  const combined: Record<string, number> = {}

  // Sum external values to combined
  for (const [date, value] of Object.entries(data?.external ?? {})) {
    combined[date] = value
  }

  // Add internal values to combined, sum if already exists
  for (const [date, value] of Object.entries(data?.internal ?? {})) {
    if (date in combined) {
      combined[date] += value
    } else {
      combined[date] = value
    }
  }

  // Compute maximum value from the combined record
  let maxCombinedValue = 0
  for (const value of Object.values(combined)) {
    if (value > maxCombinedValue) {
      maxCombinedValue = value
    }
  }

  return maxCombinedValue
}

export function getGroupedMaxValue(
  data: ScheduledAndInspectionHistoryInspectionData
): number {
  // Compute maximum value from internal and external records, defaulting to 0 if empty
  const maxInternal = data?.internal ? Math.max(0, ...Object.values(data.internal)) : 0
  const maxExternal = data?.external ? Math.max(0, ...Object.values(data.external)) : 0

  return Math.max(maxInternal, maxExternal)
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const calculateMax = (
  inspectionData: DueGraphData,
  graphView: 'counts' | 'hours',
  barMode: 'stack' | 'group',
  scalingFactor: number
): number | undefined => {
  const data: DueGraphData = inspectionData || {}

  if (barMode === 'group') {
    // Group mode: max of individual values
    return (
      Math.max(
        ...Object.values(data)
          .map((categoryData: InspectionGraphCategoryData) =>
            categoryData[graphView].flat()
          )
          .flat()
      ) * scalingFactor
    )
  } else if (barMode === 'stack') {
    // Stack mode: sum values for the same date across categories and find max
    const dateToSum: { [date: string]: number } = {}

    Object.values(data).forEach((categoryData: InspectionGraphCategoryData) => {
      const dates = categoryData.dates
      const values: number[] = categoryData[graphView]

      dates?.forEach((date, index) => {
        if (!dateToSum[date]) dateToSum[date] = 0
        dateToSum[date] += values[index] || 0
      })
    })

    return Math.max(...Object.values(dateToSum)) * scalingFactor
  }
}
