import { FC, useMemo } from 'react'
import Plot from 'react-plotly.js'
import { useResizeDetector } from 'react-resize-detector'
import { Spin, Button } from 'antd'
import { SWRResponse } from 'swr'
import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs'
import { OutageObject } from '../../../util/types/'
import { asset_manager_theme } from '../../../assets/themes'
import { OUTAGE_SCALING, DATE_GRANULARITY_MAP } from '../../../util/constants'
import { toTitleCase } from '../../../components/helpers/stringFilters'
import { calculateMax } from './helpers'
import { Column, Row } from '../../../components/Common/Grid'
import { DueGraphDataResponse } from '../InspectionSchedulingInterface'
import { PlotType } from 'plotly.js-basic-dist'

interface DueAndUnscheduledBarChartProps {
  startDate: Dayjs | null
  endDate: Dayjs | null
  setTableDateFilter: (selected: Dayjs[] | undefined) => void
  setTableInspectionTypeFilter: (selected?: string) => void
  inspectionDataSWR: SWRResponse<any, any, any>
  outageSchedule?: { [key: string]: OutageObject[] }
  graphDateGranularity: 'month' | 'year' | 'week'
  graphView: 'counts' | 'hours'
  barMode: 'stack' | 'group'
  targetLoadHours?: number
}
export const DueAndUnscheduledBarChart: FC<DueAndUnscheduledBarChartProps> = (props) => {
  const { ref, width, height } = useResizeDetector()

  const inspectionGraphData: DueGraphDataResponse = useMemo(() => {
    return props.inspectionDataSWR.data
  }, [props.inspectionDataSWR.data])

  const countMax = useMemo(() => {
    return calculateMax(
      inspectionGraphData?.graph_data,
      props.graphView,
      props.barMode,
      OUTAGE_SCALING
    )
  }, [inspectionGraphData?.graph_data, props.graphView, props.barMode])

  //this is beyond stupid, adding a point at the low and high to get the vertical line and the null in between to break the line
  const processedOutageSchedule = useMemo(() => {
    return props.outageSchedule
      ? {
          x: Object.keys(props.outageSchedule || {}).reduce((acc: any[], cur) => {
            return [...acc, cur, cur, null]
          }, []),
          y: Object.keys(props.outageSchedule || {}).reduce((acc: any[]) => {
            return [...acc, 0, countMax, null]
          }, []),
          hovertext: Object.keys(props.outageSchedule)
            .map((key) => {
              return props.outageSchedule?.[key]
                .map((d) => `${d.date.format('YYYY-MM-DD')} ${d.type} ${d.system}`)
                .join('<br>')
            })
            .reduce((acc: any[], cur) => {
              return [...acc, cur, cur, null]
            }, []),
        }
      : {}
  }, [props.outageSchedule, countMax])

  const barTrace = useMemo(
    () =>
      Object.keys(inspectionGraphData?.graph_data || {})
        .filter((d) => {
          return (
            inspectionGraphData?.graph_data[d][props.graphView].length > 0 &&
            inspectionGraphData?.graph_data[d].dates.length > 0
          )
        })
        .map((key) => {
          const dates = inspectionGraphData?.graph_data[key].dates
          const counts = inspectionGraphData?.graph_data?.[key]?.[props.graphView]
          const partialTrace: Record<string, number> = {}
          if (props.startDate && props.endDate) {
            let closestStartDate = props.startDate
            if (props.graphDateGranularity === 'week') {
              closestStartDate = props.startDate.day(0)
            }
            for (
              let i = closestStartDate;
              i <= props.endDate;
              i = i.add(1, props.graphDateGranularity as ManipulateType)
            ) {
              partialTrace[
                i.format(DATE_GRANULARITY_MAP[props.graphDateGranularity].dayjsFormat)
              ] =
                counts[
                  dates.indexOf(
                    i.format(DATE_GRANULARITY_MAP[props.graphDateGranularity].dayjsFormat)
                  )
                ] || 0
            }
          }

          return {
            x: Object.keys(partialTrace).sort((a, b) => {
              return dayjs(a).unix() - dayjs(b).unix()
            }),
            y: Object.keys(partialTrace ?? {})
              .sort((a, b) => {
                return dayjs(a).unix() - dayjs(b).unix()
              })
              .map((d) => partialTrace[d]),
            type: 'bar' as PlotType,
            name: toTitleCase(key),
            marker: {
              color:
                key.toLowerCase() === 'external'
                  ? asset_manager_theme.colors.orange
                  : asset_manager_theme.colors.yellow,
            },
          }
        }),
    [
      inspectionGraphData?.graph_data,
      props.startDate,
      props.endDate,
      props.graphDateGranularity,
      props.graphView,
    ]
  )
  type possibleGraphShapes = 'path' | 'line'

  const graphShapes = useMemo(() => {
    const shapes: Record<any, any>[] = []
    if (props.graphView === 'hours' && props.targetLoadHours) {
      shapes.push({
        type: 'line' as possibleGraphShapes,
        xref: 'paper',
        x0: 0,
        x1: 1,
        y0: props.targetLoadHours,
        y1: props.targetLoadHours,
        line: {
          color: 'rgba(255,255,255, 0.65)',
          width: 2,
          dash: 'dot',
        },
      })
    }

    return shapes
  }, [props.graphView, props.targetLoadHours])

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <div style={{ width, height, paddingTop: '.5rem' }}>
        <Spin
          spinning={
            props.inspectionDataSWR.isLoading || !props.startDate || !props.endDate
          }>
          {Object.keys(inspectionGraphData?.graph_data ?? {}).some((d) => {
            return (
              inspectionGraphData?.graph_data[d][props.graphView].length > 0 &&
              inspectionGraphData?.graph_data[d].dates.length > 0
            )
          }) ? (
            <Plot
              data={
                inspectionGraphData?.graph_data && [
                  // add outages first so they are behind the bars
                  props.outageSchedule
                    ? {
                        x: processedOutageSchedule.x,
                        y: processedOutageSchedule.y,
                        type: 'scatter',
                        name: 'Outages',
                        hoverinfo: 'text',
                        line: {
                          dash: 'dot',
                          width: 2,
                        },
                        marker: {
                          size: 1,
                          color: asset_manager_theme.background.background_color5,
                        },

                        hovertext: processedOutageSchedule.hovertext,
                      }
                    : {},
                  ...barTrace,
                ]
              }
              config={{ displayModeBar: false }}
              layout={{
                shapes: graphShapes ?? [],
                dragmode: 'select',
                selectdirection: 'h',
                width,
                height: 350,
                bargap: 0.3,
                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
                showlegend: true,
                hovermode: 'closest',
                font: {
                  color: 'rgba(255,255,255,1)',
                },
                xaxis: {
                  showgrid: false,
                  dtick: DATE_GRANULARITY_MAP[props.graphDateGranularity].plotlyDTick,
                  tickformat:
                    DATE_GRANULARITY_MAP[props.graphDateGranularity].plotlyTickformat,
                  color: asset_manager_theme.background.background_color3,
                },
                yaxis: {
                  title:
                    props.graphView === 'counts' ? 'Due Inspections' : 'Scheduled Hours',
                  fixedrange: true,
                  showgrid: false,
                  rangemode: 'tozero',
                  color: asset_manager_theme.background.background_color3,
                },
                uirevision: 'true',
                margin: {
                  t: 0,
                  b:
                    props.graphDateGranularity === 'week' &&
                    (props.endDate?.diff(props.startDate, 'months') ?? 0) >= 4
                      ? 80
                      : 20,
                  l: 50,
                },
                barmode: props.barMode,
              }}
              onSelected={(event) => {
                const selectionMargin = DATE_GRANULARITY_MAP[props.graphDateGranularity]
                if (event === undefined && props.startDate && props.endDate) {
                  props.setTableDateFilter([props.startDate, props.endDate])
                  props.setTableInspectionTypeFilter(undefined)
                } else {
                  const dateRange = event?.range?.x
                  if (dateRange?.length === 2) {
                    props.setTableDateFilter([
                      props.startDate?.isAfter(
                        dayjs(String(dateRange[0]))
                          .add(
                            selectionMargin.selectionMargin,
                            selectionMargin.selectionGranularity
                          )
                          .startOf(props.graphDateGranularity as OpUnitType)
                      )
                        ? props.startDate
                        : dayjs(String(dateRange[0]))
                            .add(
                              selectionMargin.selectionMargin,
                              selectionMargin.selectionGranularity
                            )
                            .startOf(props.graphDateGranularity as OpUnitType),
                      props.endDate?.isBefore(
                        dayjs(String(dateRange[1])).endOf(
                          props.graphDateGranularity as OpUnitType
                        )
                      )
                        ? props.endDate
                        : dayjs(String(dateRange[1])).endOf(
                            props.graphDateGranularity as OpUnitType
                          ),
                    ])
                    props.setTableInspectionTypeFilter(undefined)
                  }
                }
              }}
              onClick={(event) => {
                if (event === undefined && props.startDate && props.endDate) {
                  props.setTableDateFilter([props.startDate, props.endDate])
                  props.setTableInspectionTypeFilter(undefined)
                } else if (event?.points?.length === 1) {
                  const date = event?.points[0]?.x
                  //on click set table date filter to the start and end of granularity
                  props.setTableDateFilter([
                    dayjs(String(date)).startOf(props.graphDateGranularity as OpUnitType),
                    dayjs(String(date)).endOf(props.graphDateGranularity as OpUnitType),
                  ])
                  props.setTableInspectionTypeFilter(
                    event?.points[0]?.data?.name.replace('_inspections', '').toLowerCase()
                  ) //remove spaces
                }
              }}
            />
          ) : (
            <Column
              style={{
                justifyContent: 'center',
                minHeight: '375px',
                textAlign: 'center',
                backgroundColor: asset_manager_theme.background.background_color9,
                borderRadius: '4px',
              }}>
              <h2
                style={{
                  color: asset_manager_theme.background.background_color3,
                }}>
                No data in selected date range
              </h2>
              <h3
                style={{
                  marginTop: '.5rem',
                  color: asset_manager_theme.background.background_color5,
                }}>
                Confident there's data? Try adjusting the range in the upper right.
              </h3>
            </Column>
          )}
        </Spin>
      </div>
    </div>
  )
}
