import { AuthInfo } from '../contextProviders/useAuthInfo'
import _ from 'lodash'
import { trackEvent } from '../components/helpers/mixpanel'
import { message } from 'antd'

//------------------------------------------------------------------------------
// Function to handle error messages

const handleErrorMessage = async (
  response: Response,
  url: string,
  auth0: AuthInfo,
  body?: any
) => {
  const urlParams = new URLSearchParams(window.location.search)
  const debugParam = urlParams.get('debug')

  if (
    !auth0.permissionTags?.organization &&
    response &&
    response.status !== 200 &&
    debugParam === 'true'
  ) {
    const responseData = await response.json()
    let errorMessage = `Error: ${response.status}: ${responseData['detail']} - ${url}`
    if (body) {
      errorMessage += `\nBody: ${JSON.stringify(body)}`
    }
    const key = errorMessage

    message.error({
      content: errorMessage,
      key,
      duration: 0,
      onClick: () => {
        message.destroy(key)
      },
    })
  }
}

/**
 * A simple get fetcher for use with SWR
 * @param url The URL to query
 * @param auth0 An Auth0 object used to grab the user's token
 */
export const getFetcher = async (url: string, auth0: AuthInfo) => {
  if (!url) return

  const res = await fetch(url, {
    headers: auth0.headers,
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.json()
}

export const getFileFetcher = async (url: string, auth0: AuthInfo) => {
  if (!url) return
  const res = await fetch(url, {
    headers: auth0.headers,
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }
  return res.blob()
}

export const getMultipleFetcher = async (urls: string[], auth0: AuthInfo) => {
  if (!urls || !urls.length) {
    return
  }
  const headers = auth0.headers
  const fetchArr = urls.map((url) => {
    const fetchPromise = fetch(url, { headers })
    return { url, promise: fetchPromise }
  })

  const results = await Promise.all(fetchArr.map((item) => item.promise))

  const errorResponse = _.find(results, (res) => !res.ok)
  if (errorResponse) {
    for (const { url, promise } of fetchArr) {
      await handleErrorMessage(await promise, url, auth0)
    }

    const error = new Error('An error occurred while fetching the data.')
    error.cause = await errorResponse.json()
    throw error
  }
  return await Promise.all(
    results.map(async (res) => {
      const parsed = await res.json()
      return {
        ...parsed,
        _feched_url: res.url,
      }
    })
  )
}

//------------------------------------------------------------------------------

/**
 * A simple post fetcher for use with SWR
 * @param url The URL to query
 * @param body The query body
 * @param auth0 An Auth0 object used to grab the user's token
 */
export const postFetcher = async (
  url: string | undefined,
  body: string,
  auth0: AuthInfo
) => {
  if (!url) return

  const res = await fetch(url, {
    method: 'POST',
    headers: auth0.headers,
    body: body,
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0, body)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.json()
}

//------------------------------------------------------------------------------

/**
 * A simple image fetcher for use with SWR
 * @param url The URL to query
 * @param auth0 An Auth0 object used to grab the user's token
 */
export const fileFetcher = async (url: string | undefined, auth0: AuthInfo) => {
  if (!url) return

  const res = await fetch(url, {
    headers: auth0.headers,
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.blob()
}

//------------------------------------------------------------------------------

/**
 * A simple put fetcher for use with SWR
 * @param url The URL to query
 * @param body The query body
 * @param auth0 An Auth0 object used to grab the user's token
 */
export const putFetcher = async (
  url: string | undefined,
  auth0: AuthInfo,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  body: any = {},
  contentType = 'application/json'
) => {
  if (!url) return

  const headerAuthorization =
    typeof auth0.headers === 'object'
      ? (auth0.headers as Record<string, string>)?.Authorization
      : ''

  const res = await fetch(url, {
    method: 'PUT',
    body,
    headers: {
      ...auth0.headers,
      Authorization: headerAuthorization,
      'Content-Type': contentType,
    },
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0, body)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.json()
}

export const deleteFetcher = async (url: string | undefined, auth0: AuthInfo) => {
  if (!url) return

  const res = await fetch(url, {
    method: 'DELETE',
    headers: auth0.headers,
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0)
    const errorText = await res.text()
    const error = new Error('An error occurred while fetching the data: ' + errorText)
    error.cause = errorText
    throw error
  }

  return await res.text()
}

//------------------------------------------------------------------------------

/**
 * A simple patch fetcher for use with SWR
 * @param url The URL to query
 * @param body The query body
 * @param auth0 An Auth0 object used to grab the user's token
 */
export const patchFetcher = async (
  url: string | undefined,
  body: string,
  auth0: AuthInfo
) => {
  if (!url) return

  const res = await fetch(url, {
    method: 'PATCH',
    headers: auth0.headers,
    body: body,
  }).then((d) => {
    let jsonBody = body
    try {
      //JSON.parse will fail if the string isn't a json so worth protecting against. Sending the string back isn't a massive loss either
      //This will also prevent any subsequent callbacks from faling on their face
      jsonBody = JSON.parse(body)
    } catch (e) {
      // do nothing
    }
    trackEvent('Data Patched', {
      patch_url: url,
      patch_body: jsonBody,
    })
    return d
  })

  if (!res.ok) {
    await handleErrorMessage(res, url, auth0, body)
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.json()
}
