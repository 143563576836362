export enum RolodexType {
  ORGANIZATION = 'organization',
  SITE = 'site',
}

export enum RolodexPermission {
  READ = 'read',
  EDIT = 'edit',
  ADMIN = 'admin',
  MEMBER = 'member',
}

/**
 * Retrieves the values of an enum.
 *
 * @param enumType - The enum type.
 * @returns An array containing the values of the enum.
 */
export function getEnumValues<T>(enumType: any): T[] {
  return Object.values<T>(enumType)
}
