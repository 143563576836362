import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { TimelineDataTable } from './TimelineDataTable'
import { CardContainer } from '../../components/CardContainer'
import {
  PlotContainer,
  Container,
  FiguresContainer,
} from '../../components/StyledGraphContainerComponents'
import { withResizeDetector } from 'react-resize-detector'
import { useGetInspectionTimeline } from '../../api/useFDEBackend'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import _ from 'lodash'
import { Spin } from 'antd'
import Plot from 'react-plotly.js'
import { asset_manager_theme } from '../../assets/themes'
import { SiteContext } from '../../contextProviders/siteContextProvider'

interface TimelineTabProps {
  width: number
  height: number
}

const PLOT_WIDTH_PAD = 150

export type InspectionCountTimelineDataValue = {
  countInternal: number
  countExternal: number
}
export type InspectionCountTimelineData = Record<number, InspectionCountTimelineDataValue>

const TimelineTab: FC<TimelineTabProps> = (props) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const upcomingInternal = useGetInspectionTimeline(
    auth0,
    siteData?.siteSlug ?? '',
    'inspection_schedule.internal.next',
    'future'
  )
  const upcomingExternal = useGetInspectionTimeline(
    auth0,
    siteData?.siteSlug ?? '',
    'inspection_schedule.external.next',
    'future'
  )

  const [minYear, maxYear] = React.useMemo(() => {
    const allYears = _.uniq([
      ...(upcomingInternal.data?.map((item) => item.year) ?? []),
      ...(upcomingExternal.data?.map((item) => item.year) ?? []),
    ])
    return [_.min(allYears), _.max(allYears)]
  }, [upcomingExternal.data, upcomingInternal.data])

  const yearToCounts: InspectionCountTimelineData = React.useMemo(() => {
    if (!minYear || !maxYear) {
      return {}
    }
    const result: InspectionCountTimelineData = {}
    for (let year = minYear; year <= maxYear; year++) {
      const fromInternal = _.find(
        upcomingInternal?.data ?? [],
        (item) => item.year === year
      )
      const fromExternal = _.find(
        upcomingExternal?.data ?? [],
        (item) => item.year === year
      )
      const mapping = {
        countInternal: fromInternal?.count ?? 0,
        countExternal: fromExternal?.count ?? 0,
      }
      result[year] = {
        countInternal: _.max([result[year]?.countInternal, mapping.countInternal]) ?? 0,
        countExternal: _.max([result[year]?.countExternal, mapping.countExternal]) ?? 0,
      }
    }
    return result
  }, [upcomingExternal.data, upcomingInternal.data, minYear, maxYear])

  const thisYear = dayjs().year()
  const thisYearInternalCount = yearToCounts[thisYear]?.countInternal ?? 0
  const thisYearExternalCount = yearToCounts[thisYear]?.countExternal ?? 0

  return (
    <div style={{ width: '100%' }}>
      <Container>
        <FiguresContainer style={{ width: '95%' }}>
          <CardContainer
            cards={[
              {
                label: `Non-compliant by year end`,
                value: thisYearInternalCount + thisYearExternalCount,
                color: asset_manager_theme.colors.red,
                key: 'non-compliant',
                loading: upcomingExternal.isLoading || upcomingInternal.isLoading,
              },
              {
                label: `Externally non-compliant by year end`,
                value: thisYearExternalCount,
                key: 'external-non-compliant',
                color: asset_manager_theme.colors.yellow,
                loading: upcomingExternal.isLoading,
              },
              {
                label: `Internally non-compliant by year end`,
                value: thisYearInternalCount,
                key: 'internal-non-compliant',
                color: asset_manager_theme.colors.orange,
                loading: upcomingInternal.isLoading,
              },
            ]}
          />
          <PlotContainer
            style={{
              width: '100%',
              paddingTop: '1.2%',
            }}>
            <Spin spinning={upcomingExternal.isLoading || upcomingInternal.isLoading}>
              <Plot
                config={{
                  displayModeBar: false,
                }}
                data={[
                  {
                    type: 'bar',
                    x: upcomingInternal.data?.map((item) => item.year) ?? [],
                    y: upcomingInternal.data?.map((item) => item.count) ?? [],
                    name: 'Internal Inspection',
                    marker: {
                      color: asset_manager_theme.colors.yellow,
                    },
                  },
                  {
                    type: 'bar',
                    x: upcomingExternal.data?.map((item) => item.year) ?? [],
                    y: upcomingExternal.data?.map((item) => item.count) ?? [],
                    name: 'External Inspection',
                    marker: {
                      color: asset_manager_theme.colors.orange,
                    },
                  },
                  {
                    type: 'scatter',
                    x: Object.keys(yearToCounts),
                    y: Object.values(yearToCounts).map(
                      (data) => data.countExternal + data.countInternal
                    ),
                    name: 'Total Inspections',
                    marker: {
                      color: '#ffeb87',
                    },
                  },
                ]}
                layout={{
                  paper_bgcolor: 'rgba(0,0,0,0)',
                  plot_bgcolor: 'rgba(0,0,0,0)',
                  width: props.width - PLOT_WIDTH_PAD,
                  font: {
                    color: 'rgba(255,255,255,1)',
                  },
                  xaxis: {
                    tickmode: 'linear',
                  },
                  margin: { t: 0, b: 40 },
                }}
                style={{ width: '100%', height: '100%' }}
              />
            </Spin>
          </PlotContainer>
          <ContainerDiv style={{ paddingBottom: '1.5rem' }}>
            <TimelineDataTable
              timelineData={yearToCounts}
              loading={upcomingExternal.isLoading || upcomingInternal.isLoading}
            />
          </ContainerDiv>
        </FiguresContainer>
      </Container>
    </div>
  )
}

const ContainerDiv = styled.div`
  text-align: center;
`
export default withResizeDetector(TimelineTab)
