import React, { FC, useContext, useEffect, useState, useRef, useMemo } from 'react'
import {
  Button,
  Modal,
  Form,
  Input,
  Menu,
  Dropdown,
  Space,
  InputRef,
  Tooltip,
  List,
} from 'antd'
import { FolderOpenOutlined, SearchOutlined } from '@ant-design/icons'
import type { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words'
import {
  RolodexPatchBatch,
  RolodexPutBatch,
  useGetFDECustomerUnitData,
} from '../../util/RolodexInterface'
import { generateAssetsDbPdf } from '../../api/useFDEBackend'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import _ from 'lodash'
import { TableSliderFilter } from '../../components/Common/TableSliderFilter'
import { TableDateRangeFilter } from '../../components/Common/TableDateRangeFilter'
import {
  AssetsDatabaseTableRow,
  FDECustomerUnit,
  FDECustomerUnitData,
  AssetsDatabaseRowIndex,
  AssetDBTableColumnKeys,
  ColumnConfig,
  FilterCriteria,
  isFDESiteData,
  AssetDBColumnType,
} from './AssetDatabaseTabInterface'
import HighVisButton from '../../components/Common/HighVisButton'
import AssetTable from './AssetTable'
import AssetForm from './AssetForm'
import { v4 as uuidv4 } from 'uuid'
import { Column, PageContainer } from '../../components/Common/Grid'
import { TabHeader } from '../../components/Common/TabHeader'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { defaultSortedColumn, sortNulls } from '../../api/tableHelpers'
import { EditOutlined } from '@ant-design/icons'
import { asset_manager_theme } from '../../assets/themes'
import dayjs, { Dayjs } from 'dayjs'
import { toTitleCase } from '../../components/helpers/stringFilters'
import merge from 'deepmerge-json'
import { DownloadIcon } from '@radix-ui/react-icons'
import { defaultSort } from '../../api/tableHelpers'
import { AMBIENT_TEMP_RANGE } from '../../util/constants'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import FileBrowser from '../../components/Common/FileBrowser/FileBrowser'
import { trackEvent } from '../../components/helpers/mixpanel'
import { useRolodexEntries } from '../../api/useRolodex'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { TabKey } from '../AppLayout'
import { checkUserFCUPermission } from '../../components/helpers/authHelpers'
import { defaultColumnsConfig } from './DefaultColumnsConfig'
import { convertCamelCaseToTitleCase } from '../../util/helpers/stringHelpers'

const EDITABLE_FLAGS = ['decommissioned', 'inspection_schedule']

//these columns will always appear for everysite
const defaultColumnPrefixes: ColumnConfig[] = [
  {
    key: 'equip_description',
    label: 'Equipment Description',
    columnType: AssetDBColumnType.TEXT,
    //giving these ridiculously low values so they are always first
    sortOrder: -900000,
    width: 300,
    fixed: true,
  },
  {
    key: 'edit',
    sortOrder: -500000,
    fixed: true,
  },
  {
    key: 'files',
    sortOrder: -200000,
    fixed: true,
  },
]

interface AssetDatabaseTabProps {
  setFdeCustomerUnitSlug: (slug: string | undefined) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
}

export const sortInspectionDatesFields = (
  row1: AssetsDatabaseTableRow,
  row2: AssetsDatabaseTableRow,
  dataIndex: keyof AssetsDatabaseTableRow
) => {
  const val1 = _.get(row1, dataIndex) ?? null
  const val2 = _.get(row2, dataIndex) ?? null

  const nulls = sortNulls(val1, val2)
  if (nulls !== null) {
    return nulls
  }
  const dateA = dayjs(val1 as any)
  const dateB = dayjs(val2 as any)
  if (dateA.isValid() && dateB.isValid()) {
    return dateA.diff(dateB)
  }
  if (dateA.isValid()) {
    return 1
  }
  if (dateB.isValid()) {
    return -1
  }
  return 0
}

export const sortNumberFields = (
  row1: AssetsDatabaseTableRow,
  row2: AssetsDatabaseTableRow,
  dataIndex: keyof AssetsDatabaseTableRow
) => {
  const val1 = (_.get(row1, dataIndex) as number) ?? null
  const val2 = (_.get(row2, dataIndex) as number) ?? null

  const nulls = sortNulls(val1, val2)
  if (nulls !== null) {
    return nulls
  }

  return val1 - val2
}

const inspectionFields = [
  {
    key: 'next',
    label: 'Next',
    format: 'date',
  },
  {
    key: 'interval_months',
    label: 'Interval',
    format: 'number',
  },
  {
    key: 'last',
    label: 'Last',
    format: 'date',
  },
]

const colorByPriority = (num: number) => {
  switch (num) {
    case 1:
      return 'green'
    case 2:
      return 'yellow'
    case 3:
      return 'orange'
    case 4:
      return 'red'
    default:
      return 'inherit'
  }
}

/**
 * This function is currently out of use, but may be used in the near future
 */
function isRuggable(assetsDatabaseTableRow: AssetsDatabaseTableRow) {
  const LOW_TEMP_BOUNDARY = 0
  const HIGH_TEMP_BOUNDARY = 100
  const materialCategoriesMap: Record<string, string> = {}
  const ruggableMaterialMap: Record<string, boolean> = {}

  if (!!assetsDatabaseTableRow.material) {
    const cleanedMaterial = String(assetsDatabaseTableRow.material)
      .replace(/\s+/g, '')
      .toLowerCase()
    // set Sub-material field
    const subMaterial = materialCategoriesMap[cleanedMaterial]

    assetsDatabaseTableRow.sub_material = subMaterial ? subMaterial : ''

    // check the material side of things
    const ruggableMaterial = ruggableMaterialMap[subMaterial] || false

    // set ferrous based on if its ruggable or not
    assetsDatabaseTableRow.ferrous = ruggableMaterial

    const insulated = assetsDatabaseTableRow.insulated?.toLowerCase().includes('n') //true here is not insulated

    //initialise as not ruggable
    assetsDatabaseTableRow.ruggable = 'Not Ruggable'
    //only look at temperature if we get a ruggable material otherwise no point
    if (ruggableMaterial && assetsDatabaseTableRow.temp && insulated) {
      const temp = assetsDatabaseTableRow.temp.toLowerCase().includes('amb')
        ? 80
        : parseInt(assetsDatabaseTableRow.temp)
      return temp > LOW_TEMP_BOUNDARY && temp <= HIGH_TEMP_BOUNDARY
        ? 'Ruggable High Temp'
        : temp <= LOW_TEMP_BOUNDARY
        ? 'Ruggable'
        : 'Not Ruggable'
    }
  }
}

const AssetDatabaseTab: FC<AssetDatabaseTabProps> = (props) => {
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)

  const [selectedRow, setSelectedRow] = useState<AssetsDatabaseTableRow | null>(null)
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 })
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalForm] = Form.useForm()
  const [searchText, setSearchText] = useState<string | undefined>('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [tableFilters, setTableFilters] = useState<{ [key: string]: string[] }>({
    decommissioned: ['False'],
  })
  const [isFileBrowserVisible, setIsFileBrowserVisible] = useState(false)
  const FileBrowserMemoized = React.memo(FileBrowser)

  const unitDataSwr = useGetFDECustomerUnitData(authInfo, siteData)
  const fdeSiteSwr = useRolodexEntries(
    {
      type: RolodexEntryType.FDE_SITE,
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    },
    undefined,
    !!(siteData?.orgSlug && siteData?.siteSlug)
  )

  const ruggableMappingSWR = useRolodexEntries(
    {
      ids: [`${siteData?.siteSlug}-ruggable-mapping`],
      type: RolodexEntryType.SITE_CONFIG_TYPE,
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    },
    undefined,
    !!siteData?.orgSlug
  )

  const lockOutEnabled = userFeatureFlags?.superUser
    ? false
    : userFeatureFlags?.[TabKey.assetsDB]?.read_only ?? false

  const featureFlagAccessibleFields = useMemo(() => {
    return userFeatureFlags?.fcuAccessibleFields ?? {}
  }, [userFeatureFlags?.fcuAccessibleFields])

  function convertToAssetsDatabaseTableRows(
    customerUnits: FDECustomerUnit[]
  ): AssetsDatabaseTableRow[] {
    return customerUnits.map((customerUnit) => {
      const {
        entry: { id },
        entry: { data, data: { overrides } = {} },
      } = customerUnit

      const mergedData: FDECustomerUnitData = merge(data, overrides)

      const assetsDatabaseTableRow: AssetsDatabaseTableRow = {
        id,
        ...mergedData,
        assetConditionPriorityJoined:
          mergedData.asset_condition || mergedData.asset_priority
            ? `${mergedData.asset_condition ?? ''}-${mergedData.asset_priority ?? ''}`
            : undefined,
      }
      if (!!ruggableMappingSWR.data) {
        const ruggableMapping = ruggableMappingSWR.data[0]?.entry?.data
        assetsDatabaseTableRow.ruggable = ruggableMapping?.[assetsDatabaseTableRow.id]
      }
      assetsDatabaseTableRow.is_rug_available =
        'component' in (customerUnit.entry.tags ?? {})

      assetsDatabaseTableRow.isEditable = checkUserFCUPermission(
        assetsDatabaseTableRow,
        featureFlagAccessibleFields
      )

      return assetsDatabaseTableRow
    })
  }

  useEffect(() => {
    //need this useEffect so it doesn't infinitely rerender
    props.setAssetBaseballCardRefetchData({ func: () => unitDataSwr.mutate() })
  }, [])

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: AssetsDatabaseRowIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: AssetsDatabaseRowIndex
  ) => {
    confirm()
    clearFilters()
    handleSearch([], confirm, dataIndex)
  }
  const getColumnSearchProps = (
    dataIndex: AssetsDatabaseRowIndex,
    style: React.CSSProperties = {}
  ): ColumnType<AssetsDatabaseTableRow> => ({
    //blatantly copied from antd documentation: https://ant.design/components/table#components-table-demo-custom-filter-panel
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}>
            Close
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record?.[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) || false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex && searchText ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: asset_manager_theme.background.high_vis,
            padding: 0,
            color: '#000000',
          }}
          style={{
            ...style,
          }}
          searchWords={[searchText as string]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <p style={{ ...style }}>{text}</p>
      ),
  })

  const handleContextMenu = (
    event: React.MouseEvent<HTMLTableRowElement>,
    record: AssetsDatabaseTableRow
  ) => {
    event.preventDefault()
    setContextMenuPosition({ top: event.clientY - 90, left: event.clientX })
    setSelectedRow(record)
    setDropdownVisible(true)
  }

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === 'edit') {
      setIsModalVisible(true)
      setDropdownVisible(false)
    }
  }

  const handleSave = async (values: AssetsDatabaseTableRow) => {
    if (selectedRow === null) {
      const newUuid: string = uuidv4()

      trackEvent('Button Clicked', {
        buttonName: 'add_asset',
        pageName: 'Assets Database',
        assetDetails: {
          assetUuid: newUuid,
          assetName: values.equip_description,
        },
        raw: values,
      })

      await RolodexPutBatch([newUuid], values, authInfo, RolodexEntryType.CUSTOMER_UNIT, {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      })
      unitDataSwr.mutate()
    } else {
      const { id, ...overrideValues } = values

      //adding this up here, so we don't need to keep adding Object.keys everywhere
      const modifiedFields = new Set(Object.keys(values))

      trackEvent('Button Clicked', {
        buttonName: 'edit_asset',
        pageName: 'Assets Database',
        assetDetails: {
          assetName: values.equip_description,
        },
        raw: values,
      })

      //anything that updates the top level data rather than the overrides goes in here
      const fdeCustomerUnitDataPatch: Record<string, any> = {}

      EDITABLE_FLAGS.forEach((flag) => {
        //this isn't ideal but for any flags that are updated in overrides should actually be updated in the top level data
        if (modifiedFields.has(flag)) {
          fdeCustomerUnitDataPatch[flag] =
            overrideValues[flag as keyof typeof overrideValues]
          delete overrideValues[flag as keyof typeof overrideValues]
        }
      })

      const FDECustomerUnitPatchEntry = {
        id: id,
        type: RolodexEntryType.CUSTOMER_UNIT,
        data: { ...fdeCustomerUnitDataPatch, ...overrideValues },
        tags: {
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
        },
      }

      RolodexPatchBatch([FDECustomerUnitPatchEntry], authInfo, siteData).then(() => {
        unitDataSwr.mutate()
      })
    }

    setIsModalVisible(false)
  }

  const handleDropdownVisibleChange = (visible: boolean) => {
    if (!visible) {
      setSelectedRow(null)
    }
    setDropdownVisible(visible)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownVisible && !(event.target as HTMLElement).closest('.ant-dropdown')) {
        setDropdownVisible(false)
        setSelectedRow(null)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [dropdownVisible])

  const processedAssetDatabaseData: AssetsDatabaseTableRow[] = useMemo(
    () => convertToAssetsDatabaseTableRows((unitDataSwr.data ?? []) as FDECustomerUnit[]),
    [unitDataSwr.data]
  )

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='edit'>Edit</Menu.Item>
    </Menu>
  )

  const checkTempRecordValue = (filterMax: number, filterMin: number) => {
    const { max, min } = AMBIENT_TEMP_RANGE
    //check if the ranges instersect anywhere
    return filterMax >= min && filterMin <= max
  }

  const defaultSliderRangeFilter = (
    dataIndex: keyof AssetsDatabaseTableRow,
    tempFilter?: boolean
  ) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <TableSliderFilter
          data={processedAssetDatabaseData}
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value: boolean | React.Key, record: AssetsDatabaseTableRow) => {
        const { max, min } = value as any as { max: number; min: number }
        if (
          tempFilter &&
          (_.get(record, dataIndex) as string)?.toLowerCase()?.includes('amb')
        ) {
          return checkTempRecordValue(max, min)
        }

        const recordValue = parseInt(_.get(record, dataIndex) as string)
        return recordValue >= min && recordValue <= max
      },
    }
  }

  const defaultDateRangeFilter = (
    dataIndex: keyof AssetsDatabaseTableRow,
    removeOnstream?: boolean
  ) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <TableDateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),

      onFilter: (value: boolean | React.Key, record: AssetsDatabaseTableRow) => {
        const [min, max] = [
          (value as any as { min: Dayjs; max: Dayjs })?.min,
          (value as any as { min: Dayjs; max: Dayjs })?.max,
        ]
        const recordValue = _.get(record, dataIndex, undefined)
        const recordDateValue = dayjs(recordValue as string)
        if (removeOnstream && record?.onstream_exception) {
          //if onstream and we wanna remove onstreams from the filter then return false
          return false
        }
        if (recordValue && recordDateValue.isValid()) {
          //could use inbetween here but that threw errors
          return recordDateValue.isAfter(min) && recordDateValue.isBefore(max)
        }
        return false
      },
    }
  }

  const handleAddRow = () => {
    setSelectedRow(null)
    setIsModalVisible(true)
  }

  const generateCategoricalColumn = <REC_T extends object>(
    title: string,
    key: Extract<keyof REC_T, string>,
    data: REC_T[],
    render?: (text: string, record: REC_T, index: number) => React.ReactNode,
    valueTransform?: (value: any, record?: REC_T) => string,
    sorter?: (a: REC_T, b: REC_T) => number,
    nullFilterName?: string
  ): ColumnType<REC_T> => {
    // Default behavior for transforming values, used if no valueTransform is provided
    const defaultTransform = (value: any) => String(value).toLowerCase()

    // Use the provided valueTransform function if available, otherwise use the default
    const transform = valueTransform || defaultTransform

    const shouldAddNoInfoFilter =
      title !== 'Onstream Exception' && title !== 'Decommissioned'

    // Create a map of original values to their transformed counterparts for filtering,
    // explicitly excluding null and undefined values.
    const valueMap = new Map<string, string>()
    data.forEach((item) => {
      const itemValue = item[key]
      if (itemValue !== null && itemValue !== undefined && itemValue !== '') {
        // Check for null or undefined
        const originalValue = String(itemValue) // Get the original string value
        const transformedValue = transform(originalValue) // Transform the value for comparison
        valueMap.set(transformedValue, originalValue) // Map transformed value back to original
      }
    })

    return {
      title,
      dataIndex: key,
      key: key,
      sorter: sorter ?? defaultSort(key),
      render,
      width: 'max-content',
      filters: [
        ...(shouldAddNoInfoFilter
          ? [{ text: nullFilterName || '---', value: 'NULL' }]
          : []),
        ...Array.from(valueMap, ([transformedValue, originalValue]) => ({
          text:
            originalValue == 'true' || originalValue == 'false'
              ? toTitleCase(originalValue)
              : originalValue, // Use the original value for display
          value: transformedValue, // Use the transformed value for comparison
        })),
      ],
      onFilter: (value, record) => {
        if (value === 'NULL') {
          return !record[key]
        }
        return transform(record[key]) === transform(value)
      },
    }
  }

  const distinctInspectionObjects = useMemo(() => {
    //generate the distinct method/ type combinations
    const methodTypeSet = new Set<string>()
    unitDataSwr.data?.forEach((d: FDECustomerUnit) => {
      Object.keys(d.entry.data.inspection_schedule || {}).forEach((key) => {
        Object.keys(
          d.entry.data.inspection_schedule?.[
            key as keyof typeof d.entry.data.inspection_schedule
          ] ?? {}
        ).forEach((inspectionKey) => {
          methodTypeSet.add(`${key}_${inspectionKey}`)
        })
      })
    })
    return Array.from(methodTypeSet)
  }, [unitDataSwr.data])

  const inspectionColumns = useMemo(() => {
    return distinctInspectionObjects.reduce((acc, curr) => {
      const [type, method] = curr.split('_')
      return [
        ...acc,
        ...inspectionFields.map((field) => {
          const fieldKey = field.key
          const fieldLabel = field.label
          return {
            title: toTitleCase(`${type} ${method} ${fieldLabel}`),
            dataIndex: ['inspection_schedule', type, method, fieldKey],
            key: `${method}_${type}_${fieldKey}`,
            ...(field.format === 'date'
              ? {
                  ...defaultDateRangeFilter(
                    ['inspection_schedule', type, method, fieldKey].join(
                      '.'
                    ) as keyof AssetsDatabaseTableRow
                  ),
                  sorter: (
                    row1: AssetsDatabaseTableRow,
                    row2: AssetsDatabaseTableRow
                  ) => {
                    return sortInspectionDatesFields(
                      row1,
                      row2,
                      ['inspection_schedule', type, method, fieldKey].join(
                        '.'
                      ) as keyof AssetsDatabaseTableRow
                    )
                  },
                  render: (text: string, record: AssetsDatabaseTableRow) => {
                    if (record?.onstream_exception && type === 'internal') {
                      //not sure how to get out of hardcoding internal here
                      return (
                        <Tooltip title={`Marked On-Stream on ${text}`}>
                          <div>On-Stream</div>
                        </Tooltip>
                      )
                    }
                    if (
                      (record?.['inspection_schedule'] as any)?.[type]?.[method] ===
                      undefined
                    ) {
                      //prone to change but atm if the method doesn't exist on a type it's not applicable
                      return 'N/A'
                    } else {
                      return text
                    }
                  },
                }
              : {
                  //if not a date format add the number filters and sorters
                  ...defaultSliderRangeFilter(
                    ['inspection_schedule', type, method, fieldKey].join(
                      '.'
                    ) as keyof AssetsDatabaseTableRow
                  ),
                  sorter: (
                    row1: AssetsDatabaseTableRow,
                    row2: AssetsDatabaseTableRow
                  ) => {
                    return sortNumberFields(
                      row1,
                      row2,
                      ['inspection_schedule', type, method, fieldKey].join(
                        '.'
                      ) as keyof AssetsDatabaseTableRow
                    )
                  },
                }),
          }
        }),
      ]
    }, [] as ColumnsType<AssetsDatabaseTableRow>)
  }, [distinctInspectionObjects])

  const OverrideColumnDefinitions: Partial<
    Record<AssetDBTableColumnKeys, ColumnType<AssetsDatabaseTableRow>>
  > = {
    equip_description: {
      ...defaultSortedColumn('Equip Description', 'equip_description'),
      width: 300,
      fixed: true,
      onCell: (record: AssetsDatabaseTableRow) => {
        return {
          onClick: () => {
            props.setFdeCustomerUnitSlug(record.id)
            trackEvent('Button Clicked', {
              buttonName: 'asset_baseball_card',
              pageName: 'Assets Database',
              assetDetails: {
                assetName: record.equip_description,
              },
            })
          },
        }
      },
      ...getColumnSearchProps('equip_description', {
        cursor: 'pointer',
        fontWeight: 'bold',
      }),
    },
    edit: {
      key: 'edit',
      fixed: true,
      render: (row: AssetsDatabaseTableRow) => {
        const disabled = lockOutEnabled || !row.isEditable
        return (
          <Button
            disabled={disabled} // CheckLockout
            onClick={() => {
              props.setFdeCustomerUnitSlug(undefined)
              setSelectedRow(row)
              setIsModalVisible(true)
            }}
            style={{ border: 'none', backgroundColor: 'transparent' }}>
            <EditOutlined />
          </Button>
        )
      },
    },
    files: {
      title: 'Files',
      key: 'files',
      fixed: true,
      render: (row: AssetsDatabaseTableRow) => (
        <div>
          <Button
            onClick={() => {
              setSelectedRow(row)
              setIsFileBrowserVisible(true)
            }}
            icon={<FolderOpenOutlined />}
            style={{ border: 'none', backgroundColor: 'transparent' }}
          />
        </div>
      ),
    },
    asset_condition: {
      ...defaultSortedColumn('Asset Condition', 'asset_condition'),
      width: 'max-content',
      render: (num: number) => {
        return <span style={{ color: colorByPriority(num) }}>{num}</span>
      },
      ...defaultSliderRangeFilter('asset_condition'),
    },
    asset_priority: {
      ...defaultSortedColumn('Asset Priority', 'asset_priority'),
      width: 'max-content',
      render: (num: number) => {
        return <span style={{ color: colorByPriority(num) }}>{num}</span>
      },
      ...defaultSliderRangeFilter('asset_priority'),
    },
    erp_id: {
      ...defaultSortedColumn(
        `${unitDataSwr.data?.[0]?.entry?.data?.erp_name?.toUpperCase() ?? 'ERP'} ID`,
        'erp_id'
      ),
      width: 'max-content',
      ...getColumnSearchProps('erp_id'),
    },
    'dimensions.height_ft': {
      title: 'Dimensions - Height (ft)',
      dataIndex: ['dimensions', 'height_ft'],
      key: 'dimensions.height_ft',
      width: 'max-content',
      ...defaultSliderRangeFilter('dimensions.height_ft' as keyof AssetsDatabaseTableRow),
    },
    'dimensions.diameter_ft': {
      title: 'Dimensions - Diameter (ft)',
      dataIndex: ['dimensions', 'diameter_ft'],
      key: 'dimensions.diameter_ft',
      width: 'max-content',
      ...defaultSliderRangeFilter(
        'dimensions.diameter_ft' as keyof AssetsDatabaseTableRow
      ),
    },

    //not abstracting this bizarre edge case out for ambient temperature
    temp: {
      title: 'Temp',
      dataIndex: 'temp',
      key: 'temp',
      width: 'max-content',
      ...defaultSliderRangeFilter('temp', true),
    },
  }

  const columnsConfig = useMemo(() => {
    const fdeSiteData = fdeSiteSwr.data?.[0]?.entry?.data
    const fdeSiteColumnConfig =
      isFDESiteData(fdeSiteData) && fdeSiteData?.[TabKey.assetsDB]
        ? fdeSiteData[TabKey.assetsDB].column_config
        : defaultColumnsConfig

    const selectedColumnsConfig = [...defaultColumnPrefixes, ...fdeSiteColumnConfig]
    return _.sortBy(selectedColumnsConfig, (c) => c.sortOrder)
  }, [defaultColumnsConfig, defaultColumnPrefixes, fdeSiteSwr.data])

  const handleFilterCriteria = (
    key: keyof AssetsDatabaseTableRow,
    label?: string,
    filterCriteria?: FilterCriteria,
    columnType?: AssetDBColumnType
  ) => {
    switch (filterCriteria) {
      case FilterCriteria.CATEGORY:
        if (columnType === AssetDBColumnType.BOOLEAN) {
          return generateCategoricalColumn(
            label ?? '',
            key,
            processedAssetDatabaseData,
            (text) => toTitleCase(String(text || 'false')),
            (value) => toTitleCase(String(value || 'false'))
          )
        }
        return generateCategoricalColumn(label ?? '', key, processedAssetDatabaseData)
      case FilterCriteria.SEARCH:
        return getColumnSearchProps(key)
      case FilterCriteria.RANGE:
        return defaultSliderRangeFilter(key)
      default:
        return {}
    }
  }

  const tableColumns: ColumnsType<AssetsDatabaseTableRow> = useMemo(() => {
    const overrideColumnDefinitionsKeys = new Set(Object.keys(OverrideColumnDefinitions))

    const formatObjectForCell = (value: any) => {
      const data = Object.entries(value)
      return (
        <>
          <List
            size='small'
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item[0]}>
                <b>{convertCamelCaseToTitleCase(item[0])}</b>: {item[1] as any}
              </List.Item>
            )}></List>
        </>
      )
    }

    return (
      columnsConfig.map((d) => {
        if (overrideColumnDefinitionsKeys.has(d.key)) {
          return {
            ...OverrideColumnDefinitions[d.key],
            fixed: !!d.fixed,
            width: d.width ?? 'max-content',
            defaultFilteredValue: d.defaultFilterValue,
          }
        } else {
          // For now, this assumes that the objects have one level of nesting
          const renderCell = (value: any) => {
            if (!!value && typeof value === 'object') {
              if (Array.isArray(value)) {
                return (
                  <div>
                    <List
                      dataSource={value}
                      renderItem={(item, idx) => (
                        <>
                          <h3>{idx + 1}:</h3>
                          <List.Item key={idx}>{formatObjectForCell(item)}</List.Item>
                        </>
                      )}
                    />
                  </div>
                )
              }

              return <div>{formatObjectForCell(value)}</div>
            }

            return (
              <div>
                <p>{value}</p>
              </div>
            )
          }

          const columnDefinition = {
            ...defaultSortedColumn(d.label, d.key),
            fixed: !!d.fixed,
            width: d.width ?? 'max-content',
            defaultFilteredValue: d.defaultFilterValue,
            ...handleFilterCriteria(
              d.key as keyof AssetsDatabaseTableRow,
              d.label,
              d.filterCriteria,
              d.columnType
            ),
            render: renderCell,
          }
          return columnDefinition
        }
      }) as ColumnsType<AssetsDatabaseTableRow>
    )
      .concat(inspectionColumns)
      .filter((d) => {
        return (
          !!userFeatureFlags?.superUser ||
          !!userFeatureFlags?.[TabKey.assetsDB]?.accessible_columns?.includes(
            d.key as string
          )
        )
      })
    //need to have search text as a dependency so the column render method updates with the highlight
  }, [columnsConfig, processedAssetDatabaseData, searchText])

  const tableOnChange = (pagination: any, filters: any, sorter: any) => {
    setTableFilters(filters)
  }

  const handleGenerateAssetsDBPdf = () => {
    trackEvent('Button Clicked', {
      buttonName: 'generate_assets_inventory',
      pageName: 'Assets Database',
      filters: tableFilters,
    })

    generateAssetsDbPdf(authInfo, siteData?.siteSlug || '', tableFilters)
  }

  return (
    <PageContainer>
      <TabHeader>
        <Column>Assets Database</Column>

        <Column style={{ maxWidth: '10rem', margin: '0 1rem 0 1rem' }}>
          <Button
            style={{ display: 'inline-flex', alignItems: 'center', marginRight: '.5rem' }}
            onClick={() => handleGenerateAssetsDBPdf()}>
            <DownloadIcon />
            <span style={{ marginLeft: 8 }}>Generate PDF</span>
          </Button>
        </Column>
        {(userFeatureFlags?.superUser ||
          userFeatureFlags?.[TabKey.assetsDB]?.enableAddAsset) && (
          <Column style={{ maxWidth: '10rem' }}>
            <HighVisButton disabled={lockOutEnabled} onClick={handleAddRow}>
              {' '}
              {/* CheckLockout */}
              Add Asset
            </HighVisButton>{' '}
          </Column>
        )}
      </TabHeader>
      <AssetTable
        data={processedAssetDatabaseData}
        columns={tableColumns}
        handleContextMenu={handleContextMenu}
        dropdownVisible={dropdownVisible}
        loading={unitDataSwr.isLoading}
        onChange={tableOnChange}
      />

      {selectedRow && (
        <Dropdown
          overlay={menu}
          open={dropdownVisible}
          onOpenChange={handleDropdownVisibleChange}
          placement='bottomLeft'
          arrow>
          <div
            style={{
              position: 'absolute',
              top: contextMenuPosition.top,
              left: contextMenuPosition.left,
            }}>
            <Button type='link' />
          </div>
        </Dropdown>
      )}
      {isFileBrowserVisible && (
        <FileBrowserMemoized
          open={isFileBrowserVisible}
          onCancel={() => {
            setIsFileBrowserVisible(false)
          }}
          fdeCustomerUnitSlug={selectedRow?.id}
          assetName={selectedRow?.equip_description}
        />
      )}
      {!lockOutEnabled && (
        <Modal
          title={selectedRow ? 'Edit Asset' : 'Add Asset'}
          open={isModalVisible} // CheckLockout
          onCancel={() => {
            setIsModalVisible(false)
            modalForm.resetFields()
          }}
          footer={null}
          destroyOnClose={true}>
          <div
            style={{
              maxHeight: '70vh',
              overflowY: 'scroll',
              margin: '2rem 0rem 2rem 0rem',
            }}>
            <AssetForm
              form={modalForm}
              handleSave={handleSave}
              setIsModalVisible={setIsModalVisible}
              selectedRow={selectedRow}
              columnConfig={columnsConfig}
            />
          </div>
          <HighVisButton
            onClick={() => {
              modalForm.submit()
            }}>
            Save
          </HighVisButton>
          &nbsp; &nbsp;
        </Modal>
      )}
    </PageContainer>
  )
}

export default AssetDatabaseTab
