import { Modal } from 'antd'

interface ImagePreviewModalProps {
  imageData: string | null
  onClose: () => void
}

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  imageData,
  onClose,
}) => {
  return (
    <Modal
      open={!!imageData}
      onCancel={onClose}
      footer={null}
      bodyStyle={{ margin: '16px' }}>
      {imageData && (
        <img src={imageData} alt='Preview' style={{ width: '100%', height: 'auto' }} />
      )}
    </Modal>
  )
}
