import mixpanel from 'mixpanel-browser'
const { REACT_APP_ENVIRONMENT } = process.env

export function trackEvent(eventName: string, properties: object) {
  mixpanel.track(eventName, {
    ...properties,
    app: 'asset_manager',
    env: REACT_APP_ENVIRONMENT,
  })
}
