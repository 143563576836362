import React, { useCallback, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Spin, Tooltip, message } from 'antd'
import * as Tabs from '@radix-ui/react-tabs'
import { v4 as uuid } from 'uuid'
import Select from 'react-select'
import { useAuthInfo, AuthInfo } from '../../../contextProviders/useAuthInfo'
import { SWRResponse } from 'swr'
import { useDebounce } from 'react-use'
import dayjs from 'dayjs'
import { useResizeDetector } from 'react-resize-detector'
import { colorStyles } from '../../../components/Common/SelectColorStyles'
// TODO: replace with from lodash-es
import { useGenerateForecastButton } from '../../../components/Common/Methods/useGenerateForecastButtons'

import { asset_manager_theme } from '../../../assets/themes'
import { Row } from '../../../components/Common/Grid'
import Button from '../../../components/Common/Button'
import defaultAction from './defaultAction.json'
import { DownloadIcon } from '@radix-ui/react-icons'
import {
  LifeExtensionOptions,
  LifeExtensionTableData,
  LifeExtensionOptionsPlan,
  LifeExtensionOptionsRecs,
  TriageData,
} from '../RecommendationsInterface'
import {
  actionPlan,
  rolodex_batch_delete,
  useGetInspectionReports,
  archiveAssetRecs,
  useGetTriageUnitData,
} from '../../../api/useFDEBackend'
import { RolodexPatchBatch, RolodexPutBatch } from '../../../util/RolodexInterface'
import { AssetFixUrl, ForecastUrl } from '../../../api/defines'
import PlanTab from './LifeExtensionOptionInputTab'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { InspectionReportCard } from './InspectionCardComponent'
import { TriageLEOPlotContainer } from '../AssetRendering/TriageLEOPlotContainer'
import LifeExtensionPreviewPlot from '../AssetRendering/LifeExtensionPreviewPlot'
import { useGetDefaultSelectedActionKey } from '../../../util/DisplayLifeExtensionPlotHelpers/UseGetDefaultSelectedActionKey'
import { CloseButton } from '../../../components/Common/CloseButton'
import { SaveChangesButton } from './SaveChangesButton'
import {
  LEOInspectionReportsContainer,
  LEOTabContainer,
  LEOTabHeaderRow,
  LEOTableContainer,
} from './TriageLEOTabStyledComponents'
import { generateTriagePlanPdf } from '../../../api/useFDEBackend'
import { trackEvent } from '../../../components/helpers/mixpanel'
import { deleteRolodexEntry, getTaggedRolodexEntriesOfType } from '../../../api/rolodex'
import { RolodexLoadedEntry } from '../../../api/useRolodex'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { useRolodexEntries } from '../../../api/useRolodex'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import LifeExtensionOptionsTableRebased from './LifeExtensionOptionsTable'
import { TabKey } from '../../AppLayout'

const ApproveButton = styled.button`
  margin: 0rem 0.5rem 0rem 1rem;
  width: max-content;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  color: black;
  background-color: ${asset_manager_theme.background.high_vis};
  font-family: ${asset_manager_theme.text.font_family2};
  cursor: pointer;
  .ant-spin-dot-item {
    background-color: black;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
const EscalateButton = styled.button`
  margin: 0rem 0rem 0rem 1rem;
  width: max-content;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border-color: black;
  border-radius: 4px;
  border: 0.1rem solid ${asset_manager_theme.background.high_vis};
  font-family: ${asset_manager_theme.text.font_family2};
  background-color: ${asset_manager_theme.background.background_color8};
  color: ${asset_manager_theme.background.high_vis};
  cursor: pointer;
  // make button appear on the right side of the page
  .ant-spin-dot-item {
    background-color: ${asset_manager_theme.background.high_vis};
  }
  &:disabled {
    cursor: not-allowed;
  }
`

const AssetTitle = styled.h1`
  color: ${asset_manager_theme.text.c1};
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: center;
  vertical-align: text-top;
  margin-right: 0.1rem;
`

const StyledSubHeader = styled.h3`
  color: ${asset_manager_theme.text.c1};
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: center;
  vertical-align: text-top;
  margin: 0.4em 0.6em;
`

const TabsListExtended = styled(Tabs.List)`
  margin-left: 0rem;
  border-bottom: solid 1px ${asset_manager_theme.background.background_color6};
  width: 100%;
`

// Functional component
const LifeExtensionOptionsTab: React.FC<LifeExtensionOptionsTabProps> = ({
  fdeCustomerUnit,
  triageDataSWR,
  setSelectedFdeUnit,
}) => {
  const auth0 = useAuthInfo()
  const [selectedTab, setActivePlan] = useState<number>(-1)
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const [editedPlan, setEditedPlan] = useState<LifeExtensionOptionsPlan | undefined>(
    undefined
  )
  const [editedPlanSlug, setEditedPlanSlug] = useState<string | undefined>(undefined)
  const [recToImport, setRecToImport] = useState<
    { value: string | undefined; label: string | undefined } | undefined
  >(undefined)
  const [resetTabs, setResetTabs] = useState<boolean>(false)
  const triageUnitDataSWR = useGetTriageUnitData(
    auth0,
    fdeCustomerUnit,
    siteData?.siteSlug
  )
  const fde_customer_unit_batch = useRolodexEntries(
    {
      type: 'fde-am-batch',
      tags: {
        [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnit,
        site: siteData?.siteSlug,
      },
    },
    undefined,
    !!fdeCustomerUnit
  )

  const FDESiteData = {
    type: RolodexEntryType.PLANNING_CONFIG_TYPE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  }

  const FDESiteDataSWR = useRolodexEntries(FDESiteData)

  const currentAssetBatchId = fde_customer_unit_batch.data?.[0]?.entry?.data?.batch_id

  const lifeExtensionTableData = triageUnitDataSWR.data || {}
  const lifeExtensionOptions = lifeExtensionTableData.LifeExtensionOptions || []
  const lifeExtensionPlanLength = lifeExtensionOptions.length

  const [newPlanToggle, setNewPlanToggle] = useState<boolean>(false)
  const [selectedActionKey, setSelectedActionKey] =
    useGetDefaultSelectedActionKey(lifeExtensionTableData)

  const [checkedList, setCheckedList] = useState<string[]>([])

  const repairs = lifeExtensionTableData.repairs
  const activePlan: number =
    selectedTab >= lifeExtensionPlanLength ? lifeExtensionPlanLength - 1 : selectedTab
  const inspectionReports = useGetInspectionReports(
    auth0,
    lifeExtensionTableData.asset_slug
  )
  const lockOutEnabled = userFeatureFlags?.[TabKey.recommendations]?.read_only ?? false

  const refetch = () => {
    triageDataSWR.mutate()
    triageUnitDataSWR.mutate()
  }

  const deletePlanRec = (slug: string) => {
    trackEvent('Button Clicked', {
      buttonName: 'remove_action',
      pageName: 'Triage Recommendations',
      subPageName: 'Popover',
      assetDetails: {
        assetName: lifeExtensionTableData.asset_name,
        planName: lifeExtensionOptions?.[activePlan],
      },
    })

    if (editedPlan && editedPlan?.plan_recs?.[slug]) {
      const planToEdit = editedPlan
      delete planToEdit?.plan_recs?.[slug]
      setEditedPlan(planToEdit)
    }
    if (lifeExtensionOptions?.[activePlan]?.plan_recs?.[slug]) {
      rolodex_batch_delete(auth0, [slug]).then(() => {
        refetch()
      })
    }
  }

  const deleteBaseRec = useCallback(
    (slug: string) => {
      getTaggedRolodexEntriesOfType(
        slug,
        'fde-am-recommendation',
        'fde-am-plan-recommendation',
        auth0
      )
        .then((taggedPlanRecs: RolodexLoadedEntry[]) => {
          rolodex_batch_delete(
            auth0,
            taggedPlanRecs.map((planRec) => planRec.entry.id)
          ).then(() => {
            deleteRolodexEntry(slug, auth0).then(() => {
              refetch()
            })
          })
        })
        .catch((err) => {
          alert('Error deleting plan action: ' + err)
        })
    },
    [auth0]
  )

  const nullifyEdits = () => {
    setResetTabs(!resetTabs)
    setEditedPlan(undefined)
    setEditedPlanSlug(undefined)
    setRecToImport(undefined)
    setCheckedList([])
  }

  useEffect(() => {
    nullifyEdits()
  }, [fdeCustomerUnit, activePlan])

  const updatePlanData = (
    field: keyof LifeExtensionOptions,
    value: any,
    lifeExtensionSlug: Extract<keyof LifeExtensionOptionsRecs, string>
  ) => {
    let planToEdit = lifeExtensionOptions?.[activePlan]
    if (editedPlan) {
      planToEdit = editedPlan
    }
    // Don't mutate app state - it'll make you miss renders
    planToEdit = JSON.parse(JSON.stringify(planToEdit))

    if (planToEdit.plan_recs && planToEdit?.plan_recs?.[lifeExtensionSlug]) {
      planToEdit.plan_recs[lifeExtensionSlug] = {
        ...planToEdit.plan_recs[lifeExtensionSlug],
        [field]: value,
        edited: {
          repair_cost:
            field === 'repair_cost'
              ? true
              : planToEdit.plan_recs[lifeExtensionSlug]?.edited?.repair_cost || false,
          scheduled_date:
            field === 'scheduled_date'
              ? true
              : planToEdit.plan_recs[lifeExtensionSlug]?.edited?.scheduled_date || false,
        },
      }

      setEditedPlanSlug(planToEdit.plan_slug)
      setEditedPlan(planToEdit)
    }
  }

  const updatePlanName = (value: string) => {
    let planToEdit = lifeExtensionOptions?.[activePlan]
    if (editedPlan) {
      planToEdit = editedPlan
    }

    // Don't mutate app state - it'll make you miss renders
    planToEdit = JSON.parse(JSON.stringify(planToEdit))

    if (planToEdit) {
      planToEdit.plan_name = String(value)
      setEditedPlanSlug(planToEdit.plan_slug)
      setEditedPlan(planToEdit)
    }
  }
  const priorityMap = {
    Low: 12,
    Medium: 6,
    High: 3,
    Top: 1,
  }

  const importActionToPlan = (repairSlug: string | undefined) => {
    setRecToImport(undefined)
    if (repairSlug) {
      const newRec = repairs && { ...repairs[repairSlug] }
      if (newRec) {
        newRec.rec_slug = repairSlug
        newRec.fde_customer_unit = lifeExtensionTableData.fde_customer_unit || ''
        newRec.edited = {
          repair_cost: false,
          scheduled_date: false,
        }
        //add deadline based on priority
        if (
          newRec.priority &&
          !newRec.scheduled_date &&
          priorityMap[newRec.priority as keyof typeof priorityMap]
        ) {
          newRec.scheduled_date = dayjs()
            .add(priorityMap[newRec.priority as keyof typeof priorityMap], 'month')
            .format('YYYY-MM')
        }
        addNewActionToPlan(newRec, uuid())
      }
    }
  }

  const addNewActionToPlan = (
    newRec: LifeExtensionOptions,
    recSlug: string,
    createNewAction?: boolean
  ) => {
    let planToEdit = lifeExtensionOptions?.[activePlan]
    const planSlug = planToEdit.plan_slug
    if (editedPlan) {
      planToEdit = editedPlan
    }

    // Don't mutate app state - it'll make you miss renders
    //need to deep copy here to not modfiy the table data so unsaved changes aren't shown as legit data

    if (planToEdit && lifeExtensionOptions) {
      newRec.slug = planToEdit.slug || newRec.slug || ''
      newRec.fde_customer_unit = lifeExtensionTableData.fde_customer_unit || ''
      newRec.created_timestamp = dayjs().valueOf()
      const patchBody = {
        id: recSlug,
        type: RolodexEntryType.PLAN_REC,
        data: newRec,
        tags: {
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
          [RolodexEntryType.PLAN]: planSlug,
          [RolodexEntryType.RECOMMENDATION]: newRec.rec_slug,
        },
      }
      const patchList: any[] = [patchBody]
      if (createNewAction) {
        //create an action when doing this as well
        const newRecPatchBody = {
          id: newRec.rec_slug,
          type: RolodexEntryType.RECOMMENDATION,
          data: {
            ...newRec,
            batch_id: currentAssetBatchId,
          },
          tags: {
            site: siteData?.siteSlug,
            organization: siteData?.orgSlug,
            [RolodexEntryType.CUSTOMER_UNIT]: lifeExtensionTableData.fde_customer_unit,
          },
        }
        patchList.push(newRecPatchBody)
      }

      RolodexPatchBatch(patchList, auth0, siteData).then(() => {
        refetch()
      })
    }
  }

  const handleStateChange = (
    auth0: AuthInfo,
    plan_slug: string | undefined,
    action: string
  ) => {
    // add a status field to plan via patch batch
    // make api call to action_plan to change status to approved
    // only make api call if plan slug and action are defined
    if (plan_slug && action) {
      actionPlan(auth0, plan_slug, action).then(() => {
        refetch()
      })
    }
  }

  useDebounce(
    () => {
      if (newPlanToggle) {
        createNewPlan()
        setNewPlanToggle(false)
      }
    },
    300,
    [newPlanToggle]
  )

  const createNewPlan = () => {
    //todo make this dynamic on site and org
    let tags = {}
    if (lifeExtensionOptions) {
      tags = {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
        [RolodexEntryType.CUSTOMER_UNIT]: lifeExtensionTableData.fde_customer_unit,
        ...(!!lifeExtensionTableData.asset_slug // don't include component in tags if .asset_slug does is empty
          ? { component: lifeExtensionTableData.asset_slug }
          : {}),
      }
      const planSlug = uuid()

      const newPlan = {
        plan_name: `Plan${lifeExtensionOptions?.length + 1 || 1}`,
        created_timestamp: dayjs().valueOf(),
        asset_name: lifeExtensionTableData.asset_name || '',
        plan_slug: planSlug,
        action_taken: lifeExtensionOptions?.[0]?.action_taken || 'Needs Review',
        fde_customer_unit: lifeExtensionTableData?.fde_customer_unit || '',
        batch_id: currentAssetBatchId || 1,
        ...(lifeExtensionTableData.asset_slug
          ? { slug: lifeExtensionTableData.asset_slug }
          : {}),
      }
      lifeExtensionOptions.push(newPlan)
      lifeExtensionTableData.LifeExtensionOptions = lifeExtensionOptions
      nullifyEdits()
      setActivePlan(lifeExtensionOptions.length - 1)
      RolodexPutBatch([planSlug], newPlan, auth0, 'fde-am-plan', tags).then(() => {
        refetch()
      })
    }
  }

  const deletePlanClick = (auth0: AuthInfo) => {
    const plan_slug = lifeExtensionOptions?.[activePlan].plan_slug
    const plan_recs = lifeExtensionOptions?.[activePlan]?.plan_recs

    if (plan_slug) {
      const newPlans = lifeExtensionOptions.filter((d) => d.plan_slug !== plan_slug)
      const lifeExtensionTableCopy = { ...lifeExtensionTableData }
      lifeExtensionTableCopy.LifeExtensionOptions = newPlans
      setActivePlan(-1)
      let deleteArray = [plan_slug]
      if (typeof plan_recs === 'object') {
        deleteArray = [plan_slug, ...Object.keys(plan_recs)]
      }
      rolodex_batch_delete(auth0, deleteArray).then(() => {
        refetch()
      })
    }
  }
  const generateApprovedButton = () => {
    if (triageDataSWR.isLoading || triageDataSWR.isValidating) {
      return (
        <ApproveButton style={{ width: '6rem' }}>
          <Spin style={{ marginBottom: '0.3rem' }} />
        </ApproveButton>
      )
    }
    const approveButton = (
      <Tooltip title='Approve this plan'>
        <ApproveButton
          disabled={lockOutEnabled} // CheckLockout
          onClick={() => {
            handleStateChange(
              auth0,
              lifeExtensionOptions?.[activePlan]?.plan_slug,
              'Approved'
            )
            trackEvent('Button Clicked', {
              pageName: 'Triage Recommendations',
              subPageName: 'Popover',
              assetDetails: {
                planName: lifeExtensionOptions?.[activePlan],
                planSlug: lifeExtensionOptions?.[activePlan]?.plan_slug,
                assetName: lifeExtensionTableData.asset_name,
              },
              buttonName: 'approve_plan',
            })
          }}>
          {'Approve'}
        </ApproveButton>
      </Tooltip>
    )
    const disabledApproveButton = (
      <ApproveButton disabled={true} style={{ opacity: 0.5 }}>
        {'Approve'}
      </ApproveButton>
    )
    const unapproveButton = (
      <ApproveButton
        disabled={lockOutEnabled} // CheckLockout
        onClick={() => {
          handleStateChange(
            auth0,
            lifeExtensionOptions?.[activePlan]?.plan_slug,
            'Needs Review'
          )
          trackEvent('Button Clicked', {
            pageName: 'Triage Recommendations',
            subPageName: 'Popover',
            assetDetails: {
              planName: lifeExtensionOptions?.[activePlan],
              planSlug: lifeExtensionOptions?.[activePlan]?.plan_slug,
              assetName: lifeExtensionTableData.asset_name,
            },
            buttonName: 'unapprove_plan',
          })
        }}>
        {'Undo Approve'}
      </ApproveButton>
    )
    const disabledUnapproveButton = (
      <ApproveButton disabled={true} style={{ opacity: 0.5 }}>
        {'Undo Approve'}
      </ApproveButton>
    )
    if (lifeExtensionOptions.length < 1) {
      //disabled when no plans
      return disabledApproveButton
    }
    if (lifeExtensionTableData.asset_status === 'Needs Review') {
      if (activePlan === -1) {
        return disabledApproveButton
      } else {
        return approveButton
      }
    }
    if (lifeExtensionTableData.asset_status === 'Approved') {
      if (activePlan === -1) {
        return disabledUnapproveButton
      }
      if (lifeExtensionOptions?.[activePlan]?.action_taken === 'Approved') {
        //only unapprove on the approved plan
        return unapproveButton
      }
      return disabledUnapproveButton
    }
    return disabledApproveButton
  }

  const generateEscalateButton = () => {
    if (triageDataSWR.isLoading || triageDataSWR.isValidating) {
      return (
        <EscalateButton style={{ width: '6rem' }}>
          <Spin style={{ marginBottom: '0.3rem' }} />
        </EscalateButton>
      )
    }
    const escalateButton = (
      <Tooltip title='Save all plans for this asset'>
        <EscalateButton
          onClick={() => {
            handleStateChange(auth0, lifeExtensionOptions?.[0]?.plan_slug, 'Escalated')
            trackEvent('Button Clicked', {
              pageName: 'Triage Recommendations',
              subPageName: 'Popover',
              assetDetails: {
                assetName: lifeExtensionTableData.asset_name,
              },
              buttonName: 'escalate_plans',
            })
          }}>
          {'Approve'}
        </EscalateButton>
      </Tooltip>
    )
    const disabledEscalateButton = (
      <EscalateButton disabled={true} style={{ opacity: 0.5 }}>
        {'Approve'}
      </EscalateButton>
    )
    const unescalateButton = (
      <EscalateButton
        onClick={() => {
          handleStateChange(auth0, lifeExtensionOptions?.[0]?.plan_slug, 'Needs Review')
          trackEvent('Button Clicked', {
            pageName: 'Triage Recommendations',
            subPageName: 'Popover',
            assetDetails: {
              assetName: lifeExtensionTableData.asset_name,
            },
            buttonName: 'unescalate_plans',
          })
        }}>
        {'Undo Approve'}
      </EscalateButton>
    )

    if (
      lifeExtensionOptions.length < 1 ||
      lifeExtensionOptions.some((d) => {
        const planRecs = d.plan_recs
        if (!planRecs || Object.keys(planRecs).length < 1) {
          return true
        }
        return false
      })
    ) {
      return disabledEscalateButton
    }
    if (lifeExtensionTableData.asset_status === 'Needs Review') {
      return escalateButton
    }
    if (lifeExtensionTableData.asset_status === 'Escalated') {
      return unescalateButton
    }

    return disabledEscalateButton
  }

  const displayInspectionReports = !!inspectionReports?.data?.length

  const {
    width: previewPlotContainerWidth,
    height: previewPlotContainerHeight,
    ref: previewPlotContainerRef,
  } = useResizeDetector()

  //create set of current repairs in a plan, used to filter out repairs that are already in a plan
  const activeLifeExtensionOptionsSet = new Set(
    Object.keys(lifeExtensionOptions?.[activePlan]?.plan_recs || {}).map(
      (d) => lifeExtensionOptions?.[activePlan]?.plan_recs?.[d]?.recommendation
    )
  )

  const handleGenerateReport = (planRecs: string[]) => {
    generateTriagePlanPdf(
      auth0,
      siteData?.siteSlug,
      lifeExtensionTableData?.fde_customer_unit,
      lifeExtensionOptions?.[activePlan]?.plan_slug || 'allActions',
      planRecs
    )
  }

  const handleArchiveMessage = (fde_customer_unit_slug: string, assetName?: string) => {
    const messageContent = `Successfully archived ${
      assetName || ''
    }. Click this message to undo this action`

    message.success({
      content: messageContent,
      key: fde_customer_unit_slug,
      duration: 10,
      onClick: () => {
        archiveAssetRecs(
          auth0,
          siteData?.siteSlug || '',
          fde_customer_unit_slug,
          true
        ).then(() => {
          refetch()
        })
        message.destroy(fde_customer_unit_slug)
      },
    })
  }

  /**
   * Height Breakdown is:
   * Header -- 2 em
   * Preview Plot -- 30%
   * Action Options Display -- 40% + 2.5rem + ??
   *    - Action-Items Header -- set rem??
   *    - Action-Items Table -- 40%
   *    - Action-Items Tail -- min 2.5rem
   *  Inspection Report Display -- rest
   *    - Header -- 0.4em
   *    - List -- rest (computed)
   */
  return (
    <LEOTabContainer>
      {/* ↓↓↓ Header ↓↓↓ */}
      <LEOTabHeaderRow>
        <AssetTitle
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '2em',
            whiteSpace: 'nowrap',
            maxWidth: '20em',
            paddingTop:
              lifeExtensionTableData?.asset_name?.length &&
              lifeExtensionTableData?.asset_name?.length > 30
                ? '0.1em'
                : '0',

            fontSize:
              lifeExtensionTableData?.asset_name?.length &&
              lifeExtensionTableData?.asset_name?.length > 30
                ? '1.6em'
                : '2em',
          }}>
          {lifeExtensionTableData.asset_name}
        </AssetTitle>
        {useGenerateForecastButton(fdeCustomerUnit)}
        {fdeCustomerUnit && siteData?.siteSlug && (
          <Tooltip
            title={
              lifeExtensionTableData?.asset_status !== 'Needs Review'
                ? 'Unable to Archive while plans are approved'
                : 'Dismiss this set of repair plans'
            }>
            <Button
              style={{ marginLeft: 'auto' }}
              disabled={
                lockOutEnabled || lifeExtensionTableData?.asset_status !== 'Needs Review'
              } // CheckLockout
              onClick={() => {
                archiveAssetRecs(auth0, siteData?.siteSlug, fdeCustomerUnit).then(() => {
                  refetch()
                  handleArchiveMessage(
                    fdeCustomerUnit,
                    lifeExtensionTableData?.asset_name
                  )
                  setSelectedFdeUnit(undefined)
                })
                trackEvent('Button Clicked', {
                  buttonName: 'archive',
                  pageName: 'Triage Recommendations',
                  subPageName: 'Popover',
                  assetDetails: {
                    assetName: lifeExtensionTableData.asset_name,
                  },
                })
              }}>
              {'Archive'}
            </Button>
          </Tooltip>
        )}
        {generateEscalateButton()}
        {/* {generateApprovedButton()} */}
        <CloseButton
          onClick={() => {
            setSelectedFdeUnit(undefined)
          }}
        />
      </LEOTabHeaderRow>
      {/* ↑↑↑ Header ↑↑↑ */}

      {/* ↓↓↓ Preview Plot ↓↓↓ */}
      {/* {lifeExtensionTableData?.asset_slug &&
        (userFeatureFlags?.[TabKey.recommendations]?.enablePreviewPlot ||
          userFeatureFlags?.superUser) && (
          <TriageLEOPlotContainer ref={previewPlotContainerRef}>
            <LifeExtensionPreviewPlot
              componentSlug={lifeExtensionTableData.asset_slug}
              selectedAssetFixRepair={
                selectedActionKey
                  ? activePlan === -1
                    ? lifeExtensionTableData.repairs?.[selectedActionKey]?.af_repair_plan
                    : lifeExtensionTableData?.LifeExtensionOptions?.[activePlan]
                        .plan_recs?.[selectedActionKey]?.af_repair_plan
                  : ''
              }
              selectedAssetFixRepairSubgroup={
                selectedActionKey
                  ? activePlan === -1
                    ? lifeExtensionTableData.repairs?.[selectedActionKey]
                        ?.af_repair_plan_subgroup
                    : lifeExtensionTableData?.LifeExtensionOptions?.[activePlan]
                        .plan_recs?.[selectedActionKey]?.af_repair_plan_subgroup
                  : ''
              }
              width={previewPlotContainerWidth}
              height={previewPlotContainerHeight}
            />
          </TriageLEOPlotContainer>
        )} */}

      {/* ↑↑↑ Preview Plot ↑↑↑ */}

      {/* ↓↓↓ Action Options Display ↓↓↓ */}
      {/* ↓↓ Action-Items Header ↓↓ */}
      <LEOTabHeaderRow>
        <Tabs.Root style={{ width: '100%' }}>
          <TabsListExtended>
            {[
              { plan_slug: 'random Plan Slug', plan_name: 'All Actions' },
              ...(lifeExtensionOptions || []).sort((a, b) => {
                if (a.created_timestamp && b.created_timestamp) {
                  return a.created_timestamp - b.created_timestamp
                }
                return 0
              }),
            ]?.map((d, index) => {
              return (
                <PlanTab
                  key={d.plan_slug}
                  plan_name={d.plan_name || `Plan${index}`}
                  plan_slug={d.plan_slug}
                  activePlan={activePlan}
                  resetTabs={resetTabs}
                  index={index}
                  nullifyEdits={nullifyEdits}
                  setActivePlan={setActivePlan}
                  updatePlanName={updatePlanName}
                  refetch={refetch}
                  isApprovedPlan={
                    lifeExtensionOptions?.[index - 1]?.action_taken === 'Approved'
                  }
                />
              )
            })}
            <button
              disabled={lockOutEnabled} // CheckLockout
              onClick={() => {
                setNewPlanToggle(true)
                trackEvent('Button Clicked', {
                  buttonName: 'create_new_plan',
                  pageName: 'Triage Recommendations',
                  subPageName: 'Popover',
                  assetDetails: {
                    assetName: lifeExtensionTableData.asset_name,
                  },
                })
              }}
              style={{
                border: 'none',
                background: 'none',
                color: 'white',
                cursor: 'pointer',
              }}>
              +
            </button>
          </TabsListExtended>
        </Tabs.Root>
      </LEOTabHeaderRow>
      {/* ↑↑ Action-Items Header ↑↑ */}
      {/* ↓↓ Action-Items Table ↓↓ */}
      <LEOTableContainer>
        <LifeExtensionOptionsTableRebased
          lifeExtensionOptions={
            activePlan === -1 ? repairs : lifeExtensionOptions?.[activePlan].plan_recs
          }
          isAllActions={activePlan === -1}
          setCheckedList={setCheckedList}
          refetch={refetch}
        />
      </LEOTableContainer>
      {/* ↑↑ Action-Items Table ↑↑ */}

      {/* ↓↓ Action-Items Tail ↓↓ */}
      <LEOTabHeaderRow
        style={{
          flexDirection: activePlan !== -1 ? 'row' : 'row-reverse',
          justifyContent: 'space-between',
        }}>
        {activePlan !== -1 && (
          <>
            <div style={{ width: '30%', margin: '0 0.5em 0 0.5em' }}>
              <Select
                onChange={(newValue) => {
                  setRecToImport({ label: newValue?.label, value: newValue?.value })
                }}
                key={`${
                  activePlan === -1
                    ? 'repairs'
                    : lifeExtensionOptions?.[activePlan].plan_slug
                }_${lifeExtensionTableData.asset_name}`}
                options={
                  repairs &&
                  Object.keys(repairs)
                    .filter((d) => {
                      return !activeLifeExtensionOptionsSet.has(repairs[d].recommendation)
                    })
                    .map((d) => {
                      return { label: repairs[d].recommendation, value: d }
                    })
                }
                placeholder={<div>+ Select action to import </div>}
                styles={colorStyles}
              />
            </div>
            <Button
              disabled={!recToImport}
              onClick={() => {
                trackEvent('Button Clicked', {
                  buttonName: 'import_action',
                  pageName: 'Triage Recommendations',
                  subPageName: 'Popover',
                  assetDetails: {
                    assetName: lifeExtensionTableData.asset_name,
                    planName: lifeExtensionOptions?.[activePlan],
                  },
                })
                importActionToPlan(recToImport?.value || undefined)
              }}
              style={{
                margin: '0 0 0 1%',
              }}>
              Import
            </Button>
            <Button
              style={{ width: '180px', fontSize: '14px' }}
              onClick={() => {
                trackEvent('Button Clicked', {
                  buttonName: 'create_new_action',
                  pageName: 'Triage Recommendations',
                  subPageName: 'Popover',
                  assetDetails: {
                    assetName: lifeExtensionTableData.asset_name,
                    planName: lifeExtensionOptions?.[activePlan],
                  },
                })
                defaultAction.rec_slug = uuid()
                defaultAction.fde_customer_unit =
                  lifeExtensionTableData.fde_customer_unit ?? ''
                defaultAction.asset_name = lifeExtensionTableData.asset_name ?? ''
                addNewActionToPlan(defaultAction, uuid(), true) //The two uuids here are different, when creating a custom rec both a rec and a plan rec need to be created with seperate uuids
              }}>
              + Create new action
            </Button>
            <Button
              style={{ width: '120px', fontSize: '14px' }}
              onClick={() => {
                deletePlanClick(auth0)
                trackEvent('Button Clicked', {
                  buttonName: 'delete_plan',
                  pageName: 'Triage Recommendations',
                  subPageName: 'Popover',
                  assetDetails: {
                    assetName: lifeExtensionTableData.asset_name,
                    planName: lifeExtensionOptions?.[activePlan],
                  },
                })
              }}>
              Delete Plan
            </Button>
            {/* <SaveChangesButton
              editedPlan={editedPlan}
              editedPlanSlug={editedPlanSlug}
              nullifyEdits={nullifyEdits}
              lifeExtensionTableData={lifeExtensionTableData}
              refetch={refetch}
              FDESiteDataSWR={FDESiteDataSWR}
            /> */}
          </>
        )}

        <Button
          style={{ display: 'inline-flex', alignItems: 'center', fontSize: '14px' }}
          disabled={checkedList.length === 0}
          onClick={() => handleGenerateReport(checkedList)}>
          <DownloadIcon />
          <span style={{ marginLeft: 8 }}>Generate PDF Report</span>
        </Button>
      </LEOTabHeaderRow>

      {/* ↑↑ Action-Items Tail ↑↑ */}
      {/* ↑↑↑ Action Options Display ↑↑↑ */}

      {/* ↓↓↓ Inspection Reports ↓↓↓ */}
      {displayInspectionReports && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 10%',
            overflowY: 'auto',
          }}>
          <LEOTabHeaderRow>
            <StyledSubHeader>
              Inspection Report
              {inspectionReports.data && inspectionReports.data.length > 1 && 's'}
            </StyledSubHeader>
          </LEOTabHeaderRow>
          <LEOInspectionReportsContainer>
            {inspectionReports.data &&
              inspectionReports.data.map((d) => {
                return (
                  <Row key={d.path} style={{ marginBottom: '0.7rem' }}>
                    <InspectionReportCard report={d} />
                  </Row>
                )
              })}
          </LEOInspectionReportsContainer>
        </div>
      )}
      {/* ↑↑↑ Inspection Reports ↑↑↑ */}
    </LEOTabContainer>
  )
}

interface LifeExtensionOptionsTabProps {
  fdeCustomerUnit?: string
  triageDataSWR: SWRResponse<TriageData, any>
  setSelectedFdeUnit: (fdeUnit?: string) => void
}

export default LifeExtensionOptionsTab
