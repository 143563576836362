import { FC, ReactNode } from 'react'
import { Button, Checkbox, ConfigProvider, InputNumber, Space } from 'antd'
import styled from 'styled-components'
import { asset_manager_theme } from '../../../assets/themes'

export interface EncapsulatedValue<T extends string | number | boolean> {
  value: T
  setValue: (value: T) => void
}
export function createEncap<T extends string | number | boolean>(
  value: T,
  setValue: (value: T) => void
): EncapsulatedValue<T> {
  return { value, setValue }
}

const LabelledField = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  color: ${asset_manager_theme.text.c3};
`

export interface ComparisonViewControlsProps {
  showReplacementCostTrace?: EncapsulatedValue<boolean>
  replacementCost?: EncapsulatedValue<number>
  usefulLifeYrs?: EncapsulatedValue<number>
  reductionFactor?: EncapsulatedValue<number>
}

const inputFieldStyle = { width: 200 }

function createNumberInput(
  field: EncapsulatedValue<number>,
  disabled: boolean,
  changeStep?: number
): ReactNode {
  return (
    <InputNumber
      value={field.value}
      onChange={(val) => field.setValue(val ?? 0)}
      disabled={disabled}
      size='large'
      style={inputFieldStyle}
      step={changeStep ?? 1}
      // It's a bit sad, but this is the only way I could make
      // the formats apply to the in-field buttons
      controls={{
        upIcon: (
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: 'transparent',
                },
              },
            }}>
            <Button>{'\u2191'}</Button>
          </ConfigProvider>
        ),
        downIcon: (
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: 'transparent',
                },
              },
            }}>
            <Button>{'\u2193'}</Button>
          </ConfigProvider>
        ),
      }}
    />
  )
}

export const ComparisonViewControls: FC<ComparisonViewControlsProps> = (props) => {
  const disabled =
    !!props.showReplacementCostTrace && !props.showReplacementCostTrace.value
  return (
    <Space direction='vertical'>
      {props.showReplacementCostTrace && (
        <Checkbox
          checked={props.showReplacementCostTrace.value}
          onChange={(e) => {
            props.showReplacementCostTrace?.setValue(e.target.checked)
          }}>
          Show Replacement Cost
        </Checkbox>
      )}
      {props.replacementCost && (
        <LabelledField>
          <Label>Replacement Cost ($)</Label>
          {createNumberInput(props.replacementCost, disabled, 100000)}
        </LabelledField>
      )}
      {props.usefulLifeYrs && (
        <LabelledField>
          <Label>Useful Life (Yrs)</Label>
          {createNumberInput(props.usefulLifeYrs, disabled)}
        </LabelledField>
      )}
      {props.reductionFactor && (
        <LabelledField>
          <Label>Reduction Factor</Label>
          {createNumberInput(props.reductionFactor, disabled)}
        </LabelledField>
      )}
    </Space>
  )
}
