import {
  AssetDBColumnType,
  ColumnConfig,
  FilterCriteria,
} from './AssetDatabaseTabInterface'

export const defaultColumnsConfig: ColumnConfig[] = [
  {
    key: 'id',
    label: 'Entry Id',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 0,
    filterCriteria: FilterCriteria.SEARCH,
    width: 300,
  },
  {
    label: 'Equpment Area',
    key: 'equip_area',
    columnType: AssetDBColumnType.STRING,
    isRequired: true,
    sortOrder: 1,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Area Description',
    key: 'area_description',
    columnType: AssetDBColumnType.TEXT,
    sortOrder: 2,
    filterCriteria: FilterCriteria.SEARCH,
  },
  {
    label: 'Legacy Equip ID',
    key: 'equip_id',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 3,
    filterCriteria: FilterCriteria.SEARCH,
  },
  {
    key: 'erp_id',
    sortOrder: 4,
    columnType: AssetDBColumnType.STRING,
    filterCriteria: FilterCriteria.SEARCH,
  },
  {
    label: 'National Board',
    key: 'national_board',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 5,
    filterCriteria: FilterCriteria.SEARCH,
  },
  {
    label: 'Outage',
    key: 'outage_type',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 6,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Risk Category',
    key: 'risk_category',
    columnType: AssetDBColumnType.STRING,
    isRequired: true,
    sortOrder: 7,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Type',
    key: 'type',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 8,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Year Built',
    key: 'year_built',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 9,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Orientation',
    key: 'orientation',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 10,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Dimensions - Height (ft)',
    key: 'dimensions.height_ft',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 11,
    filterCriteria: FilterCriteria.RANGE,
  },
  {
    label: 'Dimensions - Diameter (ft)',
    key: 'dimensions.diameter_ft',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 12,
    filterCriteria: FilterCriteria.RANGE,
  },
  {
    label: 'Material',
    key: 'material',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 13,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Sub Material',
    key: 'sub_material',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 14,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Ruggable',
    key: 'ruggable',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 15,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'RUG Available',
    key: 'is_rug_available',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 16,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Temp',
    key: 'temp',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 17,
    filterCriteria: FilterCriteria.RANGE,
  },
  {
    label: 'Insulated',
    key: 'insulated',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 18,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Contents',
    key: 'contents',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 19,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'pH',
    key: 'pH',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 20,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Pressure',
    key: 'pressure',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 21,
  },
  {
    label: 'Stiffeners',
    key: 'stiffeners',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 22,
    filterCriteria: FilterCriteria.CATEGORY,
  },
  {
    label: 'Pending Repairs',
    key: 'pending_repairs',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 23,
  },
  {
    label: 'Maintenance',
    key: 'maintenance',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 24,
  },
  {
    label: 'Inspection Written',
    key: 'inspection_written',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 25,
  },
  {
    label: 'Customer Corrosion Rate',
    key: 'customer_corrosion_rate',
    columnType: AssetDBColumnType.STRING,
    sortOrder: 26,
  },
  {
    label: 'Customer Remaining Life',
    key: 'customer_remaining_life',
    columnType: AssetDBColumnType.NUMBER,
    sortOrder: 27,
  },
  {
    label: 'Comments',
    key: 'comments',
    columnType: AssetDBColumnType.TEXT,
    width: 200,

    sortOrder: 29,
    filterCriteria: FilterCriteria.SEARCH,
  },
  {
    label: 'Decommissioned',
    key: 'decommissioned',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 30,
    filterCriteria: FilterCriteria.CATEGORY,
    defaultFilterValue: ['False'],
  },
  {
    label: 'Onstream Exception',
    key: 'onstream_exception',
    columnType: AssetDBColumnType.BOOLEAN,
    sortOrder: 31,
    filterCriteria: FilterCriteria.CATEGORY,
  },
]
