import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import { useAuthInfo } from '../contextProviders/useAuthInfo'
import { EmptyStateBanner, MainHeading, SubHeading } from './Common/EmptyStateBanner'
import {
  TwoColumnDivContainer,
  FirstColumn,
  SecondColumn,
} from './Common/LayoutContainer'
import { useSiteData } from '../pages/useSiteData'

/**
 *  This page handles when an invalid / no site slug is passed in the URL.
 *  If the user has WestRock org. permissions, they will be routed to the Hodge site page.
 *  TODO: Update this such that if a user has org level permissions, take them to a landing page to choose a site
 *  TODO: When site level permissions are implemented, update this to use that to route to a specific site (instead of via org map).
 */
const InvalidSiteComponent = () => {
  const authInfo = useAuthInfo()
  const navigate = useNavigate()
  const location = useLocation()
  const siteData = useSiteData({ siteID: undefined })

  /**
   *  If a user logs in with WestRock permissions, route them to the Hodge site -- this is a temporary solution
   *
   *  This is in a useEffect so that it doesn't mess with the React render loop (we don't want to call `navigate()`, which
   *  augments state in the <Route> component, during this component's render)
   */
  useEffect(() => {
    const locationPath = location.pathname.split('/').filter((d) => d)
    if (authInfo.permissionTags?.['site'] && locationPath.length < 2) {
      navigate(`/${authInfo.permissionTags?.['site']}`)
    }
    if (siteData.sites.length > 0) {
      navigate(`/${siteData.sites[0].id}`)
    }
  }, [authInfo])

  return (
    <TwoColumnDivContainer>
      <FirstColumn>
        <div></div>
      </FirstColumn>
      <SecondColumn>
        {' '}
        <EmptyStateBanner>
          <div style={{ paddingBottom: '9rem' }}>
            <MainHeading>Invalid Site Slug</MainHeading>
            <SubHeading>Please add a valid site slug</SubHeading>
          </div>
        </EmptyStateBanner>
      </SecondColumn>
    </TwoColumnDivContainer>
  )
}

// ---------------------------------------------------------------------------------------------------------------------

/**
 * This is a mapping of organization permissions to site slugs.  This is used to route users to the correct site when
 * they open Asset-Manager without a defined site slug.
 * TODO: When site level permissions are implemented, delete this.
 * TODO: Create a landing page for if a user has org level permissions -- currently we only have 1 site customer, so
 *  that org will be routed to the site; however, once we scale this and an org has multiple sites, we'll need to
 *  account for that
 */
const ORGANIZATION_PERMISSIONS_ROUTING_MAP: Record<string, string> = {
  'westrock-53ce5f': 'westrock-hodge-pulp-mill-b08914',
  'georgia-pacific-a1ec53': 'brewton-mill-fb51be',
  'rio-tinto-plc-corporate-9ce524': 'rio-tinto-kennecott-utah-copper-0e112c',
}

// =====================================================================================================================

export default InvalidSiteComponent
