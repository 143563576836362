import React, { useEffect } from 'react'
import { Input, Modal } from 'antd'
import { TreeNode } from '../Types/FileTreeTypes'
import { addNodeToTreeData } from '../Helpers/FileTreeHelpers'

interface CreateDirectoryModalProps {
  isCreateFolderModalVisible: boolean
  setIsCreateFolderModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  newFolderName: string
  setNewFolderName: React.Dispatch<React.SetStateAction<string>>
  currentParentKey: string | null
  setCurrentParentKey: React.Dispatch<React.SetStateAction<string | null>>
  treeData: TreeNode[]
  setTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>
}

export const CreateDirectoryModal: React.FC<CreateDirectoryModalProps> = ({
  isCreateFolderModalVisible,
  setIsCreateFolderModalVisible,
  newFolderName,
  setNewFolderName,
  currentParentKey,
  setCurrentParentKey,
  treeData,
  setTreeData,
}) => {
  const [showInvalidCharacterModal, setShowInvalidCharacterModal] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    if (newFolderName) {
      createFolderAndResetStates()
    }
  }, [newFolderName]) // useEffect triggers when newFolderName changes

  const createFolderAndResetStates = () => {
    const newNode: TreeNode = {
      title: newFolderName,
      key: `${currentParentKey ? currentParentKey + '/' : ''}${newFolderName}`,
      children: [],
    }
    setTreeData(addNodeToTreeData(treeData, currentParentKey as string, newNode))
    // Continue to reset other state values
    setInputValue('')
    setCurrentParentKey(null)
    setIsCreateFolderModalVisible(false)
  }

  const handleInvalidCharOk = () => {
    const validFolderName = inputValue.replace(/\//g, '')
    setInputValue(validFolderName)
    setNewFolderName(validFolderName)
    setShowInvalidCharacterModal(false)
  }

  const handleOk = () => {
    if (inputValue.includes('/')) {
      setShowInvalidCharacterModal(true)
    } else {
      setNewFolderName(inputValue) // State update here
    }
  }

  return (
    <>
      <Modal
        title='Create Directory'
        open={isCreateFolderModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setInputValue('')
          setNewFolderName('')
          setCurrentParentKey(null)
          setIsCreateFolderModalVisible(false)
        }}>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder='Enter folder name'
        />
      </Modal>
      <Modal
        title='Invalid Character'
        open={showInvalidCharacterModal}
        onOk={handleInvalidCharOk}
        onCancel={() => setShowInvalidCharacterModal(false)}>
        <p>
          Invalid character '/' found in the folder name. Would you like to remove it and
          proceed?
        </p>
      </Modal>
    </>
  )
}
