import React, { useEffect } from 'react'
import { Form, FormInstance, Input, InputNumber } from 'antd'
import styled from 'styled-components'
import { asset_manager_theme } from '../../../assets/themes'

export type BudgetState = {
  budgetLine: number
}

interface BudgetFormProps {
  form: FormInstance<BudgetState>
  budgetState: BudgetState
  handleSave: (budgetValue: number) => void
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledInputNumber = styled(InputNumber)`
  border: none;
  background-color: ${asset_manager_theme.background.background_color6_5};
`

const BudgetForm: React.FC<BudgetFormProps> = ({
  form,
  budgetState,
  handleSave,
  setIsModalVisible,
}) => {
  useEffect(() => {
    if (budgetState.budgetLine !== -1) {
      form.setFieldsValue(budgetState)
    }
  }, [form, budgetState])
  return (
    <Form
      form={form}
      preserve={false}
      onFinish={(value) => {
        handleSave(value.budgetLine)
        setIsModalVisible(false)
      }}
      style={{ width: '100%' }}>
      <Form.Item label='Annual Budget ($):' name='budgetLine'>
        <StyledInputNumber />
      </Form.Item>
    </Form>
  )
}

export default BudgetForm
