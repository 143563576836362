import React from 'react'
import { Column, Row } from '../Grid'

interface InlineTileProps {
  children: React.ReactNode
  title?: string
  background?: string
  border?: string
  padding?: string
  titleStyle?: React.CSSProperties
  titleRowChildren?: React.ReactNode
}

const InlineTile: React.FC<InlineTileProps> = ({
  children,
  title,
  background,
  border,
  padding,
  titleStyle,
  titleRowChildren,
}) => {
  return (
    <Column
      className='inline-tile'
      style={{
        backgroundColor: background || 'none',
        ...(border ? { border: `1px solid ${border}` } : {}),
        width: '100%',
        padding: padding || '1.5rem',
        borderRadius: '6px',
      }}>
      {title && (
        <Row style={titleStyle}>
          <Column>
            <h4 style={{ marginBottom: '.4rem', ...(titleStyle || {}) }}>{title}</h4>
          </Column>
          {titleRowChildren}
        </Row>
      )}
      {children}
    </Column>
  )
}

export default InlineTile
