import React, { useEffect, useContext, useState } from 'react'
import { DatePicker } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useDebounce } from 'react-use'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import dayjs, { Dayjs } from 'dayjs'

interface EditableDatePickerElementProps {
  value: string | undefined
  objectId: string
  field: string
  refetchData?: () => void
  pickerMode?: 'date' | 'week' | 'month' | 'quarter' | 'year'
  rolodexType: string
  format?: string
}

export const EditableDatePickerElement: React.FC<EditableDatePickerElementProps> = ({
  value,
  objectId,
  field,
  refetchData,
  rolodexType,
  pickerMode = 'date',
  format = 'YYYY-MM-DD',
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<Dayjs | undefined | null>(dayjs(value))

  useEffect(() => {
    setEditedValue(dayjs(value))
  }, [value])

  useDebounce(
    () => {
      const formattedEditedValue = editedValue?.format(format)
      if (formattedEditedValue !== dayjs(value)?.format(format) && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: objectId,
          type: rolodexType,
          data: {
            [field]: formattedEditedValue ?? null,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    500,
    [editedValue]
  )

  return (
    <DatePicker
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      picker={pickerMode}
      value={dayjs(value)}
      onChange={(value) => {
        setEditedValue(value)
      }}
    />
  )
}
