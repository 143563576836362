//Set of common text filters and formatters

export function toTitleCase(str: string) {
  //Blatantly copied from stack overflow: https://stackoverflow.com/a/196991
  return str.replace(/\w\S*/g, function (txt) {
    //all this regex is really doing is finding word whitespace combos and replacing them.
    //This allows it to work for sentences as well as single words
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  })
}

export function currencyConverter(
  amount: number,
  notation: 'standard' | 'scientific' | 'engineering' | 'compact' | undefined = 'compact'
): string {
  let formattedAmount = `$${Intl.NumberFormat('en-US', {
    notation: notation,
  }).format(amount)}`

  // handle MM case
  if (formattedAmount.endsWith('M')) {
    formattedAmount += 'M'
  }

  return formattedAmount
}
