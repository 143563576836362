import styled from 'styled-components'
import { asset_manager_theme } from '../assets/themes'

export const TableContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`
export const MainTable = styled.table`
  width: 100%;
  border: 1px solid transparent;
`
export const TableHead = styled.thead`
  background-color: ${asset_manager_theme.background.background_color9};
`
export const TableHeadCell = styled.th`
  padding: 0.5rem;
  color: ${asset_manager_theme.text.c1};
  background-color: ${asset_manager_theme.background.background_color10};
  border-bottom: 1px solid ${asset_manager_theme.background.background_color4};
`
export const TableSideHeadCell = styled.th`
  padding: 0.5rem;
  color: ${asset_manager_theme.text.c1};
  background-color: ${asset_manager_theme.background.background_color9};
  border-top: 1px solid ${asset_manager_theme.background.background_color5};
`

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${asset_manager_theme.background.background_color9};
    border-radius: 0.3rem;
  }
  background-color: ${asset_manager_theme.background.background_color7};
`
export const TableCellLink = styled.a`
  color: ${asset_manager_theme.text.c1};
  &:link {
    text-decoration: none;
  }
  &:visited {
    color: ${asset_manager_theme.text.c1};
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
`
export const TableBody = styled.tbody``

export const TableCell = styled.td`
  padding: 1rem;
  color: ${asset_manager_theme.text.c1};
`

export const SubtableBackground = styled.div`
  width: 100%;
  background-color: ${asset_manager_theme.background.background_color9};
  border-radius: 0.5rem;
`

export const SubTable = styled.table`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
`

export const NumberRight = styled.div`
  text-align: right;
`

export const RotateAbleDiv = styled.div<{
  $componentSlug?: string
  $expandedRow?: string
}>`
  position: absolute;
  margin-left: 2.2rem;
  padding-right: 1.2rem;
  transform: rotate(
      ${(props) => (props.$componentSlug == props.$expandedRow ? '0' : '90')}deg
    )
    translateX(
      ${(props) => (props.$componentSlug == props.$expandedRow ? '0.5' : '0')}rem
    )
    translateY(
      ${(props) => (props.$componentSlug == props.$expandedRow ? '-0.8' : '0')}rem
    );
`

export const ArrowContainer = styled.div`
  transform: rotate(45deg);
`
export const ArrowDiv = styled.div`
  border: solid ${asset_manager_theme.text.c1};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
`

export const SortTableHeadCell = styled.th<{
  isSelected: boolean
  sortOrder: string
}>`
  padding: 8px;
  cursor: pointer;
  color: ${asset_manager_theme.text.c1};
  background-color: ${({ isSelected }) =>
    isSelected
      ? asset_manager_theme.background.background_color6
      : asset_manager_theme.background.background_color9};
  &:hover {
    background-color: ${asset_manager_theme.background.background_color5};
  }

  &::after {
    content: ${({ isSelected, sortOrder }) => {
      if (isSelected) {
        return sortOrder === 'asc' ? '" ▲"' : '" ▼"'
      }
      return '""'
    }};
  }
  border-bottom: 1px solid ${asset_manager_theme.background.background_color4};
`

export const SubTableRow = styled.tr`
  text-align: center;
  color: ${asset_manager_theme.background.background_color1};
  font-family: ${asset_manager_theme.text.font_family2};
  font-weight: bold;

  &:nth-child(even) {
    background-color: ${asset_manager_theme.background.background_color6};
    border-radius: 0.3rem;
  }
  &:nth-child(odd) {
    background-color: ${asset_manager_theme.background.background_color7};
    border-radius: 0.3rem;
  }
`

export const SubTableHeadCell = styled.th`
  // Add your sub-table head cell styles here
  padding: 8px;
  text-align: center;
  background-color: ${asset_manager_theme.background.background_color9};
  border-radius: 0.2rem;
`

export const SubTableBody = styled.tbody`
  // Add your sub-table body styles here
`

export const StyledSubtable = styled.div`
  padding: 0.5rem;
`

export const SubTableCell = styled.td<{
  isSelected: boolean
}>`
  // Add your sub-table cell styles here
  padding: 8px;
  text-align: left;
  &:first-child {
    cursor: pointer;
  }
  &:first-child:hover {
    background-color: ${asset_manager_theme.background.background_color9};
  }
  ${({ isSelected }) => {
    return (
      isSelected &&
      `border-top: thin solid rgb(162, 186, 195);
      border-bottom: thin solid rgb(162, 186, 195);
      &:first-child {
        border-left: thin solid rgb(162, 186, 195)
      }
      &:last-child {
        border-right: thin solid rgb(162, 186, 195);
      }`
    )
  }}
`
