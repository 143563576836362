import {
  RawScheduledInspection,
  RawInspectionMetadata,
} from '../../pages/InspectionSchedulingTab/InspectionSchedulingInterface'
import { Dayjs } from 'dayjs'
import { AuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteData } from '../../contextProviders/siteContextProvider'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { v4 as uuid } from 'uuid'
import { inspectionStatus } from '../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { trackEvent } from './mixpanel'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'

export const scheduleInspection = (
  scheduledInspections: RawInspectionMetadata[],
  nextInspectionDate: Dayjs,
  siteData: SiteData,
  auth0: AuthInfo,
  originPage: string,
  subpage?: string,
  refetch?: () => void
) => {
  trackEvent('Button Clicked', {
    buttonName: 'schedule_inspection',
    pageName: originPage,
    subPageName: subpage || '',
    assetDetails: {
      assetName: scheduledInspections?.[0]?.asset_name,
      date: nextInspectionDate.format('YYYY-MM-DD'),
      inspectionType: scheduledInspections?.[0]?.inspection_type,
    },
  })
  const patchEntries: {
    id: string
    type: string
    data: Record<string, any>
    tags?: Record<string, any>
  }[] = []

  scheduledInspections.forEach((scheduledInspection) => {
    const scheduledInspectionObject: RawScheduledInspection = {
      ...scheduledInspection,
      scheduled_date: nextInspectionDate.format('YYYY-MM-DD'),
      completed: false,
      next_deadline: nextInspectionDate
        .add(scheduledInspection.inspection_interval, 'month')
        .format('YYYY-MM-DD'),
    }

    const inspectionEntry = {
      id: uuid(), //new entry would have a random uuid,
      type: 'fde-am-inspection',
      data: scheduledInspectionObject,
      tags: {
        [RolodexEntryType.CUSTOMER_UNIT]: scheduledInspection.fdeCustomerUnitSlug,
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }

    const customerUnitEntry = {
      id: scheduledInspection.fdeCustomerUnitSlug,
      type: RolodexEntryType.CUSTOMER_UNIT,
      data: {
        inspection_schedule: {
          [scheduledInspection.inspection_type]: {
            [scheduledInspection.inspection_method]: {
              status: inspectionStatus.REQUIRED,
            },
          },
        },
        onstream_exception: false,
      },
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }
    patchEntries.push(inspectionEntry)
    patchEntries.push(customerUnitEntry)
  })
  RolodexPatchBatch(patchEntries, auth0, siteData).then(() => {
    refetch && refetch()
  })
}
