import React from 'react'
import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'
import { currencyConverter } from '../../components/helpers/stringFilters'

export interface DataItem {
  number: number
  label?: JSX.Element | string
  color?: string
  bold?: boolean
  format?: string
  subLabel?: string
  cardTitle?: string
}

interface Props {
  input: DataItem[]
  title?: string
}

const Container = styled.div`
  justify-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${asset_manager_theme.background.background_color8};
  border-radius: 0.4rem;
  align-items: flex-start;
  padding: 0.25rem 1.75rem;
`

interface KPINumberProps {
  colorProp?: string
  superSize?: boolean
  color?: string
}

const KPINumber = styled.div<KPINumberProps>`
  color: ${(props) => props.colorProp || 'white'};
  font-size: ${(props) => (props.superSize ? '4rem' : '3.25rem')};
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: left;
  padding-left: 0.5rem;
`

const KPILabel = styled.div<KPINumberProps>`
  color: ${(props) => (props.color ? props.color : asset_manager_theme.text.c1)};
  text-align: left;
  font-size: 1rem;
  padding-left: 0.5rem;
  font-weight: ${(props) => (props.superSize ? 'bold' : 'normal')};
`
const CardTitle = styled.div`
  text-align: left;
  padding-left: 0.5rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  color: ${asset_manager_theme.background.background_color3};
`

const CardDiv = styled.div`
  width: 30%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

const Title = styled.h3`
  text-align: left;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
  color: ${asset_manager_theme.background.background_color3};
`

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const NumberLabelComponent: React.FC<Props> = ({ input, title }) => {
  return (
    <Container>
      {title && <Title>{title}</Title>} {/* Render title only if it exists */}
      <CardsContainer>
        {input.map((item, index) => (
          <CardDiv key={index}>
            {input.some((d) => d.cardTitle) && (
              <CardTitle style={!item.cardTitle ? { color: 'rgb(0,0,0,0)' } : {}}>
                {item.cardTitle ?? ' i'}
              </CardTitle>
            )}
            <KPILabel superSize={item.bold}>{item.label}</KPILabel>
            <KPINumber colorProp={item.color} superSize={item.bold}>
              {item.format === 'currency' ? currencyConverter(item.number) : item.number}
            </KPINumber>
            {item.subLabel && (
              <KPILabel color={asset_manager_theme.background.background_color3}>
                {item.subLabel}
              </KPILabel>
            )}
          </CardDiv>
        ))}
      </CardsContainer>
    </Container>
  )
}

export default NumberLabelComponent
