import { Modal } from 'antd'
import { TreeNode } from '../Types/FileTreeTypes'

export function removePrefix(filePaths: string[]): string[] {
  return filePaths.map((path) => path.replace('asset_manager_file_system/', ''))
}

// Function to add 'asset_manager_file_system/' prefix to a file path or an array of file paths
export function addPrefix(filePath: string): string
export function addPrefix(filePaths: string[]): string[]
export function addPrefix(filePaths: string | string[]): string | string[] {
  if (typeof filePaths === 'string') {
    return 'asset_manager_file_system/' + filePaths
  } else {
    return filePaths.map((path) => 'asset_manager_file_system/' + path)
  }
}

const INVALID_FILE_CHARS = ['[', ']', '*', '?', '#', '\n']
export const sanitizeFileName = async (
  fileName: string,
  path: string,
  treeData: TreeNode[]
): Promise<string | undefined> => {
  if (new RegExp(`[${INVALID_FILE_CHARS.join('\\')}]`).test(fileName)) {
    // Show Modal asking for permission to remove invalid characters
    return new Promise((resolve) =>
      Modal.confirm({
        title: 'Filename contains invalid characters',
        content: `The file name must not contain the following characters ${INVALID_FILE_CHARS.join(
          ' , '
        )}.\nWould you like to remove special characters and proceed with the operation?`,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          // If user clicked 'Yes'
          let newName = sanitizeInvalidCharacters(fileName)

          // Get unique filename if replaced filename already exists
          newName = generateUniqueFilename(newName, path, treeData)

          resolve(newName)
        },
        onCancel: () => resolve(undefined),
      })
    )
  } else {
    return generateUniqueFilename(fileName, path, treeData)
  }
}

export const sanitizeFolderName = async (
  folderName: string,
  path: string,
  treeData: TreeNode[]
): Promise<string | undefined> => {
  if (new RegExp(`[${INVALID_FILE_CHARS.join('\\')}]`).test(folderName)) {
    // Show Modal asking for permission to remove invalid characters
    return new Promise((resolve) =>
      Modal.confirm({
        title: 'Folder name contains invalid characters',
        content: `The folder name must not contain the following characters ${INVALID_FILE_CHARS.join(
          ' , '
        )}.\nWould you like to remove special characters and proceed with the operation?`,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          // If user clicked 'Yes'
          const sanitizedFolderName = sanitizeInvalidCharacters(folderName)
          const newName = generateUniqueFolderName(sanitizedFolderName, path, treeData)

          resolve(newName)
        },
        onCancel: () => resolve(undefined),
      })
    )
  } else {
    return generateUniqueFolderName(folderName, path, treeData)
  }
}

export function generateUniqueFilename(
  fileName: string,
  path: string,
  treeData: TreeNode[]
) {
  const filenameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'))
  const fileExtension = fileName.slice(fileName.lastIndexOf('.'))

  let newFileName = fileName
  let count = 1

  // Create a function to iterate over the treeData and check if a file already exists
  function checkIfExists(nodes: TreeNode[]): boolean {
    return nodes.some((node) => {
      if (node.isLeaf && node.key === `${path ? path + '/' : ''}${newFileName}`) {
        return true
      } else if (!node.isLeaf && node.children) {
        return checkIfExists(node.children)
      } else {
        return false
      }
    })
  }

  // While a file with the same name exists at the same path, append a count to the file name
  while (checkIfExists(treeData)) {
    newFileName = `${filenameWithoutExtension} (${count})${fileExtension}`
    count++
  }

  return newFileName
}

export function generateUniqueFolderName(
  folderName: string,
  parentPath: string,
  treeData: TreeNode[]
) {
  let newFolderName = folderName
  let count = 1

  // Create a function to iterate over the treeData and check if a folder already exists
  function checkIfExists(nodes: TreeNode[]): boolean {
    return nodes.some((node) => {
      if (node.key === `${parentPath ? parentPath + '/' : ''}${newFolderName}`) {
        return true
      } else if (!node.isLeaf && node.children) {
        return checkIfExists(node.children)
      } else {
        return false
      }
    })
  }

  // While a folder with the same name exists at the same path, append a count to the folder name
  while (checkIfExists(treeData)) {
    newFolderName = `${folderName} (${count})`
    count++
  }

  return newFolderName
}

const sanitizeInvalidCharacters = (name: string): string => {
  let sanitized = name

  // Remove any of the invalid characters
  INVALID_FILE_CHARS.forEach((char) => {
    const regex = new RegExp('\\' + char, 'g')
    sanitized = sanitized.replace(regex, '')
  })

  return sanitized
}

// Function to extract last directory name from a path
export function extractFolderName(path: string | undefined): string | undefined {
  if (!path) return undefined

  const parts = path.split('/')
  return parts.pop()
}

// Function to replace the last folder name with a new one
export function replaceFolderName(
  path: string | undefined,
  newFolderName: string | undefined
): string | undefined {
  if (!path || !newFolderName) return path

  const parts = path.split('/')
  parts.pop()

  // Only add '/' if there are remaining parts left after pop
  const separator = parts.length ? '/' : ''
  return `${parts.join('/')}${separator}${newFolderName}`
}

export function extractParentDirectory(path: string | undefined): string | undefined {
  if (!path) return path

  const parts = path.split('/')
  parts.pop()

  // If there are no parts left, return an empty string as requested
  if (parts.length === 0) {
    return ''
  } else {
    return parts.join('/')
  }
}
