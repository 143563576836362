import React from 'react'

import { useMotionTemplate, useMotionValue } from 'framer-motion'
import { asset_manager_theme } from '../../assets/themes'

import { motion } from 'framer-motion'

import styled from 'styled-components'
//copied from forecast all props go to them for this awesome css

export const StyledMotionDiv = styled(motion.div)`
  --gradient-from: ${asset_manager_theme.background.background_color6};
  --gradient-to: ${asset_manager_theme.background.background_color8};
  --gradient-stops: var(--gradient-from), var(--gradient-to);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, var(--gradient-stops));
  border-radius: 4px;
  opacity: 0;
  overflow: auto;
  backdrop-filter: blur(10px);
`

const CardOutline = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
`

const GradientContainerCentered = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  background-color: ${asset_manager_theme.background.background_color6};
  transition-property: color, background-color, border-color, text-decoration-color, fill,
    stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgb(255 255 255 / 0.2);
    ${StyledMotionDiv} {
      --gradient-to: ${asset_manager_theme.background.background_color9};
      opacity: 1;
    }
  }
`

const GradientContainer = styled.div`
  position: relative;
  display: flex;
  border-radius: 4px;
  cursor: pointer;

  background-color: ${asset_manager_theme.background.background_color6};
  transition-property: color, background-color, border-color, text-decoration-color, fill,
    stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 1px solid transparent;

  &:hover {
    ${StyledMotionDiv} {
      --gradient-to: ${asset_manager_theme.background.background_color8};
      opacity: 1;
    }
  }
`

const GradientContainerDisabled = styled.div`
  position: relative;
  display: flex;
  border-radius: 4px;
  margin: 0px 0.5em;

  background-color: ${asset_manager_theme.background.background_color6};
  transition-property: color, background-color, border-color, text-decoration-color, fill,
    stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 1px solid transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`

const StyledDivContainer = styled.div`
  pointer-events: none;
  overflow: auto;
`

const ContentContainer = styled.div`
  position: relative;
  border-radius: 0.2rem;
  padding: 0.75rem;
  width: 100%;
`

export const HoveredCard: React.FC<{
  alignment: 'side' | 'middle'
  children: React.ReactNode
  disabled?: boolean
}> = ({ alignment, children, disabled }) => {
  const id = React.useId()
  const mouseX = useMotionValue(0)
  const mouseY = useMotionValue(0)

  const maskImage = useMotionTemplate`radial-gradient(400px at ${mouseX}px ${mouseY}px, white, transparent)`
  const style = { maskImage, WebkitMaskImage: maskImage }

  function onMouseMove({ currentTarget, clientX, clientY }: React.MouseEvent) {
    const { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  if (disabled) {
    return (
      <GradientContainerDisabled key={id} onMouseMove={onMouseMove}>
        <CardOutline />
        <ContentContainer>{children}</ContentContainer>
      </GradientContainerDisabled>
    )
  }

  return alignment === 'side' ? (
    <GradientContainer key={id} onMouseMove={onMouseMove}>
      <StyledDivContainer>
        <StyledMotionDiv style={style} transition={{ duration: 300 }} />
      </StyledDivContainer>
      <CardOutline />
      <ContentContainer>{children}</ContentContainer>
    </GradientContainer>
  ) : (
    <GradientContainerCentered key={id} onMouseMove={onMouseMove}>
      <StyledDivContainer>
        <StyledMotionDiv style={style} transition={{ duration: 300 }} />
      </StyledDivContainer>
      <CardOutline />
      <ContentContainer>{children}</ContentContainer>
    </GradientContainerCentered>
  )
}
