import { FC, useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { LoginDropdownV2, PageLayout } from 'gecko-ui'
import styled from 'styled-components'
import { ReactComponent as GeckoBoxLogoWhite } from '../assets/gecko-g-knockout-logo.svg'
import { HEADER_HEIGHT } from '../styles/LayoutVars'
import { RolodexEntry } from '../api/useRolodex'
import { Link } from 'react-router-dom'
import { GeckoTheme } from 'gecko-ui'
import * as Separator from '@radix-ui/react-separator'
import DropdownSearch from '../components/DropdownSearch'
import { StyledMenuItem } from '../components/SearchSortFilter'
import { CANTILEVER_BASE_PATH } from '../util/constants'

export const StyledSeparator = styled(Separator.Root)`
  background-color: ${GeckoTheme.colors.greyscale[500]};
  margin: 1.5rem;
  margin-right: 1rem;

  &[data-orientation='vertical'] {
    height: 60%;
    width: 1px;
  }
`

interface SitesPerOrg {
  organization: RolodexEntry
  sites: RolodexEntry[]
}

const NoDropdownTitle: FC<{ entry: RolodexEntry | undefined }> = (props) => {
  const { entry } = props
  return (
    <DropdownSearch>
      <DropdownSearch.Trigger hideCaret>
        <DropdownSearch.Label>{entry?.data ? entry.data.name : ''}</DropdownSearch.Label>
      </DropdownSearch.Trigger>
    </DropdownSearch>
  )
}

function LocationDropdown<T>(props: {
  entry: RolodexEntry | undefined
  entryList?: ReadonlyArray<RolodexEntry>
  filterList: T[]
  itemGetter: (item: T) => string
  itemSearchTerms: (item: T) => string[]
  onSelect: (item: T | undefined) => void
  placeholder: string
}): JSX.Element | null {
  const {
    entry,
    entryList = [],
    filterList,
    itemGetter,
    itemSearchTerms,
    onSelect,
    placeholder,
  } = props

  return entryList.length == 1 ? (
    <NoDropdownTitle entry={entry} />
  ) : entryList.length > 1 ? (
    <DropdownSearch>
      <DropdownSearch.Trigger>
        <DropdownSearch.Label>{entry!.data!.name}</DropdownSearch.Label>
      </DropdownSearch.Trigger>
      <DropdownSearch.Menu>
        <DropdownSearch.FilteredSearch
          listToFilter={filterList}
          itemGetter={itemGetter}
          itemSearchTerms={itemSearchTerms}
          onSelect={onSelect}
          placeholder={placeholder}
        />
      </DropdownSearch.Menu>
    </DropdownSearch>
  ) : null
}

// TODO: Share with ForecastTopBar 2024.07.01
export const AssetManagerTopBar: FC<{
  organization?: RolodexEntry
  organizations?: ReadonlyArray<RolodexEntry>
  site?: RolodexEntry
  sites?: ReadonlyArray<RolodexEntry>
}> = (props) => {
  const { organization, site, organizations = [], sites = [] } = props

  const { user = { name: 'Unknown User' }, logout, isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  const sitesPerOrganization = useMemo(
    () =>
      organizations
        .map((o) => ({
          organization: o,
          sites: sites.filter((s) => s.tags!.organization === o.id),
        }))
        .filter(({ sites }) => sites.length > 0),
    [organizations, sites]
  )

  const sitesFilteredByOrganization = useMemo(
    () => sites.filter((s) => s.tags?.organization === organization?.id) ?? [],
    [organization, sites]
  )

  return (
    <PageLayout.TopBar
      title={'Asset Manager'}
      backgroundColor={GeckoTheme.colors.greyscale[800]}
      height={HEADER_HEIGHT}
      leftContent={
        <>
          <Link to={!site ? '/' : `/${site.id}/overview`}>
            <GeckoBoxLogoWhite
              style={{
                height: '3rem',
                width: '3rem',
                margin: '1rem',
                marginRight: 0,
                minHeight: '48px',
                minWidth: '48px',
              }}
            />
          </Link>
          <StyledSeparator orientation='vertical' />
          <LocationDropdown<SitesPerOrg>
            entry={organization}
            entryList={organizations}
            filterList={sitesPerOrganization}
            itemGetter={({ organization }) => organization?.data?.name}
            itemSearchTerms={({ organization }) => [
              organization?.data?.name,
              organization?.data?.name.replaceAll('.', ''),
              organization?.data?.name.replace(/[^A-Z]+/g, ''), // Capital letters only
            ]}
            onSelect={(item) => {
              const site = item?.sites?.at(0)
              if (site) navigate(`/${site.id}`)
            }}
            placeholder='Search Organizations(…)'
          />
          <LocationDropdown<RolodexEntry>
            entry={site}
            entryList={sites}
            filterList={sitesFilteredByOrganization}
            itemGetter={(item) => item?.data?.name}
            itemSearchTerms={(site) => [
              site?.data?.name,
              site?.data?.name.replaceAll('.', ''),
              site?.data?.name.replace(/[^A-Z]+/g, ''), // Capital letters only
            ]}
            onSelect={(item) => {
              if (item) navigate(`/${item.id}`)
            }}
            placeholder='Search Sites(…)'
          />
          {location.pathname != '/deliverytracker' ? null : (
            <DropdownSearch.Label>Delivery Tracker</DropdownSearch.Label>
          )}
        </>
      }
      rightContent={
        // TODO: Update logout handler to use base path from a context rather than an explicit constant
        <LoginDropdownV2
          navOptions={[
            { id: 'logout', content: <StyledMenuItem>Logout</StyledMenuItem> },
          ]}
          isAuthenticated={isAuthenticated}
          logout={() => {
            const url_suffix = window.location.origin.includes(CANTILEVER_BASE_PATH)
              ? '/asset-manager'
              : ''
            logout({
              logoutParams: {
                returnTo: `${window.location.origin}${url_suffix}`,
              },
            })
          }}
          user={user}
        />
      }
    />
  )
}
