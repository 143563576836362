import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Table, Collapse, CollapseProps, Row, ColorPicker, Button, Modal } from 'antd'
import { BlitzInterface, BlitzInspectionInterface } from './BlitzInterface'
import BlitzGraph from './BlitzGraph'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'

import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { EditableStringElement } from '../../components/Common/EditableStringElement'
import { convertQuarterDateToString } from './common'
import { EditableDatePickerElement } from '../../components/Common/EditableDatePickerComponent'
import { EditableColourPicker } from '../../components/Common/EditableColourPickerElement'
import { EditableTextAreaElement } from '../../components/Common/EditableTextAreaElement'
import { rolodex_batch_delete } from '../../api/useFDEBackend'
import HighVisButton from '../../components/Common/HighVisButton'
import { DeleteOutlined } from '@ant-design/icons'
import { getBlitzInspectionIdsFromBlitzID } from './RugBlitzPlanningTab'
import { trackEvent } from '../../components/helpers/mixpanel'
import RadarModule, { MarkerData } from '../../components/RadarModule/RadarModule'
import { useRolodexEntries } from '../../api/useRolodex'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import BlitzSelector from './BlitzSelector'

interface BlitzTableProps {
  blitzData: BlitzInterface[]
  blitzInspectionData?: BlitzInspectionInterface[]
  refetchBlitz?: () => void
  setFdeCustomerUnitSlug: (slug: string | undefined) => void
}

export interface GroupedBlitzData extends BlitzInterface {
  blitzInspections?: BlitzInspectionInterface[]
}

const BlitzTable: React.FC<BlitzTableProps> = ({
  blitzData,
  blitzInspectionData,
  refetchBlitz,
  setFdeCustomerUnitSlug,
}) => {
  const auth0 = useAuthInfo()

  const [blitzDeleteId, setBlitzDeleteId] = React.useState<string | undefined>(undefined)

  const deleteBlitz = (blitzId: string) => {
    const blitzInspectionIds = getBlitzInspectionIdsFromBlitzID(
      blitzId,
      blitzInspectionData
    )
    return rolodex_batch_delete(auth0, [blitzId, ...(blitzInspectionIds ?? [])])
  }

  //Group the blitz inspections to the blitzes
  const groupedBlitzData: GroupedBlitzData[] | undefined = useMemo(() => {
    return blitzData
      ?.map((d: GroupedBlitzData) => {
        const blitzInspections = blitzInspectionData?.filter((inspection) => {
          return inspection.entry?.tags?.[RolodexEntryType.BLITZ_TYPE] === d.entry?.id
        })
        d.blitzInspections = blitzInspections
        return d
      })
      .sort((a: GroupedBlitzData, b: GroupedBlitzData) => {
        const yearA = parseInt(a.entry.data?.blitz_quarter.split('-')[0] ?? '0', 10)
        const yearB = parseInt(b.entry.data?.blitz_quarter.split('-')[0] ?? '0', 10)
        const quarterA = parseInt(a.entry.data?.blitz_quarter.split('-')[1] ?? '0', 10)
        const quarterB = parseInt(b.entry.data?.blitz_quarter.split('-')[1] ?? '0', 10)
        // if the quarter and year is the same, sort by alphabetical order of the blitz name
        if (yearA == yearB) {
          if (quarterA == quarterB) {
            return (a.entry.data?.blitz_name ?? '').localeCompare(
              b.entry.data?.blitz_name ?? ''
            )
          } else {
            return quarterA - quarterB
          }
        } else {
          return yearA - yearB
        }
      })
  }, [blitzData, blitzInspectionData])

  const siteData = useContext(SiteContext)

  const [FDESiteData, setFDESiteData] = useState<Record<any, any> | undefined>(undefined)
  const FDESiteBody = {
    type: RolodexEntryType.FDE_SITE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
    },
  }
  const FDESiteSWR = useRolodexEntries(FDESiteBody)

  useEffect(() => {
    if (!FDESiteSWR.error && !FDESiteSWR.isLoading && FDESiteSWR.data) {
      if (!FDESiteSWR.data?.[0]?.entry?.data?.radar) {
        return
      }
      setFDESiteData(FDESiteSWR.data)
    }
  }, [FDESiteSWR.data, FDESiteSWR.error, FDESiteSWR.isLoading])

  const markers = useMemo(() => {
    // for every groupedBlitzData, grab the color and then iterate through the blitzInspections
    // and get the location and create a marker
    const allMarkerData = groupedBlitzData?.map((d) => {
      const blitzColor = d.entry.data?.blitz_colour
      return d.blitzInspections?.map((inspection) => {
        // if no lat or long, contnue and dont create a marker
        if (
          !inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data?.latitude ||
          !inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data?.longitude
        ) {
          return undefined
        }
        return {
          id: inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.id,
          longitude: inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data?.longitude,
          latitude: inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data?.latitude,
          title:
            inspection.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data?.equip_description,
          color: blitzColor,
        }
      })
    })
    return allMarkerData?.flat().filter((marker) => marker !== undefined) as MarkerData[]
  }, [groupedBlitzData])
  return (
    <div key='GroupedBlitzesDiv'>
      {/* <BlitzGraph groupedBlitzData={groupedBlitzData} /> */}
      {FDESiteData?.[0].entry.data.radar && (
        <div
          style={{
            height: '45vh',
            width: '95vw',
            borderRadius: '0.5rem',
            marginBottom: '0px',
          }}>
          <RadarModule
            center={FDESiteData?.[0].entry.data.radar}
            markers={markers as MarkerData[]}
            setBaseballCardFdeCustomerUnitSlug={setFdeCustomerUnitSlug}
          />
        </div>
      )}
      {groupedBlitzData?.map((d, index) => {
        const relatedBlitzInspections = d.blitzInspections || []
        const blitzQuarter = d.entry?.data?.blitz_quarter.split('-')
        if (blitzQuarter?.length === 2) {
          const collapsePanel: CollapseProps['items'] = [
            {
              key: d.entry.id,
              label: (
                <Row style={{ margin: '0px' }}>
                  <EditableStringElement
                    style={{ width: '8%' }}
                    objectId={d.entry.id}
                    value={d.entry.data?.blitz_name}
                    field={'blitz_name'}
                    rolodexType={RolodexEntryType.BLITZ_TYPE}
                    refetchData={() => {
                      refetchBlitz?.()
                    }}
                  />
                  <div style={{ marginRight: '1rem' }}>
                    {' '}
                    <EditableDatePickerElement
                      objectId={d.entry.id}
                      rolodexType={RolodexEntryType.BLITZ_TYPE}
                      field={'blitz_quarter'}
                      value={d.entry.data?.blitz_quarter}
                      refetchData={() => {
                        refetchBlitz?.()
                      }}
                      format='YYYY-MM'
                      pickerMode='quarter'
                    />
                  </div>
                  <EditableColourPicker
                    value={d.entry.data?.blitz_colour}
                    field={'blitz_colour'}
                    objectId={d.entry.id}
                    rolodexType={RolodexEntryType.BLITZ_TYPE}
                    refetchData={() => {
                      refetchBlitz?.()
                    }}
                  />
                  <div style={{ marginLeft: '1rem' }}>
                    <HighVisButton
                      ghost={true}
                      onClick={() => {
                        setBlitzDeleteId(d.entry.id)
                      }}>
                      <DeleteOutlined />
                    </HighVisButton>
                  </div>
                </Row>
              ),
              children: (
                <div>
                  <Collapse
                    bordered={false}
                    items={[
                      {
                        key: 'notes',
                        label: 'Notes',
                        children: (
                          <EditableTextAreaElement
                            objectId={d.entry.id}
                            value={d.entry.data?.reasons}
                            field={'reasons'}
                            rolodexType={RolodexEntryType.BLITZ_TYPE}
                            refetchData={() => {
                              refetchBlitz?.()
                            }}
                          />
                          // <Table
                          //   key={`${d.entry?.data?.blitz_name} - ${d.entry?.data?.blitz_quarter} - meta`}
                          //   dataSource={[d]}
                          //   rowKey={(record) => record.entry.id}
                          //   pagination={false}
                          //   columns={[
                          //     // {
                          //     //   key: 'requirements',
                          //     //   title: 'Requirements',
                          //     //   dataIndex: ['entry', 'data', 'requirements'],
                          //     //   width: '30%',
                          //     //   render: (text, record) => {
                          //     //     return (
                          //     //       <EditableTextAreaElement
                          //     //         objectId={record.entry.id}
                          //     //         value={record.entry.data?.requirements}
                          //     //         field={'requirements'}
                          //     //         rolodexType={RolodexEntryType.BLITZ_TYPE}
                          //     //         refetchData={() => {
                          //     //           refetchBlitz?.()
                          //     //         }}
                          //     //       />
                          //     //     )
                          //     //   },
                          //     // },
                          //     {
                          //       key: 'reasons',
                          //       title: 'Notes',
                          //       dataIndex: ['entry', 'data', 'reasons'],
                          //       width: '35%',
                          //       render: (text, record) => {
                          //         return (
                          //           <EditableTextAreaElement
                          //             objectId={record.entry.id}
                          //             value={record.entry.data?.reasons}
                          //             field={'reasons'}
                          //             rolodexType={RolodexEntryType.BLITZ_TYPE}
                          //             refetchData={() => {
                          //               refetchBlitz?.()
                          //             }}
                          //           />
                          //         )
                          //       },
                          //     },
                          //     // {
                          //     //   key: 'shifts',
                          //     //   title: 'Shifts',
                          //     //   dataIndex: ['entry', 'data', 'shifts'],
                          //     //   width: '35%',
                          //     //   render: (text, record) => {
                          //     //     return (
                          //     //       <EditableTextAreaElement
                          //     //         objectId={record.entry.id}
                          //     //         value={record.entry.data?.shifts}
                          //     //         field={'shifts'}
                          //     //         rolodexType={RolodexEntryType.BLITZ_TYPE}
                          //     //         refetchData={() => {
                          //     //           refetchBlitz?.()
                          //     //         }}
                          //     //       />
                          //     //     )
                          //     //   },
                          //     // },
                          //   ]}
                          // />
                        ),
                      },
                    ]}
                  />
                  <Table
                    style={{ marginTop: '0rem' }}
                    key={`${d.entry?.data?.blitz_name} - ${d.entry?.data?.blitz_quarter}`}
                    dataSource={relatedBlitzInspections}
                    rowKey={(record) => record.entry.id}
                    pagination={false}
                    columns={[
                      {
                        key: 'name',
                        title: 'Name',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'equip_description',
                        ],
                        width: '25%',
                        defaultSortOrder: 'ascend',
                        onCell: (record) => {
                          return {
                            onClick: () => {
                              setFdeCustomerUnitSlug(
                                record.links?.[RolodexEntryType.CUSTOMER_UNIT]?.id
                              )
                              trackEvent('Button Clicked', {
                                buttonName: 'asset_baseball_card',
                                pageName: 'Blitz Planning',
                                assetDetails: {
                                  assetName: record.links?.[
                                    RolodexEntryType.CUSTOMER_UNIT
                                  ]?.data?.equip_description as string,
                                },
                              })
                            },
                          }
                        },
                        sorter: (a, b) =>
                          (
                            a.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data
                              ?.equip_description as string
                          ).localeCompare(
                            b.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data
                              ?.equip_description as string
                          ),
                      },
                      {
                        key: 'equip_id',
                        title: 'Equip ID',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'equip_id',
                        ],
                        width: '15%',
                      },
                      {
                        key: 'area',
                        title: 'Area',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'equip_area',
                        ],
                        width: '15%',
                      },
                      {
                        key: 'area_description',
                        title: 'Area Description',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'area_description',
                        ],
                        width: '15%',
                      },
                      {
                        key: 'outage',
                        title: 'Outage',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'outage_type',
                        ],
                        width: '5%',
                      },
                      {
                        key: 'riskCategory',
                        title: 'Risk Category',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'risk_category',
                        ],
                        width: '5%',
                      },
                      {
                        key: 'type',
                        title: 'Type',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'type',
                        ],
                        width: '5%',
                      },
                      {
                        key: 'year_built',
                        title: 'Year Built',
                        dataIndex: [
                          'links',
                          RolodexEntryType.CUSTOMER_UNIT,
                          'data',
                          'year_built',
                        ],
                        width: '5%',
                      },
                      // {
                      //   key: 'year_built',
                      //   title: 'Year Built',
                      //   dataIndex: ['links', RolodexEntryType.CUSTOMER_UNIT, 'data', 'year_built'],
                      //   width: '5%',
                      // },
                      // {
                      //   key: 'internal_ut_next',
                      //   title: 'Internal Ut Next',
                      //   dataIndex: [
                      //     'links',
                      //     RolodexEntryType.CUSTOMER_UNIT,
                      //     'data',
                      //     'inspection_schedule',
                      //     'internal',
                      //     'UT',
                      //     'next',
                      //   ],
                      //   width: '10%',
                      // },
                      // {
                      //   key: 'external_ut_next',
                      //   title: 'External Ut Next',
                      //   dataIndex: [
                      //     'links',
                      //     RolodexEntryType.CUSTOMER_UNIT,
                      //     'data',
                      //     'inspection_schedule',
                      //     'external',
                      //     'UT',
                      //     'next',
                      //   ],
                      //   width: '10%',
                      // },
                      {
                        // calculate estimated shifts required to inspect asset
                        // formula is  height * diameter * pi
                        // and divide by 1500 to get the number of shifts
                        key: 'estimated_shifts',
                        title: 'Estimated Shifts',
                        render: (text, record) => {
                          const diameter = Number(
                            record.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data
                              ?.dimensions?.diameter_ft
                          )
                          const height = Number(
                            record.links?.[RolodexEntryType.CUSTOMER_UNIT]?.data
                              ?.dimensions?.height_ft
                          )
                          if (diameter && height) {
                            const shifts = (diameter * height * Math.PI) / 1500
                            return String(Math.ceil(shifts))
                          }
                          return 'N/A'
                        },
                        width: '10%',
                      },
                    ]}
                  />
                </div>
              ),
            },
          ]
          return (
            <div key={index} style={{ margin: '0.25rem' }}>
              <Collapse
                size='large'
                ghost
                items={collapsePanel}
                defaultActiveKey={['1']}
              />
            </div>
          )
        }
      })}
      <Modal
        open={!!blitzDeleteId}
        title='Delete Blitz'
        onOk={() => {
          if (blitzDeleteId) {
            deleteBlitz(blitzDeleteId).then(() => {
              refetchBlitz?.()
              setBlitzDeleteId(undefined)
            })
          }
        }}
        onCancel={() => {
          setBlitzDeleteId(undefined)
        }}
      />
    </div>
  )
}

export default BlitzTable
