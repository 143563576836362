import React, { FC, useState, useContext, useMemo, useCallback, useEffect } from 'react'
import { Typography, InputNumber } from 'antd'
import { InspectionWithIncomplete } from './AssetBaseBallCardInspectionsTable'
import dayjs from 'dayjs'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { useDebounce } from 'react-use'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'

const { Text } = Typography

interface EditIntervalProps {
  fdeCustomerUnitSlug: string
  interval: number
  record: InspectionWithIncomplete
  refetchData?: () => void
  site: string
  organization: string
  disabled?: boolean
}

export const EditInterval: React.FC<EditIntervalProps> = ({
  fdeCustomerUnitSlug,
  interval,
  record,
  refetchData,
  site,
  organization,
  disabled,
}) => {
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const [doubleClicked, setDoubleClicked] = useState<boolean>(false)
  const [editedValue, setEditedValue] = useState<number | null>(interval)

  useEffect(() => {
    if (interval !== editedValue) {
      setEditedValue(interval)
    }
  }, [interval])

  const handleUpdateInterval = (
    newInterval: number,
    record: InspectionWithIncomplete
  ) => {
    const itemsToPatch = []
    // if there is a completed inspection, update the inspection object and customer unit
    if (record.inspectionId) {
      itemsToPatch.push(
        {
          id: record?.inspectionId || '',
          type: 'fde-am-inspection',
          data: {
            next_deadline: dayjs(record?.scheduled_date)
              .add(newInterval, 'months')
              .format('YYYY-MM-DD'),
            inspection_interval: newInterval,
          },
          tags: {
            [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
            site: site,
            organization: organization,
          },
        },
        // if there is a completed inspection then the method in inspection scheduling must exist
        {
          id: fdeCustomerUnitSlug,
          type: RolodexEntryType.CUSTOMER_UNIT,
          data: {
            inspection_schedule: {
              [record?.inspection_type.toLowerCase()]: {
                [record?.inspection_method]: {
                  interval_months: newInterval,
                  next: dayjs(record?.scheduled_date)
                    .add(newInterval, 'months')
                    .format('YYYY-MM-DD'),
                },
              },
            },
          },
          tags: {
            site: site,
            organization: organization,
          },
        }
      )
    } else {
      itemsToPatch.push({
        id: fdeCustomerUnitSlug,
        type: RolodexEntryType.CUSTOMER_UNIT,
        data: {
          inspection_schedule: {
            [record.inspection_type.toLowerCase()]: {
              [record.inspection_method]: {
                interval_months: newInterval,
                next: null,
                last: null,
              },
            },
          },
        },
        tags: {
          site: site,
          organization: organization,
        },
      })
    }

    // if there is incomplete data update the incomplete object
    if (record.incompleteInspection) {
      itemsToPatch.push({
        id: record?.incompleteInspection?.inspectionId || '',
        type: 'fde-am-inspection',
        data: {
          next_deadline: dayjs(record?.incompleteInspection?.scheduled_date)
            .add(newInterval, 'months')
            .format('YYYY-MM-DD'),

          // if we ever want to update the current_deadline this is what we should do
          //   current_deadline: dayjs(record?.incompleteInspection?.current_deadline)
          //     .subtract(record?.incompleteInspection?.inspection_interval, 'months')
          //     .add(newInterval, 'months')
          //     .format('YYYY-MM-DD'),
          inspection_interval: newInterval,
        },
        tags: {
          [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
          site: site,
          organization: organization,
        },
      })
    }
    RolodexPatchBatch(itemsToPatch, auth0, siteData).then(() => {
      refetchData?.()
    })
  }

  useDebounce(
    () => {
      if (editedValue !== interval && editedValue && site) {
        handleUpdateInterval(editedValue, record)
      }
    },
    300,
    [editedValue]
  )

  return (
    <div>
      {doubleClicked && !disabled ? (
        <InputNumber
          key={fdeCustomerUnitSlug + record.inspection_type + record.inspection_method}
          value={editedValue}
          controls={false}
          defaultValue={interval}
          onChange={(value) => {
            setEditedValue(value)
          }}
          onDoubleClick={() => {
            setDoubleClicked(false)
          }}
        />
      ) : (
        <Text
          onDoubleClick={() => {
            setDoubleClicked(true)
            setEditedValue(interval)
          }}>
          {editedValue ? editedValue : '----'}
        </Text>
      )}
    </div>
  )
}
