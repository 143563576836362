import React, { ReactNode, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { FeatureFlagContext } from '../contextProviders/featureFlagContextProvider'
import { TabKey } from './AppLayout'

interface ProtectedRouteProps {
  children: ReactNode
  featureKey: TabKey
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, featureKey }) => {
  const featureFlags = useContext(FeatureFlagContext)

  if (
    !featureFlags?.role ||
    featureFlags.is_loading ||
    featureFlags?.superUser ||
    featureFlags?.[featureKey]
  ) {
    //role check here is to not redirect to unauthorized until we get the role and make sure the feature flags aren't loading
    return children as React.ReactElement
  }

  return <Navigate to='/unauthorized' replace />
}

export default ProtectedRoute
