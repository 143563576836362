/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'
import { withResizeDetector } from 'react-resize-detector'
import { Spin } from 'antd'
import { PlotData } from 'plotly.js'
import Plot from 'react-plotly.js'

const WIDTH_PAD = 50

export const PlotContainer = styled.div`
  width: 100%;
  height: 320px;
  background-color: ${asset_manager_theme.background.background_color9};
  border: 0.15rem solid ${asset_manager_theme.background.background_color7};
  border-radius: 0.3rem;
  box-shadow: 0rem 0.5rem 0.5rem ${asset_manager_theme.background.background_color7};
  margin-bottom: 1rem;
`

export interface TraceViewProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  traces: Partial<PlotData>[]
  width: number
  height: number
  isLoading: boolean
  title?: string
}

const TraceView: FC<TraceViewProps> = (props) => {
  const titleConfig = useMemo(
    () =>
      props.title
        ? {
            text: props.title,
            font: { color: 'white' },
          }
        : undefined,
    [props.title]
  )

  const hasTraces = !!props.traces && props.traces.length > 0
  const [maxX, maxY, maxYBuffer] = useMemo(() => {
    if (hasTraces) {
      let maxYBuffer = (props.traces[0]?.y?.[1] as number) * 0.1
      let maxX = 0
      let maxY = 0
      for (const currTrace of props.traces) {
        maxX = Math.max(maxX, Math.max(...((currTrace?.x as number[]) || [])))
        maxY = Math.max(maxY, Math.max(...((currTrace?.y as number[]) || [])))
        maxYBuffer = Math.max(maxYBuffer, (currTrace?.y?.[1] as number) * 0.1)
      }
      return [maxX, maxY, maxYBuffer]
    } else {
      return [19, 1000000, 0]
    }
  }, [hasTraces, props.traces])

  const [layout, config] = useMemo(() => {
    const plotWidth = props.width - 50
    const xBuffer = 0.1
    const yBuffer = maxYBuffer
    const layout = {
      xaxis: {
        title: 'Time to Retire (years)',
        color: 'white',
        gridcolor: 'transparent',
        griddash: 'dot',
        range: [0, maxX + xBuffer],
        spikemode: 'toaxis',
        spikedash: 'solid',
        rangemode: 'tozero',
        dtick: 1,
      },
      yaxis: {
        title: 'Repair Cost ($)',
        color: 'white',
        gridcolor: 'transparent',
        griddash: 'dot',
        range: [0, maxY + yBuffer],
      },
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent',
      hovermode: 'x',
      margin: { pad: 5 },
      height: 335,
      width: plotWidth + WIDTH_PAD,
      title: titleConfig,
      legend: {
        font: {
          color: 'white', // Change the color of the legend font here
        },
      },
    }
    const config = { displayModeBar: false }
    return [layout, config]
  }, [maxX, maxY, maxYBuffer, props.width, titleConfig])

  return (
    <div>
      <Spin spinning={props.isLoading}>
        <Plot
          key={'combined_repair_cost_per_ttf_plot'}
          data={props.traces}
          layout={layout as any}
          config={config}
        />
      </Spin>
    </div>
  )
}

export default withResizeDetector(TraceView)
