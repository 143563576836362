import React from 'react'
import { Row } from '../../../../components/Common/Grid'
import { Col } from 'antd'
import { PlanGraph } from '../../PlanGraph'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { Asset } from '../../PlanModelingInterface'
import { noop, sumBy } from 'lodash'
import { maxCostPerYear } from './CostOptimizationUtil'

const calculateFinalTCO = (plans: AssetPlanSelection, assets: Asset[]): number => {
  return sumBy(Object.values(plans), (plan) => {
    const assetPlan = assets
      .flatMap((asset) => asset.plans)
      .find((p) => p?.plan_slug === plan.plan_slug)
    if (!assetPlan) {
      return 0
    }
    return sumBy(assetPlan.plan_recs ?? [], (rec) => rec.repair_cost ?? 0)
  })
}

interface PlanComparisonProps {
  assets: Asset[]
  oldPlanSelection: AssetPlanSelection
  newPlanSelection: AssetPlanSelection
  annualBudget?: number
}

export const PlanComparison: React.FC<PlanComparisonProps> = (props) => {
  const { assets, oldPlanSelection, newPlanSelection, annualBudget } = props

  const oldPlanMaxCost = maxCostPerYear(Object.values(oldPlanSelection), assets)
  const newPlanMaxCost = maxCostPerYear(Object.values(newPlanSelection), assets)
  const yAxisMax = Math.max(oldPlanMaxCost, newPlanMaxCost)

  const oldPlanTCO = calculateFinalTCO(oldPlanSelection, assets)
  const newPlanTCO = calculateFinalTCO(newPlanSelection, assets)

  return (
    <Col style={{ overflowY: 'auto', width: '50%' }}>
      <Row>
        <h2>Old Plan: {oldPlanTCO} </h2>
      </Row>
      <Row style={{ maxHeight: '20rem' }}>
        <PlanGraph
          selectedPlans={oldPlanSelection}
          processedPlanData={assets}
          yAxisStuck={false}
          yAxisMax={yAxisMax}
          budgetValue={annualBudget ? { budgetLine: annualBudget } : undefined}
          setYAxisMax={noop}
        />
      </Row>
      <Row>
        <h2>New Plan: {newPlanTCO}</h2>
      </Row>
      <Row style={{ maxHeight: '20rem' }}>
        <PlanGraph
          selectedPlans={newPlanSelection}
          processedPlanData={assets}
          yAxisStuck={false}
          yAxisMax={yAxisMax}
          budgetValue={annualBudget ? { budgetLine: annualBudget } : undefined}
          setYAxisMax={noop}
        />
      </Row>
    </Col>
  )
}
