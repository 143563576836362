import styled from 'styled-components'
import { Row } from './Grid'

export const TabHeader = styled(Row)`
  width: inherit;
  max-height: 4rem;
  color: white;
  font-size: 1.5rem;
  margin-top: 3rem;
`
