import useSWR, { SWRResponse } from 'swr'

import {
  getFetcher,
  getMultipleFetcher,
  postFetcher,
  getFileFetcher,
  putFetcher,
} from './fetchers'
import { fdeBackendUrl } from './helpers'
import { AuthInfo, useAuthInfo } from '../contextProviders/useAuthInfo'
import { useMemo } from 'react'
import { FdeCustomerUnit, useRolodexEntries } from './useRolodex'
import {
  LifeExtensionTableData,
  TriageData,
} from '../pages/RecommendationsTab/RecommendationsInterface'
import {
  Asset,
  PlanModelingKPIData,
} from '../pages/PlanModelingTab/PlanModelingInterface'
import _, { get } from 'lodash'
import { RepairStatValues, RolodexFileData } from '../util/DataSchema'
import { PreviewPlotData } from '../util/DisplayLifeExtensionPlotHelpers/PreviewPlotData'
import dayjs from 'dayjs'
import { BACKEND_ROOT_DIR } from '../components/helpers/toBackendPath'
import { FilterValue } from 'antd/es/table/interface'
import { RolodexEntryType } from '../util/RolodexTypeConstants'

//------------------------------------------------------------------------------

/**
 * Get the Plotly traces used in DossierTimelineAES. Don't export this - it's
 * used as a fallback from the cached traces, and is very
 *
 * @param objectSlug - The Rolodex-id slug for the selected Radar layer (for
 * (radar-demo this is the org-slug, for site this is the site-slug, and for
 * unit this is the unit-slug)
 * @param radarLayerType - The selected RadarLayer (radar-demo | site | unit)
 */
function useGetRepairCostFailurePlot(
  componentSlugs: string[] | null
): SWRResponse<Record<string, any>> {
  const encodedComponentSlugs = componentSlugs
    ? componentSlugs.map(encodeURIComponent)
    : null
  const urls = encodedComponentSlugs
    ? encodedComponentSlugs.map(
        (slug) => `${fdeBackendUrl()}/radar/repair_cost_per_rl?component_slug=${slug}`
      )
    : null
  const auth0 = useAuthInfo()
  // Special case - we need to undonditionally call the SWR to not mess up React, but we
  // swap out the fetcher on null componentSlug to avoid hitting this expensive endpoint
  // unnecessarily
  const responseSwr = useSWR([urls, auth0], ([urls, auth0]) =>
    getMultipleFetcher(urls ?? [], auth0)
  )
  let keyedResponseData: Record<string, any> | undefined
  if (responseSwr.data) {
    keyedResponseData = {}
    for (const slug of componentSlugs ?? []) {
      const response = _.find(responseSwr.data, (d) =>
        d._fetched_url.includes(encodeURIComponent(slug))
      )
      if (response) {
        keyedResponseData[slug] = response
      }
    }
  }
  return {
    ...responseSwr,
    mutate: () => responseSwr.mutate(),
    data: keyedResponseData,
  }
}

export function useGetRepairCostTtlTraces(
  componentSlugs: string[]
): SWRResponse<Record<string, any>> {
  const rcprlTraceQuery = useRolodexEntries({
    type: 'repair-cost-per-rl-fde',
    ids: componentSlugs.map((slug) => `rcprl-${slug}`),
    limit: 4000,
  })
  const componentConfigFDE = useRolodexEntries({
    type: 'forecast-component-config',
    ids: componentSlugs.map((slug) => `${slug}-config`),
    limit: 100,
  })

  const uncachedPlotQuery = useGetRepairCostFailurePlot(
    rcprlTraceQuery.error ? componentSlugs : null
  )

  // Should be a map of component slug -> trace
  const data: Record<string, Record<string, any>> = useMemo(() => {
    const keyedData: Record<string, Record<string, any>> = {}
    if (!rcprlTraceQuery.error) {
      componentSlugs.forEach((slug) => {
        const rolodexEntry = _.find(
          rcprlTraceQuery?.data ?? [],
          (rdEntry) => rdEntry.entry.id === `rcprl-${slug}`
        )
        keyedData[slug] = { ...(rolodexEntry?.entry.data ?? {}), slug }
      })
    } else {
      componentSlugs.forEach((slug) => {
        const trace = uncachedPlotQuery.data?.[slug]
        if (trace) {
          keyedData[slug] = { ...trace, slug }
        }
      })
    }
    function computeRepairCost(y: number, slug: string) {
      const configEntry = _.find(
        componentConfigFDE.data ?? [],
        (d) => d.entry.id === `${slug}-config`
      )
      const fixedRepairCost = configEntry?.entry?.data?.fixed_repair_cost ?? 0
      const repairCostPerSqFt = configEntry?.entry?.data?.repair_cost_per_sq_ft ?? 1000
      return y * repairCostPerSqFt + fixedRepairCost
    }

    return _.mapValues(keyedData, (componentTrace) => {
      return {
        ...componentTrace,
        x: componentTrace?.x ?? [],
        y: (componentTrace?.y ?? []).map((value: number) =>
          computeRepairCost(value, componentTrace.slug)
        ),
      }
    })
  }, [
    componentConfigFDE.data,
    componentSlugs,
    rcprlTraceQuery?.data,
    rcprlTraceQuery.error,
    uncachedPlotQuery.data,
  ])

  return {
    data,
    error: componentConfigFDE.error ?? uncachedPlotQuery.error,
    isLoading:
      rcprlTraceQuery.isLoading ||
      componentConfigFDE.isLoading ||
      uncachedPlotQuery.isLoading,
    isValidating:
      rcprlTraceQuery.isValidating ||
      componentConfigFDE.isValidating ||
      uncachedPlotQuery.isValidating,
    mutate: async () => {
      await rcprlTraceQuery.mutate()
      await componentConfigFDE.mutate()
      await uncachedPlotQuery.mutate()
      return {}
    },
  }
}

export interface InspectionReportDetail extends RolodexFileData {
  visible_name: string
  file_type: string
}

export function useGetInspectionReports(
  auth0: AuthInfo,
  componentSlug?: string
): SWRResponse<InspectionReportDetail[], any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/triage/get_inspection_report?component_slug=${encodeURIComponent(
    componentSlug || ''
  )}`
  return useSWR(componentSlug && auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export interface RepairPlanStat {
  name: string
  area_at_risk: number
  repair_cost: number
}
export function useGetRepairPlanStats(
  componentSlugs: string[]
): SWRResponse<Record<string, RepairPlanStat[]>> {
  const url = `${fdeBackendUrl()}/radar_capex/repair_plan_stats?`
  const auth0 = useAuthInfo()

  const body = {
    slugs: componentSlugs,
  }
  const stringifiedBody = JSON.stringify(body)
  const repairPlanComponentDataSwr = useSWR(
    auth0.headers ? [url, stringifiedBody, auth0] : false,
    ([url, stringifiedBody, auth0]) => postFetcher(url, stringifiedBody, auth0)
  )
  return useMemo(() => {
    const parsed = repairPlanComponentDataSwr.data
      ? JSON.parse(repairPlanComponentDataSwr.data)
      : undefined

    function flattenPlanNameToStats(
      response: Record<string, Record<string, RepairStatValues>>
    ): Record<string, RepairPlanStat[]> {
      return _.mapValues(response, (planNameToValues) => {
        return Object.entries(planNameToValues).map(([planName, repairPlanValues]) => {
          return {
            name: planName,
            area_at_risk: repairPlanValues.area_at_risk,
            repair_cost: repairPlanValues.repair_cost,
          }
        })
      })
    }

    return {
      ...repairPlanComponentDataSwr,
      data: parsed ? flattenPlanNameToStats(parsed) : undefined,
    }
  }, [repairPlanComponentDataSwr])
}

export async function callFDEBackendReCalc(
  componentSlugs: Array<string>,
  auth0: AuthInfo
) {
  const url = `${fdeBackendUrl()}/radar_capex/recalc`
  const body = { component_slugs: componentSlugs }
  return await postFetcher(url, JSON.stringify(body), auth0)
}

export interface RiskMatrixItem {
  risk_category: string
  age_bucket: number
  risk_level: number
  count: number
}

export function useGetRiskMatrix(
  auth0: AuthInfo,
  siteSlug: string
): SWRResponse<RiskMatrixItem[], any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/compliance/risk_matrix/${encodeURIComponent(
    siteSlug
  )}`
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function useGetRiskUnits(
  auth0: AuthInfo,
  siteSlug: string,
  ageMin: number,
  ageMax: number,
  risk_categories: string[]
): SWRResponse<FdeCustomerUnit[], any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/compliance/risk_matrix_units/${encodeURIComponent(
    siteSlug
  )}?age_min=${ageMin}&age_max=${ageMax}&risk_categories=${risk_categories.join(',')}`
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export interface InspectionTimelineItem {
  year: number
  count: number
  group?: string
}
export type DateRetrict = 'future' | 'past' | 'none' | 'now'
export type InspectionDateField =
  | 'inspection_schedule.external.next'
  | 'inspection_schedule.internal.next'
  | 'inspection_schedule.internal.last'
  | 'inspection_schedule.external.last'
export function useGetInspectionTimeline(
  auth0: AuthInfo,
  siteSlug: string,
  dateField: InspectionDateField,
  dateRestrict: DateRetrict,
  group_by_field?: 'equip_area'
): SWRResponse<InspectionTimelineItem[], any, any> {
  const params = [
    `inspection_date_field=${encodeURIComponent(dateField)}`,
    `date_restrict=${encodeURIComponent(dateRestrict)}`,
    group_by_field ? `group_by_field=${encodeURIComponent(group_by_field)}` : null,
  ].filter((param) => param !== null)
  const url = `${fdeBackendUrl()}/asset_manager/compliance/inspection_timeline/${encodeURIComponent(
    siteSlug
  )}?${params.join('&')}`
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export interface InspectionDetails {
  next?: string
  last?: string
  interval_months?: number
}

export interface InspectionType {
  [method: string]: InspectionDetails
}

export interface InspectionScheduling {
  internal: InspectionType
  external: InspectionType
}

export function useHasOverviewData(
  auth0: AuthInfo,
  siteSlug?: string
): SWRResponse<boolean, any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/compliance/has_compliance_data/${encodeURIComponent(
    siteSlug ?? ''
  )}`
  return useSWR(siteSlug && auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function useGetTriageData(
  auth0: AuthInfo,
  siteSlug?: string
): [TriageData, SWRResponse<TriageData, any, any>] {
  const url = `${fdeBackendUrl()}/asset_manager/triage/?site=${encodeURIComponent(
    siteSlug || ''
  )}`
  const triageDataSWR = useSWR(
    siteSlug && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )
  let triageData: TriageData = {}
  if (!triageDataSWR.isLoading && triageDataSWR.data) {
    try {
      triageData = triageDataSWR.data
    } catch (err) {
      console.error(err)
    }
  }
  return [triageData, triageDataSWR]
}

export function useGetTriageUnitData(
  auth0: AuthInfo,
  fdeCustomerUnitSlug?: string,
  siteSlug?: string,
  get_all?: boolean,
  fetch = true
): SWRResponse<LifeExtensionTableData, any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/triage/get_panel_data?site=${encodeURIComponent(
    siteSlug || ''
  )}&customer_unit_slug=${encodeURIComponent(fdeCustomerUnitSlug || '')}${
    get_all ? '&get_all=true' : ''
  }`
  const triageDataSWR = useSWR(
    siteSlug && auth0.headers && fdeCustomerUnitSlug && fetch ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )
  return triageDataSWR
}

export async function rolodex_batch_delete(auth0: AuthInfo, slugs: string[]) {
  const url =
    slugs.length > 0 ? `${fdeBackendUrl()}/asset_manager/triage/delete_ids` : undefined
  const body = {
    ids: slugs,
  }
  return postFetcher(url, JSON.stringify(body), auth0)
}

export async function actionPlan(auth0: AuthInfo, planSlug: string, action: string) {
  const url = `${fdeBackendUrl()}/asset_manager/triage/action_plan`
  const body = {
    plan_id: planSlug,
    action: action,
  }
  return await postFetcher(url, JSON.stringify(body), auth0)
}

export async function deletePlan(auth0: AuthInfo, planSlug: string) {
  const url = `${fdeBackendUrl()}/asset_manager/triage/delete_plan`
  const body = {
    plan_id: planSlug,
  }
  return await postFetcher(url, JSON.stringify(body), auth0)
}

export async function generateInspectionSchedulePdf(
  auth0: AuthInfo,
  startDate: string,
  endDate: string,
  site: string,
  completed = false,
  outageDateRange?: [string, string]
) {
  const url = `${fdeBackendUrl()}/asset_manager/pdf_generation/scheduled_inspections?start_date=${startDate}&end_date=${endDate}&site=${site}&completed=${completed}${
    outageDateRange
      ? `&outage_start=${outageDateRange[0]}&outage_end=${outageDateRange[1]}`
      : ''
  }`
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      // if completed, change the file name to include the word 'completed'
      link.setAttribute(
        'download',
        completed
          ? `completed_inspections_${startDate}-${endDate}.pdf`
          : `scheduled_inspections_${startDate}-${endDate}.pdf`
      )
      document.body.appendChild(link)
      link.click()
    }
  })
}

export async function generateTriagePlanPdf(
  auth0: AuthInfo,
  site?: string,
  fde_customer_unit?: string,
  plan_slug?: string,
  plan_rec_ids?: string[]
) {
  const url = `${fdeBackendUrl()}/asset_manager/pdf_generation/triage_plan_pdf?site=${site}&fde_customer_unit=${fde_customer_unit}&plan_slug=${plan_slug}&plan_rec_ids=${plan_rec_ids?.join(
    ','
  )}`
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `triage_${dayjs().format('YYYY-MM-DD')}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
  })
}

export function archiveAssetRecs(
  auth0: AuthInfo,
  site: string,
  fde_customer_unit: string,
  undo_dismiss?: boolean
) {
  const url = `${fdeBackendUrl()}/asset_manager/triage/${
    undo_dismiss ? 'undo_' : ''
  }dismiss_asset_recommendations`
  const body = { site: site, [RolodexEntryType.CUSTOMER_UNIT]: fde_customer_unit }
  return postFetcher(url, JSON.stringify(body), auth0)
}

export async function generateAssetsDbPdf(
  auth0: AuthInfo,
  site: string,
  tableFilters?: Record<string, FilterValue | null>
) {
  const url = `${fdeBackendUrl()}/asset_manager/pdf_generation/assets_db?site=${site}&filters=${encodeURIComponent(
    JSON.stringify(tableFilters)
  )}`
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `AssetsDb_${dayjs().format('YYYY-MM-DD')}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
  })
}

export function useGetModelingKPIData(
  auth0: AuthInfo,
  siteSlug?: string,
  startDate?: dayjs.Dayjs,
  endDate?: dayjs.Dayjs
): SWRResponse<PlanModelingKPIData, any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/modeling/kpis?site=${encodeURIComponent(
    siteSlug || ''
  )}&start_date=${startDate?.startOf('year').format('YYYY-MM')}&end_date=${endDate
    ?.startOf('year')
    .format('YYYY-MM')}`
  return useSWR(siteSlug && auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function useGetPlanData(
  auth0: AuthInfo,
  site?: string
): [
  Asset[],
  Asset[],
  SWRResponse<
    {
      plan_modeling: Asset[]
      plan_summary: Asset[]
    },
    any,
    any
  >
] {
  const url = `${fdeBackendUrl()}/asset_manager/modeling/?site=${encodeURIComponent(
    site || ''
  )}`
  const PlanDataSWR = useSWR(
    site && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )
  let PlanModelingData: Asset[] = []
  let PlanSummaryData: Asset[] = []
  if (!PlanDataSWR.isLoading) {
    try {
      if (PlanDataSWR?.data?.['plan_modeling']) {
        PlanModelingData = PlanDataSWR.data['plan_modeling']
      }
      if (PlanDataSWR?.data?.['plan_summary']) {
        PlanSummaryData = PlanDataSWR.data['plan_summary']
      }
    } catch (err) {
      /* eslint-disable no-console */
      console.log(err)
      /* eslint-enable no-console */
    }
  }
  return [PlanModelingData, PlanSummaryData, PlanDataSWR]
}

export function submitSelectedPlans(
  auth0: AuthInfo,
  selectedPlans: Record<
    string,
    {
      plan_slug?: string
      action_taken?: string
    }
  >,
  site?: string
) {
  const url = `${fdeBackendUrl()}/asset_manager/modeling/submit_selected_plans?site=${encodeURIComponent(
    site || ''
  )}`
  const body = { selected_plans: selectedPlans }
  return postFetcher(url, JSON.stringify(body), auth0)
}

export function undoSubmitSelectedPlans(
  auth0: AuthInfo,
  selectedAsset: string,
  site?: string
) {
  const url = `${fdeBackendUrl()}/asset_manager/modeling/undo_asset_submit?site=${site}`
  return postFetcher(url, JSON.stringify(selectedAsset), auth0)
}

export function useGetDueAndUnscheduledInspections(
  auth0: AuthInfo,
  site?: string,
  startDate?: string,
  endDate?: string,
  granularity?: 'year' | 'week' | 'month'
): SWRResponse<any, any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/inspections/get_due_unscheduled_graph?granularity=${granularity}&start_interval=${startDate}&end_interval=${endDate}&site=${encodeURIComponent(
    site || ''
  )}`
  //don't want to send nulls to the backend
  const inspectionDataSWR = useSWR(
    startDate && endDate && site && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )

  return inspectionDataSWR
}

export function useGetInspectionsTable(
  auth0: AuthInfo,
  tabKey: 'due' | 'scheduled' | 'history',
  site?: string,
  startDate?: string,
  endDate?: string,
  inspectionType?: string
): SWRResponse<any, any, any> {
  //using the same fetcher for both sets of table data
  const tabKeyEndpointMap = {
    due: 'get_due_unscheduled_table?',
    scheduled: 'get_scheduled_inspections_table?',
    history: 'get_completed_inspections_table?completed=true&',
  }
  let url = `${fdeBackendUrl()}/asset_manager/inspections/${
    tabKeyEndpointMap[tabKey]
  }start_interval=${startDate}&end_interval=${endDate}&site=${encodeURIComponent(
    site || ''
  )}`
  if (inspectionType) {
    url = url + `&inspection_type=${inspectionType}`
  }
  //don't want to send nulls to the backend
  const inspectionDataSWR = useSWR(
    startDate && endDate && site && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )

  return inspectionDataSWR
}

export function useGetScheduledInspections(
  auth0: AuthInfo,
  site?: string,
  startDate?: string,
  endDate?: string,
  completed?: boolean,
  granularity?: 'year' | 'week' | 'month'
): SWRResponse<any, any, any> {
  const url = `${fdeBackendUrl()}/asset_manager/inspections/get_scheduled_inspections_graph?granularity=${granularity}&start_interval=${startDate}&end_interval=${endDate}&site=${encodeURIComponent(
    site || ''
  )}&completed=${completed}`
  //don't want to send nulls to the backend
  const inspectionDataSWR = useSWR(
    startDate && endDate && site && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )

  return inspectionDataSWR
}

export function unmarkCompleteInspection(
  auth0: AuthInfo,
  site?: string,
  inspectionId?: string
) {
  const url = `${fdeBackendUrl()}/asset_manager/inspections/unmark_complete?site=${site}`
  return postFetcher(url, JSON.stringify(inspectionId), auth0)
}

/**
 * Get the Plotly Data[] object used to display the Life Extension Action-Preview
 *
 * @param auth0
 * @param componentSlug
 * @param regionGroup
 */
export function useGetLifeExtensionPreviewData(
  auth0: AuthInfo,
  componentSlug: string,
  regionGroup: string,
  regionSubgroup: string
): SWRResponse<PreviewPlotData> {
  const url = `${fdeBackendUrl()}/asset_manager/triage/get_display_life_extension_plot?component_slug=${encodeURIComponent(
    componentSlug
  )}&region_group=${encodeURIComponent(regionGroup)}&region_subgroup=${encodeURIComponent(
    regionSubgroup
  )}`

  const figureDataSWR = useSWR(
    componentSlug && auth0.headers ? [url, auth0] : false,
    ([url, auth0]) => getFetcher(url, auth0)
  )

  return figureDataSWR
}

export function useGetInspectionTagOptions(auth0: AuthInfo, site?: string) {
  const url = `${fdeBackendUrl()}/asset_manager/inspections/get_inspection_tag_options?site=${encodeURIComponent(
    site || ''
  )}`

  return useSWR(site && auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function updateLatestReport(
  auth0: AuthInfo,
  selectedAsset: string,
  site: string,
  file: File
) {
  const url = `${fdeBackendUrl()}/asset_manager/inspections/update_latest_report?site=${site}&fde_customer_unit=${selectedAsset}&file_name=${
    file.name
  }&root_path=${BACKEND_ROOT_DIR}`
  return putFetcher(url, auth0, file, 'application/octet-stream')
}
