import { DatePicker } from 'antd'
import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

const StyledDatePicker = styled(DatePicker)<{ highVis?: boolean; width?: string }>`
  ${(props) =>
    props.highVis ? `background-color: ${asset_manager_theme.background.high_vis};` : ''}
  font-weight: bold;
  width: ${(props) => props.width || '80%'};
  .ant-picker-input {
    flex-direction: row-reverse;
    > input {
      ::placeholder {
        color: ${(props) =>
          props.disabled ? asset_manager_theme.background.background_color5 : `black`};
      }
      ${(props) => (props.highVis ? `color: black;` : '')}
      text-align: center;
    }
  }
`

export default StyledDatePicker
