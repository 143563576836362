import { Modal } from 'antd'
import React from 'react'
import { TreeNode } from '../Types/FileTreeTypes'

interface DeleteModalProps {
  deleteConfirmVisible: boolean
  setDeleteConfirmVisible: React.Dispatch<React.SetStateAction<boolean>>
  selectedNode: string
  treeData: TreeNode[]
  onDelete: () => void
  getNode: (key: string, data: TreeNode[]) => TreeNode | null
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteConfirmVisible,
  setDeleteConfirmVisible,
  selectedNode,
  treeData,
  onDelete,
  getNode,
}) => {
  return (
    <Modal
      open={deleteConfirmVisible}
      onOk={() => {
        onDelete()
        setDeleteConfirmVisible(false)
      }}
      onCancel={() => setDeleteConfirmVisible(false)}>
      <p>
        {`Are you sure you want to delete ${
          getNode(selectedNode, treeData)?.isLeaf ? 'file' : 'all contents of folder'
        } ${selectedNode}?`}
      </p>
    </Modal>
  )
}
