import { FC, useEffect, useMemo } from 'react'
import { defaultSortedColumn } from '../../api/tableHelpers'
import Table, { ColumnType, ColumnsType } from 'antd/es/table'
import { Checkbox, Typography } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import { ComponentWithSlug } from '../../util/AssetTableHelper'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { FundViewOutlined, LineChartOutlined } from '@ant-design/icons'

const { Text } = Typography

const TableWrapper = styled.div`
  border-collapse: collapse;
  margin: 0.5rem;
  margin-left: 2rem;
  width: 450px;
`

const CenteredContent = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`

function createIncludeInCompareColumn(
  selected: string[],
  setSelected?: (selected: string[]) => void
): ColumnType<ComponentWithSlug> {
  return {
    title: <FundViewOutlined />,
    key: 'isCompareSelected',
    render: (val, row) => {
      return (
        <CenteredContent>
          <Checkbox
            checked={selected.includes(row.slug)}
            onChange={(e) => {
              if (!setSelected) {
                return
              }
              if (e.target.checked) {
                setSelected(_.sortBy(_.uniq([...selected, row.slug])))
              } else {
                setSelected(_.sortBy(selected.filter((s) => s !== row.slug)))
              }
            }}
          />
        </CenteredContent>
      )
    },
  }
}

function createSelectedPreviewColumn(
  selected: string | null,
  setSelectedPreviewSlug?: (selected: string) => void
): ColumnType<ComponentWithSlug> {
  return {
    title: 'Preview',
    key: 'isPreviewSelected',
    render: (val, row) => {
      return (
        <CenteredContent>
          <CheckableTag
            checked={selected === row.slug}
            onChange={(checked) => {
              if (!setSelectedPreviewSlug) {
                return
              }
              if (checked) {
                setSelectedPreviewSlug(row.slug)
              }
            }}>
            <LineChartOutlined size={20} />
          </CheckableTag>
        </CenteredContent>
      )
    },
  }
}

export interface AssetCompareTableProps {
  components: ComponentWithSlug[]
  isLoading?: boolean
  selectedCompareSlugs?: string[]
  setSelectedCompareSlugs?: (selected: string[]) => void

  // If 'selectedPreviewSlug' is `null`, then nothing is checked
  // If it's `undefined`, it'll hide the column
  selectedPreviewSlug?: string | null
  setSelectedPreviewSlug?: (selected: string | null) => void
}

export const AssetCompareTable: FC<AssetCompareTableProps> = (props) => {
  const { components, selectedPreviewSlug, setSelectedPreviewSlug } = props
  useEffect(() => {
    if (setSelectedPreviewSlug) {
      const componentSlugs = components.map((item) => item.slug) ?? []
      if (
        componentSlugs.length > 0 &&
        !componentSlugs?.includes(selectedPreviewSlug ?? '')
      ) {
        setSelectedPreviewSlug(componentSlugs[0])
      }
    }
  }, [components, setSelectedPreviewSlug, selectedPreviewSlug])

  const columnDefs: ColumnsType<ComponentWithSlug> = useMemo(() => {
    const defs = []
    if (!!props.selectedCompareSlugs) {
      defs.push(
        createIncludeInCompareColumn(
          props.selectedCompareSlugs,
          props.setSelectedCompareSlugs
        )
      )
    }
    if (props.selectedPreviewSlug !== undefined) {
      defs.push(
        createSelectedPreviewColumn(
          props.selectedPreviewSlug,
          props.setSelectedPreviewSlug
        )
      )
    }
    defs.push(defaultSortedColumn<ComponentWithSlug>('Asset Name', 'asset_name'))
    return defs
  }, [
    props.selectedCompareSlugs,
    props.selectedPreviewSlug,
    props.setSelectedCompareSlugs,
    props.setSelectedPreviewSlug,
  ])

  return (
    <TableWrapper>
      <Table
        title={() => {
          return (
            <CenteredContent>
              <Text strong>Select Assets to Compare</Text>
            </CenteredContent>
          )
        }}
        columns={columnDefs}
        dataSource={components}
        pagination={false}
        bordered={true}
        rowKey={(row) => row.slug}
        loading={props.isLoading}
        scroll={{ y: '25vh' }}
        tableLayout='auto'
      />
    </TableWrapper>
  )
}
