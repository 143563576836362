import { FC, useContext, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ImpactTable, ImpactTableRow } from './ImpactTable'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import {
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@radix-ui/react-tooltip'

import {
  PlotContainer,
  FiguresContainer,
} from '../../components/StyledGraphContainerComponents'
import { asset_manager_theme } from '../../assets/themes'
import { withResizeDetector } from 'react-resize-detector'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import {
  RiskMatrixItem,
  useGetRiskMatrix,
  useGetRiskUnits,
} from '../../api/useFDEBackend'
import { Spin } from 'antd'
import _ from 'lodash'
import Plot from 'react-plotly.js'
import { SiteContext } from '../../contextProviders/siteContextProvider'

interface TimelineTabProps {
  width: number
  height: number
}

const TooltipContainer = styled.div`
  padding: 1rem;
  background-color: ${asset_manager_theme.background.background_color7};
  color: ${asset_manager_theme.text.c1};
  border-radius: 4px;
  max-width: 20rem;
  text-align: left;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`
const InfoIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
`

const GRAPH_WIDTH_PAD = 20

const RISK_LEVEL_COLORS: Record<number, string> = {
  1: asset_manager_theme.colors.green,
  2: asset_manager_theme.colors.yellow,
  3: asset_manager_theme.colors.red,
}

type GraphSelection = {
  ageMin: number
  ageMax: number
  riskCategories: string[]
}

const ImpactTab: FC<TimelineTabProps> = (props) => {
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)

  const riskMatrixSwr = useGetRiskMatrix(auth0, siteData?.siteSlug ?? '')

  const [graphSelection, setGraphSelection] = useState<GraphSelection | null>(null)
  const tableDataSwr = useGetRiskUnits(
    auth0,
    siteData?.siteSlug ?? '',
    graphSelection?.ageMin ?? 0,
    graphSelection?.ageMax ?? 0,
    graphSelection?.riskCategories ?? []
  )
  const currentYear = new Date().getFullYear()
  const tableData = useMemo<ImpactTableRow[]>(() => {
    if (tableDataSwr.isLoading) {
      return []
    }
    return (
      tableDataSwr?.data?.map((item) => {
        return {
          age: item.data.year_built ? currentYear - item.data.year_built : '',
          riskLevel: item.data.risk_category ?? '',
          year_built: item.data.year_built ?? '',
          equip_id: item.data.erp_id ?? item.data.equip_id,
          equip_description: item.data.equip_description ?? '',
          area: item.data.equip_area ?? '',
          last_external: item.data.inspection_schedule.external?.last ?? '',
          last_internal: item.data.inspection_schedule.internal?.last ?? '',
        }
      }) ?? []
    )
  }, [currentYear, tableDataSwr?.data, tableDataSwr.isLoading])

  const [visible, setVisible] = useState(false)

  return (
    <div
      style={{
        paddingLeft: '3%',
        paddingRight: '3%',
        paddingBottom: '3%',
        paddingTop: '3%',
      }}>
      <FiguresContainer
        style={{
          width: '100%',
        }}>
        <PlotContainer style={{ width: '90%', paddingTop: '3%', paddingBottom: '3%' }}>
          <InfoIconContainer>
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger
                  asChild
                  onMouseEnter={() => setVisible(true)}
                  onMouseLeave={() => setVisible(false)}>
                  <StyledInfoIcon />
                </TooltipTrigger>
                <TooltipContent side={'bottom'}>
                  <TooltipContainer className={visible ? 'visible' : ''}>
                    Point colors correspond to the underlying assets’ risk levels and
                    likelihood of failure, where red = HIGH, yellow = MEDIUM, and green =
                    LOW. Select a point by clicking on it, or select multiple points by
                    clicking and dragging over an area.
                  </TooltipContainer>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </InfoIconContainer>
          <Spin spinning={riskMatrixSwr.isLoading} size='large'>
            <Plot
              style={{ width: '100%' }}
              config={{
                displayModeBar: false,
              }}
              data={
                riskMatrixSwr.data
                  ?.filter((d) => d.age_bucket > 0)
                  .map((riskMatrixItem: RiskMatrixItem) => {
                    return {
                      type: 'scatter',
                      x: [riskMatrixItem.age_bucket],
                      y: [riskMatrixItem.risk_category],
                      name: `${riskMatrixItem.count} Asset${
                        riskMatrixItem.count > 1 ? 's' : ''
                      }`,
                      marker: {
                        size: 12 + (riskMatrixItem.count - 1) * 1.5,
                        color:
                          RISK_LEVEL_COLORS[riskMatrixItem.risk_level] ??
                          asset_manager_theme.colors.orange,
                        line: { width: 1.6, color: 'white' },
                      },
                      mode: 'markers',
                    }
                  }) ?? []
              }
              layout={{
                legend: {
                  title: {
                    text: 'Number of assets',
                  },
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
                hovermode: 'closest',
                dragmode: 'select',
                font: {
                  color: 'rgba(255,255,255,1)',
                },
                xaxis: {
                  title: 'Asset Age (Years)',
                  griddash: 'dash',
                  gridcolor: 'gray',
                },
                yaxis: {
                  title: 'Assessed Risk',
                  categoryorder: 'array',
                  gridcolor: 'gray',
                  griddash: 'dash',
                  categoryarray: ['S2', 'S1', 'P3', 'P2', 'P1'],
                },
                uirevision: 'true',

                margin: { t: 0, b: 40 },
              }}
              onClick={(event) => {
                if (event?.points.length > 0) {
                  const p = event.points?.[0]
                  setGraphSelection({
                    ageMax: parseInt(String(p.x)) + 1,
                    ageMin: parseInt(String(p.x)) - 1,
                    riskCategories: [String(event.points?.[0]?.y)],
                  })
                }
              }}
              onSelected={(event) => {
                if (event?.points?.length !== 0 || !event || event.range) {
                  const selectedPoints = event?.points.map((p) => {
                    return {
                      age_bucket: parseInt(String(p.x)),
                      risk_category: String(p.y),
                    }
                  })
                  const ageMin = selectedPoints
                    .map((p) => p.age_bucket)
                    .reduce((v1, v2) => (v1 < v2 ? v1 : v2))
                  const ageMax = selectedPoints
                    .map((p) => p.age_bucket)
                    .reduce((v1, v2) => (v1 < v2 ? v2 : v1))
                  const riskCategories = _.uniq(
                    selectedPoints.map((p) => p.risk_category)
                  )
                  setGraphSelection({
                    ageMax: ageMax + 1,
                    ageMin: ageMin - 1,
                    riskCategories,
                  })
                }
              }}
              useResizeHandler={true}
            />
          </Spin>
        </PlotContainer>
        <div style={{ width: '90%' }}>
          <ImpactTable impactData={tableData} loading={tableDataSwr.isLoading} />
        </div>
      </FiguresContainer>
    </div>
  )
}

const StyledInfoIcon = styled(InfoCircledIcon)`
  display: inline;
  color: ${asset_manager_theme.text.c1};
`

export default withResizeDetector(ImpactTab)
