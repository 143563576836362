import useSWR from 'swr'

import { components } from '../util/rolodexSchema'
import { Auth0ContextInterface } from '@auth0/auth0-react'

import { useAuth0 } from '@auth0/auth0-react'
import { rolodexUrl, Auth0Audience } from './helpers'
import { deleteFetcher } from './fetchers'
import { AuthInfo } from '../contextProviders/useAuthInfo'
// TODO: there is a post-fetcher defined in this file also, but it uses Auth0ContextInterface instead of AuthInfo -- should we consolidate?
import { postFetcher as postFetcherAuthInfo } from './fetchers'
import { RolodexLoadedEntry } from './useRolodex'

type KeyType = [string, Auth0ContextInterface]
export type LoadedEntry = components['schemas']['LoadedEntry']
export type LoadOptions = components['schemas']['LoadOptions']

export const postFetcher = async (
  url: string | undefined,
  body: string,
  auth0: Auth0ContextInterface
) => {
  if (!url) return

  const accessToken = await auth0.getAccessTokenSilently({
    authorizationParams: { audience: Auth0Audience() },
  })

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: body,
  })

  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    error.cause = await res.json()
    throw error
  }

  return await res.json()
}

export function useRolodexEntries(loadOptions: LoadOptions = {}) {
  const auth0 = useAuth0()
  const endpoint = `${rolodexUrl()}/entries/load`
  const { data, error } = useSWR<Array<LoadedEntry>, Error, KeyType>(
    [endpoint, auth0],
    ([endpoint, auth0]) => postFetcher(endpoint, JSON.stringify(loadOptions), auth0)
  )
  return {
    entries: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function deleteRolodexEntry(id: string, auth0: AuthInfo) {
  const endpoint = `${rolodexUrl()}/entries/${id}`
  return deleteFetcher(endpoint, auth0)
}

/**
 * Get all the Rolodex entries tagged to `parentEntryId` of Rolodex-type `taggedEntryType`
 */
export function getTaggedRolodexEntriesOfType(
  parentEntryId: string,
  parentEntryType: string,
  taggedEntryType: string,
  auth0: AuthInfo
): Promise<RolodexLoadedEntry[]> {
  const endpoint = `${rolodexUrl()}/entries/load`
  const body = {
    type: taggedEntryType,
    tags: { [parentEntryType]: parentEntryId },
    limit: 100,
  }
  return postFetcherAuthInfo(endpoint, JSON.stringify(body), auth0)
}
