import { Tooltip } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { downloadEntryFile } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { Image } from 'antd'
import { asset_manager_theme } from '../../../assets/themes'

interface ImageTooltipProps {
  recommendationId: string
  children?: React.ReactNode
}

const RECOMMENDATION_IMAGE_FILE_NAME = 'repair_image.png'

const ImageTooltip: FC<ImageTooltipProps> = ({ recommendationId, children }) => {
  const authInfo = useAuthInfo()
  const [imageUrl, setImageUrl] = useState<string | undefined | null>(undefined)

  const retrieveRecommendationImage = useCallback(
    async (id: string) => {
      try {
        const fileURL = await downloadEntryFile(
          id,
          RECOMMENDATION_IMAGE_FILE_NAME,
          authInfo
        )
        if (!fileURL) {
          setImageUrl(null) // Immediately set image URL to null if no URL is returned
          return
        }

        const response = await fetch(fileURL, { headers: authInfo.headers })
        if (!response.ok) {
          setImageUrl(null)
          return
        }

        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)
        setImageUrl(blobUrl)
      } catch (error) {
        setImageUrl(null)
      }
    },
    [authInfo]
  )

  useEffect(() => {
    if (recommendationId) {
      retrieveRecommendationImage(recommendationId)
    } else {
      setImageUrl(undefined)
    }
  }, [recommendationId, retrieveRecommendationImage, setImageUrl])

  return (
    <Tooltip
      title={imageUrl ? <Image src={imageUrl} width='28vw' /> : undefined}
      placement='left'
      overlayStyle={{ maxWidth: '30vw' }}
      overlayInnerStyle={{ padding: '1.5rem' }}
      arrow={false}
      color={asset_manager_theme.background.background_color7}>
      {' '}
      {children}
    </Tooltip>
  )
}

export default ImageTooltip
