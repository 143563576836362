import { RolodexPatchBatch } from '../../../../util/RolodexInterface'
import { FileTags } from '../../../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { AuthInfo } from '../../../../contextProviders/useAuthInfo'
import { postFetcher } from '../../../../api/fetchers'
import { rolodexUrl } from '../../../../api/helpers'
import { SiteData } from '../../../../contextProviders/siteContextProvider'
import { RolodexEntryType } from '../../../../util/RolodexTypeConstants'

export const moveFileTags = async (
  sourcePath: string,
  destPath: string,
  fdeCustomerUnitSlug: string,
  authInfo: Readonly<AuthInfo>,
  siteData: SiteData
) => {
  const FDECustomerUnitDataBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    ids: [fdeCustomerUnitSlug],
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  }

  const FDECustomerUnitData = await postFetcher(
    `${rolodexUrl()}/entries/load`,
    JSON.stringify(FDECustomerUnitDataBody),
    authInfo
  )

  const FDECustomerUnitFileTags: FileTags =
    FDECustomerUnitData?.[0]?.entry?.data?.file_tags ?? {}

  const newFileTags: { [tag: string]: string[] } = {}

  // Loop through all tags in fileTags
  Object.entries(FDECustomerUnitFileTags).forEach(([tag, paths]) => {
    // Start by taking all paths that are not the sourcePath
    const newPaths = paths.filter((path) => path !== sourcePath)
    // Check if the sourcePath was in the original paths
    if (paths.includes(sourcePath)) {
      // Replace the sourcePath with destinationPath in the newPaths
      newPaths.push(destPath)
    }
    newFileTags[tag] = newPaths
  })

  const FDECustomerUnitDataPatchBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    id: fdeCustomerUnitSlug,
    data: {
      file_tags: newFileTags,
    },
  }

  // Call RolodexPatchBatch with the assembled patch body
  await RolodexPatchBatch([FDECustomerUnitDataPatchBody], authInfo, siteData)
}
