import { ArrowLeftIcon } from '@radix-ui/react-icons'
import styled, { css } from 'styled-components'
import * as Separator from '@radix-ui/react-separator'
import { GeckoTheme } from 'gecko-ui'
import { Input } from 'antd'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useAnimationControls, motion } from 'framer-motion'
import { createContext, useContext } from 'react'
import { DebouncedFunc } from 'lodash'

export const BackIcon = styled(ArrowLeftIcon)`
  color: white;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
`

export const StyledBackContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.greyscale[500]};
  }
`

export const StyledSeparator = styled(Separator.Root)`
  background-color: ${GeckoTheme.colors.greyscale[500]};
  margin: 1.5rem;
  margin-right: 1rem;

  &[data-orientation='vertical'] {
    height: 60%;
    width: 1px;
  }
`

export const LeftContentContainer = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: start;
  width: max-content;
`

export const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
`

export const StyledSearch = styled(Input)`
  background-color: ${({ theme }) => theme.colors.greyscale[600]} !important;
  color: ${({ theme }) => theme.colors.greyscale[100]} !important;
  width: 500px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.greyscale[500]} !important;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.greyscale[500]} !important;
  }
`

export const MenuStyles = css`
  overflow: hidden;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  text-align: left;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: ${GeckoTheme.colors.greyscale[700]};
`

export const FilteredSearchContainer = styled.div`
  ${MenuStyles}
  padding: 0.5rem;
`

export const StyledDropdownContent = styled(DropdownMenu.Content)`
  ${MenuStyles}
`

const StyledMotionDiv = styled(motion.div)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DropdownSearchMenuContext = createContext<{
  closeMenu: () => void
  firstItemRef: React.MutableRefObject<HTMLDivElement | null>
  inputRef: React.MutableRefObject<HTMLInputElement | null>
  searchText: string
  debouncedResults?: DebouncedFunc<(e: React.ChangeEvent<HTMLInputElement>) => void>
}>({
  firstItemRef: { current: null },
  inputRef: { current: null },
  searchText: '',
  closeMenu: () => {
    // noop
  },
  debouncedResults: undefined,
})

export const StyledDropdownItem = styled(DropdownMenu.Item)`
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
  user-select: none;
  border: 1px solid transparent;

  &[data-highlighted] {
    color: white;
    border-color: ${(props) => props.theme.colors.greyscale[500]};
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`

export function DropdownSearchMenuItem({
  isFirstItem,
  children,
  onSelect = () => {
    // noop
  },
}: {
  isFirstItem?: boolean
  children: React.ReactNode
  onSelect?: () => void
}) {
  const controls = useAnimationControls()
  const { closeMenu, firstItemRef } = useContext(DropdownSearchMenuContext)

  return (
    <StyledDropdownItem
      style={{
        marginTop: isFirstItem ? '0.5rem' : '',
      }}
      ref={isFirstItem ? firstItemRef : undefined}
      onSelect={async (e) => {
        e.preventDefault()

        await controls.start({
          backgroundColor: GeckoTheme.colors.greyscale[400],
          color: '#000',
          transition: { duration: 0.04 },
        })
        await controls.start({
          backgroundColor: GeckoTheme.colors.greyscale[700],
          color: '#fff',
          transition: { duration: 0.04 },
        })

        await closeMenu()
        onSelect()
      }}
      asChild>
      <StyledMotionDiv animate={controls}>{children}</StyledMotionDiv>
    </StyledDropdownItem>
  )
}
