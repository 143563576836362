import Config from '../../config.json'

// DON'T PUT SENSITIVE VALUES IN THIS FILE

//------------------------------------------------------------------------------

export const Environment = () => Config.ENVIRONMENT
export const IsProd = () => Config.ENVIRONMENT === 'prod'
export const SentryDsn = () => Config.SENTRY_DSN

//------------------------------------------------------------------------------

// While running in docker after vite has built the index.js file, it seems like
// there is some optimization where the environment variable is not evaluated at
// runtime, but at build time. This is a workaround to force that resolution at
// runtime.
export const isAuth0Authentication = () => `${Config.RUNNING_ON}` !== '2f'

export const Auth0Audience = () => Config.AUTH0_AUDIENCE

export const BasePath = () => Config.BASE_PATH

//------------------------------------------------------------------------------

export const AssetFixUrl = () => Config.ASSET_FIX_URL

export const ForecastUrl = () => Config.FORECAST_URL
