import React from 'react'
import { InspectionType } from '../../api/useFDEBackend'
import { OverviewRecord } from './ComplianceInterface'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import { defaultSort } from '../../api/tableHelpers'
import { trackEvent } from '../../components/helpers/mixpanel'

interface OverviewTableProps {
  records: OverviewRecord[]
  loading?: boolean
  setSelectedFdeCustomerUnitSlug: (slug: string) => void
}

const extractUniqueMethods = (records: OverviewRecord[]) => {
  const methods = new Set<string>()

  records.forEach((record) => {
    ;(['internal', 'external'] as const).forEach((type) => {
      const scheduling = record.inspection_schedule?.[type]
      Object.keys(scheduling ?? {}).forEach((method) => {
        //this is beyond stupid
        methods.add(`${type}&%&${method}`) // Adjust as needed to match your naming
      })
    })
  })

  return Array.from(methods)
}

const createMethodColumns = (methodName: string) => {
  const [type, ...methodParts] = methodName.split('&%&')
  const cleanName = methodParts.join('')
  const baseTitle = type === 'internal' ? 'Internal' : 'External'

  return [
    {
      title: `${baseTitle}: ${cleanName} Next`,
      dataIndex: methodName,
      key: `${methodName}-next`,
      render: (_: unknown, record: OverviewRecord) => {
        const details =
          record.inspection_schedule?.[type as 'internal' | 'external']?.[
            cleanName as keyof InspectionType
          ]
        return details?.next || '-'
      },
    },
    {
      title: `${baseTitle}: ${cleanName} Last`,
      dataIndex: methodName,
      key: `${methodName}-last`,
      render: (_: unknown, record: OverviewRecord) => {
        const details =
          record.inspection_schedule?.[type as 'internal' | 'external']?.[
            cleanName as keyof InspectionType
          ]
        return details?.last || '-'
      },
    },
    {
      title: `${baseTitle}: ${cleanName} Interval`,
      dataIndex: methodName,
      key: `${methodName}-interval`,
      render: (_: unknown, record: OverviewRecord) => {
        const details =
          record.inspection_schedule?.[type as 'internal' | 'external']?.[
            cleanName as keyof InspectionType
          ]
        return details?.interval_months?.toString() || '-'
      },
    },
  ]
}

export const OverviewTable: React.FC<OverviewTableProps> = (props) => {
  const uniqueMethods = extractUniqueMethods(props.records)
  const methodColumns = uniqueMethods.flatMap(createMethodColumns) // FlatMap to flatten the array of arrays

  const tableColumns: ColumnsType<OverviewRecord> = [
    {
      title: 'Equip Desc.',
      dataIndex: 'equip_description',
      key: 'equip_description',
      fixed: true,
      sorter: defaultSort('equip_description'),
      onCell: (record) => {
        return {
          onClick: () => {
            props.setSelectedFdeCustomerUnitSlug(record.id)
            trackEvent('Button Clicked', {
              buttonName: 'asset_baseball_card',
              pageName: 'Overview',
              assetDetails: {
                assetName: record.equip_description,
              },
            })
          },
        }
      },
      render: (text) => (
        <div style={{ cursor: 'pointer', maxWidth: '40rem' }}>{text}</div>
      ),
    },
    {
      title: 'Equip ID',
      dataIndex: 'erp_id',
      key: 'erp_id',
      sorter: defaultSort('erp_id'),
    },
    {
      title: 'Area',
      dataIndex: 'equip_area',
      key: 'equip_area',
      sorter: defaultSort('equip_area'),
    },
    ...methodColumns,
  ]
  return (
    <Table
      rowKey={(row) => row.id}
      dataSource={props.records}
      columns={tableColumns}
      pagination={{ defaultPageSize: 8 }}
      scroll={{ x: 'max-content' }}
      loading={props.loading ?? false}
    />
  )
}
