import { FC, useState } from 'react'
import { RolodexPdfViewer, RolodexPdfViewerProps } from './RolodexPdfViewer'
import { Modal } from 'antd'

export interface RolodexPdfViewerModalProps
  extends Omit<RolodexPdfViewerProps, 'width' | 'setWidth'> {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const RolodexPdfViewerModal: FC<RolodexPdfViewerModalProps> = (props) => {
  const [width, setWidth] = useState<number>(600)

  return (
    <Modal
      open={props.isOpen}
      onCancel={() => props.setIsOpen(false)}
      onOk={() => props.setIsOpen(false)}
      // Don't keep the modal around after closing - the PDF can be big
      destroyOnClose={true}
      footer=''
      width={width}
      closeIcon={null}>
      <RolodexPdfViewer {...props} width={width} setWidth={setWidth} />
    </Modal>
  )
}
