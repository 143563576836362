import React, { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'

type RawItem = Record<string, any>

interface Item extends RawItem {
  key: string
}

interface EditableColumnProps {
  title?: string
  dataIndex: string
  width?: string
  editable?: boolean
  inputType?: string
}

interface EditableTableProps {
  columns: EditableColumnProps[]
  dataSource: RawItem[]
  onRowSave: (record: RawItem) => void
  editDisabled?: boolean
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: string
  record: Item
  index: number
  children: React.ReactNode
}

const inputTypeMap = {
  number: <InputNumber />,
  text: <Input />,
  date: <DatePicker />,
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputTypeMap[inputType as keyof typeof inputTypeMap]

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const EditableTable: React.FC<EditableTableProps> = ({
  columns,
  dataSource,
  onRowSave,
  editDisabled,
}) => {
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')

  const [data, setData] = useState(
    dataSource.map((d, i) => {
      return { ...d, key: i.toString() }
    })
  )

  useEffect(() => {
    //set the data since it might take a sec for itto load
    setData(
      dataSource.map((d, i) => {
        return { ...d, key: i.toString() }
      })
    )
  }, [dataSource])

  const isEditing = (record: Item) => record.key === editingKey

  const edit = (record: Partial<Item> & { key: string }) => {
    form.setFieldsValue({ ...record })
    setEditingKey(record?.key as string)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item

      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)
      if (index > -1) {
        const item = newData[index]
        const newRow = {
          ...item,
          ...row,
        }
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        setData(newData)
        setEditingKey('')
        onRowSave(newRow)
      } else {
        //new row that was created. Need to figure out how to do this
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }
  const operationColumn = {
    // Purposefully title-less
    dataIndex: 'operation',
    render: (_: any, record: Item) => {
      const editable = isEditing(record)
      return editable ? (
        <span>
          <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
            Save
          </Typography.Link>
          <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
            <a>Cancel</a>
          </Popconfirm>
        </span>
      ) : (
        <Button
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
          style={{ border: 'none', backgroundColor: 'transparent' }}>
          <EditOutlined />
        </Button>
      )
    },
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.inputType ?? 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  if (!editDisabled) {
    mergedColumns.push(operationColumn)
  }

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  )
}

export default EditableTable
