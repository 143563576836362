import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

interface PillProps {
  text: string | null | undefined
  highViz?: boolean
}

export const Pill = styled.div<PillProps>`
  width: 12rem;
  font-size: 0.75rem;
  padding: ${({ text }) => (text ? '0.15rem' : '0')};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: center;
  border-radius: 6px;
  display: inline;
  margin: 1rem;
  ${({ highViz }) =>
    highViz
      ? `border: 0.1rem solid ${asset_manager_theme.background.high_vis};
      color: ${asset_manager_theme.background.high_vis};`
      : `background-color: ${asset_manager_theme.background.background_color5};
      color: ${asset_manager_theme.background.background_color2};
      `}
`
