import React from 'react'
import { Table } from 'antd'
import { AssetsDatabaseTableRow } from './AssetDatabaseTabInterface'
import { ColumnsType } from 'antd/es/table'

interface AssetTableProps {
  data: AssetsDatabaseTableRow[]
  columns: ColumnsType<AssetsDatabaseTableRow>
  handleContextMenu?: (
    event: React.MouseEvent<HTMLTableRowElement>,
    record: AssetsDatabaseTableRow
  ) => void
  dropdownVisible: boolean
  loading?: boolean
  onChange?: (pagination: any, filters: any, sorter: any) => void
}

const AssetTable: React.FC<AssetTableProps> = (props) => {
  return (
    <Table
      id='asset-database-table'
      bordered
      dataSource={props.data}
      columns={props.columns}
      rowKey={(record) => record.id}
      scroll={{ x: 'max-content' }}
      onRow={(record) =>
        props.handleContextMenu
          ? {
              onContextMenu: (event) => props.handleContextMenu?.(event, record),
            }
          : {}
      }
      loading={props.loading}
      onChange={props.onChange}
      // fixes the overflow on drop down https://github.com/ant-design/ant-design/issues/29366
      getPopupContainer={() =>
        document.getElementById('asset-database-table') as HTMLElement
      }
    />
  )
}

export default AssetTable
