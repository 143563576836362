import { Modal, Spin } from 'antd'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { dateSort, defaultSort } from '../../../api/tableHelpers'
import { isOutageObject } from '../../../util/types/interfaces'
import { OutageObject } from '../../../util/types/interfaces'
import dayjs from 'dayjs'
import EditableTable from '../../../components/Common/EditableTableComponent'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { SWRResponse } from 'swr'
import { RolodexLoadedEntry, RolodexEntry } from '../../../api/useRolodex'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { FC, useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { trackEvent } from '../../../components/helpers/mixpanel'
import InspectionHoursEditor from './InspectionHoursEditor'
import { TabKey } from '../../AppLayout'

interface SiteSettingsProps {
  outageScheduleSwr: SWRResponse<RolodexLoadedEntry<RolodexEntry>[], any, any>
  fdeInspectionScheduleConfigSwr: SWRResponse<any, any, any>
}

export const SiteSettings: FC<SiteSettingsProps> = (props) => {
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const auth0 = useAuthInfo()
  const lockOutEnabled =
    userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only ?? false

  const processedOutageSchedule: OutageObject[] = useMemo(
    () =>
      (
        props.outageScheduleSwr.data?.reduce((agg, d) => {
          const outageObject = {
            ...(d.entry.data as OutageObject),
            date: dayjs(d.entry.data?.date),
            entryId: d.entry.id,
          }
          if (isOutageObject(outageObject)) {
            agg.push(outageObject)
          }
          return agg
        }, new Array<OutageObject>()) ?? []
      ).filter((d) => dayjs(d.date).isAfter(dayjs())),
    [props.outageScheduleSwr.data]
  )

  const [stagedRecord, setStagedRecord] = useState<Record<any, any> | undefined>(
    undefined
  )
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const onTableSave = (record: Record<any, any>) => {
    setIsModalVisible(true)
    setStagedRecord(record)
  }

  const handleSave = () => {
    if (stagedRecord && stagedRecord.entryId) {
      const entryPatchBody = {
        id: stagedRecord.entryId,
        data: {
          date: stagedRecord.date?.format('YYYY-MM-DD'),
          type: stagedRecord.type,
          system: stagedRecord.system,
        },
        tags: {
          organization: siteData?.orgSlug,
          site: siteData?.siteSlug,
        },
      }

      trackEvent('Button Clicked', {
        buttonName: 'edit_outage',
        pageName: 'Outages',
        raw: stagedRecord,
      })

      RolodexPatchBatch([entryPatchBody], auth0, siteData).then(() => {
        props.outageScheduleSwr.mutate()
      })
    }
  }

  const outageTypes = useMemo(() => {
    return _.sortBy(_.uniq(processedOutageSchedule.map((sch) => sch.type)))
  }, [processedOutageSchedule])
  const outageSystems = useMemo(() => {
    return _.sortBy(_.uniq(processedOutageSchedule.map((sch) => sch.system)))
  }, [processedOutageSchedule])

  const columnDefs = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      inputType: 'date',
      editable: true,
      sorter: dateSort('date'),
      render(text: string) {
        return dayjs(text).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: defaultSort('type'),
      filters: outageTypes.map((type) => {
        return {
          text: type,
          value: type,
        }
      }),
      onFilter: (value: string, rec: any) => rec.type === value,
    },
    {
      title: 'System',
      dataIndex: 'system',
      key: 'system',
      sorter: defaultSort('system'),
      filters: outageSystems.map((system) => {
        return {
          text: system,
          value: system,
        }
      }),
      onFilter: (value: string, rec: any) => rec.system === value,
    },
  ]
  return (
    <div>
      {(userFeatureFlags?.superUser ||
        userFeatureFlags?.[TabKey.inspectionScheduling]?.enableHours) && (
        <InspectionHoursEditor
          fdeInspectionScheduleConfigSwr={props.fdeInspectionScheduleConfigSwr}
        />
      )}
      {(userFeatureFlags?.superUser ||
        userFeatureFlags?.[TabKey.inspectionScheduling]?.enableOutages) && (
        <div>
          <div style={{ marginBottom: '0.5rem' }}>
            <h4>Outages</h4>
          </div>
          <Spin
            spinning={
              props.outageScheduleSwr.isLoading || props.outageScheduleSwr.isValidating
            }>
            <EditableTable
              editDisabled={lockOutEnabled} // CheckLockout
              columns={columnDefs}
              dataSource={processedOutageSchedule}
              onRowSave={onTableSave}
            />
          </Spin>
          <Modal
            title={``}
            open={isModalVisible}
            onOk={() => {
              handleSave()
              setIsModalVisible(false)
              setStagedRecord(undefined)
            }}
            onCancel={() => {
              setIsModalVisible(false)
              setStagedRecord(undefined)
            }}>
            Are you sure you want to change the date of this outage to{' '}
            {stagedRecord?.date?.format('YYYY-MM-DD')}? This action cannot be undone.
          </Modal>{' '}
        </div>
      )}
    </div>
  )
}
