import ReactDOM from 'react-dom/client'

import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { SentryDsn, BasePath } from './api/defines'

import { Suspense } from 'react'

if (SentryDsn()) {
  Sentry.init({
    dsn: SentryDsn(),
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
  })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Router basename={BasePath()}>
    <Suspense fallback={<div>Loading…</div>}>
      <App />
    </Suspense>
  </Router>
)
