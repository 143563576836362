import React, { useContext, useState, useMemo, useEffect } from 'react'
import SlidePanel from '../SlidePanel'
import { CloseButton } from '../CloseButton'
import { useRolodexEntries } from '../../../api/useRolodex'
import { Row, Column } from '../Grid'
import InlineTile from './InlineTile'
import { asset_manager_theme } from '../../../assets/themes'
import { SWRResponse } from 'swr'
import { toTitleCase } from '../../helpers/stringFilters'
import { Table, message, Typography } from 'antd'
import { FolderOpenOutlined } from '@ant-design/icons'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import merge from 'deepmerge-json'
import Button from '../Button'
import UploadButton from '../UploadButton'
import { InspectionReportCard } from '../../../pages/RecommendationsTab/lifeExtensionOptionsTab/InspectionCardComponent'
import UploadLatestInspection from '../../helpers/UploadLatestInspection'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { trackEvent } from '../../helpers/mixpanel'
import FileBrowser from '../FileBrowser/FileBrowser'
import { archiveAssetRecs, useGetTriageUnitData } from '../../../api/useFDEBackend'
import { Tabs } from 'antd'
import { currencyConverter } from '../../helpers/stringFilters'
import { PriorityPill } from '../PriorityPill'
import TruncatedTextWithTooltip from '../TruncatedTextWithTooltip'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import { useGenerateForecastButton } from '../Methods/useGenerateForecastButtons'
import AssetBaseBallCardInspectionTable from './AssetBaseBallCardInspectionsTable'
import { Image } from 'antd'
import styled from 'styled-components'
import { TabKey } from '../../../pages/AppLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  FDECustomerUnit,
  FDECustomerUnitData,
} from '../../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { downloadEntryFile } from '../../../util/RolodexInterface'
import RadarModule, { Location, MarkerData } from '../../RadarModule/RadarModule'
import { AssetMappingModal } from '../../RadarModule/AssetMappingModal'
import mapboxgl from 'mapbox-gl'

export interface FlattenedAssetData extends FDECustomerUnitData {
  height_ft?: number
  diameter_ft?: number
  ruggable?: string
}

interface AssetBaseBallCardProps {
  fdeCustomerUnitSlug?: string
  setPanelActive: (active: any) => void
  originPage: string
  refetchData?: { func: () => void }
  setSelectedTab: (tabKey: TabKey) => void
  setTriageSidePanelFdeUnit: (fdeUnit: string) => void
}

const ImageContainer = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  position: relative;
  overflow: hidden;
`

const HorizontalImageContainer = styled.div`
  width: 100%;
  padding: 1rem;
  height: 14rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  position: relative;
  overflow: hidden;
`

const { Text } = Typography

const AssetBaseBallCard: React.FC<AssetBaseBallCardProps> = ({
  setPanelActive,
  fdeCustomerUnitSlug,
  refetchData,
  originPage,
  setSelectedTab,
  setTriageSidePanelFdeUnit,
}) => {
  const navigate = useNavigate()

  const [isFileBrowserVisible, setIsFileBrowserVisible] = useState(false)
  const [assetImageData, setAssetImageData] = useState<string | undefined | null>(
    undefined
  )

  const IMAGE_TAGS = [
    {
      key: 'asset_image',
      setter: setAssetImageData,
    },
  ]
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const authInfo = useAuthInfo()

  const FDESiteBody = {
    type: RolodexEntryType.FDE_SITE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
    },
  }

  const FDESiteSWR = useRolodexEntries(FDESiteBody)
  const [FDESiteData, setFDESiteData] = useState<Record<any, any> | undefined>(undefined)

  useEffect(() => {
    if (!FDESiteSWR.error && !FDESiteSWR.isLoading && FDESiteSWR.data) {
      if (!FDESiteSWR.data?.[0]?.entry?.data?.radar) {
        return
      }
      setFDESiteData(FDESiteSWR.data)
    }
  }, [FDESiteSWR.data, FDESiteSWR.error, FDESiteSWR.isLoading])

  const ruggableMappingSWR = useRolodexEntries(
    userFeatureFlags?.superUser
      ? {
          ids: [`${siteData?.siteSlug}-ruggable-mapping`],
          type: RolodexEntryType.SITE_CONFIG_TYPE,
          tags: {
            site: siteData?.siteSlug,
            organization: siteData?.orgSlug,
          },
        }
      : {}, // provide an empty or fallback request when userFeatureFlags?.superUser is not true
    undefined,
    userFeatureFlags?.superUser && !!siteData?.orgSlug
  )

  const inspectionScheduleLockOutEnabled = useMemo(() => {
    return userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only ?? false
  }, [userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only])

  const [messageApi, contextHolder] = message.useMessage()
  const fdeCustomerUnitSWR: SWRResponse<FDECustomerUnit[], any, any> = useRolodexEntries(
    {
      type: RolodexEntryType.CUSTOMER_UNIT,
      limit: 1,
      ids: [fdeCustomerUnitSlug],
    },
    {},
    !!fdeCustomerUnitSlug
  )

  const triageUnitDataSWR = useGetTriageUnitData(
    authInfo,
    fdeCustomerUnitSlug,
    siteData?.siteSlug,
    true,
    !!fdeCustomerUnitSlug &&
      (userFeatureFlags?.superUser || !!userFeatureFlags?.[TabKey.recommendations])
  )
  const triageUnitData = triageUnitDataSWR.data

  const assetBatch: SWRResponse<any, any, any> = useRolodexEntries(
    {
      type: 'fde-am-batch',
      limit: 1,
      tags: {
        [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
      },
    },
    {},
    !!fdeCustomerUnitSlug
  )

  const forecastButtons = useGenerateForecastButton(fdeCustomerUnitSlug)

  const currentBatchHistory =
    assetBatch.data?.[0]?.entry?.data.history?.[
      assetBatch.data?.[0]?.entry?.data?.batch_id || ''
    ]

  const assetData: FDECustomerUnitData = merge(
    fdeCustomerUnitSWR.data?.[0]?.entry?.data,
    fdeCustomerUnitSWR.data?.[0]?.entry?.data?.overrides
  )

  // Create marker data only of latitude and longitude are available
  const markerData: MarkerData = useMemo(
    () => ({
      color: fdeCustomerUnitSWR.data?.[0]?.entry?.data?.marker_color,
      longitude:
        fdeCustomerUnitSWR.data?.[0]?.entry?.data?.longitude ||
        FDESiteData?.[0]?.entry?.data?.radar?.longitude ||
        0,
      latitude:
        fdeCustomerUnitSWR.data?.[0]?.entry?.data?.latitude ||
        FDESiteData?.[0]?.entry?.data?.radar?.latitude ||
        0,
      title: fdeCustomerUnitSWR.data?.[0]?.entry?.data?.equip_description || 'Invalid ID',
      id: fdeCustomerUnitSWR.data?.[0]?.entry.id,
    }),
    [FDESiteData, fdeCustomerUnitSWR.data]
  )

  const center: Location = useMemo(
    () => ({
      longitude: markerData.longitude,
      latitude: markerData.latitude,
      zoom: FDESiteData?.[0]?.entry?.data?.radar?.zoom || 15,
    }),
    [FDESiteData, markerData.latitude, markerData.longitude]
  )

  const [editAssetModalVisible, setEditAssetModalVisible] = useState(false)

  const editAssetLocation = () => {
    return (
      (userFeatureFlags?.superUser || userFeatureFlags?.[TabKey.radar]) && (
        <Button
          style={{ fontSize: '.85rem' }}
          onClick={() => {
            setEditAssetModalVisible(true)
          }}>
          {fdeCustomerUnitSWR.data?.[0]?.entry?.data.longitude &&
          fdeCustomerUnitSWR.data?.[0]?.entry?.data.latitude
            ? 'Edit Location'
            : 'Add Location'}
        </Button>
      )
    )
  }

  const handleUploadFinally = () => {
    assetBatch.mutate()
    fdeCustomerUnitSWR.mutate()
    refetchData?.func()
    //when modfiying file data force it to refetch the next time its opened
  }

  const dataLabelMap: Record<string, string> = {
    erp_id: 'ERP ID',
    equip_id: 'Equip ID',
    area_description: 'Area Descr.',
    equip_area: 'Area',
    risk_category: 'Risk',
    year_built: 'Year Built',
    outage_type: 'Outage',
    material: 'Material',
    national_board: 'Ntl Board #',
    height_ft: 'Height (ft)',
    diameter_ft: 'Diameter (ft)',
    orientation: 'Orientation',
    insulated: 'Insulated',
    stiffness: 'Stiffeners',
    contents: 'Contents',
    pH: 'pH',
    pressure: 'Pressure (PSIG)',
    temp: 'Temp (F)',
    type: 'Type',
    customer_corrosion_rate: 'Corrosion Rate',
    customer_remaining_life: 'Remaining Life (yrs)',
    ruggable: 'Ruggable',
  }

  const flattenedAssetData: FlattenedAssetData = {
    ...assetData,
    height_ft: assetData?.dimensions?.height_ft,
    diameter_ft: assetData?.dimensions?.diameter_ft,
    ruggable:
      userFeatureFlags?.superUser &&
      ruggableMappingSWR?.data?.length &&
      fdeCustomerUnitSlug
        ? ruggableMappingSWR.data[0]?.entry?.data?.[fdeCustomerUnitSlug]
        : undefined,
  }

  const retrieveAssetImage = async (imageTag = 'asset_image') => {
    if (
      flattenedAssetData.file_tags &&
      flattenedAssetData.file_tags?.[imageTag]?.length > 0 &&
      fdeCustomerUnitSlug
    ) {
      const fileURL = await downloadEntryFile(
        fdeCustomerUnitSlug,
        flattenedAssetData.file_tags[imageTag][0],
        authInfo
      )
      // Fetch the actual file as Blob
      const response = await fetch(fileURL, { headers: authInfo.headers })
      return await response.blob()
    } else {
      return undefined
    }
  }

  useEffect(() => {
    setAssetImageData(undefined)
    IMAGE_TAGS.forEach((d) => {
      retrieveAssetImage(d.key).then((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob)
          d.setter(url)
        }
      })
    })
  }, [flattenedAssetData.file_tags, fdeCustomerUnitSlug])

  const generateColumn = (data: { key: string; unit?: string }[], columnHeight = 2) => {
    const chunkedData = data.reduce(
      (resultArray: { key: string; unit?: string }[][], item, index) => {
        const chunkIndex = Math.floor(index / columnHeight)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      },
      []
    )

    return chunkedData.map((data, chunkIndex) => {
      return (
        <Column key={`column-${chunkIndex}-${data[0].key}`}>
          {data.map((column, columnIndex) => {
            let value =
              flattenedAssetData?.[column.key as keyof FDECustomerUnitData] ?? '--'
            //check if its a number and that it has a decimal
            if (typeof value === 'number' && value % 1 !== 0) {
              value = value.toFixed(4)
            }
            return (
              <div key={`column-item-${chunkIndex}-${columnIndex}`}>
                <p
                  style={{
                    fontSize: '.8rem',
                    color: asset_manager_theme.background.background_color3,
                  }}>
                  {dataLabelMap[column.key as keyof object]}
                </p>
                <p style={{ marginBottom: '.4rem' }}>
                  {`${String(value)} ${column.unit || ''}`}
                </p>
              </div>
            )
          })}
        </Column>
      )
    })
  }

  const planColumnDefs = [
    {
      title: 'Action',
      dataIndex: 'recommendation',
      key: 'recommendation',
      render: (text: string) => {
        return (
          <TruncatedTextWithTooltip
            maxCharacters={100}
            text={text}></TruncatedTextWithTooltip>
        )
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (text: string) => {
        return text ? <PriorityPill text={text}>{text}</PriorityPill> : '--'
      },
      align: 'center' as const,
    },
    {
      title: 'Deadline',
      dataIndex: 'scheduled_date',
      key: 'deadline',
      render: (text: string) => {
        return text ?? '--'
      },
      align: 'center' as const,
    },
    {
      title: 'WO No.',
      dataIndex: 'work_order_number',
      key: 'work_order_number',
      render: (text: string) => {
        return text ?? '--'
      },
      align: 'center' as const,
    },
    {
      title: 'Est Cost',
      dataIndex: 'repair_cost',
      key: 'repair_cost',
      render: (text: string) => {
        return text ? currencyConverter(parseInt(text)) : '--'
      },
      align: 'center' as const,
    },
  ]

  // {
  //   label: 'All Actions',
  //   key: 'repairs',
  //   children: (
  //     <Table
  //       dataSource={Object.values(triageUnitData?.repairs || {})}
  //       columns={planColumnDefs}
  //     />
  //   ),
  // },
  const planTabItems = (triageUnitData?.LifeExtensionOptions || []).map((plan) => {
    return {
      label: plan.action_taken === 'Submitted' ? `${plan.plan_name} ✓` : plan.plan_name,
      key: plan.plan_slug || '',
      children: (
        <Table
          dataSource={Object.values(plan.plan_recs || {})}
          columns={planColumnDefs}
        />
      ),
    }
  })

  return (
    <SlidePanel
      active={!!fdeCustomerUnitSlug}
      width='40%'
      panelStyle={{ overflowY: 'scroll' }}>
      {contextHolder}
      {fdeCustomerUnitSlug && (
        <div
          style={{
            color: 'white',
            width: '100%',
            padding: '.5rem',
            overflowY: 'scroll',
          }}>
          <Row
            style={{
              width: '100%',
              padding: '1.5rem 1.5rem 0rem 1.5rem',
            }}>
            <Column>
              <Row style={{ padding: '0rem', margin: '0rem' }}>
                <h2>{assetData?.equip_description || 'Invalid ID'}</h2>
                <Button
                  onClick={() => {
                    setIsFileBrowserVisible(true)
                  }}
                  style={{ marginLeft: '1rem', width: '7rem' }}>
                  <FolderOpenOutlined />
                  <span style={{ marginLeft: '1rem' }}>Files</span>
                </Button>
                {isFileBrowserVisible && (
                  <FileBrowser
                    open={isFileBrowserVisible}
                    onCancel={() => {
                      setIsFileBrowserVisible(false)
                    }}
                    fdeCustomerUnitSlug={fdeCustomerUnitSlug}
                    assetName={assetData?.equip_description}
                    dataRefetch={() => {
                      fdeCustomerUnitSWR.mutate()
                    }}
                  />
                )}
                {forecastButtons}
              </Row>
              {userFeatureFlags?.superUser && <Row>{fdeCustomerUnitSlug}</Row>}
            </Column>

            <CloseButton onClick={() => setPanelActive(undefined)} />
          </Row>
          <Row>
            <InlineTile>
              <Row>
                {assetImageData ? (
                  <ImageContainer>
                    <Image
                      src={assetImageData}
                      height='100%'
                      width='100%'
                      style={{ objectFit: 'cover' }}
                    />
                  </ImageContainer>
                ) : (
                  <></>
                )}
                {generateColumn(
                  [
                    { key: 'equip_id' },
                    { key: 'erp_id' },
                    { key: 'equip_area' },
                    { key: 'area_description' },
                    { key: 'year_built' },
                    { key: 'risk_category' },
                    { key: 'outage_type' },
                    ...(userFeatureFlags?.superUser ? [{ key: 'ruggable' }] : []),
                  ],
                  assetImageData ? 4 : 2
                )}
              </Row>
            </InlineTile>
          </Row>
          {(userFeatureFlags?.superUser ||
            userFeatureFlags?.[TabKey.inspectionScheduling]) && (
            <Row>
              <AssetBaseBallCardInspectionTable
                fdeCustomerUnitSlug={fdeCustomerUnitSlug}
                originPage={originPage}
                flattenedAssetData={flattenedAssetData}
                refetchData={() => {
                  fdeCustomerUnitSWR.mutate()
                  refetchData?.func()
                }}
              />
            </Row>
          )}
          <Row>
            <InlineTile
              background={asset_manager_theme.background.background_color9}
              title='Comments'>
              <Row>{generateColumn([{ key: 'comments' }])}</Row>
            </InlineTile>
          </Row>
          {(userFeatureFlags?.superUser || userFeatureFlags?.radar) && (
            <Row>
              <InlineTile
                title='Radar'
                background={asset_manager_theme.background.background_color9}
                titleRowChildren={editAssetLocation()}>
                {fdeCustomerUnitSWR.data?.[0]?.entry?.data.longitude &&
                fdeCustomerUnitSWR.data?.[0]?.entry?.data.latitude ? (
                  <RadarModule
                    style={{ width: '33.5vw', height: '20vh' }}
                    center={center}
                    markers={markerData ? [markerData] : []}
                  />
                ) : (
                  <div style={{ textAlign: 'center' }}> No Data </div>
                )}
              </InlineTile>
              {fdeCustomerUnitSlug && assetData?.equip_description && (
                <AssetMappingModal
                  assetModalVisible={editAssetModalVisible}
                  setAssetModalVisible={setEditAssetModalVisible}
                  editMarkerData={markerData}
                  initialAssetId={fdeCustomerUnitSlug}
                  initialEquipDescription={assetData.equip_description}
                  mapView={true}
                  refetchData={() => {
                    fdeCustomerUnitSWR.mutate()
                    refetchData?.func?.()
                  }}
                  disableDeleteAssetLocation={
                    !(
                      fdeCustomerUnitSWR.data?.[0]?.entry?.data.longitude &&
                      fdeCustomerUnitSWR.data?.[0]?.entry?.data.latitude
                    )
                  }
                />
              )}
            </Row>
          )}
          <Row>
            <InlineTile
              background={asset_manager_theme.background.background_color9}
              title={'Construction'}>
              <Row>
                {generateColumn([
                  { key: 'material' },
                  { key: 'national_board' },
                  { key: 'height_ft' },
                  { key: 'diameter_ft' },
                  { key: 'orientation' },
                  { key: 'insulated' },
                  { key: 'stiffness' },
                ])}
              </Row>
            </InlineTile>
          </Row>
          <Row>
            <InlineTile
              background={asset_manager_theme.background.background_color9}
              title={'Process'}>
              <Row>
                {generateColumn([
                  { key: 'contents' },
                  { key: 'pH' },
                  { key: 'pressure' },
                  { key: 'temp' },
                  { key: 'type' },
                  { key: 'customer_corrosion_rate' },
                  { key: 'customer_remaining_life' },
                ])}
              </Row>
            </InlineTile>
          </Row>
          {(userFeatureFlags?.superUser ||
            userFeatureFlags?.[TabKey.recommendations]) && (
            <Row>
              <InlineTile
                background={asset_manager_theme.background.background_color9}
                titleRowChildren={
                  !triageUnitData?.LifeExtensionOptions?.some(
                    (d) => d.action_taken === 'Submitted'
                  ) && (
                    <Button
                      style={{ fontSize: '.85rem' }}
                      onClick={() => {
                        setPanelActive(undefined)
                        setSelectedTab(TabKey.recommendations)
                        setTriageSidePanelFdeUnit(fdeCustomerUnitSlug || '')
                        navigate(`/${siteData?.siteSlug}/${TabKey.recommendations}`)
                      }}>
                      View in Recommendations
                    </Button>
                  )
                }
                title={'Plan'}
                titleStyle={{
                  marginBottom: '0rem',
                  paddingBottom: '0',
                }}>
                {Object.keys(triageUnitData?.LifeExtensionOptions || {}).length > 0 && (
                  <Tabs
                    defaultActiveKey='repairs'
                    items={planTabItems}
                    style={{
                      // make 50% opacity if the asset has been dismissed
                      opacity:
                        currentBatchHistory?.action === 'dismissed' ? '50%' : '100%',
                    }}
                  />
                )}
                {siteData?.siteSlug &&
                  fdeCustomerUnitSlug &&
                  currentBatchHistory?.action === 'dismissed' && (
                    <Button
                      onClick={() => {
                        archiveAssetRecs(
                          authInfo,
                          siteData?.siteSlug,
                          fdeCustomerUnitSlug,
                          true
                        ).then(() => {
                          if (refetchData) {
                            refetchData.func()
                          }
                          assetBatch.mutate()
                        })
                        trackEvent('Button Clicked', {
                          buttonName: 'undo_last_archive',
                          pageName: originPage,
                          subPageName: 'asset_baseball_card',
                          assetDetails: {
                            assetName: assetData.equip_description,
                          },
                        })
                      }}>
                      Undo Last Archive
                    </Button>
                  )}
              </InlineTile>
            </Row>
          )}

          <Row>
            <InlineTile
              background={asset_manager_theme.background.background_color9}
              title={'Key Files'}
              titleRowChildren={
                <UploadButton
                  customRequest={UploadLatestInspection(
                    authInfo,
                    fdeCustomerUnitSlug || '',
                    siteData,
                    assetData?.equip_description || '',
                    messageApi,
                    handleUploadFinally
                  )}
                  disabled={inspectionScheduleLockOutEnabled}
                  assetName={assetData?.equip_description || ''}
                  pageName='Baseball Card'
                  subPageName=''
                  text='Upload Latest Report'
                  fileList={[]}
                />
              }>
              {flattenedAssetData.file_tags && (
                <>
                  {Object.keys(flattenedAssetData.file_tags).map((tag, columnIndex) => {
                    return (
                      <Column key={`column-${tag}-${columnIndex}`}>
                        <Row>
                          <p
                            style={{
                              fontSize: '.8rem',
                              color: asset_manager_theme.background.background_color3,
                            }}>
                            {toTitleCase(tag.split('_').join(' '))}
                          </p>
                        </Row>
                        <Row>
                          {flattenedAssetData.file_tags?.[tag].map((file, fileIndex) => {
                            return (
                              <InspectionReportCard
                                key={`file-${tag}-${fileIndex}`}
                                report={{
                                  path: file,
                                  visible_name: file.split('/').pop() || '',
                                  file_type: file.split('.').pop() || '',
                                  entry_id: fdeCustomerUnitSlug || '',
                                }}
                              />
                            )
                          })}
                        </Row>
                      </Column>
                    )
                  })}
                </>
              )}
            </InlineTile>
          </Row>
        </div>
      )}
    </SlidePanel>
  )
}

export default AssetBaseBallCard
