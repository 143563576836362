import React, { FC, useState, useEffect, useContext } from 'react'
import { SWRResponse } from 'swr'

import { PageContainer, Row } from '../../components/Common/Grid'
import NumberLabelComponent from './RecommendationsKPIBar'
import { asset_manager_theme } from '../../assets/themes'
import { TriageData } from './RecommendationsInterface'
import RecommendationsTable from './RecommendationsTable'
import SlidePanel from '../../components/Common/SlidePanel'
import LifeExtensionOptionsTab from './lifeExtensionOptionsTab/LifeExtensionOptionsTab'
import { TabHeader } from '../../components/Common/TabHeader'
import { useGetTriageData } from '../../api/useFDEBackend'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { Spin } from 'antd'

interface ReportsTriageTabProps {
  selectedFdeUnit?: string
  setSelectedFdeUnit: (fdeUnit?: string) => void
}

// function createTriageKPIData(triageData: TriageData) {
//   const data = [
//     {
//       number: triageData.triage_count ?? 0,
//       label: 'Recs to Triage',
//       color: asset_manager_theme.colors.orange,
//       bold: true,
//     },
//     {
//       number: triageData.escalated_count! + triageData.resolved_count! ?? 0,
//       label: 'Approved Assets',
//       bold: true,
//     },
//     {
//       number: triageData.capital_project_cost ?? 0,
//       label: 'Pending Capital Projects',
//       format: 'currency',
//     },
//     {
//       number: triageData.work_order_cost ?? 0,
//       label: 'Pending Work Orders',
//       format: 'currency',
//     },
//   ]
//   return data
// }

export const RecommendationsTab: FC<ReportsTriageTabProps> = ({
  selectedFdeUnit,
  setSelectedFdeUnit,
}) => {
  // const triageKPIData = createTriageKPIData(triageData)
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)
  const [triageData, triageDataSWR] = useGetTriageData(authInfo, siteData?.siteSlug ?? '')

  return (
    <PageContainer
      style={{
        justifyContent: 'space-around',
        gap: '1.2rem',
      }}>
      <TabHeader>Recommendations</TabHeader>
      {/*<Row*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    maxHeight: '19%',*/}
      {/*    flex: '0 1 18%',*/}
      {/*    justifyContent: 'center',*/}
      {/*  }}>*/}
      {/*  /!*<NumberLabelComponent input={triageKPIData} />*!/*/}
      {/*</Row>*/}
      <Spin spinning={triageDataSWR.isLoading}>
        <Row
          style={{
            justifyContent: 'center',
            flex: '5 1 50%',
            overflowY: 'auto',
            width: '100%',
            marginBottom: '1rem',
            boxSizing: 'border-box',
          }}>
          <RecommendationsTable
            triage_table_data={triageData.triage_table_data}
            setSelectedFdeUnit={setSelectedFdeUnit}
          />
        </Row>
      </Spin>
      <SlidePanel active={!!selectedFdeUnit}>
        <LifeExtensionOptionsTab
          fdeCustomerUnit={selectedFdeUnit}
          triageDataSWR={triageDataSWR}
          setSelectedFdeUnit={setSelectedFdeUnit}
        />
      </SlidePanel>
    </PageContainer>
  )
}

export default RecommendationsTab
