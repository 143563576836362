import { ManipulateType } from 'dayjs'

// For characters that cannot be in file names.
export const INVALID_FILE_CHARS = ['[', ']', '*', '?', '#', '\n']

//scale the max of the outage lines so they showup over the bars
export const OUTAGE_SCALING = 1.25

export const AMBIENT_TEMP_RANGE = {
  min: 20,
  max: 140,
}

export const DATE_GRANULARITY_MAP = {
  week: {
    dayjsFormat: 'YYYY-MM-DD',
    plotlyDTick: 7 * 86400000.0, // 7 days in milliseconds
    plotlyTickformat: '%Y-%m-%d',
    selectionMargin: 7,
    selectionGranularity: 'day' as ManipulateType,
  },
  month: {
    dayjsFormat: 'YYYY-MM',
    plotlyDTick: 'M1',
    plotlyTickformat: '%b %Y',
    selectionMargin: 1,
    selectionGranularity: 'month' as ManipulateType,
  },
  year: {
    dayjsFormat: 'YYYY',
    plotlyDTick: 'M1',
    plotlyTickformat: '%Y',
    selectionMargin: 1,
    selectionGranularity: 'year' as ManipulateType,
  },
}

export const NUM_WEEK_PER_MONTH = 52 / 12
export const NUM_WEEK_PER_YEAR = 52

export const NO_INFO_SET: Set<any> = new Set(['', null, undefined]) // eslint-disable-line @typescript-eslint/no-explicit-any

export const CANTILEVER_BASE_PATH = 'cantilever.app'
