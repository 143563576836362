import React from 'react'
import { TableContainer } from '../../components/TableComponents'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { dateSort, defaultSort } from '../../api/tableHelpers'

export type ImpactTableRow = {
  age: number | string
  riskLevel: string
  year_built: string | number | null | undefined
  equip_id: string | null | undefined
  equip_description: string | null | undefined
  area: string | null | undefined
  last_internal: string | null | undefined
  last_external: string | null | undefined
}

interface ImpactTableProps {
  impactData: ImpactTableRow[]
  loading: boolean
}

const riskLevelMap = {
  S2: 0,
  S1: 1,
  P3: 2,
  P2: 3,
  P1: 4,
}
const riskLevelToSortVal: (level: string) => number = (level) =>
  riskLevelMap[level as keyof typeof riskLevelMap] ?? -1

const tableColumns: ColumnsType<ImpactTableRow> = [
  {
    title: 'Equip ID',
    dataIndex: 'equip_id',
    key: 'equip_id',
    sorter: defaultSort('equip_id'),
  },
  {
    title: 'Equip Desc.',
    dataIndex: 'equip_description',
    key: 'equip_description',
    sorter: defaultSort('equip_description'),
  },
  {
    title: 'Area',
    dataIndex: 'area',
    key: 'area',
    sorter: defaultSort('area'),
  },
  {
    title: 'Year Built',
    dataIndex: 'year_built',
    key: 'year_built',
    sorter: defaultSort('year_built'),
  },
  {
    title: 'Age (yrs)',
    dataIndex: 'age',
    key: 'age',
    sorter: defaultSort('age'),
  },
  {
    title: 'Risk Level',
    dataIndex: 'riskLevel',
    key: 'riskLevel',
    sorter: (row1, row2) =>
      riskLevelToSortVal(row1.riskLevel) - riskLevelToSortVal(row2.riskLevel),
  },
  {
    title: 'Last Internal Inspection',
    dataIndex: 'last_internal',
    key: 'last_internal',
    sorter: dateSort('last_internal'),
  },
  {
    title: 'Last External Inspection',
    dataIndex: 'last_external',
    key: 'last_external',
    sorter: dateSort('last_external'),
  },
]

export const ImpactTable: React.FC<ImpactTableProps> = ({ impactData, loading }) => {
  return (
    <TableContainer>
      <Table
        rowKey={(row) => [row.equip_id, row.area, row.equip_description].join('~')}
        dataSource={impactData}
        columns={tableColumns}
        scroll={{
          y: 270,
        }}
        pagination={false}
        loading={loading}
      />
    </TableContainer>
  )
}
