import { Dayjs } from 'dayjs'
import typia from 'typia'

export interface OutageObject {
  date: Dayjs
  type: string
  system: string
  entryId: string
}

export const isOutageObject = typia.createIs<OutageObject>()
