import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

export const TwoColumnDivContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
`

export const FirstColumn = styled.div`
  background-color: ${asset_manager_theme.background.background_color8};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SecondColumn = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  justify-content: space-between;
`
