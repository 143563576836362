import { Recommendation } from '../../pages/PlanModelingTab/PlanModelingInterface'
import { Asset } from '../../pages/PlanModelingTab/PlanModelingInterface'
import { DataItem } from '../../pages/RecommendationsTab/RecommendationsKPIBar'
import { FilterValue } from 'antd/es/table/interface'
import { currencyConverter } from './stringFilters'
import { Dayjs } from 'dayjs'

export function compareProperty(a?: string, b?: string) {
  return !a ? -1 : !b ? 1 : a.localeCompare(b)
}

export const handlePlanRecSort = (
  planRecs?: Recommendation[],
  clusterField: keyof Recommendation = 'cluster',
  scheduledDateField: keyof Recommendation = 'scheduled_date',
  createdDateField: keyof Recommendation = 'created_timestamp'
) => {
  // Single-pass sort with proper priority: created date, then scheduled date, then cluster
  return planRecs?.sort((a: Recommendation, b: Recommendation) => {
    // Compare by created date first
    let comparison =
      (!a[createdDateField] ? -1 : !b[createdDateField] ? 1 : 0) ||
      (a[createdDateField] as number) - (b[createdDateField] as number)
    if (comparison !== 0) return comparison

    // If created dates are equal, then compare by scheduled date
    comparison = compareProperty(
      a[scheduledDateField] as string,
      b[scheduledDateField] as string
    )
    if (comparison !== 0) return comparison

    // If scheduled dates are equal, finally compare by cluster
    return compareProperty(a[clusterField] as string, b[clusterField] as string)
  })
}

export const calculateTotalPlanCost = (
  planData: Asset[],
  recommendedAction: string
): number => {
  let totalCost = 0
  planData.forEach((asset) => {
    const selectedPlan = asset.selectedPlan
    if (selectedPlan && selectedPlan.plan_recs) {
      selectedPlan.plan_recs.forEach((rec) => {
        if (rec.recommended_action === recommendedAction && rec.repair_cost != null) {
          totalCost += rec.repair_cost
        }
      })
    }
  })
  return totalCost
}

export const createDataItem = ({
  label = '',
  color,
  bold,
  format,
  filteredCount = 0,
  totalCount = 0,
  planRecFilters = {},
  assetFilters = {},
}: {
  label?: string
  color?: string
  bold?: boolean
  format?: string
  filteredCount?: number
  totalCount?: number
  planRecFilters: Record<string, FilterValue | null>
  assetFilters: Record<string, FilterValue | null>
}): DataItem => {
  // Calculate percentage and handle division by zero
  const percentage =
    totalCount > 0 ? ((filteredCount / totalCount) * 100).toFixed(2) : '0.00'

  // Initialize the dataItem object with the number and label (if provided)
  const dataItem: DataItem = {
    number: filteredCount,
  }

  // Conditionally add label if it is not an empty string
  if (label) {
    dataItem.label = label
  }

  // Conditionally add other properties if they exist and are valid
  if (color) {
    dataItem.color = color
  }

  if (bold) {
    dataItem.bold = bold
  }

  if (format) {
    dataItem.format = format
  }

  const hasActiveFilters = (filters: Record<string, FilterValue | null>) => {
    return Object.values(filters).some((value) =>
      Array.isArray(value) ? value.length > 0 : value != null
    )
  }

  // Conditionally add `subLabel` if `totalCount` is greater than `filteredCount` and `totalCount` is greater than 0
  if (hasActiveFilters(planRecFilters) || hasActiveFilters(assetFilters)) {
    dataItem.subLabel = `of ${
      dataItem.format === 'currency' ? currencyConverter(totalCount) : totalCount
    } (${percentage}%)`
  }

  return dataItem
}

export const createPlanModelingKPIData = (
  planModelingData: Asset[],
  plansInPeriodPlanData: Asset[],
  assetFilters: Record<string, FilterValue | null>,
  planRecFilters: Record<string, FilterValue | null>
) => {
  const filteredCapitalSpend = planModelingData
    ? calculateTotalPlanCost(planModelingData, 'CAP')
    : 0
  const filteredMaintenanceSpend = planModelingData
    ? calculateTotalPlanCost(planModelingData, 'Maintenance')
    : 0
  const totalCapitalSpend = plansInPeriodPlanData
    ? calculateTotalPlanCost(plansInPeriodPlanData, 'CAP')
    : 0
  const totalMaintenanceSpend = plansInPeriodPlanData
    ? calculateTotalPlanCost(plansInPeriodPlanData, 'Maintenance')
    : 0

  return [
    createDataItem({
      label: 'Assets',
      bold: true,
      filteredCount: planModelingData.length,
      totalCount: planModelingData?.length ?? 0,
      planRecFilters,
      assetFilters,
    }),
    createDataItem({
      label: 'Capital Spend',
      bold: true,
      format: 'currency',
      filteredCount: filteredCapitalSpend,
      totalCount: totalCapitalSpend,
      planRecFilters,
      assetFilters,
    }),
    createDataItem({
      label: 'Maintenance Spend',
      bold: true,
      format: 'currency',
      filteredCount: filteredMaintenanceSpend,
      totalCount: totalMaintenanceSpend,
      planRecFilters,
      assetFilters,
    }),
    createDataItem({
      label: 'Total Spend',
      bold: true,
      format: 'currency',
      filteredCount: filteredCapitalSpend + filteredMaintenanceSpend,
      totalCount: totalCapitalSpend + totalMaintenanceSpend,
      planRecFilters,
      assetFilters,
    }),
  ]
}
