import React, { useEffect, useContext, useState } from 'react'
import { InputNumber } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useDebounce } from 'react-use'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { currencyConverter } from '../helpers/stringFilters'
import { NO_INFO_SET } from '../../util/constants'

interface EditableNumberElementProps {
  numberValue: number | undefined
  objectId: string
  field: string
  format?: 'currency'
  disabled?: boolean
  refetchData?: () => void
  rolodexType: string
}

const formatValue = (value: number | undefined, format: string | undefined) => {
  if (NO_INFO_SET.has(value)) {
    return ''
  } else if (format === 'currency') {
    return currencyConverter(value ?? 0, 'standard')
  } else {
    return `${value}`
  }
}

export const EditableNumberElement: React.FC<EditableNumberElementProps> = ({
  numberValue,
  objectId,
  field,
  format,
  refetchData,
  rolodexType,
  disabled,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<number | undefined>(numberValue)

  useEffect(() => {
    setEditedValue(numberValue)
  }, [numberValue])

  useDebounce(
    () => {
      if (editedValue !== numberValue && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: objectId,
          type: rolodexType,
          data: {
            [field]: editedValue ?? null,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    300,
    [editedValue]
  )

  return (
    <InputNumber
      disabled={disabled}
      key={objectId}
      defaultValue={numberValue}
      value={editedValue}
      formatter={() => formatValue(editedValue, format)}
      onChange={(value) => {
        setEditedValue(value ?? undefined)
      }}
    />
  )
}
