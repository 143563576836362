import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { useContext, useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import StyledDatePicker from '../../../components/Common/StyledDatePicker'

interface PlanRecDeadlinePickerProps {
  planRecId: string
  updateKey: string
  deadline?: string
  dateFormat?: string
  disabled?: boolean
  refetchData: () => void
  picker?: 'date' | 'month' | 'week' | 'quarter' | 'year'
}

export const PlanRecDeadlinePicker: React.FC<PlanRecDeadlinePickerProps> = ({
  planRecId,
  deadline,
  updateKey,
  dateFormat,
  disabled,
  refetchData,
  picker = 'date',
}) => {
  const siteData = useContext(SiteContext)
  const [editedValue, setEditedValue] = useState<Dayjs | null>(
    deadline ? dayjs(deadline) : null
  )
  const auth0 = useAuthInfo()
  useEffect(() => {
    setEditedValue(deadline ? dayjs(deadline) : null)
  }, [deadline])

  const saveDeadline = async (date: dayjs.Dayjs | null) => {
    const formattedDeadline = date ? date.format(dateFormat) : null

    const patchBody = {
      id: planRecId,
      type: 'fde-am-plan-recommendation',
      data: {
        [updateKey]: formattedDeadline,
      },
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }

    RolodexPatchBatch([patchBody], auth0, siteData).then(() => {
      refetchData?.()
    })
  }

  useEffect(() => {
    if (
      editedValue &&
      editedValue.format('YYYY-MM-DD') !== dayjs(deadline).format('YYYY-MM-DD')
    ) {
      saveDeadline(editedValue)
    }
  }, [editedValue])

  return (
    <StyledDatePicker
      disabled={disabled}
      defaultValue={deadline ? dayjs(deadline) : undefined}
      value={editedValue}
      format={dateFormat}
      onChange={setEditedValue}
      picker={picker}
      width={'100%'}
      allowClear={false}
    />
  )
}
