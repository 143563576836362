import styled from 'styled-components'
import { Dispatch, FC, SetStateAction } from 'react'
import {
  ComplianceFiltersInterface,
  UnitFiltersInterface,
  ComplianceGroupByFields,
} from './ComplianceInterface'
import { Space, Tag, Checkbox } from 'antd'
import _ from 'lodash'
import { AREA_OUT_OF_SERVICE } from './OverviewTab'
import { ComplianceGroupByFieldsToLabel } from './ComplianceConstants'

interface OverviewFiltersProps {
  complianceFilters: ComplianceFiltersInterface
  setComplianceFilters: (filter: ComplianceFiltersInterface) => void
  toggledUnitFilters: UnitFiltersInterface
  setToggledUnitFilters: React.Dispatch<React.SetStateAction<UnitFiltersInterface>>
  // toggledProductionFilters: Array<string>
  // setToggledProductionFilters: (filters: string[]) => void
  areas: string[]
  groupByFields: ComplianceGroupByFields[]
  selectedGroupByField: ComplianceGroupByFields
  setSelectedGroupByField: Dispatch<SetStateAction<ComplianceGroupByFields>>
}

// const complianceFilterLabels: Record<COMPLIANCE_FILTER_TYPES, string> = {
//   BOTH: 'FULLY COMPLIANT',
//   SINGLE: 'PARTIALLY COMPLIANT',
//   NONCOMPLIANT: 'NON-COMPLIANT',
// }

interface FilterButtonProps<VALUE_T extends string> {
  value: VALUE_T
  label: string
  selected: boolean
  setSelected: (selected: boolean) => void
}

const OverviewFilterButton = <T extends string>(props: FilterButtonProps<T>) => {
  const { CheckableTag } = Tag
  return (
    <CheckableTag
      key={props.value}
      checked={props.selected}
      onChange={(checked) => props.setSelected(checked)}>
      {props.label}
    </CheckableTag>
  )
}

interface FilterButtonsProps<VALUE_T extends string> {
  values: VALUE_T[]
  selected: VALUE_T[]
  setSelected: (values: VALUE_T[]) => void
  valueToLabel?: Partial<Record<VALUE_T, string>>
}

interface GroupByButtonsProps<VALUE_T extends string> {
  allValues: VALUE_T[]
  selected: VALUE_T
  setSelected: (values: VALUE_T) => void
  valueToLabel?: Partial<Record<VALUE_T, string>>
}

const OverviewFilterButtons = <T extends string>(props: FilterButtonsProps<T>) => {
  return (
    <Space direction='vertical' style={{ marginBottom: '2rem' }}>
      {props.values.map((value) => {
        return (
          <OverviewFilterButton
            key={value}
            value={value}
            label={props.valueToLabel?.[value] ?? value}
            selected={props.selected.includes(value)}
            setSelected={(thisSelected: boolean) => {
              if (thisSelected) {
                props.setSelected(_.uniq([...props.selected, value]))
              } else {
                props.setSelected(props.selected.filter((v) => v !== value))
              }
            }}
          />
        )
      })}
    </Space>
  )
}

const ComplianceGroupByButtons = <T extends string>(props: GroupByButtonsProps<T>) => {
  return (
    <Space direction='vertical' style={{ marginBottom: '2rem' }}>
      {props.allValues.map((value) => {
        return (
          <OverviewFilterButton
            key={value}
            value={value}
            label={props.valueToLabel?.[value] ?? value}
            selected={props.selected === value}
            setSelected={(_: boolean) => {
              props.setSelected(value)
            }}
          />
        )
      })}
    </Space>
  )
}

export const OverviewFilters: FC<OverviewFiltersProps> = (props) => {
  return (
    <Container>
      <Title>Filters</Title>
      <SubTitle> Area </SubTitle>
      <OverviewFilterButtons
        values={props.areas.filter((area) => area !== AREA_OUT_OF_SERVICE)}
        selected={props.toggledUnitFilters?.equip_area ?? []}
        setSelected={(values) => {
          props.setToggledUnitFilters((prev) => {
            return { ...prev, equip_area: values as any[] }
          })
        }}
      />
      <SubTitle>Group by</SubTitle>
      <ComplianceGroupByButtons
        allValues={props.groupByFields}
        selected={props.selectedGroupByField}
        setSelected={props.setSelectedGroupByField}
        valueToLabel={ComplianceGroupByFieldsToLabel}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;

  & > * {
    margin-bottom: 1rem;
  }
`

const Title = styled.div`
  font-size: 1.4rem;
`

const SubTitle = styled.div`
  font-size: 1rem;
`
