export enum RolodexEntryType {
  PLAN_REC = 'fde-am-plan-recommendation',
  PLAN = 'fde-am-plan',
  RECOMMENDATION = 'fde-am-recommendation',
  CUSTOMER_UNIT = 'fde-customer-unit',
  INSPECTION_OBJECT = 'fde-am-inspection',
  REC_BATCH = 'fde-am-batch',
  SITE_CONFIG_TYPE = 'fde-asset-manager-site-config',
  PLANNING_CONFIG_TYPE = 'fde-am-planning-config',
  INSPECTION_SCHEDULE_CONFIG_TYPE = 'fde-am-inspection-schedule-config',
  BLITZ_TYPE = 'fde-am-rug-blitz',
  BLITZ_INSPECTION = 'fde-am-rug-blitz-inspection',
  BLITZ_OPTION = 'fde-am-rug-blitz-option',
  RADAR_ORTHOMOSAIC = 'fde-orthomosaic-overlay',
  FDE_SITE = 'fde-site',
}
