import { AuthInfo } from '../contextProviders/useAuthInfo'
import useSWR, { SWRResponse } from 'swr'
import { getFetcher } from './fetchers'
import { fdeBackendUrl } from './helpers'
import _ from 'lodash'
import { getFileFetcher } from './fetchers'
import dayjs from 'dayjs'
import {
  OverviewRecord,
  ComplianceFiltersInterface,
  UnitFiltersInterface,
  ComplianceCountItem,
  ComplianceGroupByFields,
} from '../pages/OverviewTab/ComplianceInterface'

const sortKeys = (unorderedObject: Record<string, any>) => {
  //learned today that js object now inherently have order
  //sorting the keys means that filters will be deterministic when converted to url so that the cache can be used
  return Object.keys(unorderedObject)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unorderedObject[key]
      return obj
    }, {} as Record<string, any>)
}

export function useGetComplianceRecords(
  auth0: AuthInfo,
  siteSlug: string,
  complianceFilters: ComplianceFiltersInterface,
  unit_filters: UnitFiltersInterface
): SWRResponse<OverviewRecord[], any, any> {
  const url = encodeURI(
    `${fdeBackendUrl()}/asset_manager/compliance/compliance_filtered_recs/${encodeURIComponent(
      siteSlug
    )}?compliance_filters=${encodeURIComponent(
      JSON.stringify(sortKeys(complianceFilters))
    )}&unit_filters=${encodeURIComponent(JSON.stringify(sortKeys(unit_filters)))}`
  )
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function useGetComplianceFilterOptions(
  auth0: AuthInfo,
  siteSlug: string
): SWRResponse<
  {
    [key: string]: string[]
  },
  any,
  any
> {
  const url = encodeURI(
    `${fdeBackendUrl()}/asset_manager/compliance/compliance_filter_options/${encodeURIComponent(
      siteSlug
    )}`
  )
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export function useGetComplianceCounts(
  auth0: AuthInfo,
  siteSlug: string,
  groupByField?: ComplianceGroupByFields,
  complianceFilters: ComplianceFiltersInterface = {},
  unit_filters: UnitFiltersInterface = {}
): SWRResponse<ComplianceCountItem[], any, any> {
  const groupByParam = groupByField
    ? `&group_by_field=${encodeURIComponent(groupByField)}`
    : ``
  const url = `${fdeBackendUrl()}/asset_manager/compliance/compliance_counts/${encodeURIComponent(
    siteSlug
  )}?compliance_filters=${encodeURIComponent(
    JSON.stringify(sortKeys(complianceFilters))
  )}&unit_filters=${encodeURIComponent(
    JSON.stringify(sortKeys(unit_filters))
  )}${groupByParam}`
  return useSWR(auth0.headers ? [url, auth0] : false, ([url, auth0]) =>
    getFetcher(url, auth0)
  )
}

export async function generateOverviewReport(
  auth0: AuthInfo,
  siteSlug: string,
  complianceFilters: ComplianceFiltersInterface,
  unit_filters: UnitFiltersInterface
) {
  const url = encodeURI(
    `${fdeBackendUrl()}/asset_manager/pdf_generation/compliance_assets?site_id=${encodeURIComponent(
      siteSlug
    )}&compliance_filters=${encodeURIComponent(
      JSON.stringify(sortKeys(complianceFilters))
    )}&unit_filters=${encodeURIComponent(JSON.stringify(sortKeys(unit_filters)))}`
  )
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `ComplianceAudit-${dayjs().format('YYYY-MM-DD')}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
  })
}

export async function generateStatsReport(
  auth0: AuthInfo,
  siteSlug: string,
  complianceFilters: ComplianceFiltersInterface,
  unit_filters: UnitFiltersInterface
) {
  const url = encodeURI(
    `${fdeBackendUrl()}/asset_manager/pdf_generation/compliance_stats?site_id=${encodeURIComponent(
      siteSlug
    )}&compliance_filters=${encodeURIComponent(
      JSON.stringify(sortKeys(complianceFilters))
    )}&unit_filters=${encodeURIComponent(JSON.stringify(sortKeys(unit_filters)))}`
  )
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Compliance-stats-report-${dayjs().format('YYYY-MM-DD')}.pdf`
      )
      document.body.appendChild(link)
      link.click()
    }
  })
}

export async function generateOverviewOverdueReport(
  auth0: AuthInfo,
  siteSlug: string,
  unit_filters: UnitFiltersInterface
) {
  const url = encodeURI(
    `${fdeBackendUrl()}/asset_manager/pdf_generation/overdue_compliance_assets?site_id=${encodeURIComponent(
      siteSlug
    )}&unit_filters=${encodeURIComponent(JSON.stringify(sortKeys(unit_filters)))}`
  )
  return await getFileFetcher(url, auth0).then((blob) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Compliance-Overdue-report-${dayjs().format('YYYY-MM-DD')}.pdf`
      )
      document.body.appendChild(link)
      link.click()
    }
  })
}
