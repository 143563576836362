import React, { FC, useState, useEffect, useContext } from 'react'

import OverviewPie from './OverviewPie'
import OverviewBar from './OverviewBar'
import { OverviewFilters } from './OverviewFilters'
import { CardContainer } from '../../components/CardContainer'
import {
  useGetComplianceRecords,
  useGetComplianceFilterOptions,
  useGetComplianceCounts,
  generateOverviewReport,
  generateOverviewOverdueReport,
  generateStatsReport,
} from '../../api/ComplianceRequests'

import {
  ComplianceFiltersInterface,
  UnitFiltersInterface,
  ComplianceCountItem,
  ComplianceGroupByFields,
} from './ComplianceInterface'
import {
  PlotContainer,
  PlotOrganizer,
  ControlsContainer,
  Container,
} from '../../components/StyledGraphContainerComponents'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import _ from 'lodash'
import { Spin } from 'antd'
import { OverviewTable } from './OverviewTabTable'
import { asset_manager_theme } from '../../assets/themes'
import { PageContainer, Row } from '../../components/Common/Grid'
import { Button } from 'antd'

import { DownloadIcon } from '@radix-ui/react-icons'
import { trackEvent } from '../../components/helpers/mixpanel'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { ComplianceExportModal } from './OverviewExportModal'

export const AREA_OUT_OF_SERVICE = 'OUT OF SERVICE'

interface OverviewTabProps {
  setFdeCustomerUnitSlug: (slug: string | undefined) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
}

// TODO: This doesn't want to be hardcoded - fix it
function findComplianceItem(
  data: ComplianceCountItem[],
  group: string | undefined | null,
  internally_compliant: boolean,
  externally_compliant: boolean
): number {
  const found = _.find(
    data,
    (item) =>
      (!group || item.group === group) &&
      item.internally_compliant === internally_compliant &&
      item.externally_compliant === externally_compliant
  )
  if (!found) {
    return 0
  }
  return found.count
}

const groupByFields: ComplianceGroupByFields[] = ['equip_area', 'risk_category']

export type ComplianceCounts = {
  fully_compliant: number
  non_compliant: number
  externally_non_compliant: number
  internally_non_compliant: number
}

export type AreaToOverviewCounts = Record<string, ComplianceCounts>

export const OverviewTab: FC<OverviewTabProps> = (props) => {
  const [toggledComplianceFilters, setToggledComplianceFilters] =
    useState<ComplianceFiltersInterface>({})
  const [toggledUnitFilters, setToggledUnitFilters] = useState<UnitFiltersInterface>({
    decommissioned: [false],
  })
  const [groupByField, setGroupByField] =
    useState<ComplianceGroupByFields>('risk_category')
  const [selectedKPI, setSelectedKPI] = useState<string | undefined>(undefined)
  const [exportOption, setExportOption] = useState<'table' | 'stats' | 'overdue'>('stats')
  const [exportModalOpen, setExportModalOpen] = useState(false)

  // Site Data
  const siteData = useContext(SiteContext)

  const auth0 = useAuthInfo()
  const complianceCountsSwr = useGetComplianceCounts(
    auth0,
    siteData?.siteSlug ?? '',
    groupByField,
    toggledComplianceFilters,
    toggledUnitFilters
  )
  const complianceRecsSwr = useGetComplianceRecords(
    auth0,
    siteData?.siteSlug ?? '',
    toggledComplianceFilters,
    toggledUnitFilters
  )
  const complianceFilterOptionsSwr = useGetComplianceFilterOptions(
    auth0,
    siteData?.siteSlug ?? ''
  )

  useEffect(() => {
    props.setAssetBaseballCardRefetchData({
      func: () => {
        complianceCountsSwr.mutate()
        complianceRecsSwr.mutate()
      },
    })
  }, [])

  const allGroups = React.useMemo(
    () => _.uniq(complianceFilterOptionsSwr.data?.equip_area?.filter((item) => !!item)),
    [complianceFilterOptionsSwr.data]
  ) as string[]

  const currentlyToggledGroups: string[] = React.useMemo(() => {
    return _.uniq(
      (complianceCountsSwr.data ?? []).map((d) => d.group).filter((d) => !!d) as string[]
    )
  }, [complianceCountsSwr.data])

  const groupedCompliance: AreaToOverviewCounts = React.useMemo(() => {
    const result: AreaToOverviewCounts = {}

    const filteredComplianceData = complianceCountsSwr.data ?? []

    for (const group of currentlyToggledGroups) {
      result[group] = {
        fully_compliant: findComplianceItem(filteredComplianceData, group, true, true),
        non_compliant: findComplianceItem(filteredComplianceData, group, false, false),

        externally_non_compliant: findComplianceItem(
          filteredComplianceData,
          group,
          true,
          false
        ),
        internally_non_compliant: findComplianceItem(
          filteredComplianceData,
          group,
          false,
          true
        ),
      }
    }
    return result
  }, [complianceCountsSwr.data, toggledComplianceFilters, currentlyToggledGroups])

  const sumItems = (countExtractor: (item: ComplianceCounts) => number) => {
    return Object.keys(groupedCompliance).reduce((prev, cur) => {
      const currentRec = groupedCompliance[cur]
      return prev + countExtractor(currentRec)
    }, 0)
  }

  const handleGenerateReport = () => {
    trackEvent('Button Clicked', {
      buttonName: 'generate_compliance_report',
      pageName: 'Overview',
      filters: {
        selectedKPI: selectedKPI,
        production: toggledUnitFilters,
        compliance: toggledComplianceFilters,
        exportOption: exportOption,
      },
    })

    switch (exportOption) {
      case 'overdue':
        generateOverviewOverdueReport(auth0, siteData?.siteSlug ?? '', toggledUnitFilters)
        break
      case 'table':
        generateOverviewReport(
          auth0,
          siteData?.siteSlug ?? '',
          toggledComplianceFilters,
          toggledUnitFilters
        )
        break
      case 'stats':
        generateStatsReport(
          auth0,
          siteData?.siteSlug ?? '',
          toggledComplianceFilters,
          toggledUnitFilters
        )
        break
      default:
        generateOverviewReport(
          auth0,
          siteData?.siteSlug ?? '',
          toggledComplianceFilters,
          toggledUnitFilters
        )
        break
    }

    // generateComplianceAuditReport(
    //   auth0,
    //   toggledProductionFilters,
    //   toggledComplianceFilters,
    //   toggledInspTypeFilters,
    //   props.siteSlug,
    //   selectedKPI
    // )
  }

  return (
    <Container>
      <PageContainer style={{ width: '90%' }}>
        <CardContainer
          selectedKPI={selectedKPI}
          setSelectedKPI={setSelectedKPI}
          cards={[
            {
              label: 'Externally Out of Compliance',
              key: 'external',
              loading: complianceCountsSwr.isLoading,
              value:
                sumItems((rec) => rec.externally_non_compliant) +
                sumItems((rec) => rec.non_compliant),
              color:
                sumItems((rec) => rec.externally_non_compliant) +
                  sumItems((rec) => rec.non_compliant) ==
                0
                  ? asset_manager_theme.colors.green
                  : asset_manager_theme.colors.red,
              isClickable: true,
              clickCallBack: (selected) => {
                if (!selected) {
                  setToggledComplianceFilters({
                    ...toggledComplianceFilters,
                    external: false,
                  })
                } else {
                  setToggledComplianceFilters((prev) => {
                    delete prev['external']
                    return { ...prev }
                  })
                }
              },
            },
            {
              label: 'Internally Out of Compliance',
              key: 'internal',
              loading: complianceCountsSwr.isLoading,
              value:
                sumItems((rec) => rec.internally_non_compliant) +
                sumItems((rec) => rec.non_compliant),
              color:
                sumItems((rec) => rec.internally_non_compliant) +
                  sumItems((rec) => rec.non_compliant) ==
                0
                  ? asset_manager_theme.colors.green
                  : asset_manager_theme.colors.red,
              isClickable: true,
              clickCallBack: (selected) => {
                if (!selected) {
                  setToggledComplianceFilters({
                    ...toggledComplianceFilters,
                    internal: false,
                  })
                } else {
                  setToggledComplianceFilters((prev) => {
                    delete prev['internal']
                    return { ...prev }
                  })
                }
              },
            },
            {
              label: 'Fully Compliant',
              key: 'fully_compliant',
              loading: complianceCountsSwr.isLoading,
              value: sumItems((rec) => rec.fully_compliant),
              color: asset_manager_theme.colors.green,
            },
            {
              label: 'Total Number of Assets',
              key: 'total_assets',
              loading: complianceCountsSwr.isLoading,
              value:
                sumItems((rec) => rec.fully_compliant) +
                sumItems((rec) => rec.non_compliant) +
                sumItems((rec) => rec.internally_non_compliant) +
                sumItems((rec) => rec.externally_non_compliant),
              color: 'white',
            },
          ]}
        />
        <PlotOrganizer style={{ flex: 1 }}>
          <PlotContainer style={{}}>
            <Spin spinning={complianceCountsSwr.isLoading}>
              <OverviewPie overviewData={groupedCompliance} />
            </Spin>
          </PlotContainer>
          <br />
          <PlotContainer>
            <Spin spinning={complianceCountsSwr.isLoading}>
              <OverviewBar overviewData={groupedCompliance} />
            </Spin>
          </PlotContainer>
        </PlotOrganizer>
        <Row style={{ marginTop: '1rem', flexDirection: 'row-reverse' }}>
          <Button
            style={{ display: 'inline-flex', alignItems: 'center', marginRight: '1rem' }}
            onClick={() => setExportModalOpen(true)}>
            <DownloadIcon />
            <span style={{ marginLeft: 8 }}>Generate PDF Report</span>
          </Button>
        </Row>
        <Row style={{ padding: '.8rem .8rem 5rem .8rem' }}>
          <OverviewTable
            records={complianceRecsSwr?.data ?? []}
            loading={complianceRecsSwr.isLoading}
            setSelectedFdeCustomerUnitSlug={props.setFdeCustomerUnitSlug}
          />
        </Row>
      </PageContainer>
      <ControlsContainer>
        <OverviewFilters
          areas={allGroups}
          complianceFilters={toggledComplianceFilters}
          setComplianceFilters={setToggledComplianceFilters}
          toggledUnitFilters={toggledUnitFilters}
          setToggledUnitFilters={setToggledUnitFilters}
          groupByFields={groupByFields}
          selectedGroupByField={groupByField}
          setSelectedGroupByField={setGroupByField}
        />
      </ControlsContainer>
      <ComplianceExportModal
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        selectedOption={exportOption}
        setOption={(option) => setExportOption(option)}
        onOkCallback={() => {
          handleGenerateReport()
        }}
      />
    </Container>
  )
}
