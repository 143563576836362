import JSZip from 'jszip'
import { downloadEntryFile } from '../../util/RolodexInterface'
import { message } from 'antd'
import { saveAs } from 'file-saver'

export const DownloadAndZipFiles = async (
  filePaths: string[],
  fdeCustomerUnitSlug: string,
  fileName: string,
  auth0: any,
  maintainFolderStructure = false // Optional argument with a default value of false
) => {
  if (filePaths.length === 1 && maintainFolderStructure) {
    const filePath = filePaths[0]
    try {
      const fileURL = await downloadEntryFile(fdeCustomerUnitSlug, filePath, auth0)
      const response = await fetch(fileURL)

      if (!response.ok) {
        message.error(`Failed to download file from ${fileURL}: ${response.statusText}`)
        return
      }

      // Trigger the download directly
      const blob = await response.blob()

      // Remove .zip from the fileName
      const updatedFileName = fileName.toLowerCase().endsWith('.zip')
        ? fileName.substring(0, fileName.length - 4)
        : fileName

      saveAs(blob, updatedFileName)
    } catch (error) {
      message.error(`Failed to download file: ${(error as Error).message}`)
    }
  } else {
    const zip = new JSZip()
    const rootDirectory = 'asset_manager_file_system'

    for (const filePath of filePaths) {
      try {
        const fileURL = await downloadEntryFile(fdeCustomerUnitSlug, filePath, auth0)
        const response = await fetch(fileURL)

        if (!response.ok) {
          message.error(`Failed to download file from ${fileURL}: ${response.statusText}`)
          return
        }

        const arrayBuffer = await response.arrayBuffer()
        let fileLocationInZip = filePath.replace(rootDirectory, '') // Remove root directory

        if (!maintainFolderStructure) {
          // Get the filename from the filePath (assuming the filename is the last part after '/')
          fileLocationInZip = fileLocationInZip.split('/').pop() || 'unknown'
        }

        // Add the file to the zip at the specific location
        zip.file(fileLocationInZip, arrayBuffer)
      } catch (error) {
        message.error(`Failed to download file: ${(error as Error).message}`)
      }
    }

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: 'blob' })

    // Automatically trigger the download using file-saver
    saveAs(zipBlob, fileName)
  }
}
