import React, { useContext } from 'react'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import styled from 'styled-components'
import { asset_manager_theme } from '../../../assets/themes'
import StyledDatePicker from '../StyledDatePicker'
import { useRolodexEntries } from '../../../api/useRolodex'
import { trackEvent } from '../../helpers/mixpanel'

import { SWRResponse } from 'swr'
import { v4 as uuidv4 } from 'uuid'
import { FlattenedAssetData } from './AssetBaseballCard'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { Dayjs } from 'dayjs'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { ReactComponent as CalendarBoldIcon } from '../../../assets/icons/calendar-icon-bold.svg'
import { toTitleCase } from '../../helpers/stringFilters'
import { InspectionEntry } from '../../../pages/InspectionSchedulingTab/InspectionSchedulingInterface'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'

interface AssetFormProps {
  form: FormInstance<addInspectionFormValues>
  originPage: string
  refetchData?: () => void
  flattenedAssetData: FlattenedAssetData
  rawUnitInspectionDataSWR: SWRResponse<InspectionEntry[], any, any>
  fdeCustomerUnitSlug: string
  setIsInspectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const requiredFields = ['type', 'method', 'interval', 'scheduled_date']
const StyledInputNumber = styled(InputNumber)`
  border: none;
  background-color: ${asset_manager_theme.background.background_color6_5};
`

export interface addInspectionFormValues {
  type: string
  method: string
  interval: number
  scheduled_date: Dayjs
}

const NewInspectionForm: React.FC<AssetFormProps> = ({
  form,
  originPage,
  flattenedAssetData,
  rawUnitInspectionDataSWR,
  refetchData,
  fdeCustomerUnitSlug,
  setIsInspectionModalOpen,
}) => {
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)

  const rawAllInspectionDataSWR: SWRResponse<InspectionEntry[], any, any> =
    useRolodexEntries(
      {
        type: 'fde-am-inspection',
        limit: 1000,
        tags: {
          organization: siteData?.orgSlug,
        },
      },
      {},
      !!fdeCustomerUnitSlug
    )

  const handleSaveInspection = (values: addInspectionFormValues) => {
    trackEvent('Button Clicked', {
      buttonName: 'add_inspection',
      pageName: originPage,
      subPage: 'asset_baseball_card',
      assetDetails: {
        assetName: flattenedAssetData?.equip_description,
        date: (values.scheduled_date as Dayjs)?.format('YYYY-MM-DD'),
        inspectionType: values.type,
      },
    })
    //adding some bs validation here since bad inspection objects would truly screw inspection scheduling
    if (requiredFields.some((d) => !values[d as keyof typeof values])) {
      alert(
        `${requiredFields
          .filter((d) => !values[d as keyof typeof values])
          .join(', ')} are required`
      )
      return
    }
    const inspectionPatchBody = {
      id: uuidv4(),
      type: RolodexEntryType.INSPECTION_OBJECT,
      data: {
        area: flattenedAssetData.equip_area,
        cost: null,
        completed: false,
        asset_name: flattenedAssetData.equip_description,
        outage_type: flattenedAssetData.outage_type,
        next_deadline: (values.scheduled_date as Dayjs)
          .add(values.interval || 0, 'month')
          .format('YYYY-MM-DD'),
        scheduled_date: (values.scheduled_date as Dayjs).format('YYYY-MM-DD'),
        inspection_type: values.type,
        current_deadline: null,
        inspection_method: values.method,
        inspection_interval: values.interval,
        previous_inspection: null,
      },
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
        [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
      },
    }

    RolodexPatchBatch([inspectionPatchBody], auth0, siteData).then(() => {
      rawUnitInspectionDataSWR.mutate()
      refetchData?.()
    })
  }

  // ----------------------- [START] Selection Option Creation Logic --------------------
  interface typeMethodCombination {
    type: string
    method: string
  }

  function removeDuplicates(array: typeMethodCombination[]) {
    const seen = new Set()
    return new Set(
      array.filter((item) => {
        const serializedItem = JSON.stringify(item)
        if (!seen.has(serializedItem)) {
          seen.add(serializedItem)
          return true
        }
        return false
      })
    )
  }

  function getMissingCategoriesList(
    allCategories: Set<typeMethodCombination>,
    existingCategories: Set<typeMethodCombination>
  ) {
    const missingCategoriesList: typeMethodCombination[] = []

    for (const combAll of allCategories) {
      const combAllJSON = JSON.stringify(combAll)
      let isExist = false
      for (const combExist of existingCategories) {
        const combExistJSON = JSON.stringify(combExist)
        if (combAllJSON === combExistJSON) {
          isExist = true
        }
      }
      if (!isExist) {
        missingCategoriesList.push(combAll)
      }
    }

    return missingCategoriesList
  }

  // Logic for future types methods matching
  const getMissingTypesMethods = () => {
    const existingCategories: typeMethodCombination[] = []
    const allCategories: typeMethodCombination[] = []

    rawUnitInspectionDataSWR.data?.forEach((d) => {
      existingCategories.push({
        type: d.entry.data.inspection_type,
        method: d.entry.data.inspection_method,
      })
    })

    rawAllInspectionDataSWR.data?.forEach((d) => {
      allCategories.push({
        type: d.entry.data.inspection_type,
        method: d.entry.data.inspection_method,
      })
    })

    const existingCategoriesSet = removeDuplicates(existingCategories)
    const allCategoriesSet = removeDuplicates(allCategories)
    const missingCategoriesList = getMissingCategoriesList(
      allCategoriesSet,
      existingCategoriesSet
    )

    const missingCategoriesDict = {}

    for (let i = 0; i < missingCategoriesList.length; i++) {
      const type = missingCategoriesList[i]['type']
      const method = missingCategoriesList[i]['method']

      if (type in missingCategoriesDict) {
        ;(missingCategoriesDict as any)[type].push(method)
      } else {
        ;(missingCategoriesDict as any)[type] = [method]
      }
    }
    return missingCategoriesDict
  }

  const missingCategoriesDict = getMissingTypesMethods()
  const typeOptions = Object.keys(missingCategoriesDict).map((d) => {
    return { value: d, label: toTitleCase(d) }
  })

  // ----------------------- [END] Selection Option Creation Logic --------------------

  return (
    <Form
      form={form}
      preserve={false}
      onFinish={(values) => {
        handleSaveInspection(values)
        setIsInspectionModalOpen(false)
        form.resetFields()
      }}
      style={{ width: '100%' }}>
      <Form.Item label='Type' name='type'>
        <Select options={typeOptions.length !== 0 ? typeOptions : undefined} />
      </Form.Item>

      {/* For hodge hide this form - hard code it */}
      <Form.Item
        label='Method'
        name='method'
        style={{ visibility: 'hidden', position: 'absolute' }}
        initialValue={'UT'}>
        <Select />
      </Form.Item>

      <Form.Item label='Interval' name='interval'>
        <StyledInputNumber addonAfter={'months'} />
      </Form.Item>

      <Form.Item label='Scheduled Date' name='scheduled_date'>
        <StyledDatePicker
          highVis={true}
          suffixIcon={<CalendarBoldIcon fill='black' />}
          allowClear={false}
        />
      </Form.Item>
    </Form>
  )
}

export default NewInspectionForm
