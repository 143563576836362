import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

interface StatusPillProps {
  text: string | null | undefined
}

export const StatusPill = styled.div<StatusPillProps>`
  width: 6.5rem;
  font-size: 0.75rem;
  padding: ${({ text }) => (text ? '0.05rem' : '0')};
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: center;
  border-radius: 6px;
  margin: 0 auto;

  background-color: ${({ text }) => {
    if (text === 'Needs Review') return asset_manager_theme.colors.high_vis
    if (text === 'Approved') return asset_manager_theme.background.background_color8
    return asset_manager_theme.background.background_color2
  }};
  color: ${({ text }) => {
    if (text === 'Needs Review') return asset_manager_theme.colors.yellow_dark
    if (text === 'Approved') return asset_manager_theme.background.high_vis
    return asset_manager_theme.background.background_color8
  }};
  border: ${({ text }) => {
    if (text === 'Approved')
      return `0.1rem solid ${asset_manager_theme.background.high_vis}`
  }};
`
