import { FC, useMemo, useState } from 'react'
import { RolodexFileData } from '../../util/DataSchema'
import { Document, Page } from 'react-pdf'
import { useGetSignedUrl } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import styled from 'styled-components'

import './RolodexPdfViewer.css'
import { Button, Pagination, Slider, Spin, Typography } from 'antd'
import { CloseCircleTwoTone, ExportOutlined, LoadingOutlined } from '@ant-design/icons'

import { encode } from 'base-64'
import { asset_manager_theme } from '../../assets/themes'

export interface RolodexPdfViewerProps {
  rolodexFile: RolodexFileData
  width: number
  setWidth: (width: number) => void
  showAllPages?: boolean
  hideZoon?: boolean
}

const ControlsHover = styled.div`
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ControlsArea = styled.div`
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
`

const ControlsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
`

const SliderArea = styled.div`
  display: inline-block;
  width: 300px;
`

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 250px;
`

const { Text } = Typography

const loadingSpinner = (
  <LoadingContainer>
    <Spin size='large' indicator={<LoadingOutlined />} />
    <Text>Loading</Text>
  </LoadingContainer>
)

const errorDisplay = (
  <LoadingContainer>
    <CloseCircleTwoTone
      style={{ fontSize: 128 }}
      twoToneColor={asset_manager_theme.colors.red}
    />
    <Text>Error loading PDF</Text>
  </LoadingContainer>
)

export const RolodexPdfViewer: FC<RolodexPdfViewerProps> = (props) => {
  const auth0 = useAuthInfo()
  const signedUrlSwr = useGetSignedUrl(
    props.rolodexFile.entry_id,
    props.rolodexFile.path,
    auth0
  )

  const [widthMin, widthMax] = [600, Math.max(window.innerWidth, 600)]

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(-1)

  // react-pdf uses compare-by-reference - this is to stop the PDF flickering
  // on validate - be very careful with the dependency array if you change this.
  const fileUrl: any | undefined = useMemo(() => {
    if (!signedUrlSwr.isLoading) {
      return signedUrlSwr.data
    }
  }, [signedUrlSwr.data])

  return signedUrlSwr.error ? (
    errorDisplay
  ) : (
    <Spin spinning={signedUrlSwr.isLoading}>
      <DocumentContainer>
        {signedUrlSwr.data && (
          <Document
            file={fileUrl}
            onLoadSuccess={(document) => {
              setTotalPages(document.numPages)
            }}
            loading={loadingSpinner}
            error={errorDisplay}>
            <div style={{ position: 'relative' }} className='hover-to-unhide'>
              <ControlsHover
                className='hide'
                style={{
                  display: props.hideZoon && props.showAllPages ? 'none' : undefined,
                }}>
                <ControlsArea>
                  {!props.showAllPages && (
                    <ControlsContainer>
                      <Pagination
                        simple
                        current={pageNumber}
                        onChange={(e) => setPageNumber(e)}
                        pageSize={1}
                        total={totalPages}
                      />
                      <Button
                        onClick={() => {
                          const pathBase64 = encode(props.rolodexFile.path)
                          window.open(`/pdf/${props.rolodexFile.entry_id}/${pathBase64}`)
                        }}>
                        <ExportOutlined size={12} />
                      </Button>
                    </ControlsContainer>
                  )}
                  {!props.hideZoon && (
                    <ControlsContainer>
                      Zoom:
                      <SliderArea>
                        <Slider
                          defaultValue={30}
                          min={0}
                          max={100}
                          value={((props.width - widthMin) * 100) / (widthMax - widthMin)}
                          onChange={(val) =>
                            props.setWidth(widthMin + val * ((widthMax - widthMin) / 100))
                          }
                        />
                      </SliderArea>
                    </ControlsContainer>
                  )}
                </ControlsArea>
              </ControlsHover>
              {props.showAllPages ? (
                totalPages > 0 &&
                [...Array(totalPages - 1).keys()].map((pageNum) => {
                  return (
                    <Page
                      pageNumber={pageNum + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={props.width}
                      loading={loadingSpinner}
                    />
                  )
                })
              ) : (
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={props.width}
                  loading={loadingSpinner}
                />
              )}
            </div>
          </Document>
        )}
      </DocumentContainer>
    </Spin>
  )
}
