import { RolodexFileData } from './DataSchema'
import { ROLODEX_KEY } from './OverviewConstants'
import useSWR, { SWRResponse } from 'swr'
import { useRolodexEntries, RolodexLoadedEntry, RolodexEntry } from '../api/useRolodex'
import {
  getFetcher,
  patchFetcher,
  postFetcher,
  putFetcher,
  deleteFetcher,
} from '../api/fetchers'
import { rolodexUrl } from '../api/helpers'
import { AuthInfo, useAuthInfo } from '../contextProviders/useAuthInfo'
import { SiteData } from '../contextProviders/siteContextProvider'
import { RolodexEntryType } from './RolodexTypeConstants'

/**
 * Gets the site data from Rolodex, and aggregates it.  The aggregation is so this can be used at an org-level view.
 *
 * Rolodex Schema:
 *    https://geckorobotics.atlassian.net/wiki/spaces/~62b0828e70d15a142e37f2b1/pages/3557490766/+Optimizer+Schema
 *
 *
 * @param radarObject
 */

export interface PatchBody {
  id: string
  data?: Record<string, any>
  tags?: Record<string, any>
  type?: string
  delete_data?: string[]
}

const addPermissionTags = (auth0: AuthInfo, tags?: Record<any, any>) => {
  if (auth0.permissionTags) {
    tags = { ...(tags || {}), ...auth0.permissionTags }
  }
  return tags || {}
}

export function useGetSiteData(
  auth0: AuthInfo,
  siteSlug?: string
): [SWRResponse, Record<any, any>] {
  const siteBodyData = {
    type: 'site',
    ids: [siteSlug],
    tags: addPermissionTags(auth0),
  }
  const rolodexSiteData = useRolodexEntries(siteBodyData)
  let out = {}
  if (!rolodexSiteData.isLoading && rolodexSiteData.data) {
    out = rolodexSiteData.data?.map((d: { entry: RolodexEntry }) => d.entry)?.[0] ?? {}
  }
  return [rolodexSiteData, out]
}

export function getInspectionData(auth0: AuthInfo, fdeCustomerUnitSlug: string) {
  const inspectionBodyData = {
    type: 'fde-am-inspection',
    limit: 1000,
    tags: addPermissionTags(auth0, {
      [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
    }),
  }

  const endpoint = `${rolodexUrl()}/entries/load`

  return postFetcher(endpoint, JSON.stringify(inspectionBodyData), auth0)
}

export function useGetOutageSchedule(
  siteSlug?: string
): SWRResponse<RolodexLoadedEntry<RolodexEntry>[], any, any> {
  const body = {
    type: 'fde-am-outage',
    tags: { site: siteSlug },
  }
  return useRolodexEntries(body)
}

export function useGetSupportedAssets(
  siteSlug: string
): SWRResponse<RolodexLoadedEntry<RolodexEntry>[], any, any> {
  // get all associated sites for this  View

  // prepend fde- onto the associated sites to get the fde-site entry ids
  const bodyData = {
    ids: [`fde-${siteSlug}`],
    type: RolodexEntryType.FDE_SITE,
  }
  return useRolodexEntries(bodyData)
}

export function useGetComponentFileData(
  componentSlug: string
): SWRResponse<RolodexFileData[]> {
  const entry_id = componentSlug
  const endpoint = `${rolodexUrl()}/files/list`
  const auth0 = useAuthInfo()
  const body = {
    entry_id,
    recursive: true,
    subpath: 'asset_manager_file_system',
    tags: addPermissionTags(auth0),
  }
  return useSWR(
    auth0.headers ? [endpoint, body, auth0] : false,
    ([endpoint, body, auth0]) => postFetcher(endpoint, JSON.stringify(body), auth0)
  )
}

export async function downloadEntryFile(
  entryId: string,
  filePath: string,
  auth0: AuthInfo
) {
  const endpoint = `${rolodexUrl()}/files/${entryId}/${encodeURIComponent(filePath)}`

  return await getFetcher(endpoint, auth0)
}

export function useGetSignedUrl(entry_id: string, filePath: string, auth0: AuthInfo) {
  const endpoint = `${rolodexUrl()}/files/${entry_id}/${encodeURIComponent(filePath)}`
  return useSWR(auth0.headers ? [endpoint, auth0] : false, ([endpoint, auth0]) =>
    getFetcher(endpoint, auth0)
  )
}

export const uploadComponentFile = async (
  auth0: AuthInfo,
  componentSlug: string,
  file: File,
  toPath: string
): Promise<{ error: string | undefined; data: any; fileName: string }> => {
  const entry_id = componentSlug
  const path = `${rolodexUrl()}/files/${entry_id}/${encodeURIComponent(
    toPath
  )}?content_type=application/octet-stream`
  return await putFetcher(path, auth0, {}, 'application/octet-stream')
    .then(async (signed_url) => {
      const gcsRes = await fetch(signed_url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
      return {
        error: undefined,
        data: gcsRes,
        fileName: file.name,
      }
    })
    .catch((e) => {
      return { error: e, data: undefined, fileName: file.name }
    })
}

export const getFileTags = async (
  auth0: AuthInfo,
  componentSlug: string,
  path: string
): Promise<string[]> => {
  const FDECustomerUnitDataBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    ids: [componentSlug],
  }

  const FDECustomerUnitData = await postFetcher(
    `${rolodexUrl()}/entries/load`,
    JSON.stringify(FDECustomerUnitDataBody),
    auth0
  )

  const FDECustomerUnitFileTags = FDECustomerUnitData?.[0]?.entry?.data?.file_tags ?? {}

  // Find all tags that contain the given path
  const matchingTags: string[] = []
  for (const tag in FDECustomerUnitFileTags) {
    if (FDECustomerUnitFileTags[tag].includes(path)) {
      matchingTags.push(tag)
    }
  }

  return matchingTags
}

export const editFileTags = async (
  auth0: AuthInfo,
  componentSlug: string,
  path: string,
  editTags: string[],
  siteData: SiteData
): Promise<void> => {
  const FDECustomerUnitDataBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    ids: [componentSlug],
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  }

  const FDECustomerUnitData = await postFetcher(
    `${rolodexUrl()}/entries/load`,
    JSON.stringify(FDECustomerUnitDataBody),
    auth0
  )

  const FDECustomerUnitFileTags = FDECustomerUnitData?.[0]?.entry?.data?.file_tags ?? {}

  // Iterate through editTags
  for (const tag of editTags) {
    if (!FDECustomerUnitFileTags[tag]) {
      // Tag doesn't exist in FDECustomerUnitFileTags, create a new key
      FDECustomerUnitFileTags[tag] = [path]
    } else if (FDECustomerUnitFileTags[tag].indexOf(path) === -1) {
      // Tag exists but path is not in the array, append it
      FDECustomerUnitFileTags[tag].push(path)
    }
  }

  const deleteTags: string[] = []

  // Iterate through existing tags
  for (const tag in FDECustomerUnitFileTags) {
    const paths = FDECustomerUnitFileTags[tag]
    // Check if the path exists in the current tag
    const pathIndex = paths.indexOf(path)
    if (pathIndex !== -1) {
      // Path exists in the current tag
      // Check if the tag is present in editTags
      const tagIndexInEdit = editTags.indexOf(tag)

      if (tagIndexInEdit === -1) {
        // Tag not found in editTags, remove the path from the array
        paths.splice(pathIndex, 1)
        FDECustomerUnitFileTags[tag] = paths

        // If the array becomes empty after removal, delete the tag
        if (paths.length === 0) {
          deleteTags.push('file_tags.' + tag)
        }
      }
    }
  }

  const FDECustomerUnitDataPatchBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    id: componentSlug,
    data: {
      file_tags: FDECustomerUnitFileTags,
    },
    delete_data: deleteTags,
  }

  return await RolodexPatchBatch([FDECustomerUnitDataPatchBody], auth0, siteData)
}

export async function deleteComponentFile(
  componentSlug: string,
  filePath: string,
  auth0: AuthInfo
) {
  const entry_id = componentSlug
  const endpoint = `${rolodexUrl()}/files/${entry_id}/${encodeURIComponent(filePath)}`
  return await deleteFetcher(endpoint, auth0)
}

/**
 * Get the  data for-all components under the current view.
 *
 * Returns ComponentData, the component data, and SWRResponse, the swr response for the swr fetch used, which allows us
 * to call SWRResponse.mutate to re-fetch the data.
 *
 * @param radarObject
 * @param radarObjects
 */
export function useGetComponentData(
  componentSlugs: Array<string>
): SWRResponse<RolodexLoadedEntry<RolodexEntry>[], any, any> {
  const lookupIds = componentSlugs.map((slug) => `fde-${slug}`)
  const auth0 = useAuthInfo()
  const bodyData = {
    ids: lookupIds,
    type: 'fde-component',
    limit: 1000,
    tags: addPermissionTags(auth0),
  }

  return useRolodexEntries(bodyData)
}

export function RolodexPatchBatch(
  patchBody: PatchBody[],
  auth0: AuthInfo,
  siteData: SiteData
) {
  const endpoint = `${rolodexUrl()}/entries/`
  patchBody = patchBody.map((d) => {
    d.tags = { ...d.tags, site: siteData?.siteSlug, organization: siteData?.orgSlug }
    return d
  })
  return patchFetcher(endpoint, JSON.stringify(patchBody), auth0)
}

export function RolodexPutBatch(
  ids: string[],
  data: Record<string, any>,
  auth0: AuthInfo,
  type: string,
  tags: Record<string, any> = {}
) {
  const endpoint = `${rolodexUrl()}/entries/`
  const putData = ids.map((d) => {
    return {
      id: d,
      data,
      type,
      tags,
    }
  })
  return putFetcher(endpoint, auth0, JSON.stringify(putData))
}

/**
 * Update the selected repair plan in Rolodex of the component data.
 *
 * @param componentSlug
 * @param selectedRepairPlan
 * @param ComponentSWRResponse
 */
export async function patchSelectedRepairPlan(
  rolodexId: string,
  selectedRepairPlan: string,
  auth0: AuthInfo
) {
  const endpoint = `${rolodexUrl()}/entries/`
  const tags = addPermissionTags(auth0)
  const patchData = [
    {
      id: rolodexId,
      data: {
        [ROLODEX_KEY]: {
          selected_repair_plan: selectedRepairPlan,
        },
      },
      tags,
    },
  ]
  await patchFetcher(endpoint, JSON.stringify(patchData), auth0)
}

export function useGetFDECustomerUnitData(
  auth0: AuthInfo,
  siteData?: Record<any, any>
): SWRResponse {
  const FDECustomerUnitDataBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    limit: 100000000,
    tags: {
      site: siteData?.siteSlug,
    },
  }

  const rolodexFDECustomerUnitDataBody = useRolodexEntries(
    FDECustomerUnitDataBody,
    undefined,
    !!(siteData?.siteSlug && auth0)
  )

  return rolodexFDECustomerUnitDataBody
}

export function RolodexMoveFile(
  auth0: AuthInfo,
  entry_id: string,
  file_path: string,
  source_path: string,
  content_type: string,
  source?: string
) {
  const endpoint = `${rolodexUrl()}/files/move/${entry_id}/${encodeURIComponent(
    file_path
  )}?content_type=${content_type}&source_path=${encodeURIComponent(source_path)}${
    source ? `&source=${source}` : ''
  }`
  return postFetcher(endpoint, '{}', auth0)
}
