import React, { useContext, useMemo, useState } from 'react'
import { asset_manager_theme } from '../../assets/themes'
import { Asset, PlanModelingTableRow } from './PlanModelingInterface'
import TruncatedTextWithTooltip from '../../components/Common/TruncatedTextWithTooltip'
import { EditableNumberElement } from '../../components/Common/EditableNumberElement'
import { PlanRecOptionsInput } from './Components/PlanRecOptionsInput'
import { PlanRecCheckboxInput } from './Components/PlanRecCheckboxInput'
import { SWRResponse } from 'swr'
import { BUDGET_OPTIONS_CONSTANTS } from '../../util/OptionsConstants'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { Input, Checkbox, Select, Spin, Table, Space, Button } from 'antd'
import { undoSubmitSelectedPlans } from '../../api/useFDEBackend'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { currencyConverter } from '../../components/helpers/stringFilters'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import { FilterValue } from 'antd/es/table/interface'
import { toTitleCase } from '../../components/helpers/stringFilters'
import { PlanRecPriorityDropDown } from './Components/PlanRecPriorityDropdown'
import { PlanRecDeadlinePicker } from './Components/PlanRecDeadlinePicker'
import { SearchOutlined } from '@ant-design/icons'
import GroupSelect from '../../components/Common/PlanRecGroupSelect'
import { useRolodexEntries } from '../../api/useRolodex'
import { NO_INFO_SET } from '../../util/constants'
import { TabKey } from '../AppLayout'
import { AssetPlanSelection } from './PlanModelingTab'

export const AssetFieldSet = new Set([
  'asset_name',
  'equip_area',
  'area_description',
  'risk_category',
])

interface AnalysisTabProps {
  selectedPlans: AssetPlanSelection
  setSelectedPlans: React.Dispatch<React.SetStateAction<AssetPlanSelection>>
  planModelingDataSWR: SWRResponse<
    {
      plan_modeling: Asset[]
      plan_summary: Asset[]
    },
    any,
    any
  >
  processedPlanData: Asset[]
  setPlanRecFilters: (filters: Record<string, FilterValue | null>) => void
  setAssetFilters: (filters: Record<string, FilterValue | null>) => void
  triageDataSwr?: SWRResponse<any, any, any>
  setBaseballCardFdeCustomerUnitSlug: (slug: string | undefined) => void
  selectedAssets: Record<string, boolean>
  setSelectedAssets: React.Dispatch<Record<string, boolean>>
}

interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: string[]) => void
  selectedKeys: string[]
  confirm: () => void
  clearFilters: () => void
}

const SearchDropdown: React.FC<FilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const [value, setValue] = useState(selectedKeys[0] || '')
  const handleSearch = () => {
    setSelectedKeys(value ? [value] : [])
    confirm()
  }
  const handleReset = () => {
    setValue('')
    clearFilters()
    confirm()
  }
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder='Search Asset Name'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={handleSearch}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button onClick={handleReset} size='small' style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type='primary'
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}>
          Search
        </Button>
      </Space>
    </div>
  )
}

const PlanModelingTable: React.FC<AnalysisTabProps> = ({
  selectedPlans,
  setSelectedPlans,
  processedPlanData,
  setPlanRecFilters,
  setAssetFilters,
  planModelingDataSWR,
  triageDataSwr,
  setBaseballCardFdeCustomerUnitSlug,
  selectedAssets,
  setSelectedAssets,
}) => {
  const auth0 = useAuthInfo()
  const siteData = React.useContext(SiteContext)
  // pulling feature flags for roi
  const userFeatureFlags = useContext(FeatureFlagContext)
  const lockOutEnabled = userFeatureFlags?.[TabKey.planModeling]?.read_only ?? false

  const accessibleColumnsSet = new Set(
    userFeatureFlags?.[TabKey.planModeling]?.accessible_columns || []
  )
  const planModelingData = planModelingDataSWR.data?.['plan_modeling']
  const [allGroups, setAllGroups] = useState<{ text: string; value: string }[]>([])

  const tableFormattedPlans = useMemo(() => {
    return (processedPlanData as Asset[]).reduce((acc, cur) => {
      const currentPlan = cur.selectedPlan
      const currentPlanRecs = currentPlan?.plan_recs
      if (!currentPlan || !currentPlanRecs || !currentPlanRecs.length) {
        return acc
      }

      const assetFirstRecRecord = {
        ...currentPlanRecs?.[0],
        asset_name: cur?.asset_name,
        total_plan_cost: currentPlan?.total_plan_cost,
        plans: cur?.plans,
        asset_id: cur.id,
        plan_slug: currentPlan?.plan_slug,
        action_taken: currentPlan?.action_taken,
        roi: currentPlan?.roi,
        deadline: currentPlanRecs?.[0]?.scheduled_date,
        action: currentPlanRecs?.[0]?.recommendation,
        priority: currentPlanRecs?.[0]?.priority,
        group: currentPlanRecs?.[0]?.cluster,
        recommended_action: currentPlanRecs?.[0]?.recommended_action,
        cost: currentPlanRecs?.[0]?.repair_cost,
        completed: currentPlanRecs?.[0]?.completed,
        plan_rec_id: currentPlanRecs?.[0]?.plan_rec_id,
        firstRec: true,
        area: cur?.fde_customer_unit_data?.equip_area,
        subArea: cur?.fde_customer_unit_data?.area_description,
        riskCategory: cur?.fde_customer_unit_data?.risk_category,
      }
      acc.push(assetFirstRecRecord)
      acc.push(
        ...(currentPlanRecs?.slice(1).map((d) => {
          return {
            asset_id: cur.id,
            deadline: d?.scheduled_date,
            action: d?.recommendation,
            priority: d?.priority,
            group: d?.cluster,
            recommended_action: d?.recommended_action,
            cost: d?.repair_cost,
            completed: d?.completed,
            firstRec: false,
            plan_slug: currentPlan?.plan_slug,
            plan_rec_id: d.plan_rec_id,
          }
        }) ?? [])
      )
      return acc
    }, [] as PlanModelingTableRow[])
  }, [processedPlanData])
  const generatePlanRecCategoricalColumn = (key: string) => {
    //this generates a category filter for columns that come from the plan recs rather than the plans or the assets
    return {
      title: toTitleCase(key),
      dataIndex: key,
      key: key,
      filters: [
        ...new Set(
          planModelingData?.reduce<
            (string | string[] | number | boolean | Date | null | undefined)[]
          >((acc, curr) => {
            const currentPlanSlug = selectedPlans[curr?.id]?.plan_slug
            const currentPlan = curr?.plans?.find(
              (plan) => plan.plan_slug === currentPlanSlug
            )
            const currentPlanRecs = currentPlan?.plan_recs
            acc.push(...(currentPlanRecs?.map((d) => d[key as keyof typeof d]) ?? []))
            return acc
          }, [])
        ),
      ].map((d) => {
        if (NO_INFO_SET.has(d)) {
          return { text: '------', value: '---' }
        }
        return { text: d, value: d }
      }) as { text: string; value: string }[],
    } as ColumnType<PlanModelingTableRow>
  }

  const generateAssetCategoryColumns = (key: string) => {
    //this generates a category filter for columns that come from the plan recs rather than the plans or the assets
    return {
      title: toTitleCase(key),
      dataIndex: key,
      key: key,
      filters: [
        ...new Set(
          planModelingData?.map(
            (d) =>
              d?.fde_customer_unit_data?.[key as keyof typeof d.fde_customer_unit_data]
          )
        ),
      ].map((d) => {
        if (NO_INFO_SET.has(d)) {
          return { text: '------', value: '---' }
        }
        return { text: d, value: d }
      }) as { text: string; value: string }[],
    } as ColumnType<PlanModelingTableRow>
  }

  const handlePlanChange = (
    assetId: string,
    selectedPlan: { plan_slug?: string; action_taken?: string }
  ) => {
    setSelectedPlans((prevSelectedPlans) => ({
      ...prevSelectedPlans,
      [assetId]: selectedPlan,
    }))
  }

  const fdePlanningConfigSwr = useRolodexEntries({
    type: RolodexEntryType.PLANNING_CONFIG_TYPE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
    },
  })

  useMemo(() => {
    if (!fdePlanningConfigSwr?.error && fdePlanningConfigSwr.data) {
      const siteData = fdePlanningConfigSwr.data[0]?.entry?.data
      if (siteData?.group_map) {
        setAllGroups(
          Object.keys(siteData.group_map).map((d) => {
            return { text: d, value: d }
          })
        )
      } else {
        setAllGroups([])
      }
    }
  }, [fdePlanningConfigSwr.data])

  const columnDefs: ColumnsType<PlanModelingTableRow> = useMemo(() => {
    return (
      [
        {
          dataIndex: '',
          key: 'selection',
          width: '2.1%',
          render: (_, record) => {
            if (!record.asset_name) {
              // Only render checkbox when asset_name has value
              return null
            }
            return (
              <Checkbox
                disabled={lockOutEnabled} // Checklockout
                checked={selectedAssets[record.asset_id] || false}
                onChange={(e) =>
                  setSelectedAssets({
                    ...selectedAssets,
                    [record.asset_id]: e.target.checked,
                  })
                }
              />
            )
          },
        },
        {
          title: 'Asset Name',
          dataIndex: 'asset_name',
          key: 'asset_name',
          width: '11%',
          render: (text, record) => {
            return (
              <div>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setBaseballCardFdeCustomerUnitSlug(record.asset_id)
                  }}>
                  {text}
                </span>
                {record.plans?.some((d) => d.action_taken === 'Submitted') && (
                  <button
                    style={{
                      border: `0.1rem solid ${asset_manager_theme.background.background_color6}`,
                      backgroundColor: `${asset_manager_theme.background.background_color6}`,
                      borderRadius: '0.5rem',
                      fontSize: '0.8rem',
                      marginLeft: '0.5rem',
                      lineHeight: '1rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      color: `${asset_manager_theme.background.background_color3}`,
                    }}
                    onClick={() => {
                      undoSubmitSelectedPlans(
                        auth0,
                        record?.asset_id,
                        siteData?.siteSlug
                      ).then(() => {
                        planModelingDataSWR?.mutate()
                        triageDataSwr?.mutate()
                      })
                    }}>
                    {planModelingDataSWR?.isLoading ? (
                      <Spin size={'small'} style={{ width: '5.5rem' }} />
                    ) : (
                      <p style={{ width: '5.5rem' }}>Undo Submit</p>
                    )}
                  </button>
                )}
              </div>
            )
          },
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <SearchDropdown
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys as string[]} // Asserting selectedKeys is a string array
              confirm={confirm}
              clearFilters={() => {
                clearFilters!()
                setAssetFilters({})
              }} // Use non-null assertion if sure it's not undefined
            />
          ),
          filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
        },
        {
          title: 'Plan',
          key: 'plan',
          width: '12%',
          render: (text, record) => {
            if (!record.plans || record.plans.length === 0) {
              return <div></div>
            }
            if (record.plans.length === 1) {
              return (
                <div>
                  {record?.plans[0].plan_name}
                  {record?.plans[0]?.action_taken === 'Submitted' && (
                    <span
                      style={{
                        marginLeft: '0.2rem',
                        color: asset_manager_theme.colors.high_vis,
                      }}>
                      ✓
                    </span>
                  )}{' '}
                </div>
              )
            } else {
              return (
                <Select
                  disabled={lockOutEnabled} // Checklockout
                  defaultValue={record?.plan_slug}
                  style={{ width: 170 }}
                  onChange={(value) => {
                    handlePlanChange(record.asset_id, {
                      plan_slug: value,
                      action_taken:
                        record?.plans?.find((d) => d.plan_slug === value)?.action_taken ??
                        '',
                    })
                  }}>
                  {record.plans.map((plan) => {
                    return (
                      <Select.Option key={plan.plan_slug} value={plan.plan_slug}>
                        {plan.plan_name}{' '}
                        {plan.action_taken === 'Submitted' && (
                          <span
                            style={{
                              marginLeft: '0.2rem',
                              color: asset_manager_theme.colors.high_vis,
                            }}>
                            ✓
                          </span>
                        )}
                      </Select.Option>
                    )
                  })}
                </Select>
              )
            }
          },
        },
        {
          ...generateAssetCategoryColumns('equip_area'),
          title: 'Area',
          dataIndex: 'area',
          width: '7%',
        },
        {
          ...generateAssetCategoryColumns('area_description'),
          title: 'Sub Area',
          dataIndex: 'subArea',
          width: '7%',
        },
        {
          ...generateAssetCategoryColumns('risk_category'),
          title: 'Risk Category',
          dataIndex: 'riskCategory',
          width: '7%',
        },
        {
          title: 'Est Total',
          dataIndex: 'total_plan_cost',
          key: 'total_plan_cost',
          width: '6%',
          render: (_, record) => {
            if (record.firstRec) {
              return currencyConverter(record?.total_plan_cost || 0)
            }
          },
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          width: '18%',
          render: (text) => {
            return <TruncatedTextWithTooltip maxCharacters={100} text={text} />
          },
        },
        {
          ...generatePlanRecCategoricalColumn('priority'),
          align: 'center',
          width: '7%',
          render: (text, record) => {
            return (
              <PlanRecPriorityDropDown
                disabled={lockOutEnabled} // Checklockout
                planRecId={record.plan_rec_id}
                updateKey={'priority'}
                fieldValue={text}
                rolodexType={RolodexEntryType.PLAN_REC}
                refetchData={() => {
                  planModelingDataSWR?.mutate()
                }}
              />
            )
          },
        },
        {
          title: 'Deadline',
          dataIndex: 'deadline',
          key: 'deadline',
          width: '8.5%',
          render: (text, record) => {
            return (
              <PlanRecDeadlinePicker
                disabled={lockOutEnabled} // Checklockout
                planRecId={record.plan_rec_id}
                deadline={text}
                updateKey={'scheduled_date'}
                dateFormat={'YYYY-MM'}
                picker={'month'}
                refetchData={() => {
                  planModelingDataSWR?.mutate()
                }}
              />
            )
          },
        },
        {
          title: 'Est Cost',
          dataIndex: 'cost',
          key: 'cost',
          width: '7%',
          render: (text, record) => {
            return (
              <EditableNumberElement
                disabled={lockOutEnabled} // Checklockout
                objectId={record.plan_rec_id}
                numberValue={record.cost ?? undefined}
                field={'repair_cost'}
                format={'currency'}
                rolodexType={RolodexEntryType.PLAN_REC}
                refetchData={() => {
                  planModelingDataSWR?.mutate()
                }}
              />
            )
          },
        },
        {
          title: 'Group',
          dataIndex: 'group',
          key: 'group',
          width: '8%',
          filters: allGroups,
          render: (groups, record) => (
            <GroupSelect
              disabled={lockOutEnabled} // Checklockout
              recGroups={record.group || []}
              planRecommendationSlug={record.plan_rec_id}
              refetchData={() => {
                planModelingDataSWR?.mutate()
                fdePlanningConfigSwr?.mutate()
              }}
              fdePlanningConfigSwr={fdePlanningConfigSwr}
            />
          ),
        },
        {
          ...generatePlanRecCategoricalColumn('recommended_action'),
          title: 'Budget',
          dataIndex: 'recommended_action',
          key: 'recommended_action',
          width: '7%',
          render: (text, record) => {
            return (
              <PlanRecOptionsInput
                disabled={lockOutEnabled} // Checklockout
                optionValue={record.recommended_action || ''}
                planRecId={record.plan_rec_id}
                field={'recommended_action'}
                refetchData={() => {
                  planModelingDataSWR?.mutate()
                }}
                rolodexType={RolodexEntryType.PLAN_REC}
                options={BUDGET_OPTIONS_CONSTANTS}
              />
            )
          },
        },
        {
          title: 'Complete',
          dataIndex: 'completed',
          key: 'completed',
          align: 'center',
          width: '5%',
          render: (text, record) => {
            return (
              <PlanRecCheckboxInput
                disabled={lockOutEnabled} // Checklockout
                checkboxValue={record.completed}
                planRecId={record.plan_rec_id}
                field={'completed'}
                refetchData={() => {
                  planModelingDataSWR?.mutate()
                }}
                rolodexType={RolodexEntryType.PLAN_REC}
              />
            )
          },
        },
      ] as ColumnsType<PlanModelingTableRow>
    ).filter((d) => {
      if (!userFeatureFlags?.superUser && !accessibleColumnsSet.has(d.key as string)) {
        return false
      } else {
        return true
      }
    })
  }, [planModelingDataSWR.data, allGroups, selectedPlans, selectedAssets])

  return (
    <Table
      dataSource={tableFormattedPlans}
      columns={columnDefs}
      pagination={{ pageSize: 25 }}
      style={{ minWidth: '105%', overflowX: 'auto' }}
      onChange={(pag, filters) => {
        const assetFilters: Record<string, FilterValue | null> = {}
        const PlanRecFilters: Record<string, FilterValue | null> = {}
        Object.keys(filters).forEach((key) => {
          if (AssetFieldSet.has(key)) {
            assetFilters[key] = filters[key]
          } else {
            PlanRecFilters[key] = filters[key]
          }
        })
        setPlanRecFilters(PlanRecFilters)
        setAssetFilters(assetFilters)
      }}
    />
  )
}

export default PlanModelingTable
