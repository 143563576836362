import { useCallback, useEffect, useState } from 'react'
import { LifeExtensionTableData } from '../../pages/RecommendationsTab/RecommendationsInterface'

/**
 * Set up the `selectedActionName` state, and update it when `lifeExtensionTableData` changes
 */
export function useGetDefaultSelectedActionKey(
  lifeExtensionTableData: LifeExtensionTableData
): [string | undefined, (selectedActionName: string | undefined) => void] {
  /**
   * Get the default selected action key, which is the first key in the `repairs` object
   */
  const getDefaultSelectedActionKey: () => string | undefined = useCallback(() => {
    return Object.keys(lifeExtensionTableData?.repairs ?? {})?.[0]
  }, [lifeExtensionTableData])

  const [selectedActionKey, setSelectedActionKey] = useState<string | undefined>(
    getDefaultSelectedActionKey()
  )

  useEffect(() => {
    setSelectedActionKey(getDefaultSelectedActionKey())
  }, [lifeExtensionTableData])

  return [selectedActionKey, setSelectedActionKey]
}
