import { ColumnType } from 'antd/es/table'
import { ColumnTitle } from 'antd/es/table/interface'
import dayjs, { Dayjs } from 'dayjs'

export const sortNulls: (val1: unknown, val2: unknown) => number | null = (
  val1,
  val2
) => {
  if (val1 === val2) {
    return 0
  }
  // Put nulls last
  if (!val1 && !val2) {
    return 0
  } else if (!val2) {
    return -1
  } else if (!val1) {
    return 1
  }
  return null
}

export const defaultSort: <T>(key: keyof T) => (row1: T, row2: T) => number = (key) => {
  return (row1, row2) => {
    const val1 = row1[key]
    const val2 = row2[key]

    const nulls = sortNulls(val1, val2)
    if (nulls !== null) {
      return nulls
    }

    // If we get here, they're both truth-y (non-null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((val1 as any) < (val2 as any)) {
      return -1
    } else {
      return 1
    }
  }
}

export const dateSort: <T>(key: keyof T) => (row1: T, row2: T) => number = (key) => {
  return (row1, row2) => {
    const val1 = row1[key]
    const val2 = row2[key]

    const nulls = sortNulls(val1, val2)
    if (nulls !== null) {
      return nulls
    }
    const dateA = dayjs(val1 as any)
    const dateB = dayjs(val2 as any)
    if (dateA.isValid() && dateB.isValid()) {
      return dateA.diff(dateB)
    }
    if (dateA.isValid()) {
      return 1
    }
    if (dateB.isValid()) {
      return -1
    }
    return 0
  }
}

export const dateSortHelper = (val1: Dayjs | null, val2: Dayjs | null) => {
  const nulls = sortNulls(val1, val2)
  if (nulls !== null) {
    return nulls
  }
  const dateA = dayjs(val1)
  const dateB = dayjs(val2)
  if (dateA.isValid() && dateB.isValid()) {
    return dateA.diff(dateB)
  }
  if (dateA.isValid()) {
    return 1
  }
  if (dateB.isValid()) {
    return -1
  }
  return 0
}

export function defaultSortedColumn<REC_T>(
  title: ColumnTitle<REC_T>,
  key: Extract<keyof REC_T, string>,
  render?: (value: any, record: REC_T, index: number) => React.ReactNode
): ColumnType<REC_T> {
  return {
    title,
    dataIndex: key,
    key: key,
    sorter: defaultSort(key),
    render,
  }
}
