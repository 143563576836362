import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Button, Input, Modal, Select, ColorPicker } from 'antd'
import { FDECustomerUnit } from '../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { Column, Row } from '../Common/Grid'
import RadarModule, { MarkerData } from './RadarModule'
import mapboxgl from 'mapbox-gl'
import { getMapMarkerSVG } from './MapMarker'
import { asset_manager_theme } from '../../assets/themes'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
mapboxgl.accessToken =
  'pk.eyJ1IjoiZ2Vja29yb2JvdGljcyIsImEiOiJjbGwycmE0dmMxaTg5M2xsczByYzdhd296In0.dhEv6ff1JBIRG_Uvq9GseA'

interface AssetMappingModalProps {
  assetModalVisible: boolean
  setAssetModalVisible: Dispatch<SetStateAction<boolean>>
  availableAssets?: FDECustomerUnit[]
  initialAssetId?: string // We can pass in the initial asset ID if we're editing an existing asset
  initialEquipDescription?: string // We can pass in the initial asset description if we're editing an existing asset
  editMarkerData?: MarkerData // Marker data for the marker being edited
  tempMarker?: mapboxgl.Marker | null // Temporary marker for adding a new asset
  setAddingAsset?: Dispatch<SetStateAction<boolean>> // Adding asset state
  mapView?: boolean // If true, the modal will display a map view
  style?: React.CSSProperties
  modalWidth?: string | number
  refetchData?: () => void
  disableDeleteAssetLocation?: boolean
}

export const AssetMappingModal: React.FC<AssetMappingModalProps> = ({
  assetModalVisible,
  setAssetModalVisible,
  availableAssets,
  initialAssetId,
  initialEquipDescription,
  editMarkerData,
  tempMarker,
  setAddingAsset,
  mapView,
  style,
  modalWidth,
  refetchData,
  disableDeleteAssetLocation,
}) => {
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)
  const [selectedAssetId, setSelectedAssetId] = useState<string>(initialAssetId || '')

  const [latitude, setLatitude] = useState<number | string>(
    initialAssetId && editMarkerData
      ? editMarkerData?.latitude
      : tempMarker?.getLngLat().lat || ''
  )
  const [longitude, setLongitude] = useState<number | string>(
    initialAssetId && editMarkerData
      ? editMarkerData?.longitude
      : tempMarker?.getLngLat().lng || ''
  )

  const [color, setColor] = useState<string>(
    initialAssetId && editMarkerData
      ? editMarkerData?.color || asset_manager_theme.text.high_vis
      : asset_manager_theme.text.high_vis
  )

  // Edit marker for existing asset
  const [editMarker, setEditMarker] = useState<mapboxgl.Marker | null>(() => {
    if (editMarkerData) {
      const el = document.createElement('div')
      el.innerHTML = getMapMarkerSVG(
        asset_manager_theme.background.background_color5,
        35,
        'black'
      )
      el.className = 'edit-marker'
      // Create a draggable marker
      return new mapboxgl.Marker({ element: el, draggable: true }).setLngLat([
        editMarkerData.longitude,
        editMarkerData.latitude,
      ])
    }
    return null
  })

  useEffect(() => {
    return () => {
      if (editMarker) {
        setLatitude(editMarker.getLngLat().lat)
        setLongitude(editMarker.getLngLat().lng)
      }
    }
  }, [editMarker])

  const onDragEnd = (newLngLat: mapboxgl.LngLat) => {
    if (newLngLat) {
      setLatitude(newLngLat.lat)
      setLongitude(newLngLat.lng)
    }
  }

  // Update the edit marker's position when the latitude and longitude change
  useEffect(() => {
    if (editMarker) {
      editMarker.setLngLat([Number(longitude), Number(latitude)])
    }
  }, [latitude, longitude, editMarker])

  // Temporary marker for adding a new asset
  useEffect(() => {
    if (tempMarker) {
      setLatitude(tempMarker.getLngLat().lat)
      setLongitude(tempMarker.getLngLat().lng)
    }
  }, [tempMarker])

  useEffect(() => {
    if (tempMarker) {
      tempMarker.setLngLat([Number(longitude), Number(latitude)])
    }
  }, [latitude, longitude, tempMarker])

  const updateAssetMarkerData = (
    assetId: string,
    coords: { lat: number; lng: number; color?: string }
  ) => {
    const patchBody = {
      id: assetId || '',
      type: RolodexEntryType.CUSTOMER_UNIT,
      data: { latitude: coords.lat, longitude: coords.lng, marker_color: coords.color },
    }

    RolodexPatchBatch([patchBody], authInfo, siteData).then(() => {
      refetchData?.()
    })
  }

  const deleteLocationFromAsset = (assetId: string) => {
    const patchBody = {
      id: assetId || '',
      type: RolodexEntryType.CUSTOMER_UNIT,
      delete_data: ['latitude', 'longitude'],
    }

    RolodexPatchBatch([patchBody], authInfo, siteData).then(() => {
      refetchData?.()
    })
  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete the location data for this asset?',
      onOk() {
        if (initialAssetId) {
          deleteLocationFromAsset(initialAssetId)
          setAssetModalVisible(false)
          setAddingAsset?.(false) // Reset adding asset status if it was set
        }
      },
    })
  }

  const handleOk = (assetId: string) => {
    setAssetModalVisible(false)
    setAddingAsset?.(false)

    const marker = tempMarker || editMarker
    if (marker) {
      const lngLat = marker.getLngLat()
      updateAssetMarkerData(assetId, { lat: lngLat.lat, lng: lngLat.lng, color })
    }
  }

  const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    if (val < -90) {
      setLatitude(-90) // Sets to minimum latitude if below -90
    } else if (val > 90) {
      setLatitude(90) // Sets to maximum latitude if above 90
    } else {
      setLatitude(val)
    }
  }

  const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    if (val < -180) {
      setLongitude(-180) // Sets to minimum longitude if below -180
    } else if (val > 180) {
      setLongitude(180) // Sets to maximum longitude if above 180
    } else {
      setLongitude(val)
    }
  }

  const filterOptionFunction = (
    input: string,
    option?: { label?: string; value?: string; children?: React.ReactNode }
  ) => {
    if (typeof option?.children === 'string') {
      return option.children.toLowerCase().includes(input.toLowerCase())
    }
    return false
  }
  const modalTitle =
    initialAssetId && initialEquipDescription ? 'Edit Location' : 'Add Asset Location'

  return (
    <Modal
      title={modalTitle}
      width={modalWidth}
      style={style}
      open={assetModalVisible}
      onOk={() => handleOk(selectedAssetId)} // Adjust accordingly to manage state
      onCancel={() => {
        setAssetModalVisible(false)
        setAddingAsset?.(false)
      }}
      footer={[
        <Button
          key='back'
          onClick={() => {
            setAssetModalVisible(false)
            setAddingAsset?.(false)
          }}>
          Cancel
        </Button>,
        initialAssetId && initialEquipDescription && !disableDeleteAssetLocation && (
          <Button key='delete' onClick={handleDelete}>
            Delete Location
          </Button>
        ),
        <Button key='submit' type='primary' onClick={() => handleOk(selectedAssetId)}>
          OK
        </Button>,
      ]}>
      {mapView ? (
        <Row>
          <RadarModule
            center={{
              latitude: Number(latitude),
              longitude: Number(longitude),
              zoom: 15,
            }}
            markers={[]}
            addingAsset={true}
            tempMarker={editMarker}
            setTempMarker={(marker) => {
              setEditMarker(marker)
              if (marker) {
                if (marker instanceof mapboxgl.Marker) {
                  marker.on('dragend', () => {
                    const lngLat = marker.getLngLat()
                    onDragEnd(lngLat)
                  })
                }
              }
            }}
            style={{ width: '100%', height: '400px' }}
            initializeTempMarker={true}
          />
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Column>
          <Input
            prefix='Latitude:'
            value={latitude}
            step={0.00001}
            onChange={handleLatitudeChange}
            type='number'
          />
        </Column>
        <Column>
          <Input
            prefix='Longitude:'
            value={longitude}
            step={0.00001}
            onChange={handleLongitudeChange}
            type='number'
          />
        </Column>
        <ColorPicker
          value={color}
          onChange={(value) => {
            setColor(`#${value.toHex()}`)
          }}
        />
      </Row>
      {initialAssetId && initialEquipDescription ? (
        <p>Editing location for: {initialEquipDescription}</p>
      ) : (
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder='Select an Asset'
          onChange={setSelectedAssetId}
          value={selectedAssetId}
          filterOption={filterOptionFunction}>
          {availableAssets?.map((asset) => (
            <Select.Option key={asset.entry.id} value={asset.entry.id}>
              {asset.entry.data.equip_description}
            </Select.Option>
          ))}
        </Select>
      )}
    </Modal>
  )
}
