import React, { useContext, useEffect } from 'react'
import {
  Button,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  InputNumber,
  Switch,
} from 'antd'
import styled from 'styled-components'
import {
  AssetDBColumnType,
  AssetsDatabaseTableRow,
  ColumnConfig,
} from './AssetDatabaseTabInterface'
import { asset_manager_theme } from '../../assets/themes'
import isEqual from 'lodash/isEqual'
import {
  FeatureFlagContext,
  FeatureFlags,
} from '../../contextProviders/featureFlagContextProvider'
import { TabKey } from '../AppLayout'
import typia from 'typia'
import { convertCamelCaseToTitleCase } from '../../util/helpers/stringHelpers'

/**
 * For some reason the OpenApi.IJsonSchema type from typia doesn't have the actual properties the JSON schema includes,
 * so we define the type here
 */
interface JSONSchema {
  type?: string
  $ref?: string
  format?: string
  items?: JSONSchema
  required?: string[]
  properties?: {
    [key: string]: JSONSchema
  }
}

interface AssetFormProps {
  form: FormInstance<AssetsDatabaseTableRow>
  selectedRow: AssetsDatabaseTableRow | null
  columnConfig: ColumnConfig[]
  handleSave: (values: AssetsDatabaseTableRow) => void
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledInput = styled(Input)`
  border: none;
  background-color: ${asset_manager_theme.background.background_color6_5};
`

const StyledInputNumber = styled(InputNumber)`
  border: none;
  background-color: ${asset_manager_theme.background.background_color6_5};
`

const StyledTextArea = styled(Input.TextArea)`
  border: none;
  background-color: ${asset_manager_theme.background.background_color6_5};
`

function getFormFieldFromConfig({
  columnConfig,
  userFeatureFlags,
  hiddenFormData,
  selectedRow,
}: {
  columnConfig: ColumnConfig
  userFeatureFlags: FeatureFlags
  hiddenFormData: string[]
  selectedRow: AssetsDatabaseTableRow | null
}) {
  const getInputComponentForType = (type: string) => {
    switch (type) {
      case AssetDBColumnType.NUMBER:
        return <StyledInputNumber />
      case AssetDBColumnType.TEXT:
        return <StyledTextArea />
      case AssetDBColumnType.STRING:
        return <StyledInput />
      case AssetDBColumnType.BOOLEAN:
        return <Switch />
      default:
        return <StyledInput />
    }
  }

  const rules = columnConfig.isRequired
    ? [{ required: true, message: `Input a ${columnConfig.label}` }]
    : []

  let inputComponent: React.JSX.Element | undefined
  const formProps: Partial<FormItemProps> =
    columnConfig.columnType === AssetDBColumnType.BOOLEAN
      ? { valuePropName: 'checked', initialValue: false }
      : {}

  if (columnConfig.columnType === AssetDBColumnType.OBJECT) {
    const assetsDatabaseTableRowSchemas =
      typia.json.application<[AssetsDatabaseTableRow]>()
    const assetsDatabaseTableRowSchema =
      assetsDatabaseTableRowSchemas.components.schemas?.['AssetsDatabaseTableRow']

    if (
      !!assetsDatabaseTableRowSchema &&
      typia.is<JSONSchema>(assetsDatabaseTableRowSchema)
    ) {
      const columnSchema = assetsDatabaseTableRowSchema.properties?.[columnConfig.key]
      const columnProperties = columnSchema?.properties

      // For now, this only works on arrays of flat objects
      // TODO: Expand this
      if (
        columnSchema &&
        columnSchema.type === 'array' &&
        !!columnSchema.items?.properties &&
        columnSchema.items?.type === 'object'
      ) {
        const itemProperties = columnSchema.items.properties
        const requiredKeys = new Set(columnSchema.items.required || [])
        return (
          <Form.Item key={columnConfig.key} label={columnConfig.label}>
            <Form.List key={`${columnConfig.key}-list`} name={columnConfig.key}>
              {(fields, { add }) => (
                <>
                  {fields.map((field, idx) => (
                    <>
                      {Object.keys(itemProperties).map((itemKey) => {
                        const formKey = `${columnConfig.key}.${itemKey}.${idx}`
                        const label = convertCamelCaseToTitleCase(itemKey)
                        return (
                          <Form.Item
                            key={formKey}
                            label={label}
                            name={[field.name, itemKey]}
                            required={requiredKeys.has(itemKey)}>
                            {getInputComponentForType(
                              itemProperties[itemKey]?.type || AssetDBColumnType.STRING
                            )}
                          </Form.Item>
                        )
                      })}
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() => {
                        add()
                      }}>
                      + {`New ${columnConfig.label} Item`}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )
      }

      // For now, this assumes that the objects have one level of nesting
      if (columnSchema && columnSchema.type === 'object' && columnProperties) {
        const objectKeys = Object.keys(columnSchema.properties || {})
        const requiredKeys = new Set(columnSchema.required || [])
        inputComponent = (
          <>
            {objectKeys.map((key) => {
              const formKey = `${columnConfig.key}.${key}`
              const label = convertCamelCaseToTitleCase(key)

              return (
                <Form.Item
                  key={formKey}
                  label={label}
                  name={[columnConfig.key, key]}
                  required={requiredKeys.has(key)}>
                  {getInputComponentForType(
                    columnProperties[key]?.type || AssetDBColumnType.STRING
                  )}
                </Form.Item>
              )
            })}
          </>
        )
      }
    }
  } else if (!!columnConfig.columnType) {
    inputComponent = getInputComponentForType(columnConfig.columnType)
  }

  const label =
    columnConfig.key === 'erp_id'
      ? `${selectedRow?.erp_name?.toUpperCase() ?? 'ERP'} ID`
      : columnConfig.label

  const nameProp =
    columnConfig.columnType === AssetDBColumnType.OBJECT ? {} : { name: columnConfig.key }

  return (
    (userFeatureFlags?.superUser || !hiddenFormData.includes(columnConfig.key)) &&
    !!inputComponent && (
      <Form.Item
        key={columnConfig.key}
        label={label}
        {...nameProp}
        rules={rules}
        {...formProps}>
        {inputComponent}
      </Form.Item>
    )
  )
}

const AssetForm: React.FC<AssetFormProps> = ({
  form,
  selectedRow,
  columnConfig,
  handleSave,
  setIsModalVisible,
}) => {
  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue(selectedRow || {})
    }
  }, [form, selectedRow])

  const userFeatureFlags = useContext(FeatureFlagContext)
  const hiddenFormData = userFeatureFlags?.[TabKey.assetsDB]?.hidden_form_data || []

  const saveChanges = (values: AssetsDatabaseTableRow) => {
    if (!selectedRow) {
      handleSave(values)
    } else {
      const modifiedRow = { ...selectedRow, ...values }
      const unchangedFields: string[] = []
      for (const key in modifiedRow) {
        if (
          modifiedRow[key as keyof AssetsDatabaseTableRow] === undefined &&
          !(key in selectedRow)
        ) {
          unchangedFields.push(key)
          delete modifiedRow[key as keyof AssetsDatabaseTableRow]
        } else {
          if (
            isEqual(
              selectedRow[key as keyof AssetsDatabaseTableRow],
              modifiedRow[key as keyof AssetsDatabaseTableRow]
            ) &&
            key != 'id'
          ) {
            unchangedFields.push(key)
          }
        }
      }

      const modifiedFieldsOnly = { ...modifiedRow }

      unchangedFields.forEach((field) => {
        delete modifiedFieldsOnly[field as keyof AssetsDatabaseTableRow]
      })

      handleSave(modifiedFieldsOnly)
    }
    setIsModalVisible(false)
    form.resetFields()
  }

  return (
    <Form form={form} preserve={false} onFinish={saveChanges} style={{ width: '100%' }}>
      {columnConfig.map((config) =>
        getFormFieldFromConfig({
          columnConfig: config,
          userFeatureFlags,
          hiddenFormData,
          selectedRow,
        })
      )}
    </Form>
  )
}

export default AssetForm
