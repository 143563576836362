import React, { FC, useState, useEffect, useContext } from 'react'
import { Modal } from 'antd'
import Select, { SingleValue } from 'react-select'
import { toTitleCase } from '../../../helpers/stringFilters'
import { colorStyles } from '../../SelectColorStyles'
import { trackEvent } from '../../../helpers/mixpanel'
import { editFileTags } from '../../../../util/RolodexInterface'
import { useAuthInfo } from '../../../../contextProviders/useAuthInfo'
import { removePrefix } from '../Helpers/FilePathFormatterHelpers'
import { SUPPORTED_IMAGE_TYPES } from '../FileBrowser'
import { SiteContext } from '../../../../contextProviders/siteContextProvider'

const formatTagLabel = (tag?: string) => {
  // Format the tag label without underscores and with capitalized first letters
  return toTitleCase(tag?.replace(/_/g, ' ') || '')
}
interface TagModalProps {
  visible: boolean
  filePath: string
  componentSlug: string
  tags: string[]
  onCancel: () => void
  onEditSuccess?: () => void
  isTagCheck?: boolean
  checkedFiles: string[]
  setCheckedFiles: React.Dispatch<React.SetStateAction<string[]>>
  hasAssetImageTag?: boolean
  hasAssetLocationImageTag?: boolean
}

const TagModal: FC<TagModalProps> = ({
  visible,
  filePath,
  componentSlug,
  tags,
  onCancel,
  onEditSuccess,
  isTagCheck,
  checkedFiles,
  setCheckedFiles,
  hasAssetImageTag,
  hasAssetLocationImageTag,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>(tags)
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)

  useEffect(() => {
    setSelectedTags(tags)
  }, [tags])

  // Function to check if file is of a supported image type
  const isSupportedImageType = (filePath: string): boolean => {
    const extension = filePath.split('.').pop()?.toLowerCase()
    return SUPPORTED_IMAGE_TYPES.has(extension || '')
  }

  const getPredefinedOptions = () => [
    {
      label: 'Latest Inspection Plan',
      value: 'latest_inspection_plan',
    },
    {
      label: 'Latest Engineering Drawing',
      value: 'latest_engineering_drawing',
    },
    {
      label: 'Latest Inspection Report',
      value: 'latest_inspection_report',
    },
    ...(!hasAssetImageTag && isSupportedImageType(filePath)
      ? [
          {
            label: 'Asset Image',
            value: 'asset_image',
          },
        ]
      : []),
    ...(!hasAssetLocationImageTag && isSupportedImageType(filePath)
      ? [
          {
            label: 'Location Image',
            value: 'location_image',
          },
        ]
      : []),
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const handleChange = (newValue: SingleValue<{ label?: string; value?: string }>) => {
    if (newValue === null) {
      // trackEvent('Button Clicked', {
      //   buttonName: 'clear_tags',
      // })
    }
    setSelectedTags(newValue?.value ? [newValue.value] : [])
  }

  return (
    <Modal
      title='Select Tags'
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        trackEvent('Button Clicked', {
          buttonName: 'set_tags',
          assetDetails: {
            tags: selectedTags,
          },
        })
        editFileTags(authInfo, componentSlug, filePath, selectedTags, siteData).then(
          () => {
            onEditSuccess?.()
            onCancel()
            // Automatically check the file after tagging is successful
            if (isTagCheck && !checkedFiles.includes(removePrefix([filePath])[0])) {
              // checks if there are any associated tags before checking the file
              if (selectedTags && selectedTags.length > 0) {
                setCheckedFiles([...checkedFiles, removePrefix([filePath])[0]])
              }
            }
            if (
              selectedTags.length === 0 &&
              checkedFiles.includes(removePrefix([filePath])[0])
            ) {
              setCheckedFiles(
                checkedFiles.filter((file) => file !== removePrefix([filePath])[0])
              )
            }
          }
        )
      }}>
      <Select
        styles={colorStyles}
        options={getPredefinedOptions()}
        placeholder='Select tags'
        value={{
          label: formatTagLabel(selectedTags[0]),
          value: selectedTags[0],
        }}
        onChange={handleChange}
        isClearable={true}
        //ts-ignore
      />
    </Modal>
  )
}

export default TagModal
