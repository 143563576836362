import { PriorityPill } from '../../../components/Common/PriorityPill'
import {
  LifeExtensionOptionsTableData,
  LifeExtensionOptions,
} from '../RecommendationsInterface'
import { PlanRecPriorityDropDown } from '../../PlanModelingTab/Components/PlanRecPriorityDropdown'
import { EditableTextAreaElement } from '../../../components/Common/EditableTextAreaElement'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import GroupSelect from '../../../components/Common/PlanRecGroupSelect'
import { SWRResponse } from 'swr'
import { LifeExtensionMenu } from './LifeExtensionMenu'
import { PlanRecDeadlinePicker } from '../../PlanModelingTab/Components/PlanRecDeadlinePicker'
import { Space } from 'antd'
import { currencyConverter } from '../../../components/helpers/stringFilters'
import { EditableNumberElement } from '../../../components/Common/EditableNumberElement'
import ImageTooltip from './ImageTooltip'
import { EditableCreatableElement } from '../../../components/Common/EditableCreatableElement'

export const LifeExtensionPlanRecColumns = (
  siteDataSwr: SWRResponse<any, any, any>,
  refetchCallBack: () => void,
  disabled = false,
  getSelectOptions: (
    field: keyof LifeExtensionOptions
  ) => { label: string; value: string }[]
) => [
  {
    title: 'Action',
    dataIndex: 'recommendation',
    key: 'action',
    width: '30%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <ImageTooltip recommendationId={record.rec_slug}>
          {record.editable ? (
            <EditableTextAreaElement
              value={text}
              disabled={disabled}
              key={`${record.id}_recommendation`}
              objectId={record.id}
              field={'recommendation'}
              refetchData={() => {
                refetchCallBack?.()
              }}
              rolodexType={RolodexEntryType.PLAN_REC}
            />
          ) : (
            <span>{text}</span>
          )}
        </ImageTooltip>
      )
    },
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: '5%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <PlanRecPriorityDropDown
          key={`${record.id}_priority`}
          planRecId={record.id}
          disabled={disabled}
          updateKey='priority'
          fieldValue={text}
          rolodexType={RolodexEntryType.PLAN_REC}
          refetchData={() => {
            refetchCallBack?.()
          }}
        />
      )
    },
  },
  {
    title: 'Scheduled Date',
    dataIndex: 'scheduled_date',
    key: 'scheduled_date',
    width: '10%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <PlanRecDeadlinePicker
          planRecId={record.id}
          key={`${record.id}_scheduled_date`}
          deadline={text}
          disabled={disabled}
          updateKey={'scheduled_date'}
          dateFormat={'YYYY-MM'}
          picker={'month'}
          refetchData={() => {
            refetchCallBack?.()
          }}
        />
      )
    },
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: '5%',
    render: (text: any) => <span>{text}</span>,
  },
  {
    title: 'Group',
    dataIndex: 'cluster',
    key: 'cluster',
    width: '12%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <GroupSelect
          key={`${record.id}_group`}
          recGroups={record.cluster || []}
          planRecommendationSlug={record.id}
          disabled={disabled}
          fdePlanningConfigSwr={siteDataSwr}
          refetchData={() => {
            refetchCallBack?.()
          }}
          save={true}
        />
      )
    },
  },
  {
    title: 'Work Order No,',
    dataIndex: 'work_order_number',
    key: 'work_order_number',
    width: '15%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <EditableCreatableElement
          value={text}
          objectId={record.id}
          field={'work_order_number'}
          refetchData={() => {
            refetchCallBack?.()
          }}
          rolodexType={RolodexEntryType.PLAN_REC}
          key={`${record.id}_workOrderNumber`}
          options={getSelectOptions('work_order_number')}
          disabled={disabled}
        />
      )
    },
  },
  {
    title: 'Est. Cost',
    dataIndex: 'repair_cost',
    key: 'repair_cost',
    width: '10%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <EditableNumberElement
          numberValue={text}
          objectId={record.id}
          rolodexType={RolodexEntryType.PLAN_REC}
          disabled={disabled}
          refetchData={() => {
            refetchCallBack?.()
          }}
          format='currency'
          field={'repair_cost'}
        />
      )
    },
  },
  {
    key: 'menu',
    width: '5%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      if (disabled) {
        return <Space>...</Space>
      }
      return (
        <LifeExtensionMenu
          lifeExtension={record}
          refetchCallBack={refetchCallBack}
          allActions={false}
        />
      )
    },
  },
]

export const LifeExtensionRecColumns = (refetchCallBack?: () => void) => [
  {
    title: 'Action',
    dataIndex: 'recommendation',
    key: 'action',
    width: '30%',
    render: (text: any, Record: LifeExtensionOptionsTableData) => (
      <ImageTooltip recommendationId={Record.id}>
        {Record.editable ? (
          <EditableTextAreaElement
            value={text}
            disabled={false}
            key={`${Record.id}_recommendation`}
            objectId={Record.id}
            field={'recommendation'}
            refetchData={() => {
              refetchCallBack?.()
            }}
            rolodexType={RolodexEntryType.RECOMMENDATION}
          />
        ) : (
          <span>{text}</span>
        )}
      </ImageTooltip>
    ),
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: '5%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return record.editable ? (
        <PlanRecPriorityDropDown
          key={`${record.id}_priority`}
          planRecId={record.id}
          updateKey='priority'
          fieldValue={text}
          rolodexType={RolodexEntryType.RECOMMENDATION}
          refetchData={() => {
            refetchCallBack?.()
          }}
        />
      ) : (
        <PriorityPill text={text}>{text}</PriorityPill>
      )
    },
  },
  {
    title: 'Scheduled Date',
    dataIndex: 'scheduled_date',
    key: 'scheduled_date',
    width: '10%',
    render: (text: any) => {
      return text || '--'
    },
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: '5%',
    render: (text: any) => <span>{text}</span>,
  },
  {
    title: 'Group',
    dataIndex: 'cluster',
    key: 'cluster',
    width: '12%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      if (Array.isArray(text)) {
        return <span>{text?.join(', ') ?? '--'}</span>
      } else {
        return <span>{text ?? '--'}</span>
      }
    },
  },
  {
    title: 'Work Order No,',
    dataIndex: 'work_order_number',
    key: 'work_order_number',
    width: '15%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return <span>{text ?? '--'}</span>
    },
  },
  {
    title: 'Est. Cost',
    dataIndex: 'repair_cost',
    key: 'repair_cost',
    width: '10%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return text ? currencyConverter(text) : '--'
    },
  },
  {
    key: 'menu',
    width: '5%',
    render: (text: any, record: LifeExtensionOptionsTableData) => {
      return (
        <LifeExtensionMenu
          lifeExtension={record}
          refetchCallBack={() => {
            refetchCallBack?.()
          }}
          allActions={true}
        />
      )
    },
  },
]
