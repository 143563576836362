export const BUDGET_OPTIONS_CONSTANTS = [
  {
    value: 'CAP',
    label: 'CAP',
  },
  {
    value: 'Maintenance',
    label: 'Maintenance',
  },
  {
    value: 'Inspection',
    label: 'Inspection',
  },
  {
    value: 'NA',
    label: 'N/A',
  },
]
