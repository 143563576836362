import React, { useMemo, useContext, useState, useEffect } from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'

interface PlanRecOptionsInputProps {
  optionValue: string
  planRecId: string
  field: string
  refetchData?: () => void
  rolodexType: string
  disabled?: boolean
  options: { label: string; value: string }[]
}

export const PlanRecOptionsInput: React.FC<PlanRecOptionsInputProps> = ({
  optionValue,
  planRecId,
  field,
  refetchData,
  rolodexType,
  disabled,
  options,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<string>(optionValue)

  useEffect(() => {
    setEditedValue(optionValue)
  }, [optionValue])

  useEffect(() => {
    if (editedValue !== optionValue && siteData) {
      //only worth patching when they're different
      const patchBody = {
        id: planRecId,
        type: rolodexType,
        data: {
          [field]: editedValue,
        },
        tags: {
          site: siteData.siteSlug,
          organization: siteData.orgSlug,
        },
      }
      RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
        refetchData?.()
      })
    }
  }, [editedValue])

  return (
    <Select
      disabled={disabled}
      style={{ width: '5rem' }}
      options={options}
      value={editedValue}
      onChange={(value) => {
        setEditedValue(value)
      }}
    />
  )
}
