import { useContext } from 'react'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { useRolodexEntries } from '../../../api/useRolodex'
import { ForecastUrl } from '../../../api/defines'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import Button from '../../Common/Button'
import { Column, Row } from '../Grid'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'

export const useGenerateForecastButton = (fdeCustomerUnitSlug?: string) => {
  const featureFlags = useContext(FeatureFlagContext)
  const siteData = useContext(SiteContext)
  const fde_customer_unit_swr = useRolodexEntries(
    {
      type: RolodexEntryType.CUSTOMER_UNIT,
      ids: [fdeCustomerUnitSlug],
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    },
    undefined,
    !!fdeCustomerUnitSlug
  )
  if (
    (!featureFlags?.superUser && !featureFlags?.linkToAssetFix) ||
    !fde_customer_unit_swr?.data?.[0]?.entry?.tags?.component
  ) {
    return
  }

  return (
    <Column>
      <form
        action={`${ForecastUrl()}/details/${
          fde_customer_unit_swr?.data?.[0]?.entry?.tags?.component
        }`}
        target='_blank'
        rel='noopener noreferrer'>
        <Button style={{ marginLeft: '0.6rem' }} type='submit'>
          Forecast {`\u2197`}
        </Button>
      </form>
    </Column>
  )
}
