import { StylesConfig } from 'react-select'
import { asset_manager_theme } from '../../assets/themes'

export const colorStyles: StylesConfig<{ label: string; value: string }, false> = {
  control: (styles, { isFocused }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      borderColor: isFocused
        ? asset_manager_theme.text.c1
        : asset_manager_theme.background.background_color6_5,
      backgroundColor: asset_manager_theme.background.background_color6_5,
      minHeight: '2.5rem',
      height: '2.5rem',
      boxShadow: isFocused
        ? asset_manager_theme.text.c1
        : asset_manager_theme.background.background_color6_5,
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: asset_manager_theme.text.c1,
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: asset_manager_theme.text.c1,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    height: '2.5rem',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: asset_manager_theme.background.background_color6,
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? asset_manager_theme.background.background_color6
        : asset_manager_theme.background.background_color6_5,
      color: isSelected
        ? asset_manager_theme.background.high_vis
        : asset_manager_theme.text.c1,
    }
  },

  placeholder: (styles) => {
    return {
      ...styles,
      color: asset_manager_theme.text.c1,
    }
  },
}
