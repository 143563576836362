import typia from 'typia'
import { TabKey } from '../AppLayout'

export interface FDECustomerUnit {
  entry: {
    id: string
    type: string
    data: FDECustomerUnitData
    tags?: {
      site?: string
      organization?: string
      component?: string
    }
  }
}

export enum inspectionStatus {
  REQUIRED = 1,
  NA = 2,
  ONSTREAM = 3,
}

export interface FDECustomerUnitData extends FDECustomerUnitDataOverride {
  overrides?: FDECustomerUnitDataOverride
  file_tags?: FileTags
  ruggable?: string
  onstream_exception?: boolean
  inspection_schedule?: InspectionSchedule
  is_rug_available?: boolean
}

export interface FileTags {
  [key: string]: string[]
}

export enum FilterCriteria {
  SEARCH = 'SEARCH',
  CATEGORY = 'CATEGORY',
  RANGE = 'RANGE',
}

export enum AssetDBColumnType {
  STRING = 'string',
  TEXT = 'text', // Longer form text
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
}

export type AssetDBTableColumnKeys =
  | keyof AssetsDatabaseTableRow
  | 'edit'
  | 'files'
  //i don't like this either but wtv for now
  | 'dimensions.height_ft'
  | 'dimensions.diameter_ft'

export interface ColumnConfig {
  key: AssetDBTableColumnKeys
  label?: string
  columnType?: AssetDBColumnType
  sortOrder: number
  filterCriteria?: FilterCriteria
  fixed?: boolean
  width?: number
  defaultFilterValue?: string[]
  isRequired?: boolean
}

/**
 * Data we store at the component level for use in Asset Manager
 * NOTE: Right now we only support primitive types, flat objects (no nested) or arrays of flat objects
 */
export interface FDECustomerUnitDataOverride {
  ferrous?: boolean
  sub_material?: string
  pH?: number
  psm?: string
  temp?: string
  type?: string
  erp_id?: number
  erp_name?: string
  classification?: string
  comments?: string
  contents?: string
  equip_id?: string
  material?: string
  pressure?: number
  insulated?: string
  stiffness?: number
  stiffeners?: string
  dimensions?: {
    height_ft?: number
    diameter_ft?: number
  }
  equip_area?: string
  year_built?: number
  maintenance?: string
  orientation?: string
  outage_type?: string
  risk_category?: string
  decommissioned?: boolean
  national_board?: number
  pending_repairs?: string
  area_description?: string
  equip_description?: string
  inspection_written?: string
  last_risk_category?: string
  next_risk_category?: string
  customer_corrosion_rate?: string
  customer_remaining_life?: number
  external_inspection_interval?: number
  internal_inspection_interval?: number
  latitude?: number
  longitude?: number
  marker_color?: string
  asset_condition?: number
  asset_priority?: number
  location_code?: string
  drawing_number?: string

  service?: string
  volumeCapacity?: number
  topDiagonalLength?: number
  fillHeightLimit?: number
  manfactureOrigin?: string
  yearsInService?: number // This is different from the age of the asset, doesn't include years the asset was offline

  // These properties are a stopgap for properties that should ultimately be sourced from our inspection data, but can't be easily sourced for assets we haven't scanned
  roofPlate?: {
    plateType: string
    nominal: number
  }
  floorPlate?: {
    floorType: string
    nominal: number
  }
  shellCourseData?: {
    course: string
    material?: string
    tMin?: number
    corrosionRate?: number
    minThickness?: number
    nominal?: number
  }[]
  minimumRoofThickness?: number
  minimumFloorThickness?: number
  weldType?: string
  jointEfficiency?: number
  specificGravity?: number
  last_external_inspection_work_order?: string
  last_internal_inspection_work_order?: string
}

export interface InspectionFields {
  last?: string | null
  next?: string | null
  interval_months?: number | null
  estimated_hours?: number | null
  status: inspectionStatus
}

export interface InspectionSchedule {
  internal?: {
    [key: string]: InspectionFields
  }
  external?: {
    [key: string]: InspectionFields
  }
}

export interface AssetsDatabaseTableRow extends FDECustomerUnitData {
  id: string
  isEditable?: boolean
  assetConditionPriorityJoined?: string
}

export type AssetsDatabaseRowIndex = keyof AssetsDatabaseTableRow

// TODO: Fill out with other options
export interface FDESiteData {
  [TabKey.assetsDB]?: {
    column_config: ColumnConfig[]
  }
  trial_expiry_date?: string
  weekly_target_load?: string
  [TabKey.radar]?: {
    zoom: number
    latitude: number
    longitude: number
  }
}

export const isFDESiteData = typia.createIs<FDESiteData>()
