import { FC, useMemo } from 'react'
import Plot from 'react-plotly.js'
import { useResizeDetector } from 'react-resize-detector'
import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs'
import { Spin } from 'antd'
import { SWRResponse } from 'swr'
import { OutageObject } from '../../../util/types/'
import { asset_manager_theme } from '../../../assets/themes'
import {
  NUM_WEEK_PER_MONTH,
  NUM_WEEK_PER_YEAR,
  OUTAGE_SCALING,
  DATE_GRANULARITY_MAP,
} from '../../../util/constants'
import { toTitleCase } from '../../../components/helpers/stringFilters'
import { getGroupedMaxValue, getStackedMaxValue } from './helpers'
import { Column } from '../../../components/Common/Grid'

interface ScheduledAndInspectionHistoryBarChartProps {
  startDate: Dayjs | null
  endDate: Dayjs | null
  setTableDateFilter: (selected: Dayjs[] | undefined) => void
  setTableInspectionTypeFilter: (selected?: string) => void
  inspectionDataSWR: SWRResponse<any, any, any>
  graphDateGranularity: 'month' | 'year' | 'week'
  outageSchedule?: { [key: string]: OutageObject[] }
  graphView: 'counts' | 'hours'
  barMode: 'stack' | 'group'
  targetLoadHours: number | undefined
  breakInHours: number | undefined
}
export const ScheduledAndInspectionHistoryBarChart: FC<
  ScheduledAndInspectionHistoryBarChartProps
> = (props) => {
  const { ref, width, height } = useResizeDetector()

  const groupedCountMax = useMemo(() => {
    return (
      getGroupedMaxValue(props.inspectionDataSWR.data?.[props.graphView]) * OUTAGE_SCALING
    )
  }, [props.inspectionDataSWR.data, props.graphView])

  const stackedCountMax = useMemo(() => {
    return (
      getStackedMaxValue(props.inspectionDataSWR.data?.[props.graphView]) * OUTAGE_SCALING
    )
  }, [props.inspectionDataSWR.data, props.graphView])

  const outageMax = props.barMode === 'stack' ? stackedCountMax : groupedCountMax

  //this is beyond stupid, adding a point at the low and high to get the vertical line and the null in between to break the line
  const processedOutageSchedule = useMemo(() => {
    return props.outageSchedule
      ? {
          x: Object.keys(props.outageSchedule || {}).reduce((acc: any[], cur) => {
            return [...acc, cur, cur, null]
          }, []),
          y: Object.keys(props.outageSchedule || {}).reduce((acc: any[]) => {
            return [...acc, 0, outageMax, null]
          }, []),
          hovertext: Object.keys(props.outageSchedule)
            .map((key) => {
              return props.outageSchedule?.[key]
                .map((d) => `${d.date.format('YYYY-MM-DD')} ${d.type} ${d.system}`)
                .join('<br>')
            })
            .reduce((acc: any[], cur) => {
              return [...acc, cur, cur, null]
            }, []),
        }
      : {}
  }, [props.outageSchedule, outageMax])

  const barTrace = useMemo(() => {
    const breakInXValues: string[] = []

    const dataTrace = Object.keys(
      props.inspectionDataSWR.data?.[props.graphView] ?? {}
    ).map((key) => {
      // key is external or internal
      const color =
        key === 'internal'
          ? asset_manager_theme.colors.blue_light
          : asset_manager_theme.colors.blue

      const partialTrace: Record<string, number> = {}

      if (props.startDate && props.endDate) {
        let closestStartDate = props.startDate
        if (props.graphDateGranularity === 'week') {
          closestStartDate = props.startDate.day(0)
        }
        for (
          let i = closestStartDate;
          i <= props.endDate;
          i = i.add(1, props.graphDateGranularity as ManipulateType)
        ) {
          const formattedDate = i.format(
            DATE_GRANULARITY_MAP[props.graphDateGranularity].dayjsFormat
          )
          breakInXValues.push(formattedDate)
          partialTrace[formattedDate] =
            props.inspectionDataSWR.data?.[props.graphView]?.[key]?.[formattedDate] || 0
        }
      }

      return {
        x: Object.keys(partialTrace).sort((a, b) => {
          return dayjs(a).unix() - dayjs(b).unix()
        }),
        y: Object.keys(partialTrace ?? {})
          .sort((a, b) => {
            return dayjs(a).unix() - dayjs(b).unix()
          })
          .map((d) => partialTrace[d]),
        type: 'bar' as const,
        name: toTitleCase(key),
        marker: {
          color: color,
        },
      }
    })

    let breakInHours = props.breakInHours ?? 0
    if (props.graphDateGranularity === 'week') {
      breakInHours *= 7
    } else if (props.graphDateGranularity === 'month') {
      breakInHours *= 7 * NUM_WEEK_PER_MONTH
    } else {
      breakInHours *= 7 * NUM_WEEK_PER_YEAR
    }

    let breakInYValues = new Array(breakInXValues.length).fill(0)
    if (props.graphView === 'hours') {
      breakInYValues = new Array(breakInXValues.length).fill(breakInHours)
    }
    const breakInTrace = [
      {
        x: breakInXValues,
        y: breakInYValues,
        type: 'bar' as const,
        name: 'Break In',
        marker: {
          color: asset_manager_theme.colors.blue_darkest,
        },
      },
    ]
    return { dataTrace, breakInTrace }
  }, [
    props.graphDateGranularity,
    props.inspectionDataSWR.data,
    props.startDate,
    props.endDate,
    props.graphView,
    props.breakInHours,
  ])

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <div style={{ width, height, paddingTop: '.5rem' }}>
        <Spin
          spinning={
            props.inspectionDataSWR.isLoading || !props.startDate || !props.endDate
          }>
          {props.inspectionDataSWR?.data?.counts &&
          Object.keys(props.inspectionDataSWR?.data?.counts)?.length > 0 ? (
            <Plot
              data={[
                props.outageSchedule
                  ? {
                      x: processedOutageSchedule.x,
                      y: processedOutageSchedule.y,
                      type: 'scatter',
                      name: 'Outages',
                      hoverinfo: 'text',
                      line: {
                        dash: 'dot',
                        width: 2,
                      },
                      marker: {
                        size: 1,
                        color: asset_manager_theme.background.background_color5,
                      },

                      hovertext: processedOutageSchedule.hovertext,
                    }
                  : {},
                ...barTrace.dataTrace,
                ...barTrace.breakInTrace,
              ]}
              config={{ displayModeBar: false }}
              layout={{
                ...(props.graphView === 'hours'
                  ? {
                      shapes: [
                        {
                          type: 'line',
                          xref: 'paper',
                          x0: 0,
                          x1: 1,
                          y0: props.targetLoadHours ?? 0,
                          y1: props.targetLoadHours ?? 0,
                          line: {
                            color: 'rgba(255,255,255, 0.65)',
                            width: 2,
                            dash: 'dot',
                          },
                        },
                      ],
                    }
                  : {}),
                width,
                height: 350,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                showlegend: false,
                hovermode: 'closest',
                dragmode: 'select',
                selectdirection: 'h',
                font: {
                  color: 'rgba(255,255,255,1)',
                },
                xaxis: {
                  showgrid: false,
                  dtick: DATE_GRANULARITY_MAP[props.graphDateGranularity].plotlyDTick,
                  tickformat:
                    DATE_GRANULARITY_MAP[props.graphDateGranularity].plotlyTickformat,
                },
                yaxis: {
                  title:
                    props.graphView === 'counts'
                      ? 'Scheduled Inspections'
                      : 'Scheduled Hours',
                  showgrid: false,
                  rangemode: 'tozero',
                },
                uirevision: 'true',
                margin: {
                  t: 0,
                  b:
                    //this nonsense is for the margin
                    props.graphDateGranularity === 'week' &&
                    (props.endDate?.diff(props.startDate, 'months') ?? 0) >= 4
                      ? 80
                      : props.graphDateGranularity === 'month' &&
                        (props.endDate?.diff(props.startDate, 'months') ?? 0) >= 24
                      ? 60
                      : 20,
                  l: 50,
                },
                barmode: props.barMode,
              }}
              onSelected={(event) => {
                const selectionMargin = DATE_GRANULARITY_MAP[props.graphDateGranularity]
                if (event === undefined && props.startDate && props.endDate) {
                  props.setTableDateFilter([props.startDate, props.endDate])
                  props.setTableInspectionTypeFilter(undefined)
                } else {
                  const dateRange = event?.range?.x
                  if (dateRange?.length === 2) {
                    props.setTableInspectionTypeFilter(undefined)
                    props.setTableDateFilter([
                      props.startDate?.isAfter(
                        dayjs(String(dateRange[0]))
                          .add(
                            selectionMargin.selectionMargin,
                            selectionMargin.selectionGranularity
                          )
                          .startOf(props.graphDateGranularity as OpUnitType)
                      )
                        ? props.startDate
                        : dayjs(String(dateRange[0]))
                            .add(
                              selectionMargin.selectionMargin,
                              selectionMargin.selectionGranularity
                            )
                            .startOf(props.graphDateGranularity as OpUnitType),
                      props.endDate?.isBefore(
                        dayjs(String(dateRange[1])).endOf(
                          props.graphDateGranularity as OpUnitType
                        )
                      )
                        ? props.endDate
                        : dayjs(String(dateRange[1])).endOf(
                            props.graphDateGranularity as OpUnitType
                          ),
                    ])
                  }
                }
              }}
              onClick={(event) => {
                if (event === undefined && props.startDate && props.endDate) {
                  props.setTableDateFilter([props.startDate, props.endDate])
                  props.setTableInspectionTypeFilter(undefined)
                } else if (event?.points?.length === 1) {
                  const date = event?.points[0]?.x
                  //on click the
                  props.setTableDateFilter([
                    dayjs(String(date)).startOf(props.graphDateGranularity as OpUnitType),
                    dayjs(String(date)).endOf(props.graphDateGranularity as OpUnitType),
                  ])
                  props.setTableInspectionTypeFilter(
                    event?.points[0]?.data?.name.toLowerCase()
                  ) //remove spaces
                }
              }}
            />
          ) : (
            <Column
              style={{
                justifyContent: 'center',
                minHeight: '375px',
                textAlign: 'center',
                backgroundColor: asset_manager_theme.background.background_color9,
                borderRadius: '4px',
              }}>
              <h2
                style={{
                  color: asset_manager_theme.background.background_color3,
                }}>
                No data in selected date range
              </h2>
              <h3
                style={{
                  marginTop: '.5rem',
                  color: asset_manager_theme.background.background_color5,
                }}>
                Confident there's data? Try adjusting the range in the upper right.
              </h3>
            </Column>
          )}
        </Spin>
      </div>
    </div>
  )
}
