import React, { ReactNode } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

const StyledHighVisButton = styled(Button)`
  background-color: ${asset_manager_theme.background.high_vis};
  border-color: ${asset_manager_theme.background.high_vis};
  color: ${asset_manager_theme.text.c2};
  border-radius: 4px;
  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.disabled
        ? asset_manager_theme.background.background_color8
        : 'rgba(238, 255, 0, 0.7)'};
    border-color: ${(props) =>
      props.disabled
        ? asset_manager_theme.background.background_color8
        : 'rgba(238, 255, 0, 0.7)'};
  }
  &:disabled {
    background-color: ${asset_manager_theme.background.background_color8};
    border: none;
    color: ${asset_manager_theme.background.background_color9};
  }
`

interface HighVisButtonProps {
  onClick?: () => void
  children?: ReactNode
  ghost?: boolean
  disabled?: boolean
}
const HighVisButton: React.FC<HighVisButtonProps> = ({
  onClick,
  children,
  ghost,
  disabled,
}) => {
  return (
    <StyledHighVisButton onClick={onClick} ghost={ghost} disabled={disabled}>
      {children}
    </StyledHighVisButton>
  )
}

export default HighVisButton
