import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'

import { KPICard } from './KPICard'

interface KPICardProps {
  selectedKPI?: string
  setSelectedKPI?: Dispatch<SetStateAction<string | undefined>>
  cards: {
    label: string
    value: string | number | undefined
    color?: string
    delta?: number
    key: string
    deltaPeriod?: string
    loading?: boolean
    isClickable?: boolean
    clickCallBack?: (enabling: boolean) => void
  }[]
}

export const CardContainer: FC<KPICardProps> = (props) => {
  return (
    <Container>
      {props.cards.map((d) => {
        return (
          <KPICard
            key={d.label}
            label={d.label}
            value={d.value}
            color={d.color}
            delta={d.delta}
            deltaPeriod={d.deltaPeriod}
            loading={d.loading}
            clickCallBack={d.clickCallBack}
            isClickable={d.isClickable}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
`
