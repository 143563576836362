import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react'
import { DueAndUnscheduledBarChart } from './Graphs/DueAndUnscheduledBarChart'
import { ScheduledAndInspectionHistoryBarChart } from './Graphs/ScheduledAndInspectionHistoryBarChart'
import {
  useGetInspectionsTable,
  generateInspectionSchedulePdf,
  unmarkCompleteInspection,
  useGetInspectionTagOptions,
} from '../../api/useFDEBackend'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { FilterConfirmProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words'
import { dateSort, defaultSort } from '../../api/tableHelpers'
import dayjs, { Dayjs } from 'dayjs'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import {
  OutOfPeriodCounts,
  ScheduledInspectionComponentData,
  isScheduledInspection,
} from './InspectionSchedulingInterface'
import {
  Table,
  Spin,
  DatePicker,
  Modal,
  Button,
  message,
  Tooltip,
  Typography,
  Input,
  Space,
  InputRef,
  TableColumnsType,
} from 'antd'
import InlineTile from '../../components/Common/AssetBaseballCard/InlineTile'
import {
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  ScheduledInspection,
  RawScheduledInspection,
  RawInspectionMetadata,
} from './InspectionSchedulingInterface'
import { isOutageObject, OutageObject } from '../../util/types/interfaces'
import { trackEvent } from '../../components/helpers/mixpanel'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import {
  useGetDueAndUnscheduledInspections,
  useGetScheduledInspections,
  rolodex_batch_delete,
} from '../../api/useFDEBackend'
import { asset_manager_theme } from '../../assets/themes'
import HighVisButton from '../../components/Common/HighVisButton'
import { rolodexUrl } from '../../api/helpers'
import { postFetcher } from '../../api/fetchers'
import { toTitleCase } from '../../components/helpers/stringFilters'
import { ReactComponent as CalendarBoldIcon } from '../../assets/icons/calendar-icon-bold.svg'
import { RolodexLoadedEntry, RolodexEntry, useRolodexEntries } from '../../api/useRolodex'
import { SWRResponse } from 'swr'
import { Column, Row } from '../../components/Common/Grid'
import StyledDatePicker from '../../components/Common/StyledDatePicker'
import { DownloadIcon } from '@radix-ui/react-icons'
import UploadButton from '../../components/Common/UploadButton'
import { scheduleInspection } from '../../components/helpers/InspectionSchedulingHelpers'
import UploadLatestInspection from '../../components/helpers/UploadLatestInspection'
import { WarningFilled } from '@ant-design/icons'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import FileBrowser from '../../components/Common/FileBrowser/FileBrowser'
import { DATE_GRANULARITY_MAP } from '../../util/constants'
import { EditableNumberElement } from '../../components/Common/EditableNumberElement'
import { TabKey } from '../AppLayout'
import { InspectionScheduleExportModal } from './InspectionScheduleExportModal'
import { EditableGroupSelect } from '../../components/Common/EditableGroupSelectElement'
import {
  FastForwardOutlined,
  FastBackwardOutlined,
  ForwardOutlined,
  BackwardOutlined,
} from '@ant-design/icons'
import { PlanRecPriorityDropDown } from '../PlanModelingTab/Components/PlanRecPriorityDropdown'
import { ResizableTitle } from '../../components/Common/ResizeableTitle'
import { Statistic } from 'antd'

const { Text } = Typography

interface GraphTableComponentProps {
  inspectionGroupingColumns?: string[]
  tabKey: string
  tabLabel: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  graphGranularity: 'month' | 'year' | 'week'
  outageScheduleSWR?: SWRResponse<RolodexLoadedEntry<RolodexEntry>[], any, any>
  setFdeCustomerUnitSlug: (slug: string) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
  graphView: 'counts' | 'hours'
  barMode: 'stack' | 'group'
  adjustedTargetLoad: number | undefined
  breakInHours: number | undefined
  resourceMapping?: Record<string, string>
  setStartGraphDate: (date: Dayjs) => void
  setEndGraphDate: (date: Dayjs) => void
  setGraphGranularity: (granularity: 'month' | 'year' | 'week') => void
}

export const GraphTableComponent: FC<GraphTableComponentProps> = (props) => {
  //this should be a date range with length 2
  //tableDateFilter[0] = start
  //tableDateFilter[1] = end
  const [tableDateFilter, setTableDateFilter] = useState<Dayjs[] | undefined>(
    props.startDate && props.endDate ? [props.startDate, props.endDate] : undefined
  )
  const [tableInspectionTypeFilter, setTableInspectionTypeFilter] = useState<
    string | undefined
  >(undefined)
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const inspectionSchedulingFeatureFlags = userFeatureFlags?.[TabKey.inspectionScheduling]
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRow, setSelectedRow] = useState<ScheduledInspection | undefined>()
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>()
  const [selectedRescheduleDate, setSelectedRescheduleDate] = useState<
    Dayjs | undefined
  >()
  const [selectedOperation, setOperation] = useState<
    | 'schedule'
    | 'reschedule'
    | 'complete'
    | 'unschedule'
    | 'upload'
    | 'unmarkComplete'
    | undefined
  >('schedule')
  const [selectedRows, setSelectedRows] = useState<ScheduledInspection[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [openFileTableSlug, setOpenFileTableSlug] = React.useState<string | null>(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [completionDate, setCompletionDate] = useState<Dayjs | undefined>(dayjs())
  const [searchText, setSearchText] = useState<string | undefined>('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)
  const [tableKey, setTableKey] = useState(0)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [exportOutageDateRange, setExportOutageDateRange] = useState<
    [string, string] | undefined
  >()

  const commonColumnsSet = new Set([
    'assetName',
    'assetTag',
    'area',
    'inspectionType',
    'inspectionMethod',
    'outageType',
  ])

  const priorityToValueMap = {
    Top: 1,
    High: 2,
    Medium: 3,
    Low: 4,
    '---': 5,
  }

  const valueToPriorityMap = {
    1: 'Top',
    2: 'High',
    3: 'Medium',
    4: 'Low',
    5: '---',
  }
  const lockOutEnabled =
    userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only ?? false

  const handleUploadFinally = () => {
    closeModal()
  }

  // Re-render Table to reset filters
  // https://github.com/ant-design/ant-design/issues/18001
  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1)
  }

  // Reset selection when tabKey changes
  useEffect(() => {
    setSelectedRowKeys([])
    setSelectedRows([])
    setSearchText('')
    resetFilters()
  }, [props.tabKey])

  useEffect(() => {
    if (props.startDate && props.endDate) {
      setTableDateFilter([props.startDate, props.endDate])
    }
  }, [props.startDate, props.endDate])

  const inspectionTableDataSWR = useGetInspectionsTable(
    auth0,
    props.tabKey as 'due' | 'scheduled' | 'history',
    siteData?.siteSlug,
    tableDateFilter?.[0]?.startOf(props.graphGranularity)?.format('YYYY-MM-DD'),
    tableDateFilter?.[1].endOf(props.graphGranularity)?.format('YYYY-MM-DD'),
    tableInspectionTypeFilter
  )
  const dueInspectionGraphDataSWR = useGetDueAndUnscheduledInspections(
    auth0,
    siteData?.siteSlug,
    props.startDate?.format('YYYY-MM-DD'),
    props.endDate?.format('YYYY-MM-DD'),
    props.graphGranularity
  )

  const scheduledInspectionGraphDataSWR = useGetScheduledInspections(
    auth0,
    siteData?.siteSlug,
    props.startDate?.format('YYYY-MM-DD'),
    props.endDate?.format('YYYY-MM-DD'),
    false,
    props.graphGranularity
  )

  const completedInspectionGraphDataSWR = useGetScheduledInspections(
    auth0,
    siteData?.siteSlug,
    props.startDate?.format('YYYY-MM-DD'),
    props.endDate?.format('YYYY-MM-DD'),
    true,
    props.graphGranularity
  )

  const ruggableMappingSWR = useRolodexEntries(
    {
      ids: [`${siteData?.siteSlug}-ruggable-mapping`],
      type: RolodexEntryType.SITE_CONFIG_TYPE,
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    },
    undefined,
    !!siteData?.orgSlug
  )

  const inspectionTagOptionsSwr = useGetInspectionTagOptions(auth0, siteData?.siteSlug)
  const inspectionTagOptions = useMemo(() => {
    return (
      inspectionTagOptionsSwr.data?.map((tag: string) => {
        return {
          text: tag,
          value: tag,
        }
      }) ?? []
    )
  }, [inspectionTagOptionsSwr.data])

  const processedOutageSchedule = useMemo(() => {
    return (
      props.outageScheduleSWR?.data?.reduce((agg, outage) => {
        const outageObject = {
          ...outage.entry.data,
          entryId: outage.entry.id,
          date: dayjs(outage.entry.data?.date),
        }
        if (isOutageObject(outageObject)) {
          agg.push(outageObject as unknown as OutageObject)
        } else {
          console.error('Outage object is not valid: ', outageObject)
        }
        return agg
      }, new Array<OutageObject>()) ?? []
    )
      .filter((d) => {
        const dayjsDate = d.date
        return dayjsDate.isAfter(props.startDate) && dayjsDate.isBefore(props.endDate)
      })
      .reduce((acc: Record<any, any>, curr) => {
        const key = curr.date.format(
          DATE_GRANULARITY_MAP[props.graphGranularity].dayjsFormat
        )
        if (acc[key]) {
          acc[key].push(curr)
        } else {
          acc[key] = [curr]
        }
        return acc
      }, {})
  }, [
    props.outageScheduleSWR?.data,
    props.startDate,
    props.endDate,
    props.graphGranularity,
  ])

  const tableData: ScheduledInspection[] = useMemo(() => {
    const rawInspectionData: {
      id: string
      data: RawScheduledInspection
      tags: Record<any, any>
    }[] = inspectionTableDataSWR?.data ?? []

    const { inspectionGroupingColumns } = props

    if (!!inspectionGroupingColumns && inspectionGroupingColumns.length > 0) {
      const groupedRawInspections = Array.from(
        rawInspectionData
          .reduce(
            (agg, curr) => {
              const currData = curr.data
              const inspectionKey = inspectionGroupingColumns.reduce((agg, curr) => {
                // if (!typia.is<keyof RawScheduledInspection>(curr)) return agg
                return agg + `-${currData[curr as keyof RawScheduledInspection]}`
              }, '')
              const currGroup = agg.get(inspectionKey) ?? []
              currGroup.push(curr)
              agg.set(inspectionKey, currGroup)
              return agg
            },
            new Map<
              string,
              {
                id: string
                data: RawScheduledInspection
                tags: Record<string, string>
              }[]
            >()
          )
          .entries()
      )

      return (
        groupedRawInspections
          .map(([key, inspectionGroup]) => {
            const { data, tags } = inspectionGroup[0]
            const fdeCustomerUnitSlug = tags?.[RolodexEntryType.CUSTOMER_UNIT] ?? ''

            const ruggable =
              ruggableMappingSWR.data && ruggableMappingSWR.data.length > 0
                ? ruggableMappingSWR.data[0]?.entry?.data?.[fdeCustomerUnitSlug]
                : null

            //ugly TODO clean up
            const resource = props.resourceMapping
              ? data?.inspection_method.toLowerCase() in props.resourceMapping
                ? props.resourceMapping[
                    data?.inspection_method?.toLowerCase() as keyof typeof props.resourceMapping
                  ]
                : 'Third Party'
              : undefined

            const componentData: ScheduledInspectionComponentData[] = inspectionGroup.map(
              (inspection) => {
                return {
                  rowId: inspection.id,
                  assetName: inspection.data?.asset_name,
                  assetTag: inspection.data?.asset_tag ?? '',
                  inspectionId: inspection.id,
                  inspectionType: inspection.data?.inspection_type ?? '',
                  componentName: inspection.data?.component_name ?? '',
                  area: inspection.data?.area ?? '',
                  inspectionMethod: inspection.data?.inspection_method ?? '',
                  outageType: inspection.data?.outage_type ?? '',
                  interval: inspection.data?.inspection_interval,
                  scheduledDate: inspection.data?.scheduled_date ?? '',
                  deadline: inspection.data?.next_deadline ?? '',
                  prevInspection: inspection.data?.previous_inspection ?? '',
                  fdeCustomerUnitSlug,
                  completed: inspection.data?.completed ?? false,
                  inspectionTags: inspection.data.inspection_tags ?? [],
                  priority: inspection.data?.priority ?? '---',
                  cost: inspection.data?.cost ?? undefined,
                  currentDeadline: inspection.data?.current_deadline ?? '',
                  latest: inspection.data?.latest ?? false,
                  estimatedHours: inspection.data?.estimated_hours,
                  ruggable: ruggable ?? '',
                  resource,
                }
              }
            )
            //aggregate data for the groups
            if (componentData.length > 1) {
              return isScheduledInspection({
                rowId: key,
                assetName: data?.asset_name,
                assetTag: data?.asset_tag ?? '',
                componentData,
                inspectionId: inspectionGroup[0].id,
                inspectionType: data?.inspection_type ?? '',
                area: data?.area ?? '',
                inspectionMethod: data?.inspection_method ?? '',
                outageType: data?.outage_type ?? '',
                interval: data?.inspection_interval,
                scheduledDate: data?.scheduled_date ?? '',
                deadline: data?.next_deadline ?? '',
                prevInspection: data?.previous_inspection ?? '',
                fdeCustomerUnitSlug,
                completed: data?.completed ?? false,
                inspectionTags: componentData.reduce((agg: string[], cur) => {
                  const tags = agg.concat(cur.inspectionTags)
                  return [...new Set(tags)]
                }, []),
                priority:
                  valueToPriorityMap[
                    Math.min(
                      ...componentData.map((d) => priorityToValueMap[d.priority])
                    ) as 1 | 2 | 3 | 4 | 5
                  ] ?? '---',
                cost: componentData.reduce((agg, cur) => {
                  return agg + (cur.cost ?? 0)
                }, 0),
                currentDeadline: data?.current_deadline ?? '',
                latest: data?.latest ?? false,
                estimatedHours: componentData.reduce((agg, cur) => {
                  return agg + (cur.estimatedHours ?? 0)
                }, 0),
                ruggable: ruggable ?? '',
                resource,
              })
            }

            return isScheduledInspection({
              rowId: key,
              assetName: data?.asset_name,
              assetTag: data?.asset_tag ?? '',
              componentData,
              componentName: data?.component_name ?? '',
              inspectionId: inspectionGroup[0].id,
              inspectionType: data?.inspection_type ?? '',
              area: data?.area ?? '',
              inspectionMethod: data?.inspection_method ?? '',
              outageType: data?.outage_type ?? '',
              interval: data?.inspection_interval,
              scheduledDate: data?.scheduled_date ?? '',
              deadline: data?.next_deadline ?? '',
              prevInspection: data?.previous_inspection ?? '',
              fdeCustomerUnitSlug,
              completed: data?.completed ?? false,
              inspectionTags: data.inspection_tags ?? [],
              priority: data?.priority ?? '---',
              cost: data?.cost ?? undefined,
              currentDeadline: data?.current_deadline ?? '',
              latest: data?.latest ?? false,
              estimatedHours: data?.estimated_hours,
              ruggable: ruggable ?? '',
              resource,
            })
          })
          .sort((a: ScheduledInspection, b: ScheduledInspection) => {
            return dayjs(a.scheduledDate).isBefore(dayjs(b.scheduledDate)) ? -1 : 1
          }) ?? []
      )
    }

    return (
      rawInspectionData
        .map((inspection) => {
          const { data, tags } = inspection
          const fdeCustomerUnitSlug = tags?.[RolodexEntryType.CUSTOMER_UNIT] ?? ''

          let ruggable

          return isScheduledInspection({
            rowId: inspection.id,
            componentName: data?.component_name ?? '',
            componentType: data?.component_type ?? '',
            inspectionId: inspection.id,
            assetName: data?.asset_name,
            assetTag: data?.asset_tag ?? '',
            inspectionType: data?.inspection_type ?? '',
            area: data?.area ?? '',
            inspectionMethod: data?.inspection_method ?? '',
            outageType: data?.outage_type ?? '',
            interval: data?.inspection_interval,
            scheduledDate: data?.scheduled_date ?? '',
            deadline: data?.next_deadline ?? '',
            prevInspection: data?.previous_inspection ?? '',
            fdeCustomerUnitSlug,
            completed: data?.completed ?? false,
            inspectionTags: data.inspection_tags ?? [],
            priority: data?.priority ?? '---',
            cost: data?.cost ?? undefined,
            currentDeadline: data?.current_deadline ?? '',
            latest: data?.latest ?? false,
            estimatedHours: data?.estimated_hours,
            ruggable: ruggable ?? '',
          })
        })
        .sort((a: ScheduledInspection, b: ScheduledInspection) => {
          return dayjs(a.scheduledDate).isBefore(dayjs(b.scheduledDate)) ? -1 : 1
        }) ?? []
    )
  }, [inspectionTableDataSWR, ruggableMappingSWR])

  const createFilterValues = (
    tableData: ScheduledInspection[],
    key: keyof ScheduledInspection
  ) => {
    const filterList = [
      ...new Set(tableData.map((item: ScheduledInspection) => item[key])),
    ].map((datum) => {
      // All nullish data are converted to empty string in TableData
      if (datum == '') {
        datum = '------'
      }
      return {
        text: toTitleCase(String(datum)),
        value: datum,
      }
    })
    return filterList
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof ScheduledInspection
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof ScheduledInspection
  ) => {
    confirm()
    clearFilters()
    handleSearch([], confirm, dataIndex)
  }
  const getColumnSearchProps = (
    dataIndex: keyof ScheduledInspection,
    style: React.CSSProperties = {},
    customFilterProps?: {
      subItemsKey: 'componentData'
    }
  ): ColumnType<ScheduledInspection> => ({
    //blatantly copied from antd documentation: https://ant.design/components/table#components-table-demo-custom-filter-panel
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}>
            Close
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      //handle the case where each item could have sub fields that need to be searched
      if (customFilterProps && customFilterProps?.subItemsKey) {
        return (
          record?.[customFilterProps.subItemsKey]?.some((subItem) =>
            subItem?.[dataIndex as keyof ScheduledInspectionComponentData]
              ?.toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          ) ?? false
        )
      }
      return (
        record?.[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) || false
      )
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex && searchText ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: asset_manager_theme.background.high_vis,
            padding: 0,
            color: '#000000',
          }}
          style={{
            ...style,
          }}
          searchWords={[searchText as string]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <b style={{ cursor: 'pointer' }}>{text}</b>
      ),
  })

  useEffect(() => {
    if (tableDateFilter && tableDateFilter.length > 1) {
      const startDate = tableDateFilter[0]?.startOf('month')?.format('YYYY-MM-DD')
      const endDate = tableDateFilter[1]?.endOf('month')?.format('YYYY-MM-DD')
      const buttonName = props.tabLabel.toLowerCase().replace(/\s/g, '_') + '_range'
      trackEvent('Button Clicked', {
        buttonName: buttonName,
        tableDateRangeStart: startDate,
        tableDateRangeEnd: endDate,
        pageName: 'Inspection Scheduling',
        subPageName: props.tabLabel,
      })
    }
  }, [tableDateFilter, props.tabKey])

  const priorityColumnTemplate = {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    sorter: (a: ScheduledInspection, b: ScheduledInspection) => {
      if (!a.priority || !b.priority) return 0
      return priorityToValueMap?.[a.priority] - priorityToValueMap[b.priority]
    },
    filterMode: 'tree',
    onFilter: (value: string, record: ScheduledInspection) =>
      record.priority?.indexOf(value as string) === 0,
    filters: [
      {
        text: 'Top',
        value: 'Top',
      },
      {
        text: 'High',
        value: 'High',
      },
      {
        text: 'Medium',
        value: 'Medium',
      },
      {
        text: 'Low',
        value: 'Low',
      },
    ],
    align: 'center',
    width: '7%',
  }

  const expandedComponentTableRender = (
    componentTableColumns: ColumnType<ScheduledInspection>[]
  ) => {
    return (record: ScheduledInspection) => {
      const data = record.componentData
      const formattedColumns = [...componentTableColumns].filter((d) => {
        return !commonColumnsSet.has(d.dataIndex as string)
      })
      return (
        <Table
          columns={formattedColumns}
          dataSource={data}
          pagination={false}
          size='small'
        />
      )
    }
  }

  // TODO: Refactor Column creation into generator function
  const scheduledTableColumns: ColumnsType<ScheduledInspection> = useMemo(
    () =>
      (
        [
          {
            title: 'Name',
            dataIndex: 'assetName',
            key: 'assetName',
            onCell: (record) => {
              return {
                onClick: () => {
                  props.setFdeCustomerUnitSlug(record.fdeCustomerUnitSlug)
                  props.setAssetBaseballCardRefetchData({
                    func: () => {
                      inspectionTableDataSWR.mutate()
                      dueInspectionGraphDataSWR.mutate()
                      scheduledInspectionGraphDataSWR.mutate()
                      completedInspectionGraphDataSWR.mutate()
                    },
                  })
                  trackEvent('Button Clicked', {
                    buttonName: 'asset_baseball_card',
                    pageName: 'Inspection Scheduling',
                    subPageName: 'Scheduled Inspections',
                    assetDetails: {
                      assetName: record.assetName,
                    },
                  })
                },
              }
            },
            ...getColumnSearchProps('assetName', {
              cursor: 'pointer',
              fontWeight: 'bold',
            }),
          },
          {
            title: 'Tag',
            dataIndex: 'assetTag',
            key: 'assetTag',
            width: '3rem',
            ...getColumnSearchProps('assetTag'),
          },
          {
            title: 'Component Name',
            dataIndex: 'componentName',
            key: 'componentName',
            ...getColumnSearchProps(
              'componentName',
              {},
              {
                subItemsKey: 'componentData',
              }
            ),
          },
          {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            sorter: defaultSort('area'),
            filters: createFilterValues(tableData, 'area'),
            filterMode: 'tree',
            onFilter: (value, record) => record.area?.indexOf(value as string) === 0,
          },
          {
            title: 'Ruggable',
            dataIndex: 'ruggable',
            key: 'ruggable',
            width: '10rem',
            sorter: defaultSort('ruggable'),
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'ruggable'),
            onFilter: (value, record) => record.ruggable?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Type',
            dataIndex: 'inspectionType',
            key: 'inspectionType',
            sorter: defaultSort('inspectionType'),
            render: toTitleCase,
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'inspectionType'),
            onFilter: (value, record) =>
              record.inspectionType?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Method',
            dataIndex: 'inspectionMethod',
            key: 'inspectionMethod',
            sorter: defaultSort('inspectionMethod'),
            render: toTitleCase,
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'inspectionMethod'),
            onFilter: (value, record) =>
              record.inspectionMethod?.indexOf(value as string) === 0,
          },

          {
            title: 'Outage Type',
            dataIndex: 'outageType',
            key: 'outageType',
            sorter: defaultSort('outageType'),
          },
          {
            title: 'Est. Hours',
            dataIndex: 'estimatedHours',
            key: 'estimatedHours',
            render: (text, record) => {
              return (
                <EditableNumberElement
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  objectId={record.inspectionId}
                  numberValue={record.estimatedHours}
                  field={'estimated_hours'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                    scheduledInspectionGraphDataSWR.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Last Insp',
            dataIndex: 'prevInspection',
            key: 'prevInspection',
            sorter: dateSort('prevInspection'),
          },
          {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval',
            sorter: dateSort('interval'),
          },
          {
            title: 'Current Deadline',
            dataIndex: 'currentDeadline',
            key: 'currentDeadline',
            sorter: dateSort('currentDeadline'),
          },
          {
            title: 'Scheduled Date',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            defaultSortOrder: 'ascend',
            sorter: dateSort('scheduledDate'),
            render: (text, record) => {
              return (
                <DatePicker
                  suffixIcon={null}
                  disabled={lockOutEnabled} // CheckLockout
                  value={dayjs(text)}
                  allowClear={false}
                  onSelect={(value) => {
                    setOperation('reschedule')
                    setSelectedDate(value)
                    setSelectedRow(record)
                    setIsModalVisible(true)
                  }}
                />
              )
            },
          },
          {
            title: 'Next Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            sorter: dateSort('deadline'),
          },
          {
            ...priorityColumnTemplate,
            render: (text, record) => {
              return (
                <PlanRecPriorityDropDown
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // Checklockout
                  planRecId={record.inspectionId}
                  updateKey={'priority'}
                  fieldValue={record.priority ?? '---'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR?.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            sorter: defaultSort('resource'),
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'resource'),
            onFilter: (value, record) => record.resource?.indexOf(value as string) === 0,
          },
          {
            title: 'Tags',
            dataIndex: 'inspectionTags',
            width: 150,
            key: 'tags',
            filters: inspectionTagOptions,
            filterMode: 'tree',
            onFilter: (value, record) => record.inspectionTags?.includes(value as string),
            render: (text, record) => {
              return (
                <EditableGroupSelect
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  value={record.inspectionTags}
                  objectId={record.inspectionId}
                  field={'inspection_tags'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  options={inspectionTagOptions}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                    inspectionTagOptionsSwr.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Inspection Cost',
            dataIndex: 'cost',
            key: 'cost',
            sorter: defaultSort('cost'),
            render: (text, record) => {
              return (
                <EditableNumberElement
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  objectId={record.inspectionId}
                  numberValue={record.cost}
                  field={'cost'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Generate Packet',
            key: 'generatePacket',
            render: (record) => {
              return (
                <>
                  <div>
                    <Button
                      onClick={() => {
                        setOpenFileTableSlug(record.fdeCustomerUnitSlug)
                      }}
                      icon={<DownloadIcon />}
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    />
                    <FileBrowser
                      open={openFileTableSlug === record.fdeCustomerUnitSlug}
                      onCancel={() => {
                        setOpenFileTableSlug(null)
                      }}
                      fdeCustomerUnitSlug={record.fdeCustomerUnitSlug}
                      assetName={record.asset}
                      isInspectionPacketGenerationMode={true}
                    />
                  </div>
                </>
              )
            },
          },
          {
            // mark completed button
            // intentionally title-less
            key: 'complete',
            render: (text, record) => {
              return (
                <HighVisButton
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  onClick={() => {
                    setOperation('complete')
                    setSelectedRow(record)
                    setIsModalVisible(true)
                  }}>
                  {`\u2714`}
                </HighVisButton>
              )
            },
          },
          {
            // unschedule button
            key: 'unschedule',
            width: '5rem',
            render: (text, record) => {
              return (
                <HighVisButton
                  ghost={true}
                  disabled={lockOutEnabled} // CheckLockout
                  onClick={() => {
                    setOperation('unschedule')
                    setSelectedRows([record])
                    setIsModalVisible(true)
                  }}>
                  <DeleteOutlined />
                </HighVisButton>
              )
            },
          },
        ] as ColumnsType<ScheduledInspection>
      ).filter(
        (d) =>
          userFeatureFlags?.superUser ||
          inspectionSchedulingFeatureFlags?.accessible_columns?.includes(d.key as string)
      ),
    [tableData, userFeatureFlags, inspectionSchedulingFeatureFlags]
  )

  const dueTableColumns: ColumnsType<ScheduledInspection> = useMemo(
    () =>
      (
        [
          {
            title: 'Name',
            dataIndex: 'assetName',
            key: 'assetName',
            onCell: (record) => {
              return {
                onClick: () => {
                  props.setFdeCustomerUnitSlug(record.fdeCustomerUnitSlug)
                  props.setAssetBaseballCardRefetchData({
                    func: () => {
                      inspectionTableDataSWR.mutate()
                      dueInspectionGraphDataSWR.mutate()
                      scheduledInspectionGraphDataSWR.mutate()
                      completedInspectionGraphDataSWR.mutate()
                    },
                  })
                  trackEvent('Button Clicked', {
                    buttonName: 'asset_baseball_card',
                    pageName: 'Inspection Scheduling',
                    subPageName: 'Due and Unscheduled',
                    assetDetails: {
                      assetName: record.assetName,
                    },
                  })
                },
              }
            },
            ...getColumnSearchProps('assetName', {
              cursor: 'pointer',
              fontWeight: 'bold',
            }),
          },
          {
            title: 'Tag',
            dataIndex: 'assetTag',
            key: 'assetTag',
            width: '3rem',
            ...getColumnSearchProps('assetTag'),
          },
          {
            title: 'Component Name',
            dataIndex: 'componentName',
            key: 'componentName',
            ...getColumnSearchProps(
              'componentName',
              {},
              {
                subItemsKey: 'componentData',
              }
            ),
          },
          {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            sorter: defaultSort('area'),
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'area'),
            onFilter: (value, record) => record.area?.indexOf(value as string) === 0,
          },
          {
            title: 'Ruggable',
            dataIndex: 'ruggable',
            key: 'ruggable',
            filterMode: 'tree',
            sorter: defaultSort('ruggable'),
            filters: createFilterValues(tableData, 'ruggable'),
            onFilter: (value, record) => record.ruggable?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Type',
            dataIndex: 'inspectionType',
            key: 'inspectionType',
            sorter: defaultSort('inspectionType'),
            render(text: string) {
              return toTitleCase(text)
            },
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'inspectionType'),
            onFilter: (value, record) =>
              record.inspectionType?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Method',
            dataIndex: 'inspectionMethod',
            key: 'inspectionMethod',
            sorter: defaultSort('inspectionMethod'),
            render: toTitleCase,
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'inspectionMethod'),
            onFilter: (value, record) =>
              record.inspectionMethod?.indexOf(value as string) === 0,
          },
          {
            title: 'Outage',
            dataIndex: 'outageType',
            key: 'outageType',
            sorter: defaultSort('outageType'),
          },
          {
            title: 'Est. Hours',
            dataIndex: 'estimatedHours',
            key: 'estimatedHours',
            render: (text, record) => {
              return (
                <EditableNumberElement
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  objectId={record.inspectionId}
                  numberValue={record.estimatedHours}
                  field={'estimated_hours'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                    dueInspectionGraphDataSWR.mutate()
                  }}
                />
              )
            },
          },
          {
            ...priorityColumnTemplate,
            render: (text, record) => {
              return (
                <PlanRecPriorityDropDown
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // Checklockout
                  planRecId={record.inspectionId}
                  updateKey={'priority'}
                  fieldValue={text}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR?.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Inspection Cost',
            dataIndex: 'cost',
            key: 'cost',
            sorter: defaultSort('cost'),
            render: (text, record) => {
              return (
                <EditableNumberElement
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  objectId={record.inspectionId}
                  numberValue={record.cost}
                  field={'cost'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            sorter: defaultSort('resource'),
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'resource'),
            onFilter: (value, record) => record.resource?.indexOf(value as string) === 0,
          },
          {
            title: 'Tags',
            dataIndex: 'inspectionTags',
            width: 150,
            key: 'tags',
            filters: inspectionTagOptions,
            filterMode: 'tree',
            onFilter: (value, record) => record.inspectionTags?.includes(value as string),
            render: (text, record) => {
              return (
                <EditableGroupSelect
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  value={record.inspectionTags}
                  objectId={record.inspectionId}
                  field={'inspection_tags'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                    inspectionTagOptionsSwr.mutate()
                  }}
                  options={inspectionTagOptions}
                />
              )
            },
          },
          {
            title: 'Last Insp',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            sorter: dateSort('scheduledDate'),
          },
          {
            title: 'Deadline',
            dataIndex: 'deadline',
            defaultSortOrder: 'ascend',
            key: 'deadline',
            sorter: dateSort('deadline'),
          },
          {
            title: 'Schedule Inspection',
            key: 'complete',
            render: (text, record) => {
              return (
                <StyledDatePicker
                  disabled={lockOutEnabled} // CheckLockout
                  defaultValue={record.deadline ? dayjs(record.deadline) : dayjs()}
                  highVis={true}
                  value={null}
                  suffixIcon={<CalendarBoldIcon fill='black' />}
                  placeholder={'Schedule'}
                  onSelect={(value) => {
                    setOperation('schedule')
                    setSelectedDate(value)
                    setSelectedRows([record])
                    setIsModalVisible(true)
                  }}
                />
              )
            },
          },
        ] as ColumnsType<ScheduledInspection>
      ).filter(
        (d) =>
          userFeatureFlags?.superUser ||
          inspectionSchedulingFeatureFlags?.accessible_columns?.includes(d.key as string)
      ),
    [tableData, userFeatureFlags, inspectionSchedulingFeatureFlags]
  )

  const completedTableColumns: ColumnsType<ScheduledInspection> = useMemo(
    () =>
      (
        [
          {
            title: 'Name',
            dataIndex: 'assetName',
            key: 'assetName',
            onCell: (record) => {
              return {
                onClick: () => {
                  props.setFdeCustomerUnitSlug(record.fdeCustomerUnitSlug)
                  props.setAssetBaseballCardRefetchData({
                    func: () => {
                      inspectionTableDataSWR.mutate()
                      dueInspectionGraphDataSWR.mutate()
                      scheduledInspectionGraphDataSWR.mutate()
                      completedInspectionGraphDataSWR.mutate()
                    },
                  })
                  trackEvent('Button Clicked', {
                    buttonName: 'asset_baseball_card',
                    pageName: 'Inspection Scheduling',
                    subPageName: 'Completed Inspections',
                    assetDetails: {
                      assetName: record.assetName,
                    },
                  })
                },
              }
            },
            ...getColumnSearchProps('assetName', {
              cursor: 'pointer',
              fontWeight: 'bold',
            }),
          },
          {
            title: 'Tag',
            dataIndex: 'assetTag',
            key: 'assetTag',
            width: '3rem',
            ...getColumnSearchProps('assetTag'),
          },
          {
            title: 'Component Name',
            dataIndex: 'componentName',
            key: 'componentName',
            ...getColumnSearchProps(
              'componentName',
              {},
              {
                subItemsKey: 'componentData',
              }
            ),
          },
          {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            sorter: defaultSort('area'),
            filters: createFilterValues(tableData, 'area'),
            filterMode: 'tree',
            onFilter: (value, record) => record.area?.indexOf(value as string) === 0,
          },
          {
            title: 'Ruggable',
            dataIndex: 'ruggable',
            key: 'ruggable',
            width: '7rem',
            sorter: defaultSort('ruggable'),
            filters: createFilterValues(tableData, 'ruggable'),
            filterMode: 'tree',
            onFilter: (value, record) => record.ruggable?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Type',
            dataIndex: 'inspectionType',
            key: 'inspectionType',
            sorter: defaultSort('inspectionType'),
            render: toTitleCase,
            filters: createFilterValues(tableData, 'inspectionType'),
            filterMode: 'tree',
            onFilter: (value, record) =>
              record.inspectionType?.indexOf(value as string) === 0,
          },
          {
            title: 'Insp Method',
            dataIndex: 'inspectionMethod',
            key: 'inspectionMethod',
            sorter: defaultSort('inspectionMethod'),
            render: toTitleCase,
            filters: createFilterValues(tableData, 'inspectionMethod'),
            filterMode: 'tree',
            onFilter: (value, record) =>
              record.inspectionMethod?.indexOf(value as string) === 0,
          },
          {
            title: 'Outage Type',
            dataIndex: 'outageType',
            key: 'outageType',
            sorter: defaultSort('outageType'),
          },
          {
            title: 'Est. Hours',
            dataIndex: 'estimatedHours',
            key: 'estimatedHours',
          },
          {
            title: 'Inspection Cost',
            dataIndex: 'cost',
            key: 'cost',
            sorter: defaultSort('cost'),
            render: (text, record) => {
              return (
                <EditableNumberElement
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // CheckLockout
                  objectId={record.inspectionId}
                  numberValue={record.cost}
                  field={'cost'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Deadline',
            dataIndex: 'currentDeadline',
            key: 'deadline',
            sorter: dateSort('currentDeadline'),
          },
          {
            title: 'Completed Date',
            dataIndex: 'scheduledDate',
            defaultSortOrder: 'ascend',
            key: 'completedDate',
            sorter: dateSort('scheduledDate'),
            render: (text, record) => {
              return !record?.deadline &&
                record?.inspectionType?.toLowerCase() === 'internal' ? (
                <Tooltip title={`Marked On-Stream on ${text}`}>
                  <div>On-Stream</div>
                </Tooltip>
              ) : (
                text
              )
            },
          },
          {
            ...priorityColumnTemplate,
            render: (text, record) => {
              return (
                <PlanRecPriorityDropDown
                  disabled={
                    lockOutEnabled ||
                    Boolean(
                      record.componentData?.length && record.componentData?.length > 1
                    )
                  } // Checklockout
                  planRecId={record.inspectionId}
                  updateKey={'priority'}
                  fieldValue={record.priority ?? '---'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR?.mutate()
                  }}
                />
              )
            },
          },
          {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval',
            sorter: dateSort('interval'),
          },
          {
            title: 'Next Deadline',
            dataIndex: 'deadline',
            key: 'nextDeadline',
            sorter: dateSort('deadline'),
            render: (text, record) => {
              return !record?.deadline &&
                record?.inspectionType?.toLowerCase() === 'internal' ? (
                <Tooltip title={`N/A due to On-Stream exception`}>
                  <div>N/A</div>
                </Tooltip>
              ) : (
                text
              )
            },
          },
          {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            sorter: defaultSort('resource'),
            filterMode: 'tree',
            filters: createFilterValues(tableData, 'resource'),
            onFilter: (value, record) => record.resource?.indexOf(value as string) === 0,
          },
          {
            title: 'Tags',
            dataIndex: 'inspectionTags',
            width: 150,
            key: 'tags',
            filters: inspectionTagOptions,
            filterMode: 'tree',
            onFilter: (value, record) => record.inspectionTags?.includes(value as string),
            render: (text, record) => {
              return (
                <EditableGroupSelect
                  value={record.inspectionTags}
                  objectId={record.inspectionId}
                  field={'inspection_tags'}
                  rolodexType={RolodexEntryType.INSPECTION_OBJECT}
                  refetchData={() => {
                    inspectionTableDataSWR.mutate()
                    inspectionTagOptionsSwr.mutate()
                  }}
                  options={inspectionTagOptions}
                />
              )
            },
          },
          {
            width: '5rem',
            key: 'complete',
            render: (text, record) => {
              return (
                <Tooltip
                  title={
                    // if the inspection is the latest inspection, then it can be unmarked as complete
                    record.latest
                      ? 'Unmark inspection as completed'
                      : `You can only unmark an asset's latest inspection as completed`
                  }>
                  <div>
                    <HighVisButton
                      disabled={lockOutEnabled || !record.latest} // CheckLockout
                      ghost={true}
                      onClick={() => {
                        setOperation('unmarkComplete')
                        setSelectedRow(record)
                        setIsModalVisible(true)
                      }}>
                      <DeleteOutlined />
                    </HighVisButton>
                  </div>
                </Tooltip>
              )
            },
          },
        ] as ColumnsType<ScheduledInspection>
      ).filter(
        (d) =>
          userFeatureFlags?.superUser ||
          inspectionSchedulingFeatureFlags?.accessible_columns?.includes(d.key as string)
      ),
    [tableData, userFeatureFlags, inspectionSchedulingFeatureFlags]
  )

  const dueGraphComponent = (
    <DueAndUnscheduledBarChart
      startDate={props.startDate}
      endDate={props.endDate}
      setTableDateFilter={setTableDateFilter}
      setTableInspectionTypeFilter={setTableInspectionTypeFilter}
      inspectionDataSWR={dueInspectionGraphDataSWR}
      outageSchedule={processedOutageSchedule}
      graphDateGranularity={props.graphGranularity}
      graphView={props.graphView}
      barMode={props.barMode}
      targetLoadHours={props.adjustedTargetLoad}
    />
  )
  const scheduledGraphComponent = (
    <ScheduledAndInspectionHistoryBarChart
      startDate={props.startDate}
      endDate={props.endDate}
      setTableDateFilter={setTableDateFilter}
      setTableInspectionTypeFilter={setTableInspectionTypeFilter}
      inspectionDataSWR={scheduledInspectionGraphDataSWR}
      outageSchedule={processedOutageSchedule}
      graphDateGranularity={props.graphGranularity}
      targetLoadHours={props.adjustedTargetLoad}
      breakInHours={props.breakInHours}
      graphView={props.graphView}
      barMode={props.barMode}
    />
  )
  const completedGraphComponent = (
    <ScheduledAndInspectionHistoryBarChart
      startDate={props.startDate}
      endDate={props.endDate}
      setTableDateFilter={setTableDateFilter}
      setTableInspectionTypeFilter={setTableInspectionTypeFilter}
      inspectionDataSWR={completedInspectionGraphDataSWR}
      targetLoadHours={props.adjustedTargetLoad}
      outageSchedule={processedOutageSchedule}
      graphDateGranularity={props.graphGranularity}
      breakInHours={props.breakInHours}
      graphView={props.graphView}
      barMode={props.barMode}
    />
  )

  const handleUnscheduleInspection = (inspectionObjects: ScheduledInspection[]) => {
    const inspectionIds: string[] = []

    inspectionObjects.forEach((obj) => {
      if (obj.componentData?.length && obj.componentData?.length > 1) {
        obj.componentData.forEach((component) => {
          inspectionIds.push(component.inspectionId)
        })
      } else {
        inspectionIds.push(obj.inspectionId)
      }
    })

    inspectionObjects.forEach((obj) => {
      trackEvent('Button Clicked', {
        buttonName: 'unschedule_inspection',
        pageName: 'Inspection Scheduling',
        assetDetails: {
          assetName: obj.assetName,
          inspectionId: obj.inspectionId,
          inspectionType: obj.inspectionType,
        },
      })
    })
    rolodex_batch_delete(auth0, inspectionIds).then(() => {
      inspectionTableDataSWR.mutate()
      scheduledInspectionGraphDataSWR.mutate()
      dueInspectionGraphDataSWR.mutate()
    })
  }

  const convertToRawInspectionMetadata = (inspectionObject: ScheduledInspection) => {
    const scheduledInspectionObject: RawInspectionMetadata = {
      asset_name: inspectionObject.assetName,
      asset_tag: inspectionObject.assetTag,
      component_name: inspectionObject.componentName,
      component_type: inspectionObject.componentType,
      inspection_type: inspectionObject.inspectionType,
      area: inspectionObject.area,
      inspection_method: inspectionObject.inspectionMethod,
      outage_type: inspectionObject.outageType,
      inspection_interval: inspectionObject.interval,
      previous_inspection: inspectionObject.scheduledDate,
      current_deadline: inspectionObject.deadline,
      inspection_tags: inspectionObject.inspectionTags,
      priority:
        inspectionObject.priority === '---' ? undefined : inspectionObject.priority,
      cost: inspectionObject.cost,
      estimated_hours: inspectionObject.estimatedHours,
      fdeCustomerUnitSlug: inspectionObject.fdeCustomerUnitSlug,
    }
    return scheduledInspectionObject
  }

  const handleScheduleInspection = (
    prevInspectionObjects: ScheduledInspection[],
    nextInspectionDate: Dayjs
  ) => {
    const scheduleInspections: RawInspectionMetadata[] = []

    prevInspectionObjects.forEach((prevInspectionObject) => {
      if (
        prevInspectionObject.componentData?.length &&
        prevInspectionObject.componentData?.length > 0
      ) {
        prevInspectionObject.componentData.forEach((component) => {
          scheduleInspections.push(convertToRawInspectionMetadata(component))
        })
      } else {
        scheduleInspections.push(convertToRawInspectionMetadata(prevInspectionObject))
      }
    })

    scheduleInspection(
      scheduleInspections,
      nextInspectionDate,
      siteData,
      auth0,
      'inspection_scheduling',
      undefined,
      () => {
        inspectionTableDataSWR.mutate()
        scheduledInspectionGraphDataSWR.mutate()
        dueInspectionGraphDataSWR.mutate()
      }
    )
  }

  const handleRescheduleInspection = (
    inspectionObjects: ScheduledInspection[],
    newInspectionDate: Dayjs
  ) => {
    const patchEntries: any = []

    inspectionObjects.forEach((inspectionObject: ScheduledInspection) => {
      trackEvent('Button Clicked', {
        buttonName: 'reschedule_inspection',
        pageName: 'Inspection Scheduling',
        assetDetails: {
          assetName: inspectionObject.assetName,
          date: newInspectionDate.format('YYYY-MM-DD'),
          inspectionType: inspectionObject.inspectionType,
        },
      })

      if (
        inspectionObject.componentData?.length &&
        inspectionObject.componentData?.length > 1
      ) {
        inspectionObject.componentData.forEach((component) => {
          const inspectionPatchEntry = {
            id: component.inspectionId,
            type: 'fde-am-inspection',
            data: {
              scheduled_date: newInspectionDate.format('YYYY-MM-DD'),
              next_deadline: newInspectionDate
                .add(component.interval, 'month')
                .format('YYYY-MM-DD'),
            },
            tags: {
              [RolodexEntryType.CUSTOMER_UNIT]: component.fdeCustomerUnitSlug,
              site: siteData?.siteSlug,
              organization: siteData?.orgSlug,
            },
          }
          patchEntries.push(inspectionPatchEntry)
        })
      } else {
        const inspectionPatchEntry = {
          id: inspectionObject.inspectionId,
          type: 'fde-am-inspection',
          data: {
            scheduled_date: newInspectionDate.format('YYYY-MM-DD'),
            next_deadline: newInspectionDate
              .add(inspectionObject.interval, 'month')
              .format('YYYY-MM-DD'),
          },
          tags: {
            [RolodexEntryType.CUSTOMER_UNIT]: inspectionObject.fdeCustomerUnitSlug,
            site: siteData?.siteSlug,
            organization: siteData?.orgSlug,
          },
        }

        patchEntries.push(inspectionPatchEntry)
      }
    })

    if (selectedRow) {
      setSelectedRow(undefined)
    }

    RolodexPatchBatch(patchEntries, auth0, siteData).then(() => {
      inspectionTableDataSWR.mutate()
      scheduledInspectionGraphDataSWR.mutate()
    })
  }

  const handleCompleteInspection = (inspectionObject: ScheduledInspection) => {
    trackEvent('Button Clicked', {
      buttonName: 'mark_inspection_complete',
      pageName: 'Inspection Scheduling',
      assetDetails: {
        assetName: inspectionObject.assetName,
        date: inspectionObject.scheduledDate,
      },
    })

    const endpoint = `${rolodexUrl()}/entries/load`
    const body = {
      ids: [inspectionObject.fdeCustomerUnitSlug],
      limit: 1,
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }
    postFetcher(endpoint, JSON.stringify(body), auth0).then(() => {
      const itemsToPatch = []
      // change the inspection to completed
      itemsToPatch.push({
        id: inspectionObject.inspectionId,
        type: 'fde-am-inspection',
        data: {
          completed: true,
          scheduled_date: completionDate?.format('YYYY-MM-DD'),
          next_deadline: completionDate
            ?.add(inspectionObject.interval, 'month')
            .format('YYYY-MM-DD'),
        },
        tags: {
          [RolodexEntryType.CUSTOMER_UNIT]: inspectionObject.fdeCustomerUnitSlug,
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
        },
      })

      const inspection_schedule = {
        [inspectionObject.inspectionType.toLowerCase()]: {
          [inspectionObject.inspectionMethod]: {
            last: completionDate?.format('YYYY-MM-DD'),
            next: inspectionObject.deadline,
            // this interval will be passed in to the function when we add the interval field to
            // the post complete modal
            interval_months: inspectionObject.interval,
          },
        },
      }

      itemsToPatch.push({
        id: inspectionObject.fdeCustomerUnitSlug,
        type: RolodexEntryType.CUSTOMER_UNIT,
        data: {
          inspection_schedule: inspection_schedule,
        },
        tags: {
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
        },
      })
      RolodexPatchBatch(itemsToPatch, auth0, siteData).then(() => {
        inspectionTableDataSWR.mutate()
        scheduledInspectionGraphDataSWR.mutate()
        dueInspectionGraphDataSWR.mutate()
        completedInspectionGraphDataSWR.mutate()
      })
    })
  }

  const handleGenerateSchedule = () => {
    trackEvent('Button Clicked', {
      buttonName: 'generate_inspection_schedule',
      pageName: 'Inspection Scheduling',
      subPageName: 'Scheduled Inspections',
      tableDateRangeStart:
        tableDateFilter?.[0]?.startOf('month')?.format('YYYY-MM-DD') || '',
      tableDateRangeEnd: tableDateFilter?.[1]?.endOf('month')?.format('YYYY-MM-DD') || '',
    })
    generateInspectionSchedulePdf(
      auth0,
      tableDateFilter?.[0]?.startOf('month')?.format('YYYY-MM-DD') || '',
      tableDateFilter?.[1]?.endOf('month')?.format('YYYY-MM-DD') || '',
      siteData?.siteSlug || '',
      false,
      exportOutageDateRange
    )
  }

  const handleGenerateCompletedSchedule = () => {
    trackEvent('Button Clicked', {
      buttonName: 'generate_completed_inspection_schedule',
      pageName: 'Inspection Scheduling',
      subPageName: 'Completed Inspections',
      tableDateRangeStart:
        tableDateFilter?.[0]?.startOf('month')?.format('YYYY-MM-DD') || '',
      tableDateRangeEnd: tableDateFilter?.[1]?.endOf('month')?.format('YYYY-MM-DD') || '',
    })

    generateInspectionSchedulePdf(
      auth0,
      tableDateFilter?.[0]?.startOf('month')?.format('YYYY-MM-DD') || '',
      tableDateFilter?.[1]?.endOf('month')?.format('YYYY-MM-DD') || '',
      siteData?.siteSlug || '',
      true,
      exportOutageDateRange
    )
  }

  const handleUnmarkComplete = (inspectionObject: ScheduledInspection) => {
    trackEvent('Button Clicked', {
      buttonName: 'unmark_inspection_complete',
      pageName: 'Inspection Scheduling',
      subPageName: 'Inspection History',
      assetDetails: {
        assetName: inspectionObject.assetName,
        inspectionId: inspectionObject.inspectionId,
        date: inspectionObject.scheduledDate,
        inspectionType: inspectionObject.inspectionType,
      },
    })
    unmarkCompleteInspection(
      auth0,
      siteData?.siteSlug,
      inspectionObject.inspectionId
    ).then(() => {
      inspectionTableDataSWR.mutate()
      scheduledInspectionGraphDataSWR.mutate()
      dueInspectionGraphDataSWR.mutate()
      completedInspectionGraphDataSWR.mutate()
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ScheduledInspection[]) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedRows(selectedRows)
    },
  }

  const formatOutOfPeriodCounts: (outOfPeriodCounts: OutOfPeriodCounts) => {
    pre_count: number
    post_count: number
    pre_data: {
      earliest_date: dayjs.Dayjs
      latest_date: dayjs.Dayjs
    }
    post_data: {
      earliest_date: dayjs.Dayjs
      latest_date: dayjs.Dayjs
    }
  } = (outOfPeriodCounts) => {
    return {
      ...outOfPeriodCounts,
      pre_data: {
        earliest_date: dayjs(outOfPeriodCounts?.pre_data?.earliest_date, 'YYYY-MM-DD'),
        latest_date: dayjs(outOfPeriodCounts?.pre_data?.latest_date, 'YYYY-MM-DD'),
      },
      post_data: {
        earliest_date: dayjs(outOfPeriodCounts?.post_data?.earliest_date, 'YYYY-MM-DD'),
        latest_date: dayjs(outOfPeriodCounts?.post_data?.latest_date, 'YYYY-MM-DD'),
      },
    }
  }

  const outOfPeriodCounts: {
    pre_count: number
    post_count: number
    pre_data?: {
      earliest_date: dayjs.Dayjs
      latest_date: dayjs.Dayjs
    }
    post_data?: {
      earliest_date: dayjs.Dayjs
      latest_date: dayjs.Dayjs
    }
  } = useMemo(() => {
    switch (props.tabKey) {
      case 'due':
        return formatOutOfPeriodCounts(
          dueInspectionGraphDataSWR.data?.out_of_period_counts
        )
      case 'scheduled':
        return formatOutOfPeriodCounts(
          scheduledInspectionGraphDataSWR.data?.out_of_period_counts
        )
      case 'history':
        return formatOutOfPeriodCounts(
          completedInspectionGraphDataSWR.data?.out_of_period_counts
        )
      default:
        return {
          pre_count: 0,
          post_count: 0,
        }
    }
  }, [props.tabKey, dueInspectionGraphDataSWR.data, scheduledInspectionGraphDataSWR.data])

  // Check if there are selected rows
  const isRowsSelected = selectedRows.length > 0

  // Determine the placeholder text
  const bulkSchedulePlaceholderText = isRowsSelected
    ? `Schedule ${selectedRows.length} inspection${selectedRows.length > 1 ? 's' : ''}`
    : 'Schedule Inspections'

  const unScheduleButtonLabel =
    selectedRows.length > 0 ? `Unschedule ${selectedRows.length} inspections` : undefined
  const rescheduleButtonLabel =
    selectedRows.length > 0
      ? `Reschedule ${selectedRows.length} inspections`
      : 'Reschedule inspections'

  const scheduleMessage = (
    <div>
      {selectedDate && selectedDate.diff(dayjs(), 'day') < 0 ? (
        <Row>
          <InlineTile background={asset_manager_theme.colors.orange_dark} padding='.5rem'>
            <Text style={{ color: 'white' }}>
              &nbsp;&nbsp;
              <WarningFilled style={{ color: asset_manager_theme.colors.orange }} />
              &nbsp;&nbsp; This date is in the past.
            </Text>
          </InlineTile>
        </Row>
      ) : (
        ''
      )}
      <Row>
        {selectedRows.length === 1
          ? ` Are you sure you want to schedule the inspection for ${
              selectedRows[0]?.assetName
            } on ${selectedDate?.format('YYYY-MM-DD')}?`
          : ` Are you sure you want to schedule the inspections for ${selectedRows
              .map((row) => row.assetName)
              .join(', ')} on ${selectedDate?.format('YYYY-MM-DD')}?`}
      </Row>
    </div>
  )

  const unScheduleMessage =
    selectedRows.length === 1
      ? ` Are you sure you want to unschedule the inspection for ${selectedRows[0]?.assetName} on ${selectedRows[0]?.scheduledDate}?`
      : ` Are you sure you want to unschedule the inspections for ${selectedRows
          .map((row) => row.assetName)
          .join(', ')} ?`

  const rescheduleMessage = selectedRow
    ? ` Are you sure you want to reschedule the inspection for ${
        selectedRow?.assetName
      } on ${selectedRow?.scheduledDate} to ${selectedDate?.format('YYYY-MM-DD')}?`
    : ` Are you sure you want to reschedule the inspections for ${selectedRows
        .map((row) => row.assetName)
        .join(', ')} to ${selectedRescheduleDate?.format('YYYY-MM-DD')}?`

  const closeModal = () => {
    setIsModalVisible(false)
    setSelectedRows([])
    setSelectedRowKeys([])
    setSelectedDate(undefined)
  }

  const tableColumns = useMemo(() => {
    return (
      {
        due: dueTableColumns,
        scheduled: scheduledTableColumns,
        history: completedTableColumns,
      }[props.tabKey] ?? []
    )
  }, [dueTableColumns, scheduledTableColumns, completedTableColumns])

  const getScheduledCompletedTotal = (inspectionCounts: Record<string, number>) => {
    return Object.values(inspectionCounts ?? {}).reduce(
      (acc: number, cur: number | undefined) => {
        return acc + (cur ?? 0)
      },
      0
    )
  }

  const InspectionTotalInDateRange = useMemo(() => {
    return {
      due: {
        is_loading: dueInspectionGraphDataSWR.isLoading,
        internal: (
          dueInspectionGraphDataSWR?.data?.graph_data?.internal?.counts ??
          ([] as number[])
        ).reduce((acc: number, cur: number | undefined) => {
          return acc + (cur ?? 0)
        }, 0),
        external: (
          dueInspectionGraphDataSWR?.data?.graph_data?.external?.counts ??
          ([] as number[])
        ).reduce((acc: number, cur: number | undefined) => {
          return acc + (cur ?? 0)
        }, 0),
      },
      scheduled: {
        is_loading: dueInspectionGraphDataSWR.isLoading,
        internal: getScheduledCompletedTotal(
          scheduledInspectionGraphDataSWR.data?.counts?.internal
        ),
        external: getScheduledCompletedTotal(
          scheduledInspectionGraphDataSWR.data?.counts?.external
        ),
      },
      history: {
        is_loading: dueInspectionGraphDataSWR.isLoading,
        internal: getScheduledCompletedTotal(
          completedInspectionGraphDataSWR.data?.counts?.internal
        ),
        external: getScheduledCompletedTotal(
          completedInspectionGraphDataSWR.data?.counts?.external
        ),
      },
    }
  }, [
    completedInspectionGraphDataSWR.data,
    dueInspectionGraphDataSWR.data,
    scheduledInspectionGraphDataSWR.data,
  ])

  // TODO: Add user-enabled resizing like here: https://ant.design/components/table#table-demo-resizable-column
  return (
    <div style={{ width: '100%', color: 'white' }}>
      {contextHolder}

      <Row style={{ justifyContent: 'end', marginTop: '-1.5rem' }}>
        <Tooltip title={`View all data prior to date range`} placement={'bottom'}>
          <Column style={{ maxWidth: '4%', minWidth: '4%', marginRight: '0.5rem' }}>
            <HighVisButton
              ghost={true}
              disabled={
                !outOfPeriodCounts?.pre_data?.earliest_date ||
                !outOfPeriodCounts.pre_data.earliest_date.isValid()
              }
              onClick={() => {
                if (
                  outOfPeriodCounts?.pre_data?.earliest_date &&
                  outOfPeriodCounts?.pre_data?.earliest_date.isValid()
                ) {
                  const newStartDate = outOfPeriodCounts.pre_data.earliest_date
                  props.setStartGraphDate(outOfPeriodCounts.pre_data.earliest_date)
                  if (props.endDate && props.endDate.diff(newStartDate, 'year') > 2) {
                    props.setGraphGranularity('year')
                  }
                }
              }}>
              <FastBackwardOutlined /> {outOfPeriodCounts?.pre_count}
            </HighVisButton>
          </Column>
        </Tooltip>{' '}
        <Tooltip title={`View next data prior to date range`} placement={'bottom'}>
          <Column style={{ maxWidth: '4%', minWidth: '4%', marginRight: '0.5rem' }}>
            <HighVisButton
              ghost={true}
              disabled={
                !outOfPeriodCounts?.pre_data?.latest_date ||
                !outOfPeriodCounts.pre_data.latest_date.isValid()
              }
              onClick={() => {
                if (
                  outOfPeriodCounts?.pre_data?.latest_date &&
                  outOfPeriodCounts?.pre_data?.latest_date.isValid()
                ) {
                  props.setStartGraphDate(
                    outOfPeriodCounts.pre_data.latest_date.subtract(1, 'year')
                  )
                  props.setEndGraphDate(
                    outOfPeriodCounts.pre_data.latest_date.add(1, 'month')
                  )
                  props.setGraphGranularity('month')
                }
              }}>
              <BackwardOutlined />
            </HighVisButton>
          </Column>
        </Tooltip>{' '}
        <Column style={{ maxWidth: '4%', marginRight: '0.5rem' }}>
          <HighVisButton
            ghost={true}
            onClick={() => {
              props.setStartGraphDate(dayjs())
              props.setEndGraphDate(dayjs().add(1, 'year'))
            }}>
            Today
          </HighVisButton>
        </Column>{' '}
        <Tooltip title={`View next data after date range`} placement={'bottom'}>
          <Column style={{ maxWidth: '4%', minWidth: '4%', marginRight: '0.5rem' }}>
            <HighVisButton
              ghost={true}
              onClick={() => {
                if (
                  outOfPeriodCounts.post_data?.earliest_date &&
                  outOfPeriodCounts.post_data?.earliest_date.isValid()
                ) {
                  props.setStartGraphDate(
                    outOfPeriodCounts.post_data?.earliest_date.subtract(1, 'month')
                  )
                  props.setEndGraphDate(
                    outOfPeriodCounts.post_data?.earliest_date.add(1, 'year')
                  )
                  props.setGraphGranularity('month')
                }
              }}
              disabled={
                !outOfPeriodCounts.post_data?.earliest_date ||
                !outOfPeriodCounts.post_data?.earliest_date.isValid()
              }>
              <ForwardOutlined />
            </HighVisButton>
          </Column>
        </Tooltip>{' '}
        <Tooltip title={`View all data after date range`} placement={'bottom'}>
          <Column style={{ maxWidth: '4%', minWidth: '4%', marginRight: '0.5rem' }}>
            <HighVisButton
              ghost={true}
              onClick={() => {
                if (
                  outOfPeriodCounts.post_data?.latest_date &&
                  outOfPeriodCounts.post_data?.latest_date.isValid()
                ) {
                  const newEndGraphDate = outOfPeriodCounts.post_data?.latest_date
                  props.setEndGraphDate(newEndGraphDate)
                  if (
                    props.startDate &&
                    newEndGraphDate.diff(props.startDate, 'year') > 2
                  ) {
                    props.setGraphGranularity('year')
                  }
                }
              }}
              disabled={
                !outOfPeriodCounts.post_data?.latest_date ||
                !outOfPeriodCounts.post_data?.latest_date.isValid()
              }>
              {outOfPeriodCounts?.post_count} <FastForwardOutlined />
            </HighVisButton>
          </Column>
        </Tooltip>{' '}
      </Row>

      <Row style={{ justifyContent: 'center', marginTop: '.5rem' }}>
        {!InspectionTotalInDateRange[props.tabKey as 'due' | 'scheduled' | 'history']
          .is_loading && (
          <>
            <Statistic
              title={<span style={{ color: '#ffffff' }}>Inspections in Range</span>}
              style={{ marginRight: '1rem' }}
              value={
                InspectionTotalInDateRange[
                  props.tabKey as 'due' | 'scheduled' | 'history'
                ].external ??
                0 +
                  InspectionTotalInDateRange[
                    props.tabKey as 'due' | 'scheduled' | 'history'
                  ].internal ??
                0
              }
            />
            <Statistic
              title={
                <span style={{ color: '#ffffff' }}>Internal Inspections in Range</span>
              }
              style={{ marginRight: '1rem' }}
              value={
                InspectionTotalInDateRange[
                  props.tabKey as 'due' | 'scheduled' | 'history'
                ].internal ?? 0
              }
            />
            <Statistic
              title={
                <span style={{ color: '#ffffff' }}>External Inspections in Range</span>
              }
              value={
                InspectionTotalInDateRange[
                  props.tabKey as 'due' | 'scheduled' | 'history'
                ].external ?? 0
              }
            />
          </>
        )}
      </Row>

      {
        {
          due: dueGraphComponent,
          scheduled: scheduledGraphComponent,
          history: completedGraphComponent,
        }[props.tabKey]
      }
      <Spin spinning={inspectionTableDataSWR.isLoading}>
        <Row style={{ marginTop: '1rem' }}>
          <Column>
            <p
              style={{
                padding: '1rem',
                fontWeight: 'bold',
                color: `${asset_manager_theme.text.high_vis}`,
              }}>
              Selected Date Range:{' '}
              {tableDateFilter?.[0] &&
                tableDateFilter?.[1] &&
                `${tableDateFilter?.[0]?.format(
                  'YYYY-MM-DD'
                )} - ${tableDateFilter?.[1]?.format('YYYY-MM-DD')}`}
            </p>
          </Column>
          <Button
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '.5rem',
            }}
            onClick={() => {
              resetFilters()
            }}>
            <span style={{ marginLeft: 8 }}>Reset Table Filters</span>
          </Button>
          {props.tabKey === 'due' && (
            <Column style={{ maxWidth: '15%', marginRight: '1.5rem' }}>
              <StyledDatePicker
                highVis={true}
                value={null}
                suffixIcon={
                  <CalendarBoldIcon
                    fill={
                      isRowsSelected
                        ? 'black'
                        : asset_manager_theme.background.background_color5
                    }
                  />
                }
                disabled={!isRowsSelected}
                placeholder={bulkSchedulePlaceholderText}
                onSelect={(value) => {
                  setOperation('schedule')
                  setSelectedDate(value)
                  setIsModalVisible(true)
                }}
              />
            </Column>
          )}
          {props.tabKey === 'scheduled' && (
            <>
              <Column style={{ maxWidth: '16%', marginRight: '1.5rem' }}>
                <HighVisButton
                  ghost={true}
                  disabled={selectedRows.length === 0}
                  onClick={() => {
                    setOperation('unschedule')
                    setIsModalVisible(true)
                  }}>
                  <DeleteOutlined />
                  {unScheduleButtonLabel ? (
                    <span style={{ marginLeft: 8 }}>{unScheduleButtonLabel}</span>
                  ) : (
                    <span>Unschedule Inspections</span>
                  )}
                </HighVisButton>
              </Column>
              <Column style={{ maxWidth: '18%', marginRight: '.25rem' }}>
                <StyledDatePicker
                  highVis={true}
                  value={null}
                  style={{ width: '90%' }}
                  suffixIcon={
                    <CalendarBoldIcon
                      fill={
                        isRowsSelected
                          ? 'black'
                          : asset_manager_theme.background.background_color5
                      }
                    />
                  }
                  disabled={!isRowsSelected}
                  placeholder={rescheduleButtonLabel}
                  onSelect={(value) => {
                    setOperation('reschedule')
                    setSelectedRescheduleDate(value)
                    setIsModalVisible(true)
                  }}
                />
              </Column>
            </>
          )}
          {props.tabKey !== 'due' && (
            <Button
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '.5rem',
              }}
              onClick={() => {
                setExportModalOpen(true)
              }}>
              <DownloadIcon />
              <span style={{ marginLeft: 8 }}>Generate PDF Schedule</span>
            </Button>
          )}
        </Row>
        <Table
          key={tableKey}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          rowKey={(row) =>
            row.rowId || //needs to be unique
            [
              row.assetName,
              row.scheduledDate,
              row.inspectionType,
              row.inspectionMethod,
            ].join('~')
          }
          rowSelection={
            lockOutEnabled
              ? undefined
              : {
                  type: 'checkbox',
                  ...rowSelection,
                }
          }
          dataSource={tableData}
          columns={tableColumns}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (
                !Boolean(
                  record?.componentData?.length && record?.componentData?.length > 1
                )
              ) {
                //annoying that i have to check this in two places ...
                return undefined
              }
              return expanded ? (
                <MinusOutlined
                  style={{ fontSize: '20px', color: asset_manager_theme.text.high_vis }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <PlusOutlined
                  style={{ fontSize: '20px', color: asset_manager_theme.text.high_vis }}
                  onClick={(e) => onExpand(record, e)}
                />
              )
            },
            expandedRowRender: expandedComponentTableRender(tableColumns),
            rowExpandable: (record) =>
              Boolean(record?.componentData?.length && record?.componentData?.length > 1),

            defaultExpandedRowKeys: ['0'],
          }}
        />
        <Modal
          title={
            // if selectedOperation is preUpload or postUpload, don't show the title
            selectedOperation === 'upload' || selectedOperation === 'unmarkComplete'
              ? ''
              : `Confirm ${toTitleCase(selectedOperation || '')} Inspection`
          }
          open={isModalVisible}
          onOk={() => {
            switch (selectedOperation) {
              case 'schedule':
                if (selectedRows && selectedDate) {
                  handleScheduleInspection(selectedRows, selectedDate)
                }
                closeModal()
                break
              case 'reschedule':
                if (selectedRow && selectedDate) {
                  handleRescheduleInspection([selectedRow], selectedDate)
                } else if (selectedRows && selectedRescheduleDate) {
                  handleRescheduleInspection(selectedRows, selectedRescheduleDate)
                }
                closeModal()
                break
              case 'complete':
                if (selectedRow) {
                  handleCompleteInspection(selectedRow)
                }
                setSelectedDate(undefined)
                setOperation('upload')
                break
              case 'unschedule':
                if (selectedRows) {
                  handleUnscheduleInspection(selectedRows)
                }
                closeModal()
                break
              case 'unmarkComplete':
                if (selectedRow) {
                  handleUnmarkComplete(selectedRow)
                }
                closeModal()
                break

              default:
                break
            }
          }}
          onCancel={() => {
            closeModal()
          }}
          footer={(_, { OkBtn, CancelBtn }) =>
            selectedOperation === 'upload' ? (
              <>
                <Button onClick={closeModal}>Dismiss</Button>
                <UploadButton
                  customRequest={UploadLatestInspection(
                    auth0,
                    selectedRow?.fdeCustomerUnitSlug || '',
                    siteData,
                    selectedRow?.assetName,
                    messageApi,
                    handleUploadFinally
                  )}
                  assetName={selectedRow?.assetName || ''}
                  pageName='Inspection Scheduling'
                  subPageName='Mark Complete'
                />
              </>
            ) : (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )
          }>
          {{
            schedule: scheduleMessage,
            reschedule: rescheduleMessage,
            complete: (
              <div>
                <p>
                  Are you sure you want to mark the inspection as completed? If so, please
                  confirm the completion date.
                </p>
                <br></br>
                <StyledDatePicker
                  value={completionDate}
                  onSelect={(date) => setCompletionDate(date)}
                />
              </div>
            ),
            unschedule: unScheduleMessage,
            upload: `Completed Inspection for ${selectedRow?.assetName}. You can upload the inspection report now and modify ${selectedRow?.assetName}'s plan in Repair Planning.`,
            unmarkComplete: `Are you sure you want to unmark the inspection for ${selectedRow?.assetName} as completed?`,
          }?.[
            selectedOperation as
              | 'schedule'
              | 'reschedule'
              | 'complete'
              | 'unschedule'
              | 'upload'
              | 'unmarkComplete'
          ] || 'do nothing'}
        </Modal>
        <InspectionScheduleExportModal
          dateRange={exportOutageDateRange}
          open={exportModalOpen}
          onClose={() => setExportModalOpen(false)}
          setDateRange={setExportOutageDateRange}
          onOkCallback={() => {
            props.tabKey === 'scheduled'
              ? handleGenerateSchedule()
              : handleGenerateCompletedSchedule()
            setExportOutageDateRange(undefined)
          }}
        />
      </Spin>
    </div>
  )
}
