import React, { useEffect, useContext, useState, useMemo } from 'react'
import { Input as InputString } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useDebounce } from 'react-use'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { Select } from 'antd'

interface EditableGroupSelectElementProps {
  value: string[] | undefined
  objectId: string
  field: string
  refetchData: () => void
  rolodexType: string
  options?: { text: string; value: string }[]
  disabled?: boolean
}

export const EditableGroupSelect: React.FC<EditableGroupSelectElementProps> = ({
  value,
  objectId,
  field,
  refetchData,
  rolodexType,
  disabled,
  options,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<string[] | undefined>(value)

  useEffect(() => {
    setEditedValue(value)
  }, [value])

  const formattedOptions = useMemo(() => {
    return (
      options?.map((option) => {
        return { label: option, value: option }
      }) ?? []
    )
  }, [options])

  useDebounce(
    () => {
      if (editedValue !== value && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: objectId,
          type: rolodexType,
          data: {
            [field]: editedValue ?? null,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    500,
    [editedValue]
  )

  return (
    <Select
      disabled={disabled}
      mode='tags' // Enable multiple selection and tag creation
      style={{ width: '100%' }}
      placeholder='Select Groups'
      value={value}
      onChange={(value) => {
        setEditedValue(value)
      }}
      options={options}
    />
  )
}
