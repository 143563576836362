import React, { useMemo } from 'react'
import { Button, Slider, Row, InputNumber } from 'antd'
import _ from 'lodash'
import { trackEvent } from '../helpers/mixpanel'

interface TableSliderFilterProps {
  setSelectedKeys: (selectedKeys: React.Key[]) => void
  selectedKeys: React.Key[]
  confirm: () => void
  clearFilters?: () => void
  dataIndex: string
  data: any[]
}

export const TableSliderFilter: React.FC<TableSliderFilterProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  data,
}) => {
  //grab max and min to threshold the slider and add protections
  const [maxVal, minVal] = useMemo(() => {
    const valueList = data
      .map((d) => parseInt((_.get(d, dataIndex) as string) || ''))
      .filter((d) => d)
    return [_.max(valueList) || 0, _.min(valueList) || 0]
  }, [data])

  const { max, min } =
    selectedKeys.length > 0
      ? (selectedKeys[0] as any as {
          max: number
          min: number
        })
      : {
          max: maxVal,
          min: minVal,
        }

  return (
    <div
      className='custom-filter-dropdown ant-table-filter-dropdown'
      style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}>
      <Row>
        <Slider
          range={{ draggableTrack: true }}
          defaultValue={[minVal, maxVal]}
          max={maxVal}
          min={minVal}
          value={max && min ? [min, max] : [minVal, maxVal]}
          style={{ width: '100%' }}
          onChange={(value: number[]) => {
            const [min, max] = value
            setSelectedKeys([
              {
                max,
                min,
              },
            ] as any as React.Key[])
          }}
        />
      </Row>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}>
        <div style={{ paddingRight: '.5rem' }}>
          <strong>Min</strong>
        </div>
        <InputNumber
          min={minVal}
          max={max}
          value={min}
          controls={false}
          style={{ width: 80 }}
          onChange={(value: number | null) =>
            setSelectedKeys([
              {
                max,
                min: value || 0,
              },
            ] as any as React.Key[])
          }
        />
        <div>
          <strong>Max</strong>
        </div>
        <InputNumber
          value={max}
          min={min}
          max={maxVal}
          controls={false}
          style={{ width: 80 }}
          onChange={(value: number | null) =>
            setSelectedKeys([
              {
                max: value || 0,
                min,
              },
            ] as any as React.Key[])
          }
        />{' '}
      </Row>
      <Row style={{ justifyContent: 'right' }}>
        <Button
          size='small'
          style={{ width: 90 }}
          onClick={() => {
            clearFilters?.()
            setSelectedKeys([])
            confirm()
          }}>
          Reset
        </Button>
        <Button
          type='primary'
          size='small'
          style={{ width: 90, marginLeft: '.5rem' }}
          onClick={() => confirm()}>
          Apply
        </Button>
      </Row>
    </div>
  )
}
