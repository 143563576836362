import React, { useContext, useEffect, useState, useMemo } from 'react'
import { ReactComponent as AncientGeckoStars } from '../../../assets/ancient_gecko_stars.svg'
import {
  Tree,
  Modal,
  Button,
  Input,
  TreeDataNode,
  Select,
  message,
  Spin,
  Tooltip,
  Checkbox,
} from 'antd'
const { DirectoryTree } = Tree
import {
  DownloadOutlined,
  DeleteOutlined,
  TagOutlined,
  FolderAddOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { DataNode } from 'antd/es/tree'
import { Column, Row } from '../Grid'
import { asset_manager_theme } from '../../../assets/themes'
import {
  deleteComponentFile,
  downloadEntryFile,
  editFileTags,
  RolodexMoveFile,
  uploadComponentFile,
  useGetComponentFileData,
} from '../../../util/RolodexInterface'
import { RolodexFileData } from '../../../util/DataSchema'
import { DownloadAndZipFiles } from '../../helpers/DownloadAndZipFiles'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import TagModal from './Modals/TagModal'
import { FileTags } from '../../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { Pill } from '../Pill'
import { ImagePreviewModal } from './Modals/ImagePreviewModal'
import { PdfPreviewModal } from './Modals/PdfPreviewModal'
import { TreeNode } from './Types/FileTreeTypes'
import { UploadModal } from './Modals/UploadModal'
import { DeleteModal } from './Modals/DeleteModal'
import {
  addPrefix,
  extractFolderName,
  extractParentDirectory,
  generateUniqueFilename,
  removePrefix,
  replaceFolderName,
  sanitizeFileName,
  sanitizeFolderName,
} from './Helpers/FilePathFormatterHelpers'
import {
  StyledCheckbox,
  StyledDirectoryComponents,
  StyledInspectionPacketGenerationNode,
  StyledNode,
} from './StyledComponents/StyledDirectoryComponents'
import { StyledTreeContainer } from './StyledComponents/StyledTreeContainer'
import { CreateDirectoryModal } from './Modals/CreateDirectoryModal'
import { useRolodexEntries } from '../../../api/useRolodex'
import { toTitleCase } from '../../helpers/stringFilters'
import {
  getAllFolders,
  getNode,
  transformToTreeData,
  traverseNodes,
  traverseNodesGetAllDescendants,
} from './Helpers/FileTreeHelpers'
import { RenameModal } from './Modals/RenameModal'
import { SWRResponse } from 'swr'
import { moveFileTags } from './Helpers/FileOperationHelpers'
import { treeViewClasses } from '@mui/x-tree-view'
import { trackEvent } from '../../helpers/mixpanel'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { TabKey } from '../../../pages/AppLayout'
import { checkUserFCUPermission } from '../../helpers/authHelpers'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'

interface FileBrowserProps {
  open: boolean
  onCancel: () => void
  fdeCustomerUnitSlug: string | undefined
  assetName: string | undefined
  dataRefetch?: () => void
  isInspectionPacketGenerationMode?: boolean
}

export const SUPPORTED_IMAGE_TYPES = new Set(['jpg', 'jpeg', 'png', 'gif'])
const SUPPORTED_PDF_TYPES = new Set(['pdf'])
const SUPPORTED_FILE_TYPES = new Set([...SUPPORTED_IMAGE_TYPES, ...SUPPORTED_PDF_TYPES])

export const FileBrowser = ({
  open,
  onCancel,
  fdeCustomerUnitSlug,
  assetName,
  dataRefetch,
  isInspectionPacketGenerationMode,
}: FileBrowserProps) => {
  const siteData = useContext(SiteContext)
  // States related to Tree Data
  const [treeData, setTreeData] = useState<TreeNode[]>([])
  const [selectedNode, setSelectedNode] = useState<string | null>(null)

  // States related to Drag
  const [draggingNodeKey, setDraggingNodeKey] = useState<string | null>(null)
  const [isDragOverRootDirectory, setIsDragOverRootDirectory] = useState(false)
  const [isDragOverFolder, setIsDragOverFolder] = useState<Record<string, boolean>>({})

  // States related to Upload Modal
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false)
  const [selectedUploadPath, setSelectedUploadPath] = useState<string>('')
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)

  // States related to PDF Preview
  const [pdfData, setPdfData] = useState<string | null>(null)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  // States related to Image Preview
  const [imageData, setImageData] = useState<string | null>(null)

  // States related to Tag Modal
  const [fileTags, setFileTags] = useState<FileTags | undefined>(undefined)
  const [tagModalVisible, setTagModalVisible] = useState(false)

  // State related to Asset Image Tag
  const [hasAssetImageTag, setHasAssetImageTag] = useState<boolean>(false)
  const [hasAssetLocationImageTag, setHasAssetLocationImageTag] = useState<boolean>(false)

  // States related to Delete Confirm Modal
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false)

  // States related to new directory
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false)
  const [currentParentKey, setCurrentParentKey] = useState<string | null>(null)
  const [newFolderName, setNewFolderName] = useState('')

  // States related to rename
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false)
  const [newName, setNewName] = useState('')

  // Add this to your state declarations
  const [fileOperationInProgress, setFileOperationInProgress] = useState(false)

  // Inspection Packet Generation States
  const [checkedFiles, setCheckedFiles] = useState<string[]>([])
  const [isTagCheck, setIsTagCheck] = useState<boolean>(false)
  const [isFirstRun, setIsFirstRun] = useState(true)

  // Lockout Featureflag
  const userFeatureFlags = useContext(FeatureFlagContext)

  // Fetch tags matching the given filePath from assetDatabaseTableRow
  function getTagsForFilePath(filePath: string) {
    if (fileTags) {
      const tagsArray = Object.entries(fileTags) as [string, string[]][]

      const matchingTags = tagsArray
        .filter(([tag, paths]) => paths.includes(`asset_manager_file_system/${filePath}`))
        .map(([tag]) => tag)

      return matchingTags
    }
    return [] // return an empty array if assetDatabaseTableRow or file_tags is null or undefined
  }

  const getAllTaggedFiles = (treeData: TreeNode[]) => {
    const taggedFiles: string[] = []

    function traverseTree(treeNodes: TreeNode[]) {
      treeNodes.forEach((node) => {
        if (node.isLeaf && getTagsForFilePath(node.key).length > 0) {
          taggedFiles.push(node.key)
        }

        if (node.children) {
          traverseTree(node.children)
        }
      })
    }

    traverseTree(treeData)

    return taggedFiles
  }

  // function to check if a specified key exists with a non-empty array
  function hasNonEmptyKey(tags: FileTags, key: string): boolean {
    return tags && key in tags && tags[key].length > 0
  }

  const authInfo = useAuthInfo()

  const FDECustomerUnitDataBody = {
    type: RolodexEntryType.CUSTOMER_UNIT,
    limit: 1,
    ids: [fdeCustomerUnitSlug],
  }
  const fdeCustomerUnitSWR = useRolodexEntries(
    FDECustomerUnitDataBody,
    undefined,
    !!fdeCustomerUnitSlug
  )
  const lockOutEnabled = useMemo(() => {
    return (
      userFeatureFlags?.[TabKey.assetsDB]?.read_only ??
      !checkUserFCUPermission(
        fdeCustomerUnitSWR.data?.[0].entry.data ?? {},
        userFeatureFlags?.fcuAccessibleFields ?? {}
      )
    )
  }, [userFeatureFlags, fdeCustomerUnitSWR.data])

  const fileDataSwr = useGetComponentFileData(fdeCustomerUnitSlug || '')

  useEffect(() => {
    const data = fileDataSwr.data
    const error = fileDataSwr.error
    if (!error && data) {
      setTreeData(
        transformToTreeData(
          removePrefix(data.map((object: RolodexFileData) => object.path)) || []
        )
      )
    }
  }, [fileDataSwr.data, fdeCustomerUnitSlug, open])

  useEffect(() => {
    setIsFirstRun(true)
  }, [open, fdeCustomerUnitSlug])

  useEffect(() => {
    // only call setCheckedFiles on the first run
    if (isFirstRun && fdeCustomerUnitSlug) {
      // As treeData is getting updated asynchronously,
      // if you try to access its value within the same useEffect but in an
      // expression after `setTreeData`, it will give you the previous value.
      // Hence you should call the function generating the checked files directly.
      setCheckedFiles(getAllTaggedFiles(treeData))

      // afterwards, set isFirstRun to false so that it this block doesn't run again
      setIsFirstRun(false)
    }
  }, [treeData, isFirstRun])

  useEffect(() => {
    // Reset page number and numPages when pdfData changes
    setNumPages(null)
    setPageNumber(1)
  }, [pdfData])

  useEffect(() => {
    if (fdeCustomerUnitSWR.data) {
      const data = fdeCustomerUnitSWR.data
      const error = fdeCustomerUnitSWR.error
      if (!error && data) {
        setFileTags(data[0]?.entry.data?.file_tags)
        if (hasNonEmptyKey(data[0].entry.data?.file_tags, 'asset_image')) {
          setHasAssetImageTag(true)
        } else {
          setHasAssetImageTag(false)
        }
        if (hasNonEmptyKey(data[0].entry.data?.file_tags, 'location_image')) {
          setHasAssetLocationImageTag(true)
        } else {
          setHasAssetLocationImageTag(false)
        }
      }
    }
  }, [fdeCustomerUnitSWR.data])

  if (!fdeCustomerUnitSlug || !treeData) {
    return <> </>
  }

  // Checkbox click handler
  const onCheckboxClick = (node: TreeNode) => {
    if (node && node.isLeaf) {
      if (getTagsForFilePath(node.key).length === 0) {
        // If no tags found, open TagModal
        setIsTagCheck(true)
        setTagModalVisible(true)
      } else {
        // If it's already checked, uncheck it (remove it from checkedFiles); otherwise, check it (add it to checkedFiles)
        if (checkedFiles.includes(node.key)) {
          setCheckedFiles(checkedFiles.filter((file) => file !== node.key))
        } else {
          setCheckedFiles([...checkedFiles, node.key])
        }
      }
    }
  }

  function onSelect(_: React.Key[], info: any) {
    setSelectedNode(info.node.key)
  }

  function onDownload() {
    if (selectedNode && fdeCustomerUnitSlug) {
      trackEvent('Button Clicked', {
        buttonName: 'download_file',
        assetDetails: {
          assetName: assetName,
        },
        fileDetails: {
          filePath: selectedNode,
        },
      })
      const filePaths: string[] = []
      const selectedTreeDataNode = getNode(selectedNode, treeData ? treeData : [])
      if (selectedTreeDataNode) traverseNodes(selectedTreeDataNode, filePaths)

      // Pass the array of file paths to the downloadFile endpoint
      DownloadAndZipFiles(
        addPrefix(filePaths),
        fdeCustomerUnitSlug,
        assetName + ' ' + selectedNode + '.zip',
        authInfo,
        true
      )
    }
  }

  async function onDelete() {
    if (selectedNode && fdeCustomerUnitSlug) {
      trackEvent('Button Clicked', {
        buttonName: 'delete_file',
        assetDetails: {
          assetName: assetName,
        },
        fileDetails: {
          filePath: selectedNode,
        },
      })
      setFileOperationInProgress(true)

      const filePaths: string[] = []
      const selectedTreeDataNode = getNode(selectedNode, treeData ? treeData : [])
      if (selectedTreeDataNode) traverseNodes(selectedTreeDataNode, filePaths)

      // Reverse the file paths to start deleting from innermost files
      const reversedFilePaths = filePaths.reverse()

      // Pass each file path to the deleteComponentFile endpoint
      for (const filePath of reversedFilePaths) {
        // Wait for deleteComponentFile to complete
        await deleteComponentFile(fdeCustomerUnitSlug, addPrefix(filePath), authInfo)

        // Then perform the rest of the operations
        await editFileTags(
          authInfo,
          fdeCustomerUnitSlug,
          addPrefix(filePath),
          [],
          siteData
        )
        fileDataSwr.mutate()
        fdeCustomerUnitSWR.mutate()
        dataRefetch?.()
      }

      setFileOperationInProgress(false)
    }
  }

  function onUpload(
    e?: React.ChangeEvent<HTMLInputElement>,
    fileList?: FileList,
    modalPath?: string
  ) {
    const files = fileList || e?.target.files
    const path = modalPath !== undefined ? modalPath : selectedNode || ''

    if (files && fdeCustomerUnitSlug) {
      setFileOperationInProgress(true)

      const numFiles = files.length
      let numUploaded = 0

      Array.from(files).forEach(async (file) => {
        const sanitizedFileName = await sanitizeFileName(file.name, path, treeData || [])
        if (sanitizedFileName) {
          // Now upload the file with the adjusted name
          const toPath = addPrefix((path ? path + '/' : '') + sanitizedFileName)
          await uploadComponentFile(authInfo, fdeCustomerUnitSlug, file, toPath)
          numUploaded++
          trackEvent('Button Clicked', {
            buttonName: 'upload_file',
            assetDetails: {
              assetName: assetName,
            },
            fileDetails: {
              filePath: toPath,
            },
          })

          if (numUploaded === numFiles) {
            // Check if all files have been handled
            setFileOperationInProgress(false)
          }
          fileDataSwr.mutate()
          dataRefetch?.()
        }
      })
    }
  }
  const handlePreview = async (filePath: string) => {
    trackEvent('Button Clicked', {
      buttonName: 'preview_file',
      assetDetails: {
        assetName: assetName,
      },
      fileDetails: {
        filePath: filePath,
      },
    })

    const fileType = filePath.split('.').pop()?.toLowerCase()

    if (SUPPORTED_PDF_TYPES.has(fileType || '')) {
      // Fetch the URL
      const fileURL = await downloadEntryFile(
        fdeCustomerUnitSlug,
        addPrefix(filePath),
        authInfo
      )

      const response = await fetch(fileURL, { headers: authInfo.headers })
      const data = await response.blob()

      setPdfData(URL.createObjectURL(data))
    } else if (SUPPORTED_IMAGE_TYPES.has(fileType || '')) {
      const fileURL = await downloadEntryFile(
        fdeCustomerUnitSlug,
        addPrefix(filePath),
        authInfo
      )

      // Fetch the actual file as Blob
      const response = await fetch(fileURL, { headers: authInfo.headers })
      const blob = await response.blob()

      // Create a Blob URL for the blob
      const blobURL = URL.createObjectURL(blob)

      // Set the blob URL to imageData state
      setImageData(blobURL)
    }
  }

  const isFolder = (node: TreeDataNode) => {
    return node.isLeaf !== true
  }

  const isLeaf = (node: DataNode): boolean => Boolean(node.isLeaf)

  // Function to handle antd drag and drop
  async function onDrop(info: any) {
    if (lockOutEnabled) {
      // CheckLockout
      return
    }
    const srcKey = info.dragNode.key
    const targetKey = info.node.key

    trackEvent('Button Clicked', {
      buttonName: 'move_file',
      assetDetails: {
        assetName: assetName,
      },
      fileDetails: {
        fromFilePath: srcKey,
        toFilePath: targetKey,
      },
    })

    const fileName = srcKey.split('/').pop()
    const uniqueFileName = await sanitizeFileName(fileName, targetKey, treeData || [])
    const targetPath = `${targetKey}/${uniqueFileName}`

    if (isFolder(info.node) && fdeCustomerUnitSlug) {
      setFileOperationInProgress(true)

      await RolodexMoveFile(
        authInfo,
        fdeCustomerUnitSlug,
        addPrefix(targetPath),
        addPrefix(srcKey),
        'application/json'
      )

      await moveFileTags(
        addPrefix(srcKey),
        addPrefix(targetPath),
        fdeCustomerUnitSlug,
        authInfo,
        siteData
      )

      fileDataSwr.mutate()
      fdeCustomerUnitSWR.mutate()
      dataRefetch?.()

      setFileOperationInProgress(false)
    }
  }

  async function moveFileToFolder(srcKey: string, dropKey?: string) {
    const fileName = srcKey.split('/').pop()
    if (!fileName) {
      message.error('File name not found')
      return
    }

    // If the file is being moved to the same folder, do nothing
    if (
      (dropKey && srcKey === `${dropKey}/${fileName}`) ||
      (dropKey === undefined && !srcKey.includes('/'))
    ) {
      return
    }

    const uniqueFileName = await sanitizeFileName(
      fileName,
      dropKey ? dropKey : '',
      treeData || []
    )
    const targetPath = dropKey ? `${dropKey}/${uniqueFileName}` : `${uniqueFileName}`

    trackEvent('Button Clicked', {
      buttonName: 'move_file',
      assetDetails: {
        assetName: assetName,
      },
      fileDetails: {
        fromFilePath: srcKey,
        toFilePath: targetPath,
      },
    })

    if (fileName && fdeCustomerUnitSlug && targetPath !== srcKey) {
      setFileOperationInProgress(true)

      await RolodexMoveFile(
        authInfo,
        fdeCustomerUnitSlug,
        addPrefix(targetPath), // Destination is root path
        addPrefix(srcKey),
        'application/json'
      )

      await moveFileTags(
        addPrefix(srcKey),
        addPrefix(targetPath),
        fdeCustomerUnitSlug,
        authInfo,
        siteData
      )

      fileDataSwr.mutate()
      fdeCustomerUnitSWR.mutate()
      dataRefetch?.()

      setFileOperationInProgress(false)
    }
  }

  function onDropToFolder(e: React.DragEvent<HTMLDivElement>, folderPath?: string) {
    e.preventDefault()
    if (lockOutEnabled) {
      // CheckLockout
      return
    }

    const files = e.dataTransfer.files

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      trackEvent('Button Clicked', {
        buttonName: 'upload_file',
        assetDetails: {
          assetName: assetName,
        },
        fileDetails: {
          filePath: file.name,
        },
      })
    }

    // Then use files here to upload each file
    onUpload(undefined, files, folderPath ? folderPath : '')
  }

  // Filter out only directories for Select dropdown in Upload modal
  const directories = getAllFolders(treeData)

  // Rename a file or folder, adjusting all descendant paths if it's a folder
  const renameFileOrFolder = async (
    componentSlug: string,
    oldPath: string,
    newPath: string
  ) => {
    if (oldPath === newPath) {
      return
    }

    const oldFolderPath = oldPath + '/'
    const newFolderName = extractFolderName(newPath)
    const parentDirectory = extractParentDirectory(oldPath)

    let sanitizedPath = newPath
    if (
      !getNode(oldPath, treeData)?.isLeaf &&
      newFolderName &&
      parentDirectory !== undefined
    ) {
      const sanitizedFolderName = await sanitizeFolderName(
        newFolderName,
        parentDirectory,
        treeData
      )
      const updatedPath = replaceFolderName(newPath, sanitizedFolderName)
      sanitizedPath = updatedPath ? updatedPath : newPath
    }

    // Fetch all descendants under the folder
    const descendants = traverseNodesGetAllDescendants(treeData, oldFolderPath)

    // Iterate through each descendant and rename it by replacing the old folder path with the new one in its key
    for (const descendant of descendants) {
      if (descendant.isLeaf) {
        const newKey = descendant.key.replace(oldFolderPath, sanitizedPath + '/')

        // Wait for RolodexMoveFile operation to complete
        await RolodexMoveFile(
          authInfo,
          componentSlug!,
          addPrefix(newKey),
          addPrefix(descendant.key),
          'application/json'
        )

        // Then wait for moveFileTags operation to complete
        await moveFileTags(
          addPrefix(descendant.key),
          addPrefix(newKey),
          fdeCustomerUnitSlug,
          authInfo,
          siteData
        )

        await fdeCustomerUnitSWR.mutate()

        trackEvent('Button Clicked', {
          buttonName: 'rename_file',
          assetDetails: {
            assetName: assetName,
          },
          fileDetails: {
            oldFilePath: addPrefix(descendant.key),
            newFilePath: addPrefix(newKey),
          },
        })
      }
      // After all operations are completed for the current descendant, re-fetch data
      await fileDataSwr.mutate()
      dataRefetch?.()
    }

    // Rename the file itself
    if (getNode(oldPath, treeData)?.isLeaf) {
      const sanitizedFileName = await sanitizeFileName(
        newFolderName || '',
        parentDirectory || '',
        treeData
      )
      const newFilePath = addPrefix(
        (parentDirectory ? parentDirectory + '/' : '') + sanitizedFileName
      )
      await RolodexMoveFile(
        authInfo,
        componentSlug!,
        newFilePath,
        addPrefix(oldPath),
        'application/json'
      ).then(() => {
        moveFileTags(
          addPrefix(oldPath),
          addPrefix(newPath),
          fdeCustomerUnitSlug,
          authInfo,
          siteData
        ).then(() => {
          fdeCustomerUnitSWR.mutate()
          dataRefetch?.()
        })
      })

      trackEvent('Button Clicked', {
        buttonName: 'rename_file',
        assetDetails: {
          assetName: assetName,
        },
        fileDetails: {
          oldFilePath: addPrefix(oldPath),
          newFilePath: addPrefix(newPath),
        },
      })
    }
  }
  const onRename = () => {
    if (selectedNode && fdeCustomerUnitSlug) {
      setFileOperationInProgress(true)
      const newPath = selectedNode.split('/').slice(0, -1).concat([newName]).join('/')

      renameFileOrFolder(fdeCustomerUnitSlug, selectedNode, newPath).then(() => {
        fileDataSwr.mutate() // Trigger SWR revalidation to fetch updated data
        dataRefetch?.()
        setFileOperationInProgress(false)
      })

      // Close the modal and reset new name
      setIsRenameModalVisible(false)
      setNewName('')
    }
  }

  // To download selected files
  const onGenerateInspectionPacket = () => {
    if (checkedFiles.length > 0) {
      // download selected files
      DownloadAndZipFiles(
        checkedFiles.map((filePath) => addPrefix(filePath)),
        fdeCustomerUnitSlug,
        `${assetName}_Inspection_Packet.zip`,
        authInfo,
        true
      )

      for (let i = 0; i < checkedFiles.length; i++) {
        trackEvent('Button Clicked', {
          buttonName: 'generate_inspection_packet',
          assetDetails: {
            assetName: assetName,
          },
          fileDetails: {
            filePath: checkedFiles[i],
          },
        })
      }
    } else {
      message.error('No files have been selected for download')
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => {
        setSelectedNode(null)
        setTreeData([])
        onCancel()
      }}
      width={'50%'}
      footer={[
        // add "Generate Inspection Packet" button if `isInspectionPacketGenerationMode` is true
        isInspectionPacketGenerationMode && (
          <Button key='generate-packet' onClick={onGenerateInspectionPacket}>
            Generate Inspection Packet
          </Button>
        ),
      ]}>
      <Spin spinning={fileOperationInProgress}>
        <Row style={{ justifyContent: 'space-between', marginTop: '1.75rem' }}>
          <StyledDirectoryComponents
            onDrop={async (e) => {
              if (lockOutEnabled) {
                // CheckLockout
                return
              }
              e.preventDefault()
              // Take dropped node (file / folder) from your drag state
              const srcKey = draggingNodeKey
              if (srcKey) {
                await moveFileToFolder(srcKey)
                setDraggingNodeKey('')
              } else if (e.dataTransfer.files.length > 0) {
                onDropToFolder(e)
              }
              setIsDragOverRootDirectory(false)
            }}
            onDragOver={(e) => {
              if (lockOutEnabled) {
                // CheckLockout
                return
              }
              e.preventDefault()
              setIsDragOverRootDirectory(true) // Set isDragOver to true on dragover
            }}
            onDragLeave={() => {
              if (lockOutEnabled) {
                // CheckLockout
                return
              }
              setIsDragOverRootDirectory(false)
            }}
            isDragOver={isDragOverRootDirectory}>
            {assetName}
          </StyledDirectoryComponents>
          {!isInspectionPacketGenerationMode && (
            <div>
              <Button
                disabled={lockOutEnabled} // CheckLockout
                onClick={(e) => {
                  setCurrentParentKey('') // Set current folder key
                  setIsCreateFolderModalVisible(true) // Open folder creation Modal
                }}
                icon={<FolderAddOutlined />}>
                Create Directory
              </Button>
              <Button
                disabled={lockOutEnabled} // CheckLockout
                onClick={() => setUploadModalVisible(true)}
                icon={<PlusOutlined />}
                style={{ marginLeft: '0.5rem' }}
                type='primary'>
                Upload File(s)
              </Button>
            </div>
          )}
        </Row>
        <Row style={{ minWidth: '90%' }}>
          <StyledTreeContainer>
            {treeData.length > 0 ? (
              <DirectoryTree
                treeData={treeData}
                onSelect={onSelect}
                draggable={{
                  nodeDraggable: isLeaf,
                  icon: false,
                }}
                onDrop={onDrop}
                allowDrop={({ dropNode }) => !dropNode.isLeaf}
                style={{ minWidth: '100%' }}
                onDragStart={({ node }) => setDraggingNodeKey(node.key)}
                titleRender={(nodeData) => {
                  const matchingTags = getTagsForFilePath(nodeData.key)
                  if (isInspectionPacketGenerationMode) {
                    return (
                      <StyledInspectionPacketGenerationNode>
                        <span>{nodeData.title}</span>
                        {matchingTags.map((tag) => (
                          <Pill text={toTitleCase(tag?.replace(/_/g, ' ') || '')}>
                            {toTitleCase(tag?.replace(/_/g, ' ') || '')}
                          </Pill>
                        ))}
                        {nodeData.isLeaf && (
                          <StyledCheckbox
                            onClick={() => onCheckboxClick(nodeData)}
                            checked={checkedFiles.includes(nodeData.key)}
                          />
                        )}
                      </StyledInspectionPacketGenerationNode>
                    )
                  } else {
                    return (
                      <StyledNode
                        onDrop={async (e) => {
                          if (!nodeData.isLeaf) {
                            e.preventDefault()
                            if (draggingNodeKey) {
                              await moveFileToFolder(draggingNodeKey, nodeData.key)
                              setDraggingNodeKey('')
                            } else if (e.dataTransfer.files.length > 0) {
                              onDropToFolder(e, nodeData.key)
                            }
                            setIsDragOverFolder((prev) => ({
                              ...prev,
                              [nodeData.key]: false,
                            }))
                          }
                        }}
                        onDoubleClick={() => {
                          return nodeData && handlePreview(nodeData.key)
                        }}
                        onDragOver={(e) => {
                          if (!nodeData.isLeaf) {
                            e.preventDefault()
                            setIsDragOverFolder((prev) => ({
                              ...prev,
                              [nodeData.key]: true,
                            }))
                          }
                        }}
                        onDragLeave={() => {
                          if (!nodeData.isLeaf) {
                            setIsDragOverFolder((prev) => ({
                              ...prev,
                              [nodeData.key]: false,
                            }))
                          }
                        }}
                        isDragOver={isDragOverFolder[nodeData.key]}
                        isFolder={!nodeData.isLeaf}>
                        <span>{nodeData.title}</span>
                        {matchingTags.map((tag) => (
                          <Pill text={toTitleCase(tag?.replace(/_/g, ' ') || '')}>
                            {toTitleCase(tag?.replace(/_/g, ' ') || '')}
                          </Pill>
                        ))}
                      </StyledNode>
                    )
                  }
                }}
              />
            ) : (
              <Row style={{ marginTop: '5rem' }}>
                <Column>
                  <AncientGeckoStars
                    style={{
                      margin: 'auto',
                      width: '50%',
                      opacity: '90%',
                    }}></AncientGeckoStars>
                  <h2
                    style={{
                      margin: '2rem',
                      color: 'white',
                      textAlign: 'center',
                      opacity: '50%',
                    }}>
                    No Files
                  </h2>
                </Column>
              </Row>
            )}
          </StyledTreeContainer>
        </Row>
        <UploadModal
          uploadModalVisible={uploadModalVisible}
          setUploadModalVisible={setUploadModalVisible}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          selectedUploadPath={selectedUploadPath}
          setSelectedUploadPath={setSelectedUploadPath}
          directories={directories}
          onUpload={onUpload}
          assetName={assetName}
        />
        <CreateDirectoryModal
          isCreateFolderModalVisible={isCreateFolderModalVisible}
          setNewFolderName={setNewFolderName}
          currentParentKey={currentParentKey}
          setCurrentParentKey={setCurrentParentKey}
          setIsCreateFolderModalVisible={setIsCreateFolderModalVisible}
          newFolderName={newFolderName}
          setTreeData={setTreeData}
          treeData={treeData}
        />

        {selectedNode && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0.5rem 0 1rem',
              }}>
              <hr
                style={{
                  width: '100%',
                  borderColor: asset_manager_theme.background.background_color7,
                }}
              />
            </div>

            <Row>
              <Column>
                <h3>{selectedNode}</h3>
              </Column>
              <Column style={{ alignItems: 'flex-end' }}>
                <Row style={{ gap: '0.5rem' }}>
                  {getNode(selectedNode, treeData)?.isLeaf && (
                    <>
                      <Tooltip title='Tag file'>
                        <Button
                          icon={<TagOutlined />}
                          onClick={() => setTagModalVisible(true)}
                        />
                      </Tooltip>
                      <Tooltip title='Preview file'>
                        <Button
                          icon={<EyeOutlined />}
                          onClick={() => handlePreview(selectedNode)}
                          disabled={
                            !selectedNode ||
                            !SUPPORTED_FILE_TYPES.has(
                              selectedNode.split('.').pop()?.toLowerCase() || ''
                            )
                          }
                        />
                      </Tooltip>
                      <TagModal
                        visible={tagModalVisible}
                        filePath={addPrefix(selectedNode)}
                        componentSlug={fdeCustomerUnitSlug}
                        tags={getTagsForFilePath(selectedNode)}
                        onCancel={() => setTagModalVisible(false)}
                        onEditSuccess={() => {
                          fileDataSwr.mutate()
                          fdeCustomerUnitSWR.mutate()
                          dataRefetch?.()
                          setIsTagCheck(false)
                        }}
                        isTagCheck={isTagCheck}
                        checkedFiles={checkedFiles}
                        setCheckedFiles={setCheckedFiles}
                        hasAssetImageTag={hasAssetImageTag}
                        hasAssetLocationImageTag={hasAssetLocationImageTag}
                      />
                    </>
                  )}
                  {!isInspectionPacketGenerationMode && (
                    <>
                      {' '}
                      <Tooltip title='Rename'>
                        <Button
                          disabled={lockOutEnabled} // CheckLockout
                          icon={<EditOutlined />}
                          onClick={() => {
                            setNewName(selectedNode.split('/').pop() || '')
                            setIsRenameModalVisible(true)
                          }}
                        />
                      </Tooltip>
                      {!getNode(selectedNode, treeData)?.isLeaf && (
                        <>
                          <Tooltip title='Upload File(s)'>
                            <Button
                              disabled={lockOutEnabled} // CheckLockout
                              onClick={() => {
                                document.getElementById('fileUpload')?.click()
                              }}
                              icon={<PlusOutlined />}
                            />
                          </Tooltip>
                          <Input
                            type='file'
                            id='fileUpload'
                            multiple
                            style={{ display: 'none' }}
                            onChange={onUpload}
                          />
                        </>
                      )}
                      {!getNode(selectedNode, treeData)?.isLeaf && (
                        <>
                          <Tooltip title='Create folder'>
                            <Button
                              disabled={lockOutEnabled} // CheckLockout
                              onClick={() => {
                                getNode(selectedNode, treeData)
                                setCurrentParentKey(
                                  getNode(selectedNode, treeData)?.key || null
                                ) // Set current folder key
                                setIsCreateFolderModalVisible(true) // Open folder creation Modal
                              }}
                              icon={<FolderAddOutlined />}
                            />
                          </Tooltip>
                        </>
                      )}
                      <Tooltip title='Download'>
                        <Button onClick={onDownload} icon={<DownloadOutlined />} />
                      </Tooltip>
                      <Tooltip title='Delete'>
                        <Button
                          disabled={lockOutEnabled} // CheckLockout
                          onClick={() => {
                            setDeleteConfirmVisible(true)
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                      <DeleteModal
                        deleteConfirmVisible={deleteConfirmVisible}
                        onDelete={onDelete}
                        selectedNode={selectedNode}
                        getNode={getNode}
                        setDeleteConfirmVisible={setDeleteConfirmVisible}
                        treeData={treeData}
                      />
                    </>
                  )}
                </Row>
              </Column>
            </Row>
          </div>
        )}
        {pdfData && (
          <PdfPreviewModal
            pdfData={pdfData}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setNumPages={setNumPages}
            onClose={() => setPdfData(null)}
          />
        )}
        {/* Modal to display image preview */}
        {imageData && (
          <ImagePreviewModal imageData={imageData} onClose={() => setImageData(null)} />
        )}
        <RenameModal
          isRenameModalVisible={isRenameModalVisible}
          onRename={onRename}
          newName={newName}
          setNewName={setNewName}
          srcFilePath={selectedNode}
          onClose={() => setIsRenameModalVisible(false)}
        />
      </Spin>
    </Modal>
  )
}

export default FileBrowser
