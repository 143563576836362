/**
 * Create a Map by associating each object in an array with a single key.
 * If multiple objects have the same key, the last occurrence of the object will map to that key.
 * @param arr The array of objects to be mapped
 * @param key The key on each object which will index the Map
 * @returns Map<Value, T>
 */
export function associateBy<T extends object, Key extends keyof T>({
  arr,
  key,
}: {
  arr: T[]
  key: Key
}): Map<T[Key], T> {
  return arr.reduce((agg, item) => {
    agg.set(item[key], item)
    return agg
  }, new Map<T[Key], T>())
}
