import styled from 'styled-components'
import { asset_manager_theme } from '../assets/themes'
import { Row } from './Common/Grid'

export const FiguresContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 75%;
  padding-left: 0.5rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`

export const PlotContainer = styled.div`
  width: 48%;
  background-color: ${asset_manager_theme.background.background_color8};
  border-radius: 6px;
  height: fit-content;
  padding: 1.5rem;
`

export const PlotOrganizer = styled(Row)`
  justify-content: space-evenly;
  align-content: center;
`

export const ControlsContainer = styled.div`
  height: inherit;
  width: 10%;
  background-color: ${asset_manager_theme.background.background_color8_5};
  color: white;
  border: 0.15rem solid ${asset_manager_theme.background.background_color8_5};
  border-radius: 0.3rem;
  padding: 1.5rem;
`
