import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

export const EmptyStateBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  text-align: center;
`
export const MainHeading = styled.h1`
  font-size: 24px;
  margin: 0;
  color: ${asset_manager_theme.text.c1};
`

export const SubHeading = styled.p`
  font-size: 18px;
  margin: 0;
  color: ${asset_manager_theme.text.c1};
`
