import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { CaretDownIcon } from '@radix-ui/react-icons'
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import { toTitleCase } from './helpers/stringFilters'
import styled, { css } from 'styled-components'
import { ReactComponent as SearchIcon } from '../assets/u_search-plus.svg'
import {
  DropdownSearchMenuContext,
  DropdownSearchMenuItem,
  FilteredSearchContainer,
  StyledDropdownContent,
} from './SearchSortFilter'

import React from 'react'

import { debounce } from 'lodash'
import { GeckoTheme } from 'gecko-ui'

const DropDownContainer = styled.header`
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-bottom-width: 1px;
  border-color: #f3f4f6;
`

const StyledInput = styled.input`
  position: relative;
  border: 1px solid transparent;
  padding-left: 1.75rem;
  background-color: ${(props) => props.theme.colors.greyscale[800]};
  width: 100%;
  color: white;

  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.greyscale[500]};
    outline-offset: 2px;
  }
`
const SearchIconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.375rem;
  padding-right: 0.75rem;
  color: white;
`

const commonDropdownHeaderStyles = css`
  padding: 0.375rem 0.375rem 0.375rem 0.5rem;
  font-weight: bold;
  color: white;
  border-radius: 0.25rem;
  font-size: 1.5rem;
  line-height: 2rem;
  cursor: default;
  user-select: none;
  background-color: ${GeckoTheme.colors.greyscale[800]};
  // clamp this to one line
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DropdownHeader = styled.span`
  ${commonDropdownHeaderStyles}
`

const StyledDropdownTrigger = styled(DropdownMenu.Trigger)`
  ${commonDropdownHeaderStyles};
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.greyscale[500]};
  }

  &:focus-visible {
    outline: none;
    outline-offset: 2px;
  }

  & > svg {
    height: 22px;
    width: 22px;
  }

  &[data-state='open'] {
    border-color: ${(props) => props.theme.colors.highVis};
  }
`

export const IconSpacer = styled.div`
  width: 22px;
`

export const DropdownSearchContext = createContext<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
  open: false,
  setOpen: () => {
    // noop
  },
})

export const useDebouncedInput = (delay?: number) => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const debouncedResults = React.useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
      }, delay ?? 600),
    [delay]
  )

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  return { debouncedResults, searchTerm, setSearchTerm }
}

export default function DropdownSearch({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false)
  return (
    <DropdownSearchContext.Provider value={{ open, setOpen }}>
      <DropDownContainer>
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
          {children}
        </DropdownMenu.Root>
      </DropDownContainer>
    </DropdownSearchContext.Provider>
  )
}

function DropdownSearchButton({
  children,
  hideCaret,
}: {
  children: React.ReactNode
  hideCaret?: boolean
}) {
  return (
    <StyledDropdownTrigger>
      {children}
      {hideCaret ? <IconSpacer /> : <CaretDownIcon />}
    </StyledDropdownTrigger>
  )
}

DropdownSearch.Trigger = DropdownSearchButton

export function DropdownSearchMenu({ children }: { children: React.ReactNode }) {
  const { open, setOpen } = useContext(DropdownSearchContext)
  const firstItemRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const firstRender = useRef(true)

  const [searchText, setSearchText] = useState('')

  const { debouncedResults, searchTerm, setSearchTerm } = useDebouncedInput(300)

  useEffect(() => {
    setSearchText(searchTerm)
  }, [searchTerm])

  const controls = useAnimationControls()

  async function closeMenu() {
    await controls.start('closed')
    setSearchText('')
    setSearchTerm('')
    setOpen(false)
  }

  useEffect(() => {
    const handleOpen = async () => {
      if (open) {
        setSearchTerm('')
        await controls.start('open')
        inputRef.current?.focus()
      }
    }
    handleOpen()
  }, [controls, open, setSearchTerm])

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' || (e.key === 'ArrowUp' && firstRender?.current)) {
      firstItemRef?.current?.focus()
      firstRender.current = false
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <DropdownSearchMenuContext.Provider
      value={{
        closeMenu,
        firstItemRef,
        inputRef,
        debouncedResults,
        searchText,
      }}>
      <AnimatePresence>
        {open && (
          <DropdownMenu.Portal forceMount>
            <StyledDropdownContent align='start' asChild>
              <motion.div
                initial={false}
                animate={controls}
                exit='closed'
                variants={{
                  open: {
                    opacity: 1,
                    transition: { ease: 'easeOut', duration: 0.1 },
                  },
                  closed: {
                    opacity: 0,
                    transition: { ease: 'easeIn', duration: 0.2 },
                  },
                }}>
                {children}
              </motion.div>
            </StyledDropdownContent>
          </DropdownMenu.Portal>
        )}
      </AnimatePresence>
    </DropdownSearchMenuContext.Provider>
  )
}

DropdownSearch.Menu = DropdownSearchMenu

export function DropdownSearchLabel({ children }: { children: React.ReactNode }) {
  const formattedChildren =
    typeof children === 'string' ? toTitleCase(children) : children
  return <DropdownHeader>{formattedChildren}</DropdownHeader>
}

DropdownSearch.Label = DropdownSearchLabel

export function FilteredSearch<T>({
  listToFilter,
  itemGetter,
  itemSearchTerms,
  placeholder,
  onSelect,
}: {
  listToFilter: T[]
  itemGetter: (item: T) => string
  itemSearchTerms?: (item: T) => string[]
  placeholder?: string
  onSelect: (item?: T) => void
}) {
  const { inputRef, debouncedResults, searchText } = useContext(DropdownSearchMenuContext)
  const { open } = useContext(DropdownSearchContext)

  const filteredList = useMemo(() => {
    if (!searchText) return listToFilter
    const lowerCaseSearch = searchText.toLowerCase()
    return listToFilter.filter((item) => {
      const searchTerms = itemSearchTerms?.(item) ?? []
      searchTerms.concat(itemGetter(item))
      return searchTerms.some((term) => term.toLowerCase().includes(lowerCaseSearch))
    })
  }, [searchText, listToFilter, itemSearchTerms, itemGetter])

  return open ? (
    <FilteredSearchContainer>
      <div style={{ position: 'relative' }}>
        <StyledInput
          onChange={debouncedResults}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (
              e.key !== 'Escape' &&
              e.key !== 'Enter' &&
              e.key !== 'Tab' &&
              e.key !== 'ArrowDown' &&
              e.key !== 'ArrowUp'
            ) {
              e.stopPropagation()
            }
          }}
          ref={inputRef}
        />
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
      </div>
      <List
        className='List'
        // if the list is short, we don't need to expand the dropdown all the way
        height={filteredList?.length < 10 ? filteredList?.length * 50 : 315}
        itemCount={filteredList?.length}
        itemSize={45}
        width={500}>
        {({ index, style }) => (
          <div style={style}>
            <DropdownSearchMenuItem
              key={index}
              isFirstItem={index === 0}
              onSelect={() => onSelect(filteredList[index])}>
              {toTitleCase(itemGetter(filteredList[index]))}
            </DropdownSearchMenuItem>
          </div>
        )}
      </List>
    </FilteredSearchContainer>
  ) : null
}

DropdownSearch.FilteredSearch = FilteredSearch

DropdownSearch.MenuItem = DropdownSearchMenuItem
