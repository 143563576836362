import React, { useEffect, useContext, useState } from 'react'
import { Input as InputString } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useDebounce } from 'react-use'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import Creatable from 'react-select/creatable'
import { colorStyles } from './SelectColorStyles'

interface EditableCreatableElementProps {
  value: string | undefined
  objectId: string
  field: string
  refetchData?: () => void
  rolodexType: string
  key?: string
  disabled?: boolean
  options: { label: string; value: string }[]
}

export const EditableCreatableElement: React.FC<EditableCreatableElementProps> = ({
  value,
  objectId,
  field,
  refetchData,
  rolodexType,
  key,
  disabled,
  options,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<string | undefined>(value)

  useEffect(() => {
    setEditedValue(value)
  }, [value])

  useDebounce(
    () => {
      if (editedValue !== value && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: objectId,
          type: rolodexType,
          data: {
            [field]: editedValue ?? null,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    500,
    [editedValue]
  )

  return (
    <Creatable
      isClearable
      onChange={(newValue: any) => {
        setEditedValue(newValue?.value)
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'} //this is a hack to fix the issue with the menu showing up at a lower z index
      value={{
        label: editedValue ?? '',
        value: editedValue ?? '',
      }}
      key={key ?? `${objectId}_${field}`}
      isDisabled={disabled}
      options={options}
      placeholder={'-'}
      styles={colorStyles}
    />
  )
}
