import React from 'react'

import { FallbackProps } from 'react-error-boundary'

import { GeckoTheme } from 'gecko-ui'

import styled from 'styled-components'
import { asset_manager_theme } from '../assets/themes'

const ErrorBoundaryStyled = styled.div`
  margin: 5rem auto;
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: center;
  background-color: ${GeckoTheme.colors.crimson['500_base']};
`

const ErrorBoundaryTextStyled = styled.h3`
  margin-top: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  font-family: ${asset_manager_theme.text.font_family2}};
  color: ${GeckoTheme.colors.gray['200_white']};
`

const ErrorBoundarySpacing = styled.div`
  margin-top: 1.5rem;
`

const ErrorButtonStyled = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-color: transparent;
  border-radius: 0.2rem;
  width: 10rem;
  height: 3rem;
`
//   color: ${GeckoTheme.colors.gray['200_white']};
//   background-color: ${GeckoTheme.colors.gecko_brand['600_base']};
//   &:hover {
//     background-color: ${GeckoTheme.colors.gecko_brand[700]};
//   }
//   &:focus {
//     background-color: ${GeckoTheme.colors.gecko_brand[500]};
//     outline: 0.125rem solid transparent;
//     outline-offset: 0.125rem;
//   }
// `

export const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <ErrorBoundaryStyled role='alert'>
    <ErrorBoundaryTextStyled>There was an error:</ErrorBoundaryTextStyled>
    <ErrorBoundaryTextStyled>{error?.message}</ErrorBoundaryTextStyled>
    <ErrorBoundarySpacing>
      <ErrorButtonStyled onClick={resetErrorBoundary}>Return Home</ErrorButtonStyled>
    </ErrorBoundarySpacing>
  </ErrorBoundaryStyled>
)
