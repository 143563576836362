import React from 'react'
import { DatePicker, Modal } from 'antd'
import dayjs from 'dayjs'
const RangePicker = DatePicker.RangePicker

export interface InspectionScheduleExportModalProps {
  open: boolean
  onClose: () => void
  dateRange?: [string, string]
  setDateRange?: (dateRange: [string, string]) => void
  onOkCallback?: () => void
}

export const InspectionScheduleExportModal: React.FC<
  InspectionScheduleExportModalProps
> = ({ open, onClose, setDateRange, onOkCallback, dateRange }) => {
  return (
    <Modal
      title='Select Outage Date Range'
      open={open}
      onCancel={() => onClose()}
      onOk={() => {
        if (onOkCallback) {
          onOkCallback()
        }
        onClose()
      }}>
      <RangePicker
        value={
          dateRange?.[0] && dateRange?.[1]
            ? [dayjs(dateRange[0]), dayjs(dateRange[1])]
            : undefined
        }
        onChange={(value) => {
          if (value?.[0] && value?.[1]) {
            setDateRange?.([value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')])
          }
        }}
      />
    </Modal>
  )
}
