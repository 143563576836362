import React, { createContext, useContext } from 'react'
import { SiteContext } from './siteContextProvider'
import { useParams } from 'react-router-dom'
import { useRolodexEntries } from '../api/useRolodex'
import { useAuthInfo } from './useAuthInfo'
import { LoadingContent } from '../components/LoadingPageComponent'
import { TabKey } from '../pages/AppLayout'

interface FeatureFlagContextProviderInterface {
  children: JSX.Element | JSX.Element[]
}

export type FeatureFlags =
  | {
      is_loading?: boolean
      fcuAccessibleFields?: {
        [key: string]: Set<string>
      }
      role: string
      trialEnd?: string
      [TabKey.timeline]?: Record<string, any>
      [TabKey.assetsDB]?: {
        read_only?: boolean
        hidden_form_data?: string[]
        accessible_columns: string[]
        enableAddAsset?: boolean
        enableAddInspection?: boolean
      }
      [TabKey.planModeling]?: {
        read_only?: boolean
        accessible_columns: string[]
        lockYAxis?: boolean
        enableBudgetPlugin?: boolean
      }
      [TabKey.overview]?: Record<string, any>
      [TabKey.recommendations]?: {
        read_only?: boolean
        life_extension?: boolean
        enablePreviewPlot?: boolean
      }
      [TabKey.inspectionScheduling]?: {
        read_only?: boolean
        defaultView: 'due' | 'scheduled' | 'history'
        defaultGraphView: 'counts' | 'hours'
        enableOutages?: boolean
        enableHours?: boolean
        accessible_columns: string[]
        inspectionGroupingColumns: string[]
        graphMonthWindow?: number
        defaultDateGranularity?: 'month' | 'year' | 'week'
      }
      [TabKey.RugBlitzPlanning]?: Record<string, any>
      [TabKey.assetCompare]?: Record<string, any>
      [TabKey.impact]?: Record<string, any>
      [TabKey.radar]?: {
        enableKPIs?: boolean
      }
      superUser?: boolean
      linkToAssetFix?: boolean
      defaultPage?: string
    }
  | undefined

export const FeatureFlagContext = createContext<FeatureFlags>(undefined)

export const FeatureFlagContextProvider: React.FC<
  FeatureFlagContextProviderInterface
> = ({ children }) => {
  const auth0Info = useAuthInfo()
  const siteData = useContext(SiteContext)
  let contextData: FeatureFlags = undefined
  const userFeatureFlags = useRolodexEntries(
    {
      type: 'asset-manager-feature-config',
      tags: { organization: siteData?.orgSlug, site: siteData?.siteSlug },
      limit: 1,
    },
    {},
    !!(siteData?.orgSlug && siteData?.siteSlug)
  )
  if (auth0Info.userRole === 'gecko') {
    const fetchedUserFeatureFlags =
      userFeatureFlags.data?.[0]?.entry?.data?.[auth0Info?.userRole ?? '']
    contextData = {
      superUser: !fetchedUserFeatureFlags,
      role: 'gecko',
      defaultPage: TabKey.overview,
      ...(fetchedUserFeatureFlags ?? {}),
    }
  } else {
    const fcuAccessibleFields = Object.keys(auth0Info.accessibleFCUFields ?? {}).reduce(
      (acc, cur) => {
        acc[cur] = new Set([...(auth0Info.accessibleFCUFields?.[cur] ?? [])])
        return acc
      },
      {} as { [key: string]: Set<string> }
    )
    contextData = {
      is_loading: userFeatureFlags.isLoading,
      role: auth0Info.userRole ?? '',
      fcuAccessibleFields: fcuAccessibleFields,
      ...(userFeatureFlags.data?.[0]?.entry?.data?.[auth0Info?.userRole ?? ''] ?? {}),
    }
  }

  return (
    <FeatureFlagContext.Provider value={contextData}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
