import { useEffect, useState, Suspense, FC, useContext } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import InvalidSiteComponent from '../components/invalidSiteComponent'
import { useAuth0 } from '@auth0/auth0-react'
import { ErrorFallback } from './ErrorFallback'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { PageLayout, useLayoutContext } from 'gecko-ui'
import { SiteContext, SiteContextProvider } from '../contextProviders/siteContextProvider'
import { FeatureFlagContextProvider } from '../contextProviders/featureFlagContextProvider'
import styled from 'styled-components'
import { asset_manager_theme } from '../assets/themes'
const { REACT_APP_ENVIRONMENT } = process.env
import mixpanel from '../mixpanel'
import RecommendationsTab from './RecommendationsTab/RecommendationsTab'
import AssetDatabaseTab from './AssetDatabaseTab/AssetDatabaseTab'
import { OverviewTab } from './OverviewTab/OverviewTab'
import { InspectionSchedulingTab } from './InspectionSchedulingTab/InspectionSchedulingTab'
import { AssetCompareTab } from './AssetCompareTab/AssetCompareTab'
import TimelineTab from './TimelineTab/TimelineTab'
import ImpactTab from './ImpactTab/ImpactTab'
import { PlanModelingTab } from './PlanModelingTab/PlanModelingTab'
import AppLayout, { TabKey } from './AppLayout'
import * as Sentry from '@sentry/react'
import RugBlitzPlanningTab from './RugBlitzPlanning/RugBlitzPlanningTab'
import ProtectedRoute from './ProtectedRoute'
import { RadarTab } from './RadarTab/RadarTab'
import { useSiteData } from './useSiteData'
import { AssetManagerTopBar } from './AssetManagerTopBar'

const ErrorBoundaryBox = styled.div`
  width: 100%;
`

export const GLOBAL_SEARCH_PARAMS = new Set(['unit'])

const LoggedInContent: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    user = { name: 'Unknown User' },
    logout,
    isLoading,
    isAuthenticated,
  } = useAuth0()
  const { darkMode } = useLayoutContext()
  const siteContext = useContext(SiteContext)
  const [siteName, setSiteName] = useState<string | undefined>(undefined)
  const [siteId, setSiteId] = useState<string | undefined>(undefined)

  const {
    organization,
    organizations,
    sites,
    site: siteEntry,
    sitesForOrg,
    isLoading: siteDataLoading,
  } = useSiteData({ siteID: siteId })

  useEffect(() => {
    mixpanel.identify(user.email)
    mixpanel.people.set({ $name: user.name, $email: user.email })
    mixpanel.track('Log In', { app: 'asset_manager', env: REACT_APP_ENVIRONMENT })
    Sentry.setUser({ email: user.email })
  }, [user])

  // Selected Fde Customer Unit Slug
  const [selectedFdeCustomerUnitSlug, setSelectedFdeCustomerUnitSlug] = useState<
    string | undefined
  >(undefined)

  // Effect to update state from URL when the component mounts or URL changes
  useEffect(() => {
    const unitFromURL = searchParams.get('unit')
    if (unitFromURL) {
      setSelectedFdeCustomerUnitSlug(unitFromURL)
    }
  }, [searchParams])

  // Effect to update URL when state changes
  useEffect(() => {
    const currentUnitParam = searchParams.get('unit')
    if (selectedFdeCustomerUnitSlug) {
      if (currentUnitParam !== selectedFdeCustomerUnitSlug) {
        setSearchParams({ unit: selectedFdeCustomerUnitSlug })
      }
    } else {
      if (currentUnitParam) {
        setSearchParams({})
      }
    }
  }, [selectedFdeCustomerUnitSlug])

  // Baseball Card Refetch
  const [assetBaseballCardRefetch, setAssetBaseballCardRefetch] = useState<
    //this is stupid but set state will try and invoke the function if directly passed in, need to pass it in as an object so it doens't get invoked on assignment
    { func: () => void } | undefined
  >()

  const [selectedTriageSidePanelFdeUnit, setSelectedTriageSidePanelFdeUnit] = useState<
    string | undefined
  >(undefined)

  return (
    <>
      <AssetManagerTopBar
        organization={organization}
        organizations={organizations}
        site={siteEntry}
        sites={sites}
      />
      <PageLayout.Content
        backgroundColor={asset_manager_theme.background.background_color8_5}>
        <ErrorBoundaryBox>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  path='/:siteId/*'
                  element={
                    <SiteContextProvider>
                      <FeatureFlagContextProvider>
                        <AppLayout
                          setSelectedFdeCustomerUnitSlug={setSelectedFdeCustomerUnitSlug}
                          selectedFdeCustomerUnitSlug={selectedFdeCustomerUnitSlug}
                          assetBaseballCardRefetch={assetBaseballCardRefetch}
                          setSelectedTriageSidePanelFdeUnit={
                            setSelectedTriageSidePanelFdeUnit
                          }
                          setSiteName={setSiteName}
                          setSiteId={setSiteId}
                        />
                      </FeatureFlagContextProvider>
                    </SiteContextProvider>
                  }>
                  <Route
                    path={`${TabKey.overview}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.overview}>
                        <OverviewTab
                          setFdeCustomerUnitSlug={setSelectedFdeCustomerUnitSlug}
                          setAssetBaseballCardRefetchData={setAssetBaseballCardRefetch}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.assetsDB}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.assetsDB}>
                        <AssetDatabaseTab
                          setFdeCustomerUnitSlug={setSelectedFdeCustomerUnitSlug}
                          setAssetBaseballCardRefetchData={setAssetBaseballCardRefetch}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.recommendations}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.recommendations}>
                        <RecommendationsTab
                          selectedFdeUnit={selectedTriageSidePanelFdeUnit}
                          setSelectedFdeUnit={setSelectedTriageSidePanelFdeUnit}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.planModeling}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.planModeling}>
                        <PlanModelingTab
                          setBaseballCardFdeCustomerUnitSlug={
                            setSelectedFdeCustomerUnitSlug
                          }
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.inspectionScheduling}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.inspectionScheduling}>
                        <InspectionSchedulingTab
                          setFdeCustomerUnitSlug={setSelectedFdeCustomerUnitSlug}
                          setAssetBaseballCardRefetchData={setAssetBaseballCardRefetch}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.RugBlitzPlanning}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.RugBlitzPlanning}>
                        <RugBlitzPlanningTab
                          setFdeCustomerUnitSlug={setSelectedFdeCustomerUnitSlug}
                          setAssetBaseballCardRefetchData={setAssetBaseballCardRefetch}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.assetCompare}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.assetCompare}>
                        <AssetCompareTab />{' '}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.timeline}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.timeline}>
                        <TimelineTab />{' '}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.impact}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.impact}>
                        <ImpactTab />{' '}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${TabKey.radar}`}
                    element={
                      <ProtectedRoute featureKey={TabKey.radar}>
                        <RadarTab
                          setBaseballCardFdeCustomerUnitSlug={
                            setSelectedFdeCustomerUnitSlug
                          }
                          setAssetBaseballCardRefetchData={setAssetBaseballCardRefetch}
                        />{' '}
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path='*' element={<InvalidSiteComponent />} />
                <Route path='unauthorized' element={<InvalidSiteComponent />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </ErrorBoundaryBox>
      </PageLayout.Content>
    </>
  )
}

export default LoggedInContent
