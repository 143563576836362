import React, { useState, useContext, useMemo, useCallback } from 'react'
import { InspectionSchedule } from '../../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import {
  Table,
  Modal,
  Dropdown,
  Space,
  MenuProps,
  message,
  Button as AntdButton,
  Tooltip,
  Typography,
  Form,
} from 'antd'
import {
  InspectionEntry,
  RawInspectionMetadata,
  MergedInspectionSchedule,
} from '../../../pages/InspectionSchedulingTab/InspectionSchedulingInterface'
import { rolodex_batch_delete } from '../../../api/useFDEBackend'
import { WarningFilled } from '@ant-design/icons'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { AuthInfo, useAuthInfo } from '../../../contextProviders/useAuthInfo'
import UploadButton from '../UploadButton'
import { Row } from '../Grid'
import { SWRResponse } from 'swr'
import { useRolodexEntries } from '../../../api/useRolodex'
import { dateSort } from '../../../api/tableHelpers'
import dayjs, { Dayjs } from 'dayjs'
import InlineTile from './InlineTile'
import { asset_manager_theme } from '../../../assets/themes'
import { toTitleCase } from '../../helpers/stringFilters'
import StyledDatePicker from '../StyledDatePicker'
import { FlattenedAssetData } from './AssetBaseballCard'
import { RolodexPatchBatch, uploadComponentFile } from '../../../util/RolodexInterface'
import { scheduleInspection } from '../../helpers/InspectionSchedulingHelpers'
import { toBackendPath } from '../../helpers/toBackendPath'
import { trackEvent } from '../../helpers/mixpanel'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { EditInterval } from './EditInterval'
import InspectionForm from './InspectionForm'
import HighVisButton from '../HighVisButton'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import Button from '../Button'
import { TabKey } from '../../../pages/AppLayout'
import { inspectionStatus } from '../../../pages/AssetDatabaseTab/AssetDatabaseTabInterface'
import { InspectionType, isInspectionType } from '../../../util/types/enumTypes'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
const { Text } = Typography

interface AssetBaseBallCardInspectionTableProps {
  fdeCustomerUnitSlug: string
  refetchData?: () => void
  flattenedAssetData: FlattenedAssetData
  originPage: string
}

export interface InspectionWithIncomplete extends MergedInspectionSchedule {
  incompleteInspection?: MergedInspectionSchedule
}

const intervalMap = {
  external: 60,
  internal: 120,
}

function getRecordInspectionType(
  inspectionType: string | undefined
): InspectionType | undefined {
  const lowerInspectionType = inspectionType?.toLowerCase()

  if (isInspectionType(lowerInspectionType)) {
    return lowerInspectionType
  } else {
    console.warn(`Invalid inspection type: ${lowerInspectionType}`)
  }
  return undefined
}

const AssetBaseBallCardInspectionTable: React.FC<
  AssetBaseBallCardInspectionTableProps
> = ({ fdeCustomerUnitSlug, refetchData, flattenedAssetData, originPage }) => {
  //instantiating contexts
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const [messageApi, contextHolder] = message.useMessage()

  //Fetchers
  const rawInspectionDataSWR: SWRResponse<InspectionEntry[], any, any> =
    useRolodexEntries(
      {
        type: 'fde-am-inspection',
        limit: 1000,
        tags: {
          [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug,
        },
      },
      {},
      !!fdeCustomerUnitSlug
    )

  const [scheduleInspectionData, setScheduleInspectionData] = React.useState<
    | {
        inspectionType: InspectionType | undefined
        inspectionMethod: string | undefined
        nextInspectionDate: Dayjs | undefined
        inspectionObject: MergedInspectionSchedule | undefined
        operation:
          | 'schedule'
          | 'reschedule'
          | 'deadlineException'
          | 'onstreamException'
          | 'uploadExceptionForm'
          | 'markNA'
          | 'undoOnstream'
          | 'undoMarkNA'
      }
    | undefined
  >(undefined)

  const lockOutEnabled = useMemo(() => {
    return userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only ?? false
  }, [userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only])
  const [isInspectionModalOpen, setIsInspectionModalOpen] = useState(false)
  const [modalForm] = Form.useForm()

  const getLatestInspection = useCallback(
    (
      groupingString: string,
      groupedInspections?: Record<string, any>,
      groupedFCUInspections?: Record<string, any>
    ) => {
      //This function does a few things:
      //1. It reconciles dates in the fde_customer_unit with the inspection objects
      //2. Merges any incomplete inspections with the latest complete inspection so that we can display the incomplete inspection in the table
      //3. It returns the latest inspections
      const inspections = groupedInspections?.[groupingString]
      const fdeCustomerUnitInspections = groupedFCUInspections?.[groupingString]
      const [type, method] = groupingString.split('.')
      //assume inspections are sorted by date
      let latest_inspection = inspections?.[0]
      let incompleteInspection // starts as undefined
      if (inspections?.[0]?.completed === false) {
        //if the first inspection is incomplete, that's not the one we want
        incompleteInspection = inspections[0]
        latest_inspection = inspections[1]
      }
      //the incomplete inspection has been handled so we can now just grab the latest inspection and combined the two sets of data
      //prioritize the inspection object data over the fde-customer-unit data
      const combinedLatestInspection = {
        inspection_type: type,
        inspection_method: method,
        ...(fdeCustomerUnitInspections ?? {}),
        ...(latest_inspection ?? {}),
      }
      return {
        ...combinedLatestInspection,
        incompleteInspection,
      }
    },
    []
  )

  const inspectionMenuItems = (record: MergedInspectionSchedule): MenuProps['items'] => {
    const currentInspectionStatus =
      flattenedAssetData?.inspection_schedule?.[
        record?.inspection_type?.toLowerCase() as 'internal' | 'external'
      ]?.[record?.inspection_method]?.status
    const currentInspectionType = record?.inspection_type?.toLowerCase() as
      | 'internal'
      | 'external'
    return [
      currentInspectionType === 'internal'
        ? !(
            flattenedAssetData.onstream_exception ||
            record.status === inspectionStatus.ONSTREAM
          )
          ? {
              label: 'Mark Inspection as Onstream',
              key: 'Onstream',
            }
          : {
              label: 'Undo Mark Inspection as Onstream',
              key: 'undoOnstream',
            }
        : null,

      !(flattenedAssetData.onstream_exception && currentInspectionType === 'internal') //should no be able to mark NA if onstream and internal
        ? currentInspectionStatus === inspectionStatus.REQUIRED
          ? {
              label: 'Mark Inspection Method as N/A',
              key: 'notApp',
            }
          : currentInspectionStatus === inspectionStatus.NA
          ? {
              label: ' Undo Mark Inspection Method as N/A',
              key: 'undoNotApp',
            }
          : null
        : null,
    ].filter((d) => d)
  }

  const grouped_fde_customer_unit_inspections = useMemo(() => {
    /* 
    groups the customer units inspection by type an method to an object:
    {
      type.method: InspectionSchedule
    }
    */
    const inspection_schedule = flattenedAssetData?.inspection_schedule
    if (!inspection_schedule) {
      return {}
    }
    return Object.keys(inspection_schedule).reduce((acc, type) => {
      const methods = inspection_schedule?.[type as keyof InspectionSchedule]
      Object.keys(methods ?? {}).forEach((method) => {
        if (method && methods && methods[method]) {
          const formattedInspectionObject = {
            inspection_interval: methods[method].interval_months,
            next_deadline: methods[method].next,
            scheduled_date: methods[method].last,
            status: methods[method].status ?? inspectionStatus.REQUIRED, //default to required if another status hasn't been set
          }
          acc[`${type}.${method}`] = formattedInspectionObject
        }
      })
      return acc
    }, {} as Record<string, any>)
  }, [fdeCustomerUnitSlug, flattenedAssetData])

  //This gets us the inspections grouped by type
  const groupedInspectionData = useMemo(() => {
    return rawInspectionDataSWR.data
      ?.map((inspection) => ({
        ...inspection.entry.data,
        inspectionId: inspection.entry.id,
      }))
      ?.reduce((acc: Record<string, any>, inspection) => {
        const groupingString = `${inspection.inspection_type}.${inspection.inspection_method}`
        if (!acc[groupingString]) {
          acc[groupingString] = []
        }
        acc[groupingString].push(inspection)
        //ideally don't sort every time but don't wanna do another map after to sort these arrays
        acc[groupingString] = acc[groupingString]
          .sort(dateSort('scheduled_date'))
          .reverse()
        return acc
      }, {})
  }, [rawInspectionDataSWR.data])

  const inspectionData = useMemo(() => {
    //want to maintain the internal/external paradigm
    return {
      internal: _.uniq([
        ...Object.keys(groupedInspectionData ?? {}),
        ...Object.keys(grouped_fde_customer_unit_inspections ?? {}),
      ])
        .filter((d) => {
          const [type, _] = d.split('.')
          return type === 'internal'
        })
        .map((d) => {
          return getLatestInspection(
            d,
            groupedInspectionData,
            grouped_fde_customer_unit_inspections
          )
        }),
      external: _.uniq([
        ...Object.keys(groupedInspectionData ?? {}),
        ...Object.keys(grouped_fde_customer_unit_inspections ?? {}),
      ])
        .filter((d) => {
          const [type, _] = d.split('.')
          return type === 'external'
        })
        .map((d) => {
          return getLatestInspection(
            d,
            groupedInspectionData,
            grouped_fde_customer_unit_inspections
          )
        }),
    }
  }, [groupedInspectionData, grouped_fde_customer_unit_inspections])

  const inspectionColumnDefs = useMemo(() => {
    return [
      {
        title: 'Type',
        dataIndex: 'inspection_type',
        key: 'inspection_type',
      },
      {
        title: 'Method',
        dataIndex: 'inspection_method',
        key: 'inspection_method',
      },
      {
        title: 'Last',
        dataIndex: 'scheduled_date',
        key: 'scheduled_date',
        // if asset is onstream and inspection type is internal, display 'Onstream' instead of date
        render: (text: string, record: MergedInspectionSchedule) => {
          const currentInspectionStatus = record?.status
          switch (currentInspectionStatus) {
            case inspectionStatus.NA:
              return <Text>N/A</Text>
            case inspectionStatus.ONSTREAM:
              return (
                <Tooltip title={`Marked On-Stream on ${text}`}>
                  <div>On-Stream</div>
                </Tooltip>
              )
            case inspectionStatus.REQUIRED:
              return text
            default:
              return text
          }
        },
      },

      {
        title: 'Interval',
        dataIndex: 'inspection_interval',
        key: 'inspection_interval',
        // make interval editable on double click
        render: (interval: number, record: InspectionWithIncomplete) => {
          return (
            <EditInterval
              fdeCustomerUnitSlug={fdeCustomerUnitSlug}
              interval={interval}
              record={record}
              disabled={lockOutEnabled}
              refetchData={() => {
                refetchData?.()
                rawInspectionDataSWR.mutate()
              }}
              site={siteData?.siteSlug || ''}
              organization={siteData?.orgSlug || ''}
            />
          )
        },
      },
      {
        title: 'Deadline',
        dataIndex: 'next_deadline',
        key: 'next_deadline',

        render: (text: string, record: MergedInspectionSchedule) => {
          const currentInspectionStatus = record?.status
          const dateObject = dayjs(text)
          switch (currentInspectionStatus) {
            case inspectionStatus.NA:
              return <Text>N/A</Text>
            case inspectionStatus.ONSTREAM:
              return (
                <Tooltip title={`Marked On-Stream on ${text}`}>
                  <div>On-Stream</div>
                </Tooltip>
              )
            case inspectionStatus.REQUIRED:
              return dateObject.isValid() ? (
                <StyledDatePicker
                  disabled={lockOutEnabled}
                  suffixIcon={null}
                  value={dateObject}
                  allowClear={false}
                  onSelect={(value) => {
                    setScheduleInspectionData({
                      inspectionType: getRecordInspectionType(record.inspection_type),
                      inspectionMethod: record.inspection_method,
                      nextInspectionDate: value,
                      inspectionObject: record,
                      operation: 'deadlineException',
                    })
                  }}
                />
              ) : (
                text
              )
            default:
              return text
          }
        },
      },
      {
        title: 'Schedule Inspection',
        render: (text: string, record: InspectionWithIncomplete) => {
          return (
            <StyledDatePicker
              disabled={lockOutEnabled}
              highVis={true}
              allowClear={false}
              defaultPickerValue={
                !!record.incompleteInspection
                  ? dayjs(record.incompleteInspection?.scheduled_date)
                  : record.next_deadline &&
                    (record.inspection_type.toLowerCase() === 'external' ||
                      (!(
                        flattenedAssetData?.onstream_exception ||
                        record.status === inspectionStatus.ONSTREAM
                      ) &&
                        record.inspection_type.toLowerCase() === 'internal'))
                  ? dayjs(record.next_deadline).isValid()
                    ? dayjs(record.next_deadline)
                    : dayjs()
                  : dayjs()
              }
              suffixIcon={null}
              placeholder={record.incompleteInspection ? 'Reschedule' : 'Schedule'}
              value={
                record.incompleteInspection
                  ? dayjs(record.incompleteInspection?.scheduled_date) || null
                  : null
              }
              onSelect={(value) => {
                const inspectionType = getRecordInspectionType(record.inspection_type)
                if (!!record.incompleteInspection) {
                  //there is a scheduled inspection so we need to reschedule it rather than scheduling a new inspection
                  setScheduleInspectionData({
                    inspectionType: inspectionType,
                    nextInspectionDate: value,
                    inspectionMethod: record.incompleteInspection.inspection_method, //methods should be the same between the incomplete and the complete record but no need to risk it
                    inspectionObject: record.incompleteInspection,
                    operation: 'reschedule',
                  })
                } else {
                  setScheduleInspectionData({
                    inspectionType: inspectionType,
                    inspectionMethod: record.inspection_method, //methods should be the same between the incomplete and the complete record but no need to risk it
                    nextInspectionDate: value,
                    inspectionObject: record,
                    operation: 'schedule',
                  })
                }
              }}
            />
          )
        },
      },
      {
        render: (text: string, record: MergedInspectionSchedule) => {
          return (
            <Dropdown
              menu={{
                items: inspectionMenuItems(record),
                onClick: (event) => {
                  handleMenuClick(event, record)
                },
              }}
              disabled={lockOutEnabled}
              trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space
                  style={{
                    fontSize: '1.5rem',
                  }}>
                  ...
                </Space>
              </a>
            </Dropdown>
          )
        },
      },
    ]
  }, [flattenedAssetData, setScheduleInspectionData])

  const addInspectionButton = () => {
    return (
      (userFeatureFlags?.superUser ||
        userFeatureFlags?.[TabKey.assetsDB]?.enableAddInspection) && (
        <Button
          style={{ fontSize: '.85rem' }}
          onClick={() => {
            setIsInspectionModalOpen(!isInspectionModalOpen)
          }}>
          Add Inspection
        </Button>
      )
    )
  }

  const handleMenuClick = (
    event: Record<string, any>,
    record: MergedInspectionSchedule
  ) => {
    inspectionMenuPropsMap[event.key](record)
  }

  const inspectionMenuPropsMap: Record<
    string,
    (record: MergedInspectionSchedule) => void
  > = {
    // Options for Plan Recs
    Onstream: (record) => {
      setScheduleInspectionData({
        inspectionType: getRecordInspectionType(record.inspection_type),
        nextInspectionDate: dayjs(),
        inspectionMethod: record.inspection_method,
        inspectionObject: undefined,
        operation: 'onstreamException',
      })
    },
    undoOnstream: (record) => {
      setScheduleInspectionData({
        inspectionType: getRecordInspectionType(record.inspection_type),
        inspectionMethod: record.inspection_method,
        nextInspectionDate: undefined,
        inspectionObject: record,
        operation: 'undoOnstream',
      })
    },
    notApp: (record) => {
      setScheduleInspectionData({
        inspectionType: getRecordInspectionType(record.inspection_type),
        inspectionMethod: record.inspection_method,
        nextInspectionDate: dayjs(),
        inspectionObject: undefined,
        operation: 'markNA',
      })
    },
    undoNotApp: (record) => {
      setScheduleInspectionData({
        inspectionType: getRecordInspectionType(record.inspection_type),
        inspectionMethod: record.inspection_method,
        nextInspectionDate: dayjs(),
        inspectionObject: record,
        operation: 'undoMarkNA',
      })
    },
    None: () => {
      void 0
    },
  }

  const handleUploadFinally = () => {
    rawInspectionDataSWR.mutate()
    refetchData?.()
    //when modfiying file data force it to refetch the next time its opened
  }

  const handleOnstreamUploadSuccess = () => {
    // triggering re-render
    messageApi
      .open({
        type: 'success',
        content: `Successfully uploaded the Onstream inspection form. ${flattenedAssetData?.equip_description}'s internal inspection has been updated accordingly.`,
      })
      .then(() => {
        handleUploadFinally()
      }),
      setScheduleInspectionData(undefined)
  }

  const handleScheduleInspection = (
    inspectionType: InspectionType,
    nextInspectionDate: Dayjs,
    inspectionObject: MergedInspectionSchedule | undefined = undefined
  ) => {
    trackEvent('Button Clicked', {
      buttonName: 'schedule_inspection',
      pageName: originPage,
      subPageName: 'asset_baseball_card',
      assetDetails: {
        assetName: flattenedAssetData?.equip_description,
        date: nextInspectionDate.format('YYYY-MM-DD'),
        inspectionType: inspectionType,
      },
    })

    const scheduledInspectionObject: RawInspectionMetadata = {
      asset_name: flattenedAssetData?.equip_description || '',
      asset_tag: inspectionObject?.asset_tag ?? '',
      component_name: inspectionObject?.component_name ?? '',
      component_type: inspectionObject?.component_type,
      inspection_type: inspectionType,
      area: flattenedAssetData?.equip_area || '',
      inspection_method: inspectionObject?.inspection_method ?? '',
      outage_type: flattenedAssetData?.outage_type || '',
      inspection_interval:
        flattenedAssetData?.[`${inspectionType}_inspection_interval`] ||
        inspectionObject?.inspection_interval ||
        0,
      previous_inspection: inspectionObject?.scheduled_date || 'N/A',
      current_deadline: inspectionObject?.next_deadline || 'N/A',
      fdeCustomerUnitSlug: fdeCustomerUnitSlug || '',
      inspection_tags: inspectionObject?.inspection_tags,
      priority: inspectionObject?.priority,
      cost: inspectionObject?.cost,
      estimated_hours: inspectionObject?.estimated_hours,
    }

    scheduleInspection(
      [scheduledInspectionObject],
      nextInspectionDate,
      siteData,
      auth0,
      originPage,
      'asset_baseball_card',
      () => {
        rawInspectionDataSWR.mutate()
        refetchData?.()
      }
    )
  }

  const handleModifyInspection = (
    inspectionObject: MergedInspectionSchedule,
    newDate: Dayjs,
    onlyDeadline?: boolean
  ) => {
    trackEvent('Button Clicked', {
      buttonName: onlyDeadline ? 'modify_deadline' : 'reschedule_inspection',
      pageName: originPage,
      subPage: 'asset_baseball_card',
      assetDetails: {
        assetName: flattenedAssetData?.equip_description,
        date: newDate.format('YYYY-MM-DD'),
        inspectionType: inspectionObject.inspection_type,
      },
    })
    const inspectionPatchBody = {
      id: inspectionObject?.inspectionId || '',
      type: 'fde-am-inspection',
      data: {},
    }
    let fdeCustomerUnitPatchBody: { id: string; data: Record<any, any>; type: string }[] =
      []
    if (onlyDeadline) {
      //kinda hacky but this only modifies the next deadline
      inspectionPatchBody['data'] = {
        next_deadline: newDate.format('YYYY-MM-DD'),
      }
      if (inspectionObject.completed) {
        //If the inspection is not scheduled, need to modify deadline on the customer unit as well
        fdeCustomerUnitPatchBody = [
          {
            id: fdeCustomerUnitSlug || '',
            type: RolodexEntryType.CUSTOMER_UNIT,
            data: {
              inspection_schedule: {
                [inspectionObject.inspection_type.toLowerCase()]: {
                  [inspectionObject.inspection_method]: {
                    next: newDate.format('YYYY-MM-DD'),
                    last: inspectionObject.scheduled_date,
                  },
                },
              },
            },
          },
        ]
      }
    } else {
      inspectionPatchBody['data'] = {
        scheduled_date: newDate.format('YYYY-MM-DD'),
        next_deadline: newDate
          .add(inspectionObject?.inspection_interval || 0, 'month')
          .format('YYYY-MM-DD'),
      }
    }

    RolodexPatchBatch(
      [inspectionPatchBody, ...fdeCustomerUnitPatchBody],
      auth0,
      siteData
    ).then(() => {
      rawInspectionDataSWR.mutate()
      refetchData?.()
    })
  }

  const handleUploadOnstreamForm = (
    auth0: AuthInfo,
    componentSlug: string,
    onUploadSuccess?: () => void
  ) => {
    return async (file: File) => {
      await uploadComponentFile(
        auth0,
        componentSlug,
        file,
        toBackendPath(`Onstream Exception/${file.name}`)
      )
        .then(() => {
          RolodexPatchBatch(
            [
              {
                id: componentSlug,
                data: {
                  file_tags: {
                    onstream_form: [toBackendPath(`Onstream Exception/${file.name}`)],
                  },
                },
              },
            ],
            auth0,
            siteData
          )
        })
        .then(() => {
          onUploadSuccess?.()
        })
    }
  }

  const inspectionType = useMemo(
    () => getRecordInspectionType(scheduleInspectionData?.inspectionType),
    [scheduleInspectionData?.inspectionType]
  )
  const handleUndoNA = () => {
    //Add the latest inspection's last and next dates to the fde_customer_unit
    const currentFCUInspection = inspectionType
      ? flattenedAssetData?.inspection_schedule?.[inspectionType]?.[
          scheduleInspectionData?.inspectionMethod as string
        ]
      : undefined
    const nextDeadline =
      currentFCUInspection?.next ??
      scheduleInspectionData?.inspectionObject?.next_deadline ??
      null
    const lastScheduled =
      currentFCUInspection?.last ??
      scheduleInspectionData?.inspectionObject?.scheduled_date ??
      null
    const inspectionInterval =
      currentFCUInspection?.interval_months ??
      scheduleInspectionData?.inspectionObject?.inspection_interval ??
      null
    if (scheduleInspectionData?.inspectionMethod && inspectionType) {
      const patchBody = [
        {
          id: fdeCustomerUnitSlug || '',
          type: RolodexEntryType.CUSTOMER_UNIT,
          data: {
            inspection_schedule: {
              [inspectionType]: {
                [scheduleInspectionData?.inspectionMethod]: {
                  status: inspectionStatus.REQUIRED,
                  //keep this part in for a bit but long term we shouldn't need to touch the dates
                  next: nextDeadline,
                  last: lastScheduled,
                  interval_months: inspectionInterval,
                },
              },
            },
          },
        },
      ]

      RolodexPatchBatch(patchBody, auth0, siteData).then(() => {
        rawInspectionDataSWR.mutate()
        refetchData?.()
      })
    }
  }

  const handleOnstreamOk = (auth0: AuthInfo, componentSlug: string, site: string) => {
    trackEvent('Button Clicked', {
      buttonName: 'mark_internal_onstream',
      pageName: originPage,
      subPageName: 'asset_baseball_card',
      assetDetails: {
        assetName: flattenedAssetData?.equip_description,
        date: scheduleInspectionData?.nextInspectionDate?.format('YYYY-MM-DD'),
        inspectionType: inspectionType,
      },
    })
    const inspectionScheduleData =
      scheduleInspectionData?.inspectionMethod && inspectionType
        ? {
            [inspectionType]: {
              [scheduleInspectionData.inspectionMethod]: {
                status: inspectionStatus.ONSTREAM,
                next: null,
                //this is a bit smelly --> setScheduleInspectionData's inspectionObject only takes a MergedInspectionSchedule type,
                // so had to place value in nextInspectionDate
                last: scheduleInspectionData?.nextInspectionDate?.format('YYYY-MM-DD'),
              },
            },
          }
        : {}
    const patchBody = [
      {
        id: uuid(),
        type: 'fde-am-inspection',
        data: {
          area: flattenedAssetData?.equip_area || '',
          cost: null,
          completed: true,
          asset_name: flattenedAssetData?.equip_description || '',
          outage_type: flattenedAssetData?.outage_type || '',
          next_deadline: null,
          scheduled_date:
            scheduleInspectionData?.nextInspectionDate?.format('YYYY-MM-DD'),
          inspection_type: inspectionType,
          current_deadline: null,
          inspection_method: scheduleInspectionData?.inspectionMethod,
          inspection_interval:
            inspectionType && scheduleInspectionData?.inspectionMethod
              ? flattenedAssetData?.inspection_schedule?.[inspectionType]?.[
                  scheduleInspectionData.inspectionMethod
                ]?.interval_months
              : // adding this naive map back in until we figure out something better
                intervalMap[scheduleInspectionData?.inspectionType || 'external'],

          previous_inspection: null,
        },
        tags: {
          [RolodexEntryType.CUSTOMER_UNIT]: fdeCustomerUnitSlug || '',
          site: siteData?.siteSlug || '',
          organization: siteData?.orgSlug || '',
        },
      },
      {
        id: fdeCustomerUnitSlug || '',
        type: RolodexEntryType.CUSTOMER_UNIT,
        data: {
          inspection_schedule: inspectionScheduleData,
          onstream_exception: true,
        },
      },
    ]

    // use RolodexPatchBatch to create the entry
    RolodexPatchBatch(patchBody, auth0, siteData).then(() => {
      rawInspectionDataSWR.mutate()
      refetchData?.()
    })
  }

  const handleNAInspection = (inspectionData: typeof scheduleInspectionData) => {
    trackEvent('Button Clicked', {
      buttonName: 'mark_na_inspection',
      pageName: originPage,
      subPageName: 'asset_baseball_card',
      assetDetails: {
        assetName: flattenedAssetData?.equip_description,
      },
    })
    const inspectionType = getRecordInspectionType(inspectionData?.inspectionType)

    const patchBody = {
      id: fdeCustomerUnitSlug || '',
      type: RolodexEntryType.CUSTOMER_UNIT,
      data: {
        inspection_schedule: inspectionType
          ? {
              [inspectionType]: {
                [inspectionData?.inspectionMethod as string]: {
                  status: inspectionStatus.NA,
                },
              },
            }
          : {},
      },
    }

    RolodexPatchBatch([patchBody], auth0, siteData).then(() => {
      rawInspectionDataSWR.mutate()
      refetchData?.()
    })
  }

  const handleUndoOnstream = () => {
    //This function deletes the onstream inspection object and replaces the next and
    //last fields in the inspection type with the previous values
    const onstreamInspectionId = scheduleInspectionData?.inspectionObject?.inspectionId
    //this is the actual previous inspectionObject
    const inspectionTypeHist = groupedInspectionData?.[inspectionType!]
    const requestList = []
    if (scheduleInspectionData?.inspectionMethod) {
      const fdeCustomerUnitPatchBody: {
        id: string
        type: string
        data: Record<any, any>
      } = {
        id: fdeCustomerUnitSlug || '',
        type: RolodexEntryType.CUSTOMER_UNIT,
        data: {
          onstream_exception: false,
          inspection_schedule: {
            [inspectionType!]: {
              [scheduleInspectionData?.inspectionMethod]: {
                status: inspectionStatus.REQUIRED,
                next: null,
                last: null,
              },
            },
          },
        },
      }
      if (inspectionTypeHist?.length > 1) {
        //Check that there has been a previous inspection
        const previousInspection = inspectionTypeHist[1]
        const previousInspectionType = getRecordInspectionType(
          previousInspection.inspectionType
        )
        fdeCustomerUnitPatchBody.data.inspection_schedule = previousInspectionType
          ? {
              [previousInspectionType]: {
                [previousInspection.inspectionMethod]: {
                  status: inspectionStatus.REQUIRED,
                  next: previousInspection.next_deadline,
                  //this is a bit smelly --> setScheduleInspectionData's inspectionObject only takes a MergedInspectionSchedule type,
                  // so had to place value in nextInspectionDate
                  last: previousInspection.scheduled_date,
                },
              },
            }
          : {}
      }

      requestList.push(RolodexPatchBatch([fdeCustomerUnitPatchBody], auth0, siteData))
      if (onstreamInspectionId) {
        requestList.push(rolodex_batch_delete(auth0, [onstreamInspectionId]))
      }
      Promise.all(requestList).then(() => {
        rawInspectionDataSWR.mutate()
        refetchData?.()
      })
    }
  }

  const inspectionTableDataSource = useMemo(() => {
    return Object.keys(inspectionData || {}).reduce((acc, cur, index) => {
      const groupedInspectionTypeData =
        inspectionData?.[cur as keyof typeof inspectionData]
      const formattedGroupedInspectionData = groupedInspectionTypeData
        //filter out any inspections that don't have a type or method, mainly to wait for data to come in
        .filter((d) => d.inspection_type && d.inspection_method)
        .map((d) => {
          return {
            key: `${d.inspection_type}.${d.inspection_method}`,
            ...d,
            inspection_type: toTitleCase(d.inspection_type),
          }
        })
      return [...acc, ...formattedGroupedInspectionData]
    }, [] as any[])
  }, [inspectionData])

  return (
    <InlineTile
      background={asset_manager_theme.background.background_color9}
      title={'Inspections'}
      titleRowChildren={addInspectionButton()}>
      <Table
        size={'middle'}
        style={{
          width: '100%',
        }}
        pagination={false}
        columns={inspectionColumnDefs}
        dataSource={inspectionTableDataSource}
      />
      <Modal
        open={scheduleInspectionData !== undefined}
        onCancel={() => {
          setScheduleInspectionData(undefined)
        }}
        title={'Confirm Action'}
        onOk={() => {
          switch (scheduleInspectionData?.operation) {
            case 'schedule':
              handleScheduleInspection(
                inspectionType!,
                scheduleInspectionData?.nextInspectionDate as Dayjs,
                scheduleInspectionData?.inspectionObject as MergedInspectionSchedule
              )
              setScheduleInspectionData(undefined)

              break
            case 'reschedule':
              handleModifyInspection(
                scheduleInspectionData?.inspectionObject as MergedInspectionSchedule,
                scheduleInspectionData?.nextInspectionDate as Dayjs
              )
              setScheduleInspectionData(undefined)

              break
            case 'deadlineException':
              handleModifyInspection(
                scheduleInspectionData?.inspectionObject as MergedInspectionSchedule,
                scheduleInspectionData?.nextInspectionDate as Dayjs,
                true
              )
              setScheduleInspectionData(undefined)
              break
            case 'onstreamException':
              handleOnstreamOk(auth0, fdeCustomerUnitSlug || '', siteData?.siteSlug || '')
              setScheduleInspectionData({
                inspectionType,
                nextInspectionDate: scheduleInspectionData?.nextInspectionDate,
                inspectionMethod: scheduleInspectionData?.inspectionMethod,
                inspectionObject: scheduleInspectionData?.inspectionObject,
                operation: 'uploadExceptionForm',
              })
              break
            case 'markNA':
              handleNAInspection(scheduleInspectionData)
              setScheduleInspectionData(undefined)
              break
            case 'undoOnstream':
              handleUndoOnstream()
              setScheduleInspectionData(undefined)
              break
            case 'undoMarkNA':
              handleUndoNA()
              setScheduleInspectionData(undefined)
              break
          }
        }}
        footer={(_, { OkBtn, CancelBtn }) => {
          if (scheduleInspectionData?.operation === 'onstreamException') {
            return (
              <Row
                style={{
                  justifyContent: 'space-between',
                  width: '75%',
                  margin: '0 auto',
                  padding: '1rem 1rem 0rem 1rem',
                }}>
                <StyledDatePicker
                  suffixIcon={null}
                  value={scheduleInspectionData?.nextInspectionDate}
                  allowClear={false}
                  onSelect={(value) => {
                    setScheduleInspectionData({
                      inspectionType,
                      nextInspectionDate: value,
                      inspectionMethod: scheduleInspectionData?.inspectionMethod,
                      inspectionObject: scheduleInspectionData?.inspectionObject,
                      operation: 'onstreamException',
                    })
                  }}
                />
                <OkBtn />
              </Row>
            )
          } else if (scheduleInspectionData?.operation === 'uploadExceptionForm') {
            return (
              <Row
                style={{
                  justifyContent: 'right',
                  width: '100%',
                  margin: '0 auto',
                  padding: '1rem 1rem 0rem 1rem',
                }}>
                <AntdButton onClick={() => setScheduleInspectionData(undefined)}>
                  Dismiss
                </AntdButton>
                <UploadButton
                  customRequest={handleUploadOnstreamForm(
                    auth0,
                    fdeCustomerUnitSlug || '',
                    handleOnstreamUploadSuccess
                  )}
                  assetName={flattenedAssetData?.equip_description || ''}
                  pageName='Baseball Card'
                  subPageName='Onstream exception'
                />
              </Row>
            )
          } else {
            return (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )
          }
        }}>
        {
          {
            schedule: (
              <div>
                {scheduleInspectionData?.nextInspectionDate &&
                scheduleInspectionData?.nextInspectionDate.diff(dayjs(), 'day') < 0 ? (
                  <Row>
                    <InlineTile
                      background={asset_manager_theme.colors.orange_dark}
                      padding='.5rem'>
                      <Text style={{ color: 'white' }}>
                        &nbsp;&nbsp;
                        <WarningFilled
                          style={{ color: asset_manager_theme.colors.orange }}
                        />
                        &nbsp;&nbsp; This date is in the past.
                      </Text>
                    </InlineTile>
                  </Row>
                ) : (
                  ''
                )}
                <Row>
                  {`Are you sure you want to schedule an ${inspectionType} inspection on ${scheduleInspectionData?.nextInspectionDate?.format(
                    'YYYY-MM-DD'
                  )}?
                `}
                </Row>
              </div>
            ),
            deadlineException: `Are you sure you want to make a deadline exception for an ${inspectionType} inspection on ${scheduleInspectionData?.nextInspectionDate?.format(
              'YYYY-MM-DD'
            )}?`,
            reschedule: `Are you sure you want to reschedule an ${inspectionType} inspection on  ${scheduleInspectionData?.nextInspectionDate?.format(
              'YYYY-MM-DD'
            )}?`,
            onstreamException: `Are you sure you want to mark an On-Stream Evaluation in Lieu of Internal Inspection on ${scheduleInspectionData?.nextInspectionDate?.format(
              'YYYY-MM-DD'
            )}?`,
            uploadExceptionForm: `An On-Stream Exception has been made. You may upload the supporting On-Stream Evaluation in Lieu of Internal Inspection Form here.`,
            markNA: `Are you sure you want to mark ${flattenedAssetData?.equip_description}'s ${inspectionType} inspection as N/A?`,
            undoOnstream: `Are you sure you want to undo the Onstream Exception for ${flattenedAssetData?.equip_description}'s ${inspectionType} inspection?`,
            undoMarkNA: `Are you sure you want to undo the N/A for ${flattenedAssetData?.equip_description}'s ${inspectionType} inspection?`,
          }[
            scheduleInspectionData?.operation as
              | 'schedule'
              | 'reschedule'
              | 'deadlineException'
              | 'onstreamException'
              | 'uploadExceptionForm'
              | 'markNA'
              | 'undoOnstream'
              | 'undoMarkNA'
          ]
        }
      </Modal>
      <Modal
        title={'Add Inspection'}
        open={isInspectionModalOpen}
        onCancel={() => {
          setIsInspectionModalOpen(false)
          modalForm.resetFields()
        }}
        footer={null}
        destroyOnClose={true}>
        <div
          style={{
            maxHeight: '70vh',
            overflowY: 'scroll',
            margin: '2rem 0rem 2rem 0rem',
          }}>
          <InspectionForm
            form={modalForm}
            originPage={originPage}
            refetchData={refetchData}
            flattenedAssetData={flattenedAssetData}
            rawUnitInspectionDataSWR={rawInspectionDataSWR}
            fdeCustomerUnitSlug={fdeCustomerUnitSlug ?? ''}
            setIsInspectionModalOpen={setIsInspectionModalOpen}
          />
        </div>
        <HighVisButton
          onClick={() => {
            modalForm.submit()
          }}>
          Save
        </HighVisButton>
        &nbsp; &nbsp;
      </Modal>
    </InlineTile>
  )
}

export default AssetBaseBallCardInspectionTable
