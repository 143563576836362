import { GeckoTheme } from 'gecko-ui'

export const asset_manager_theme = {
  ...GeckoTheme,
  background: {
    background_color0: GeckoTheme.colors.greyscale['100'],
    background_color1: GeckoTheme.colors.greyscale['200'],
    background_color2: GeckoTheme.colors.greyscale['300'],
    background_color3: GeckoTheme.colors.greyscale['400'],
    background_color4: GeckoTheme.colors.greyscale['500'],
    background_color5: GeckoTheme.colors.greyscale_alternate['200'],
    background_color6: GeckoTheme.colors.greyscale['600'],
    background_color6_5: 'rgba(43, 43, 43, 1)', // custom color used for tiles / widgets
    background_color7: GeckoTheme.colors.greyscale_alternate['100'],
    background_color8: 'rgba(28, 28, 28, 1)', // TODO: replace with gecko-ui color
    background_color8_5: 'rgba(20, 20, 20, 1)', // custom color used for app background
    background_color9: GeckoTheme.colors.greyscale['700'],
    background_color10: GeckoTheme.colors.greyscale['800'],
    high_vis: GeckoTheme.colors.highVis,
  },
  text: {
    c1: GeckoTheme.colors.greyscale[100],
    c2: GeckoTheme.colors.greyscale[800],
    c3: GeckoTheme.colors.greyscale[500],
    c4: GeckoTheme.colors.greyscale[300],
    high_vis: GeckoTheme.colors.highVis,
    font_family1: 'Roboto',
    font_family2: 'Manrope',
    disabled: GeckoTheme.colors.slate['700_base'],
  },
  colors: {
    red: 'rgba(255, 64, 0, 1)', // used for out of compliance
    red_light: 'rgba(255, 152, 117, 1)',
    red_dark: 'rgba(117, 29, 0, 1)',
    orange_red: 'rgba(255, 96, 0)', // used for partial out of compliance
    orange: 'rgba(255, 170, 0, 1)', // used for external ooc
    orange_light: '#FFD12D', // custom light orange
    orange_dark: '#5B3F06',
    green: 'rgba(105, 255, 66, 1)',
    green_light: 'rgba(105, 255, 66, 0.5)',
    green_dark: 'rgba(01, 108, 0, 1)',
    high_vis: 'rgba(238, 255, 0, 1)',
    yellow: '#FFF500', // custom yellow, used for internal ooc
    purple: 'rgba(255, 0, 255, 1)',
    yellow_light: 'rgba(255, 255, 0, 0.5)',
    yellow_dark: 'rgba(63,73,0,1)',
    blue: '#0A80C3',
    blue_light: '#42BAFF',
    blue_dark: '#0D5D90',
    blue_darkest: '#0F425F',
    grey: '#CCCCCC',
    grey_light: '#666666'
  },
}
