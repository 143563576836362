import { TreeNode } from '../Types/FileTreeTypes'

// Function to find a node based on its title
export const getNode = (key: string, nodes: TreeNode[]): TreeNode | null => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].key === key) {
      return nodes[i]
    }
    if (nodes[i].children.length) {
      const foundNode = getNode(key, nodes[i].children)
      if (foundNode) return foundNode
    }
  }
  return null
}

// Traverse a node and add file paths to filePaths array
export const traverseNodes = (node: TreeNode, filePaths: string[]): void => {
  if (node.isLeaf) {
    filePaths.push(node.key)
  } else {
    node.children.forEach((childNode) => traverseNodes(childNode, filePaths))
  }
}

// Function to transform file paths to tree data
export const transformToTreeData = (files: string[]): Array<TreeNode> => {
  const treeData: { [key: string]: TreeNode } = {}

  files.forEach((filepath) => {
    filepath
      .split('/')
      .reduce((parent: TreeNode | null, name: string, index: number, array: string[]) => {
        const key = parent ? `${parent.key}/${name}` : name
        if (!treeData[key]) {
          treeData[key] = {
            title: name,
            key: key,
            isLeaf: index === array.length - 1,
            children: [],
          }
          if (parent) {
            parent.children.push(treeData[key])
          }
        }
        return treeData[key]
      }, null)
  })

  return Object.values(treeData).filter((node) => node.key.indexOf('/') === -1)
}

export function getAllFolders(nodes: TreeNode[], prefixPath = ''): TreeNode[] {
  let folders: TreeNode[] = []

  nodes.forEach((node) => {
    if (!node.isLeaf) {
      const fullPath = `${prefixPath}${node.title}`
      folders.push({ ...node, key: fullPath }) // Replacing the 'key' with the full path
      if (node.children?.length > 0) {
        folders = [...folders, ...getAllFolders(node.children, fullPath + '/')]
      }
    }
  })

  return folders
}

export const addNodeToTreeData = (
  treeNodes: TreeNode[],
  key: string,
  newNode: TreeNode
): TreeNode[] => {
  if (key === '') {
    // Special case: add newNode to root
    return [...treeNodes, newNode]
  }
  return treeNodes.map((node) => {
    if (node.key === key) {
      return { ...node, children: [...node.children, newNode] }
    } else if (node.children?.length > 0) {
      return { ...node, children: addNodeToTreeData(node.children, key, newNode) }
    } else {
      return node
    }
  })
}

export const traverseNodesGetAllDescendants = (nodes: TreeNode[], parentPath: string) => {
  let descendants: TreeNode[] = []

  nodes.forEach((node) => {
    if (node.key.startsWith(parentPath)) {
      descendants.push(node)
    }

    if (node.children) {
      descendants = [
        ...descendants,
        ...traverseNodesGetAllDescendants(node.children, parentPath),
      ]
    }
  })

  return descendants
}
