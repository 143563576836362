import React, { useMemo, useState } from 'react'
import { Row } from '../../../../components/Common/Grid'
import { CloseButton } from '../../../../components/Common/CloseButton'
import SlidePanel from '../../../../components/Common/SlidePanel'
import { Button, Col, InputNumber, Steps } from 'antd'
import { asset_manager_theme } from '../../../../assets/themes'
import { OptionsReview } from './OptionsReview'
import { PlanComparison } from './PlanComparison'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { Asset, Plan } from '../../PlanModelingInterface'
import { calculateTCO, formatCurrency } from './CostOptimizationUtil'
import { orderBy, sortBy, sumBy } from 'lodash'

export interface CostMinimizerDrawerProps {
  isPanelActive: boolean
  setPanelActive: (active: boolean) => void
  selectedPlans: AssetPlanSelection
  setSelectedPlans: (plans: AssetPlanSelection) => void
  processedPlanData: Asset[]
}

export const CostMinimizerDrawer: React.FC<CostMinimizerDrawerProps> = ({
  isPanelActive,
  selectedPlans,
  processedPlanData,
  setPanelActive,
  setSelectedPlans,
}) => {
  const [newPlanSelection, setNewPlanSelection] = useState<AssetPlanSelection>()
  const [maxAnnualBudget, setMaxAnnualBudget] = useState<number>()

  const handleBack = () => setNewPlanSelection(undefined)

  const handleSaveAndApply = () => {
    if (newPlanSelection) {
      setSelectedPlans(newPlanSelection)
      setPanelActive(false)
      setNewPlanSelection(undefined)
    }
  }

  const selectOption = (selected: Plan[]) => {
    const newSelectedPlans: AssetPlanSelection = {}

    selected.forEach((plan) => {
      const asset = processedPlanData.find((asset) => asset.plans?.includes(plan))
      if (asset) {
        newSelectedPlans[asset.id] = {
          plan_slug: plan.plan_slug,
          action_taken: plan.action_taken,
        }
      }
    })

    setNewPlanSelection(newSelectedPlans)
  }

  // Sort by assets with steepest dropoff between most and second-most expensive plans
  const assetsByCostDropoff = useMemo(
    () =>
      orderBy(
        processedPlanData,
        (a) => {
          const sortedPlans = orderBy(
            a.plans?.filter((p) => Boolean(p.total_plan_cost)) ?? [],
            (p) => p.total_plan_cost
          )
          return (
            (sortedPlans[0]?.total_plan_cost ?? 0) -
            (sortedPlans[1]?.total_plan_cost ?? 0)
          )
        },
        'asc'
      ),

    [processedPlanData]
  )

  const combinations: Plan[][] = useMemo(() => {
    // Allow expansion of the 4 flattest-dropoff assets (up to 2 cheapest plans)
    // This allows for choice while reducing cartesian complexity
    return assetsByCostDropoff.reduce((planArray, asset, idx) => {
      if (asset.plans && asset.plans.length > 0) {
        const cheapestPlans = orderBy(
          asset.plans,
          (p) => p.total_plan_cost ?? Infinity,
          'asc'
        ).slice(0, idx < 4 ? 2 : 1)
        return cheapestPlans.flatMap((plan) =>
          planArray.length === 0 ? [[plan]] : planArray.map((e) => [plan, ...e])
        )
      }
      return planArray
    }, [] as Plan[][])
  }, [assetsByCostDropoff])
  const sortedCombinations = sortBy(combinations, (c) => calculateTCO(c))

  return (
    <SlidePanel
      active={isPanelActive}
      panelStyle={{
        display: isPanelActive ? undefined : 'none',
        overflow: 'hidden',
      }}>
      <Row>
        <CloseButton
          style={{
            height: '3rem',
          }}
          onClick={() => setPanelActive(false)}
        />
        <h1 style={{ color: asset_manager_theme.text.c1 }}>
          Optimize Capital Allocation
        </h1>
        <Steps
          direction='horizontal'
          current={newPlanSelection ? 1 : 0}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '2rem',
            width: 'unset',
          }}>
          <Steps.Step title='Options Review' />
          <Steps.Step title='Finalize and Apply' />
        </Steps>
      </Row>
      <Row style={{ color: asset_manager_theme.text.c1, gap: '1rem' }}>
        <Col span={6}>
          <h2 style={{ margin: '1rem 0' }}>Parameters</h2>
          <Row style={{ alignItems: 'center', gap: '1rem' }}>
            <label>Max. Annual Budget: </label>
            <InputNumber
              style={{ width: '50%', color: 'white' }}
              formatter={(value) => (value ? formatCurrency(value) : '')}
              onChange={(val) =>
                setMaxAnnualBudget(val ? parseInt(val.toString()) : undefined)
              }
              placeholder='N/A'
            />
          </Row>
        </Col>
        {!newPlanSelection ? (
          <OptionsReview
            sortedCombinations={sortedCombinations}
            assets={processedPlanData}
            selectedPlans={selectedPlans}
            onSelect={selectOption}
            annualBudget={maxAnnualBudget}
          />
        ) : (
          <PlanComparison
            assets={processedPlanData}
            oldPlanSelection={selectedPlans}
            newPlanSelection={newPlanSelection}
            annualBudget={maxAnnualBudget}
          />
        )}
      </Row>

      {Boolean(newPlanSelection) && (
        <>
          <Button
            type='primary'
            onClick={handleBack}
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '220px',
            }}>
            Back
          </Button>
          <Button
            type='primary'
            onClick={handleSaveAndApply}
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '80px',
            }}>
            Save and Apply
          </Button>
        </>
      )}
    </SlidePanel>
  )
}
