import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  TwoColumnDivContainer,
  FirstColumn,
  SecondColumn,
} from '../components/Common/LayoutContainer'
import { Menu, MenuProps, Tooltip } from 'antd'
import {
  BranchesOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  DatabaseOutlined,
  DotChartOutlined,
  GlobalOutlined,
  LineChartOutlined,
  ScheduleOutlined,
  StockOutlined,
} from '@ant-design/icons'
import { asset_manager_theme } from '../assets/themes'
import { FeatureFlagContext } from '../contextProviders/featureFlagContextProvider'
import { useHasOverviewData } from '../api/useFDEBackend'
import { useAuthInfo } from '../contextProviders/useAuthInfo'
import { SiteContext } from '../contextProviders/siteContextProvider'
import { useGetSupportedAssets } from '../util/RolodexInterface'
import { RolodexEntry } from '../api/useRolodex'
import AssetBaseballCard from '../components/Common/AssetBaseballCard/AssetBaseballCard'

type MenuItem = Required<MenuProps>['items'][number]
export enum TabKey {
  assetsDB = 'assets-db',
  overview = 'overview',
  timeline = 'timeline',
  impact = 'impact',
  // plan_summary = "plan-summary", // Uncomment or remove as needed
  recommendations = 'recommendations',
  planModeling = 'plan-modeling',
  assetCompare = 'asset-compare',
  inspectionScheduling = 'inspection-scheduling',
  RugBlitzPlanning = 'rug-blitz-planning',
  radar = 'radar',
}

enum TabGroupKey {
  compliance_group,
  plan_summary_group,
}

export type TabGroupKeyString = keyof typeof TabGroupKey

interface LayoutWithMenuProps {
  selectedFdeCustomerUnitSlug?: string
  setSelectedFdeCustomerUnitSlug: Dispatch<React.SetStateAction<string | undefined>>
  assetBaseballCardRefetch?: { func: () => void }
  setSelectedTriageSidePanelFdeUnit: Dispatch<React.SetStateAction<string | undefined>>
  setSiteName: React.Dispatch<React.SetStateAction<string | undefined>>
  setSiteId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const AppLayout: React.FC<LayoutWithMenuProps> = ({
  selectedFdeCustomerUnitSlug,
  setSelectedFdeCustomerUnitSlug,
  assetBaseballCardRefetch,
  setSelectedTriageSidePanelFdeUnit,
  setSiteName,
  setSiteId,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)

  useEffect(() => {
    if (siteData?.siteName) {
      setSiteName(siteData.siteName)
    }
    if (siteData?.siteSlug) {
      setSiteId(siteData?.siteSlug)
    }
  }, [siteData])

  const userFeatureFlags = useContext(FeatureFlagContext)
  function getItem(
    label: React.ReactNode,
    key: TabKey | TabGroupKeyString,
    disabled = false,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem | undefined {
    if (!userFeatureFlags?.superUser && !!children && children.length === 0) {
      //if there are children and none of them are available to the user, don't show the parent
      return undefined
    }
    if (!userFeatureFlags?.superUser && !children && !userFeatureFlags?.[key as TabKey]) {
      return undefined
    }
    return {
      key,
      icon,
      children,
      label: disabled ? (
        <Tooltip
          title={`Tab unavailable, please upload ${label} data`}
          placement='right'
          color={asset_manager_theme.background.background_color5}>
          {label}
        </Tooltip>
      ) : (
        label
      ),
      type,
      disabled,
    } as MenuItem
  }

  const hasComplianceDataSwr = useHasOverviewData(authInfo, siteData?.siteSlug ?? '')
  const hasComplianceData = !hasComplianceDataSwr.isLoading && hasComplianceDataSwr.data

  const supportedAssetsSwr = useGetSupportedAssets(siteData?.siteSlug ?? '')
  const supportedAssets: string[] = useMemo(
    () =>
      supportedAssetsSwr.data
        ? supportedAssetsSwr.data?.map(
            (rolodexEntry: { entry: RolodexEntry }) =>
              rolodexEntry.entry.data?.capex_optimizer?.supported_assets ?? []
          )[0]
        : [],
    [supportedAssetsSwr.data]
  )

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      getItem('Overview', TabKey.overview, false, <GlobalOutlined />),
      getItem('Radar', TabKey.radar, false, <GlobalOutlined />),
      getItem('Assets Database', TabKey.assetsDB, false, <DatabaseOutlined />),
      getItem(
        'Inspection Scheduling',
        TabKey.inspectionScheduling,
        false,
        <ScheduleOutlined />
      ),
      getItem(
        'Compliance',
        'compliance_group',
        !hasComplianceData,
        <CheckCircleOutlined />,
        [
          getItem('Timeline', TabKey.timeline, false, <LineChartOutlined />),
          getItem('Impact', TabKey.impact, false, <DotChartOutlined />),
        ].filter((d): d is MenuItem => d !== undefined)
      ),
      getItem('Rug Blitz Planning', TabKey.RugBlitzPlanning, false, <DatabaseOutlined />),
      getItem(
        'Planning',
        'plan_summary_group',
        false,
        <LineChartOutlined />,
        [
          getItem('Recommendations', TabKey.recommendations, false, <BulbOutlined />),
          getItem('Plan Modeling', TabKey.planModeling, false, <StockOutlined />),
          // getItem('Plan Summary', 'plan_summary', false, <GlobalOutlined />),
          getItem(
            'Compare',
            TabKey.assetCompare,
            !(supportedAssets?.length > 0),
            <BranchesOutlined />
          ),
        ].filter((d): d is MenuItem => d !== undefined)
      ),
    ].filter((d): d is MenuItem => d !== undefined)
  }, [hasComplianceData, supportedAssets?.length, userFeatureFlags])

  const [selectedKey, setSelectedKey] = useState<TabKey>(
    location.pathname.split('/')[2] as TabKey
  )

  useEffect(() => {
    if (
      userFeatureFlags?.defaultPage &&
      !location.pathname.split('/')[2] &&
      siteData?.siteSlug
    ) {
      const newSelectedKey = userFeatureFlags?.defaultPage as TabKey
      setSelectedKey(newSelectedKey)
      navigate(`/${siteData?.siteSlug}/${newSelectedKey}`)
    }
  }, [siteData?.siteSlug, userFeatureFlags?.defaultPage])

  return (
    <TwoColumnDivContainer>
      <FirstColumn>
        <Menu
          style={{
            width: '250px',
            paddingTop: '2rem',
          }}
          defaultOpenKeys={[]}
          selectedKeys={[selectedKey]}
          onSelect={(event) => {
            const newSelectedKey = event.key as TabKey
            setSelectedKey(newSelectedKey)
            setSelectedFdeCustomerUnitSlug(undefined)
            navigate(`/${siteData?.siteSlug}/${newSelectedKey}`)
          }}
          items={menuItems}
          mode='inline'
        />
      </FirstColumn>
      <SecondColumn>
        <Outlet /> {/* This will render the matched route component */}
      </SecondColumn>
      <AssetBaseballCard
        fdeCustomerUnitSlug={selectedFdeCustomerUnitSlug}
        //pasing this in so the panel can close itself
        setPanelActive={setSelectedFdeCustomerUnitSlug}
        refetchData={assetBaseballCardRefetch}
        originPage={selectedKey}
        setSelectedTab={setSelectedKey}
        key={selectedFdeCustomerUnitSlug}
        setTriageSidePanelFdeUnit={setSelectedTriageSidePanelFdeUnit}
      />
    </TwoColumnDivContainer>
  )
}

export default AppLayout
