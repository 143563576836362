import { FC, useMemo, useState } from 'react'
import { useGetRepairCostTtlTraces } from '../../../api/useFDEBackend'
import TraceView from '../TraceView'
import { ComponentWithSlug } from '../../../util/AssetTableHelper'
import _ from 'lodash'
import styled from 'styled-components'
import { Divider } from 'antd'
import { ComparisonViewControls, createEncap } from './ComparisonViewControls'
import { asset_manager_theme } from '../../../assets/themes'

const DEFAULT_TRACE_YRS = 20

const ComparisonViewArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${asset_manager_theme.text.c1};
  font-size: 30px;
  font-family: ${asset_manager_theme.fontStyles.header.header_h1.value.fontFamily};
`

const TraceViewArea = styled.div`
  width: 70%;
`

export interface ComparisonViewProps {
  assetSlugs: string[]
  components: ComponentWithSlug[]
}

function hexToRGBA(hex: string, opacity: number) {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

function getDistinctColors(numColors: number): string {
  const colors: string[] = [
    '#FFFFFF', // white
    '#0D5D90', // dark blue
    '#44BBFF', // light blue
    '#008888', // dark teal
    '#57E7B2', // light teal
  ]

  const colorIndex = numColors % colors.length

  return colors[colorIndex]
}

function defaultRLComparisonTrace(
  costToReplace: number,
  usefulNewLife: number,
  reductionFactor: number
) {
  return {
    mode: 'lines+markers',
    name: 'Equivalent Annual Cost',
    type: 'scatter',
    marker: {
      size: 4,
      color: 'white',
    },
    fillcolor: hexToRGBA(getDistinctColors(0), 0.1),
    y: Array.from(Array(DEFAULT_TRACE_YRS).keys()).map((d) => {
      return reductionFactor * (costToReplace / usefulNewLife) * d
    }),
    x: Array.from(Array(DEFAULT_TRACE_YRS).keys()),
    hovertemplate: '$%{y:.2s}',
    hoverlabel: {
      bgcolor: 'rgba(0, 0, 0, 0)',
      font: {
        color: 'white',
      },
    },
    line: { color: getDistinctColors(0) },
  }
}

export const ComparisonView: FC<ComparisonViewProps> = (props) => {
  const [costToReplace, setCostToReplace] = useState<number>(3000000)
  const [usefulNewLifeYrs, setUsefulNewLifeYrs] = useState<number>(30)
  const [reductionFactor, setReductionFactor] = useState<number>(1)
  const [showReplacementTrace, setShowReplacementTrace] = useState<boolean>(false)

  const slugToTrace = useGetRepairCostTtlTraces(props.assetSlugs)
  const formattedTraces = useMemo(() => {
    return Object.entries(slugToTrace.data ?? {}).map(([slug, trace], idx) => {
      const traceColor = getDistinctColors(idx + (showReplacementTrace ? 1 : 0))
      return {
        ...trace,
        name: _.find(props.components, (comp) => comp.slug === slug)?.asset_name ?? slug,
        line: { color: traceColor },
        fillColor: hexToRGBA(traceColor, 0.1),
        hoverlabel: {
          bgcolor: 'rgba(0, 0, 0, 0)',
          font: {
            color: 'white',
          },
        },
      }
    })
  }, [props.components, showReplacementTrace, slugToTrace.data])

  return (
    <div>
      <Heading>Repair Plan Comparison</Heading>
      <ComparisonViewArea>
        <TraceViewArea>
          <TraceView
            traces={
              showReplacementTrace
                ? [
                    defaultRLComparisonTrace(
                      costToReplace,
                      usefulNewLifeYrs,
                      reductionFactor
                    ),
                    ...formattedTraces,
                  ]
                : formattedTraces
            }
            isLoading={slugToTrace.isLoading}
          />
        </TraceViewArea>
        <Divider type='vertical' />
        <ComparisonViewControls
          showReplacementCostTrace={createEncap(
            showReplacementTrace,
            setShowReplacementTrace
          )}
          replacementCost={createEncap(costToReplace, setCostToReplace)}
          usefulLifeYrs={createEncap(usefulNewLifeYrs, setUsefulNewLifeYrs)}
          reductionFactor={createEncap(reductionFactor, setReductionFactor)}
        />
      </ComparisonViewArea>
    </div>
  )
}
