import { RolodexEntry, RolodexLoadedEntry } from '../api/useRolodex'
import { useMemo } from 'react'
import { associateBy } from '../util/helpers/mapHelpers'
import { RolodexType } from '../util/types'
import { useAuthInfo } from '../contextProviders/useAuthInfo'
import useSWR, { SWRResponse } from 'swr'
import { rolodexUrl } from '../api/helpers'
import { postFetcher } from '../api/fetchers'

/**
 * A hook to load data relevant for the entire site.
 * TODO: Share with `useLandingPageData` 2024.07.01
 * @param props Site ID
 */

export const useSiteData = (props: { siteID: string | undefined }) => {
  // useRolodexEntries only returns sites and orgs to which the user has permission
  const authInfo = useAuthInfo()
  const rolodexFetchEndpoint = `${rolodexUrl()}/entries/load`

  const siteOrgQuery = useMemo(() => {
    //ugly querying logic here but based on
    //https://geckorobotics.atlassian.net/wiki/spaces/SE/pages/3966337128/Adapting+Forecast+to+Site-Based+Permissions
    const commonQueryProps = {
      order_by: [{ key: 'name', descending: false }],
      limit: 5000,
    }
    const defaultQuery = {
      type: [RolodexType.SITE, RolodexType.ORGANIZATION],
      ...commonQueryProps,
    }
    if (authInfo.is_Loading === false) {
      if (authInfo.userRole === 'gecko') {
        //assume gecko permissions here they should have access to everything
        return defaultQuery
      }
      if (
        authInfo.permissionTags?.['site'] &&
        authInfo.permissionTags?.['site'].length > 0
      ) {
        return {
          ids: authInfo.permissionTags['site'],
          type: RolodexType.SITE,
          links: [RolodexType.ORGANIZATION],
          ...commonQueryProps,
          tags: { site: authInfo.permissionTags['site'] },
        }
      }
      if (
        authInfo.permissionTags?.['organization'] &&
        authInfo.permissionTags?.['organization'].length > 0
      ) {
        return {
          ...defaultQuery,
          tags: { organization: authInfo.permissionTags['organization'] },
        }
      }
      return defaultQuery
    } else {
      return undefined
    }
  }, [authInfo.permissionTags, authInfo.userRole])

  const { data, isLoading } = useSWR(
    siteOrgQuery ? [rolodexFetchEndpoint, siteOrgQuery, authInfo] : false,
    ([endpoint, siteOrgQuery, auth0]) =>
      postFetcher(endpoint, JSON.stringify(siteOrgQuery), auth0)
  ) as SWRResponse<RolodexLoadedEntry[]>

  const formattedData = useMemo(() => {
    //for site perms the organizaton shows up in the links intead of the array so
    if (
      authInfo.is_Loading === false &&
      authInfo.permissionTags?.['site'] &&
      authInfo.permissionTags?.['site'].length > 0
    ) {
      //if its in site all the orgs are in the links and need to be cleaned up
      return data?.reduce((acc, cur) => {
        const organizationLink = cur?.links?.organization
        if (organizationLink) {
          return [...acc, cur.entry, organizationLink]
        }
        return [...acc, cur.entry]
      }, [] as RolodexEntry[])
    } else {
      return data?.map((d) => d.entry)
    }
  }, [data])

  const sitesAndOrgs: {
    sites: RolodexEntry[]
    organizations: RolodexEntry[]
  } = {
    sites: [],
    organizations: [],
  }

  const { sites, organizations } = useMemo(() => {
    return !!formattedData
      ? formattedData?.reduce((agg, entry) => {
          const currEntry = entry
          if (
            currEntry.type == RolodexType.ORGANIZATION &&
            !agg.organizations.map((d) => d.id).includes(currEntry.id)
          ) {
            agg.organizations.push(currEntry)
          } else if (
            currEntry.type == RolodexType.SITE &&
            !agg.sites.map((d) => d.id).includes(currEntry.id)
          ) {
            agg.sites.push(currEntry)
          }

          return agg
        }, sitesAndOrgs)
      : sitesAndOrgs
  }, [formattedData])

  const siteById = associateBy({ arr: sites, key: 'id' })
  const organizationsById = associateBy({ arr: organizations, key: 'id' })

  const { site, sitesForOrg, organization } = useMemo(() => {
    const site = !!props.siteID
      ? // If there's a site ID in the URL, find that site
        siteById.get(props.siteID)
      : // Otherwise, redirect to the demo site if the user has access to
        // the demo and the first available site if the user doesn't
        sites.at(0)

    const organization =
      site && site?.tags?.organization
        ? organizationsById.get(site?.tags.organization)
        : undefined
    const sitesForOrg = sites.filter((s) => s.tags?.organization === organization?.id)
    return { site, sitesForOrg, organization }
  }, [props.siteID, sites, siteById, organizationsById])

  return {
    organization,
    organizations,
    site,
    sites,
    sitesForOrg,
    isLoading,
  }
}
