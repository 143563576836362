import { postFetcher } from '../../api/fetchers'
import { AuthInfo } from '../../contextProviders/useAuthInfo'
import {
  RolodexMoveFile,
  RolodexPatchBatch,
  uploadComponentFile,
} from '../../util/RolodexInterface'
import { toBackendPath } from './toBackendPath'
import { rolodexUrl } from '../../api/helpers'
import { MessageInstance } from 'antd/es/message/interface'
import { SiteData } from '../../contextProviders/siteContextProvider'

const UploadLatestInspection = (
  auth0: AuthInfo,
  componentSlug: string,
  siteData: SiteData,
  assetName?: string,
  messageApi?: MessageInstance,
  onUploadFinally?: () => void
) => {
  return async (file: File) => {
    try {
      // Upload component file
      await uploadComponentFile(auth0, componentSlug, file, toBackendPath(file.name))

      // Fetch entries after upload
      const results = await postFetcher(
        `${rolodexUrl()}/entries/load`,
        JSON.stringify({
          ids: [componentSlug],
          limit: 1,
          tags: { site: siteData?.siteSlug, organization: siteData?.orgSlug },
        }),
        auth0
      )

      const latestInspectionReport =
        results?.[0]?.entry?.data?.file_tags?.latest_inspection_report
      if (latestInspectionReport) {
        // Move each file in latestInspectionReport to /archive
        await Promise.all(
          latestInspectionReport.map((filePath: string) =>
            RolodexMoveFile(
              auth0,
              componentSlug,
              toBackendPath(`Inspection_Archive/${filePath.split('/').pop()}`),
              filePath,
              'application/json'
            )
          )
        )
      }

      // Patch batch to update with new file path
      await RolodexPatchBatch(
        [
          {
            id: componentSlug,
            data: { file_tags: { latest_inspection_report: [toBackendPath(file.name)] } },
          },
        ],
        auth0,
        siteData
      )

      // Display success message
      messageApi?.open({
        type: 'success',
        content: `Successfully uploaded the latest inspection report! You can edit ${
          assetName || componentSlug
        }'s plan in the Planning Tab.`,
        duration: 7,
      })
    } catch (error) {
      const message = (error as { cause?: { detail?: string } }).cause?.detail
      if (message === 'Source file not found') {
        // Handle specific error (e.g., tagged file doesn't exist)
        await RolodexPatchBatch(
          [
            {
              id: componentSlug,
              data: {
                file_tags: { latest_inspection_report: [toBackendPath(file.name)] },
              },
            },
          ],
          auth0,
          siteData
        )
        messageApi?.open({
          type: 'success',
          content: `Successfully uploaded the latest inspection report! You can edit ${
            assetName || componentSlug
          }'s plan in the Planning Tab.`,
          duration: 7,
        })
      } else {
        // Handle general upload error
        messageApi?.open({
          type: 'error',
          content: `Error uploading the latest inspection report: ${error}`,
          duration: 7,
        })
      }
    } finally {
      // Callback to update UI after upload, e.g., close modal
      if (onUploadFinally) onUploadFinally()
    }
  }
}

export default UploadLatestInspection
