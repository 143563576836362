/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { FC, useMemo } from 'react'
import { AreaToOverviewCounts } from './OverviewTab'
import { Data } from 'plotly.js'
import Plot from 'react-plotly.js'
import { asset_manager_theme } from '../../assets/themes'
import { useResizeDetector } from 'react-resize-detector'

interface OverviewPieProps {
  overviewData: AreaToOverviewCounts
}

type OverviewPieLabel = 'Fully Compliant' | 'Partially/Non Compliant' | 'Out Of Service'

const labelToColor: Record<OverviewPieLabel, string> = {
  'Fully Compliant': asset_manager_theme.colors.green,
  'Partially/Non Compliant': asset_manager_theme.colors.red,
  'Out Of Service': asset_manager_theme.background.background_color3,
}

const OverviewPie: FC<OverviewPieProps> = (props) => {
  const { ref, width, height } = useResizeDetector()

  const [traces, layout] = useMemo(() => {
    const includeOOS = props.overviewData['OUT OF SERVICE']
    // accumulate overview data based on filters
    const overviewDataAcc = {
      fully_compliant: 0,
      non_compliant: 0,
      out_of_service: includeOOS
        ? props.overviewData['OUT OF SERVICE'].fully_compliant +
          props.overviewData['OUT OF SERVICE'].internally_non_compliant +
          props.overviewData['OUT OF SERVICE'].externally_non_compliant +
          props.overviewData['OUT OF SERVICE'].non_compliant
        : 0,
    }

    const areas = Object.keys(props.overviewData).filter(
      (area) => area !== 'OUT OF SERVICE'
    )

    areas.forEach((area) => {
      overviewDataAcc.fully_compliant += props.overviewData[area]?.fully_compliant ?? 0
      overviewDataAcc.non_compliant += props.overviewData[area]?.non_compliant ?? 0
      overviewDataAcc.non_compliant +=
        props.overviewData[area]?.internally_non_compliant ?? 0
      overviewDataAcc.non_compliant +=
        props.overviewData[area]?.externally_non_compliant ?? 0
    })

    const labelToValue: Record<OverviewPieLabel, number> = {
      'Fully Compliant': overviewDataAcc.fully_compliant,
      'Partially/Non Compliant': overviewDataAcc.non_compliant,
      'Out Of Service': overviewDataAcc.out_of_service,
    }

    const filteredLabelToValue: Partial<Record<OverviewPieLabel, number>> = {}
    Object.entries(labelToValue).forEach(([key, value]) => {
      if (value > 0) {
        filteredLabelToValue[key as OverviewPieLabel] = value
      }
    })

    const traces: Data[] = [
      {
        type: 'pie',
        labels: Object.keys(filteredLabelToValue),
        values: Object.values(filteredLabelToValue),
        name: 'Compliant Sites',
        hole: 0.5,
        marker: {
          colors: Object.entries(labelToColor)
            .filter(([key]) => Object.keys(filteredLabelToValue).includes(key))
            .map(([, value]) => value),
        },
      },
    ]

    const hiddenLabels = Object.entries(labelToValue)
      .filter(([, value]) => !value)
      .map(([key]) => key)

    const layout = {
      hiddenlabels: hiddenLabels,
      paper_bgcolor: 'transparent',
      plot_bgcolor: 'transparent',
      font: {
        color: 'rgba(255,255,255,1)',
      },
      margin: { t: 10, b: 10 },
      autosize: true,
      width,
      height: 320,
    } as Partial<Plotly.Layout>

    return [traces, layout]
  }, [props.overviewData, height, width])

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      <div style={{ width, height, paddingTop: '.5rem' }}>
        <Plot
          data={traces}
          layout={layout}
          config={{
            displayModeBar: false,
            responsive: true,
          }}
          onLegendClick={() => false}
          useResizeHandler={true}
        />
      </div>
    </div>
  )
}

export default OverviewPie
