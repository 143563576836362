import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { TriageTableData } from './RecommendationsInterface'
import { asset_manager_theme } from '../../assets/themes'
import { PriorityPill } from '../../components/Common/PriorityPill'
import { StatusPill } from '../../components/Common/StatusPill'
import { trackEvent } from '../../components/helpers/mixpanel'
import { Pill } from '../../components/Common/Pill'
import dayjs from 'dayjs'
import { Tooltip, Table as AntDTable } from 'antd'
import { InspectionSchedule } from '../AssetDatabaseTab/AssetDatabaseTabInterface'
import { toTitleCase } from '../../components/helpers/stringFilters'

const TableWrapper = styled.div`
  width: inherit;
  height: 66vh;
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: ${asset_manager_theme.text.c1};
`

const TableHeader = styled.th`
  color: ${asset_manager_theme.text.c4};
  padding: 10px;
  border-bottom: 1px solid ${asset_manager_theme.background.background_color6};
  font-weight: normal;
  font-size: 14px;
  position: sticky; /* Make the header sticky */
  top: 0; /* Set the header to stick to the top of the table */
  z-index: 2; /* Ensures the header is above other content */
  background-color: ${asset_manager_theme.background.background_color8};
`
const TableRow = styled.tr`
  line-height: 1.8rem;
  transition: 0.1s ease-out;

  &:hover {
    cursor: pointer;
    background-color: ${asset_manager_theme.background.background_color8};
    border-radius: 6px;
    padding: 1rem;
  }
`

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
  font-family: ${asset_manager_theme.text.font_family2};
`

const BoldTableCell = styled.td`
  padding: 10px;
  text-align: center;
  font-family: ${asset_manager_theme.text.font_family2};
  font-weight: bold;
`

interface RecommendationsTableProps {
  triage_table_data: TriageTableData[] | undefined
  setSelectedFdeUnit: (FdeUnit: string) => void
}

export const SortTableHeadCell = styled.th<{
  isSelected: boolean
  sortOrder: string | undefined
}>`
  color: ${asset_manager_theme.text.c4};
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid ${asset_manager_theme.text.c3};
  font-weight: normal;
  position: sticky; /* Make the header sticky */
  top: 0; /* Set the header to stick to the top of the table */
  z-index: 2; /* Ensures the header is above other content */
  background-color: ${asset_manager_theme.background.background_color8};

  :hover {
    background-color: ${asset_manager_theme.background.background_color6};
  }

  &::after {
    content: ${({ isSelected, sortOrder }) => {
      if (isSelected) {
        return sortOrder === 'asc' ? '" ▲"' : '" ▼"'
      }
      return '""'
    }};
  }

  border-bottom: 1px solid ${asset_manager_theme.background.background_color6};
`

const RecommendationsTable: React.FC<RecommendationsTableProps> = ({
  triage_table_data,
  setSelectedFdeUnit,
}) => {
  const handleAssetNameClick = (asset: TriageTableData) => {
    trackEvent('Button Clicked', {
      buttonName: 'asset_recommendations',
      pageName: 'Triage Recommendations',
      assetDetails: { assetName: asset?.asset_name, assetSlug: asset?.slug },
    })
    if (asset.fde_customer_unit) {
      setSelectedFdeUnit(asset.fde_customer_unit)
    }
  }

  const [sortColumn, setSortColumn] = useState<string | null>('rec_count')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(undefined)

  const toggleSortOrder = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const priorityOrder: { [key: string]: number } = { Low: 0, Medium: 1, High: 2, Top: 3 }
  const actionTakenOrder: { [key: string]: number } = {
    'Needs Review': 0,
    Approved: 1,
  }

  const sortedData = useMemo(() => {
    if (!triage_table_data) return []

    return triage_table_data
      .map((item) => ({
        ...item,
        latestLastDate: findLatestLastDate(item?.inspection_schedule),
      }))
      .sort((a, b) => {
        let aVal = Number.MAX_VALUE
        let bVal = Number.MAX_VALUE

        if (sortColumn === 'latest_last_date') {
          const dateA = a.latestLastDate ? dayjs(a.latestLastDate).valueOf() : 0
          const dateB = b.latestLastDate ? dayjs(b.latestLastDate).valueOf() : 0
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
        }

        if (sortColumn === 'priority') {
          aVal = priorityOrder[a.priority || 'Low'] ?? Number.MAX_VALUE
          bVal = priorityOrder[b.priority || 'Low'] ?? Number.MAX_VALUE
        } else if (sortColumn === 'action_taken') {
          aVal = actionTakenOrder[a.action_taken || 'Needs Review'] ?? Number.MAX_VALUE
          bVal = actionTakenOrder[b.action_taken || 'Needs Review'] ?? Number.MAX_VALUE
        } else if (
          new Set([
            'operational_criticality_rating',
            'rec_count',
            'total_cost',
            'remaining_life',
          ]).has(sortColumn || '')
        ) {
          aVal =
            a[
              sortColumn as
                | 'operational_criticality_rating'
                | 'rec_count'
                | 'total_cost'
                | 'remaining_life'
            ] ?? Number.MAX_VALUE
          bVal =
            b[
              sortColumn as
                | 'operational_criticality_rating'
                | 'rec_count'
                | 'total_cost'
                | 'remaining_life'
            ] ?? Number.MAX_VALUE
        }

        return sortOrder === 'asc' ? aVal - bVal : bVal - aVal
      })
  }, [triage_table_data, sortColumn, sortOrder])

  function findLatestLastDate(
    schedule: InspectionSchedule | undefined | null
  ): string | null {
    if (!schedule) {
      return null
    }

    let latestDateString: string | null = null

    function updateLatestDate(dateStr: string | null | undefined) {
      if (dateStr) {
        const currentDate = dayjs(dateStr)
        if (!currentDate.isValid()) {
          return
        }
        const latestDate = latestDateString ? dayjs(latestDateString) : null
        if (!latestDate || currentDate.isAfter(latestDate)) {
          latestDateString = currentDate.format('YYYY-MM-DD')
        }
      }
    }

    const keys: (keyof InspectionSchedule)[] = ['external', 'internal']
    for (const key of keys) {
      const inspectionType = schedule[key]
      if (inspectionType) {
        for (const detail of Object.values(inspectionType)) {
          updateLatestDate(detail.last)
        }
      }
    }

    return latestDateString
  }

  type InspectionTypeKey = keyof InspectionSchedule

  // Function to render inspection schedule as a table in the tooltip
  function renderScheduleTable(inspectionSchedule?: InspectionSchedule) {
    if (!inspectionSchedule) {
      return <span>No schedule data available.</span>
    }

    interface DataSourceItem {
      key: string
      method: string
      last: string
      next: string
      intervalMonths: string
    }

    const dataSource: DataSourceItem[] = []
    const columns = [
      { title: 'Method', dataIndex: 'method', key: 'method' },
      { title: 'Last', dataIndex: 'last', key: 'last' },
      { title: 'Next', dataIndex: 'next', key: 'next' },
      { title: 'Interval (Months)', dataIndex: 'intervalMonths', key: 'intervalMonths' },
    ]

    const inspectionTypes: InspectionTypeKey[] = ['external', 'internal']
    inspectionTypes.forEach((type) => {
      const inspectionType = inspectionSchedule[type]
      if (inspectionType) {
        Object.entries(inspectionType).forEach(([method, details]) => {
          dataSource.push({
            key: `${type}-${method}`,
            method: `${type} - ${method}`,
            last: details.last || '--',
            next: details.next || '--',
            intervalMonths:
              typeof details.interval_months === 'number'
                ? Number.isInteger(details.interval_months)
                  ? details.interval_months.toString()
                  : details.interval_months.toFixed(2)
                : '--',
          })
        })
      }
    })

    return (
      <AntDTable
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        size='small'
      />
    )
  }

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <TableHeader style={{ textAlign: 'left' }}>Asset Name</TableHeader>
            <SortTableHeadCell
              style={{ textAlign: 'center' }}
              isSelected={sortColumn === 'area'}
              sortOrder={sortOrder}>
              Area
            </SortTableHeadCell>
            <SortTableHeadCell
              style={{ textAlign: 'center' }}
              isSelected={sortColumn === 'latest_last_date'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('latest_last_date')}>
              Latest Inspection
            </SortTableHeadCell>
            <SortTableHeadCell
              isSelected={sortColumn === 'priority'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('priority')}>
              Priority
            </SortTableHeadCell>
            {/* <SortTableHeadCell
              isSelected={sortColumn === 'remaining_life'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('remaining_life')}>
              Time to Retire (yrs)
            </SortTableHeadCell>
            <SortTableHeadCell
              isSelected={sortColumn === 'operational_criticality_rating'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('operational_criticality_rating')}>
              Operational Criticality
            </SortTableHeadCell> */}
            <SortTableHeadCell
              isSelected={sortColumn === 'rec_count'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('rec_count')}>
              Plans
            </SortTableHeadCell>

            {/*<SortTableHeadCell*/}
            {/*  isSelected={sortColumn === 'total_cost'}*/}
            {/*  sortOrder={sortOrder}*/}
            {/*  onClick={() => toggleSortOrder('total_cost')}>*/}
            {/*  Est. Total Cost*/}
            {/*</SortTableHeadCell>*/}
            <SortTableHeadCell
              isSelected={sortColumn === 'action_taken'}
              sortOrder={sortOrder}
              onClick={() => toggleSortOrder('action_taken')}>
              Status
            </SortTableHeadCell>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((item, index) => (
            <TableRow key={index} onClick={() => handleAssetNameClick(item)}>
              <BoldTableCell style={{ textAlign: 'left' }}>
                {item.asset_name}
                {item.rec_count === 0 && (
                  <Pill text={'new'} highViz={true}>
                    {' '}
                    New{' '}
                  </Pill>
                )}
              </BoldTableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {toTitleCase(item.area || '')}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Tooltip
                  overlayStyle={{
                    maxWidth: 'max-content',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  title={renderScheduleTable(item?.inspection_schedule)}>
                  {findLatestLastDate(item?.inspection_schedule) ?? '--'}
                </Tooltip>
              </TableCell>
              <TableCell style={{ justifyContent: 'center', alignItems: 'center' }}>
                <PriorityPill text={item.priority}>{item.priority}</PriorityPill>
              </TableCell>
              {/* <TableCell>
                {item.remaining_life && item.remaining_life < 0
                  ? 'Below Retirement'
                  : item.remaining_life}
              </TableCell>
              <TableCell>{item.operational_criticality_rating}</TableCell> */}
              <TableCell style={{ color: asset_manager_theme.text.c1 }}>
                {item?.plans?.length ? item?.plans?.length : '--'}
              </TableCell>
              {/*<TableCell>*/}
              {/*  $*/}
              {/*  {(item?.total_cost ?? 0) > 99999*/}
              {/*    ? `${Math.round((item.total_cost || 0) / 1000) / 1000}MM`*/}
              {/*    : `${item.total_cost || 0}`}*/}
              {/*</TableCell>*/}
              <TableCell>
                {item?.action_taken ? (
                  <StatusPill
                    text={
                      item.action_taken === 'Escalated' ? 'Approved' : 'Needs Review'
                    }>
                    {item.action_taken === 'Escalated' ? 'Approved' : 'Needs Review'}
                  </StatusPill>
                ) : (
                  '--'
                )}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

export default RecommendationsTable
