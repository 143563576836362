import React, { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components'
import { asset_manager_theme } from '../assets/themes'
import { Spin } from 'antd'

interface KPICardProps {
  label: string
  value: string | number | undefined
  color?: string
  delta?: number
  deltaPeriod?: string
  loading?: boolean
  key: string
  clickCallBack?: (enabling: boolean) => void
  isClickable?: boolean
}

interface KPINumberProps {
  colorProp?: string
}

const KPINumber = styled.div<KPINumberProps>`
  color: ${(props) => props.colorProp || 'white'};
  font-size: 4rem;
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: left;
  padding-left: 0.5rem;
`

const KPILabel = styled.div`
  color: ${asset_manager_theme.text.c1};
  text-align: left;
  font-size: 1rem;
  padding-left: 0.5rem;
  font-weight: bold;
`

const KPIDelta = styled.div`
  color: ${asset_manager_theme.background.background_color2};
  font-size: 0.9rem;
  padding-left: 0.5rem;
  text-align: left;
`

export const KPICard: FC<KPICardProps> = (props) => {
  const [kpiSelected, setKpiSelected] = React.useState<boolean>(false)

  const handleKPIClick = () => {
    if (props.clickCallBack) {
      props.clickCallBack(kpiSelected)
      setKpiSelected((prev) => !prev)
    }
  }

  return (
    <CardDiv
      selectedKPI={kpiSelected}
      label={props.label}
      isClickable={props.isClickable || false}
      onClick={() => handleKPIClick()}>
      <Spin spinning={!!props.loading}>
        <KPINumber colorProp={props.color}>{props.value}</KPINumber>
        <KPILabel>{props.label}</KPILabel>
        {props.delta && (
          <KPIDelta>
            {props.delta > 0 ? '+' : ''}
            {props.delta} since last{' '}
            {(props.deltaPeriod && props.deltaPeriod.toLowerCase()) || 'week'}
          </KPIDelta>
        )}
      </Spin>
    </CardDiv>
  )
}

const CardDiv = styled.div<{
  selectedKPI: boolean
  label: string
  isClickable: boolean
}>`
  width: 30%;
  max-height: 12rem;
  padding: 1.5rem;
  background-color: ${asset_manager_theme.background.background_color8};
  border-radius: 6px;
  margin: 0 1rem;
  text-align: center;
  display: flex;
  flex-direction: Column;
  transition: 0.3s ease-out;

  &:hover {
    ${(props) =>
      props.isClickable &&
      props.selectedKPI &&
      `background-color: ${asset_manager_theme.background.background_color6_5};`}
  }

  ${(props) =>
    props.selectedKPI &&
    `border: 1px solid ${asset_manager_theme.background.background_color3};
    background-color: ${asset_manager_theme.background.background_color7}
    `}
`
