import React, { FC, useContext, useLayoutEffect, useState } from 'react'
import { PageContainer, Row, Column } from '../../components/Common/Grid'
import { Button, Modal, Tabs, Select, Tooltip } from 'antd'
import type { TabsProps } from 'antd'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker
import { GraphTableComponent } from './InspectionGraphTable'
import dayjs, { Dayjs } from 'dayjs'
import { TabHeader } from '../../components/Common/TabHeader'
import { asset_manager_theme } from '../../assets/themes'
import { SiteSettings } from './SiteSettings/SiteSettings'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useGetOutageSchedule } from '../../util/RolodexInterface'
import { trackEvent } from '../../components/helpers/mixpanel'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import {
  DATE_GRANULARITY_MAP,
  NUM_WEEK_PER_MONTH,
  NUM_WEEK_PER_YEAR,
} from '../../util/constants'
import { toTitleCase } from '../../components/helpers/stringFilters'
import { useRolodexEntries } from '../../api/useRolodex'
import { SettingOutlined } from '@ant-design/icons'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { TabKey } from '../AppLayout'

type TabOption = {
  key: string
  label: React.ReactNode
}

interface InspectionSchedulingProps {
  setFdeCustomerUnitSlug: (slug: string) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
}

export const getLabelByKey = (key: string, options: TabOption[]): string => {
  const tab = options.find((tab) => tab.key === key)
  return tab ? (tab.label as string) : ''
}

// Target load calculation on graph granularity
const calculateTargetLoad = (
  weeklyTargetLoad: number | undefined,
  graphGranularity: string
) => {
  let adjustedTargetLoad = weeklyTargetLoad ?? 0

  if (graphGranularity === 'month') {
    adjustedTargetLoad = adjustedTargetLoad * NUM_WEEK_PER_MONTH
  } else if (graphGranularity === 'year') {
    adjustedTargetLoad = adjustedTargetLoad * NUM_WEEK_PER_YEAR
  }
  return adjustedTargetLoad
}

export const InspectionSchedulingTab: FC<InspectionSchedulingProps> = (props) => {
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const [SelectedTab, setSelectedTab] = useState<'due' | 'scheduled' | 'history'>('due')
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false)
  const inspectionSchedulingFeatureFlags = userFeatureFlags?.[TabKey.inspectionScheduling]

  useLayoutEffect(() => {
    if (inspectionSchedulingFeatureFlags?.defaultView) {
      setSelectedTab(inspectionSchedulingFeatureFlags.defaultView)
    }
  }, [inspectionSchedulingFeatureFlags?.defaultView])

  const onTabChange = (key: 'due' | 'scheduled' | 'history', label: string) => {
    setSelectedTab(key)

    const buttonName = label.toLowerCase().replace(/\s/g, '_')
    trackEvent('Button Clicked', {
      buttonName: buttonName + '_tab',
      pageName: 'Inspection Scheduling',
    })
  }

  const [graphGranularity, setGraphGranularity] = useState<'month' | 'year' | 'week'>(
    (inspectionSchedulingFeatureFlags?.defaultDateGranularity as
      | 'month'
      | 'year'
      | 'week') ?? 'month'
  )
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf('month'))
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs()
      .add(inspectionSchedulingFeatureFlags?.graphMonthWindow ?? 12, 'month')
      .endOf('month')
  )
  const [graphView, setGraphView] = useState<'counts' | 'hours'>('counts')
  const [barMode, setBarMode] = useState<'stack' | 'group'>('stack')
  const [weeklyTargetLoad, setWeeklyTargetLoad] = useState<number | undefined>()
  const [breakInInput, setBreakInInput] = useState<number | undefined>()

  const OutageScheduleSWR = useGetOutageSchedule(siteData?.siteSlug)
  // Get inspection hours value from fde-inspection-schedule-config
  const fdeInspectionScheduleConfigSwr = useRolodexEntries({
    type: RolodexEntryType.INSPECTION_SCHEDULE_CONFIG_TYPE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  })

  useLayoutEffect(() => {
    if (inspectionSchedulingFeatureFlags?.defaultGraphView) {
      setGraphView(inspectionSchedulingFeatureFlags.defaultGraphView)
    }
  }, [inspectionSchedulingFeatureFlags?.defaultGraphView])

  React.useEffect(() => {
    if (!fdeInspectionScheduleConfigSwr.error && fdeInspectionScheduleConfigSwr.data) {
      const loadedWeeklyTargetLoad =
        fdeInspectionScheduleConfigSwr.data[0]?.entry?.data?.weekly_target_load
      const loadedBreakInHours =
        fdeInspectionScheduleConfigSwr.data[0]?.entry?.data?.break_in_hours

      setWeeklyTargetLoad(loadedWeeklyTargetLoad ?? undefined)
      setBreakInInput(loadedBreakInHours ?? undefined)
    }
  }, [fdeInspectionScheduleConfigSwr.data, fdeInspectionScheduleConfigSwr.error])

  const tabOptions: TabsProps['items'] = [
    {
      key: 'due',
      label: 'Due and Unscheduled',
    },
    {
      key: 'scheduled',
      label: 'Scheduled Inspections',
    },
    {
      key: 'history',
      label: 'Inspection History',
    },
  ]

  return (
    <PageContainer style={{ maxWidth: '100%' }}>
      <TabHeader>Inspection Scheduling</TabHeader>
      <Row
        style={{
          maxHeight: '4%',
          color: 'white',
          fontSize: '1.5rem',
        }}>
        <Column>
          <Tabs
            items={tabOptions}
            activeKey={SelectedTab}
            onChange={(key) =>
              onTabChange(
                key as 'due' | 'scheduled' | 'history',
                getLabelByKey(key, tabOptions)
              )
            }
          />
        </Column>

        <Column
          style={{
            maxWidth: 'max-content',
            margin: '0 1.5rem 0 0',
            borderColor: asset_manager_theme.background.background_color6,
          }}>
          <Select
            value={graphGranularity}
            onChange={(value) => {
              setGraphGranularity(value)
            }}
            options={Object.keys(DATE_GRANULARITY_MAP).map((key) => ({
              label: toTitleCase(key),
              value: key,
            }))}
          />
        </Column>
        {(userFeatureFlags?.superUser ||
          inspectionSchedulingFeatureFlags?.enableHours) && (
          <Column
            style={{
              maxWidth: 'max-content',
              margin: '0 1.5rem 0 0',
              borderColor: asset_manager_theme.background.background_color6,
            }}>
            <Select
              value={graphView}
              onChange={(value) => {
                setGraphView(value)
              }}
              style={{ width: '11rem' }}
              options={[
                { value: 'counts', label: 'Inspection Counts' },
                { value: 'hours', label: 'Hours' },
              ]}
            />
          </Column>
        )}
        <Column
          style={{
            maxWidth: 'max-content',
            margin: '0 1.5rem 0 0',
            borderColor: asset_manager_theme.background.background_color6,
          }}>
          <Select
            value={barMode}
            onChange={(value) => {
              setBarMode(value)
            }}
            style={{ width: '7rem' }}
            options={[
              { value: 'stack', label: 'Stacked' },
              { value: 'group', label: 'Grouped' },
            ]}
          />
        </Column>
        <Column style={{ maxWidth: '15%' }}>
          {' '}
          <RangePicker
            picker={'month'}
            defaultValue={[
              dayjs(),
              dayjs().add(
                inspectionSchedulingFeatureFlags?.graphMonthWindow ?? 12,
                'month'
              ),
            ]}
            value={[startDate, endDate]}
            allowClear={false}
            onChange={(values) => {
              setStartDate(values?.[0]?.startOf('month') || null)
              setEndDate(values?.[1]?.endOf('month') || null)
            }}
          />
        </Column>
        {/* Outage Modal Trigger Button */}
        {(userFeatureFlags?.superUser ||
          inspectionSchedulingFeatureFlags?.enableOutages ||
          inspectionSchedulingFeatureFlags?.enableHours) && (
          <Column
            style={{
              maxWidth: 'max-content',
              margin: '0 0.5rem 0 1rem',
              borderColor: asset_manager_theme.background.background_color6,
            }}>
            <Button ghost={true} onClick={() => setIsSettingsModalVisible(true)}>
              <Tooltip title='Settings'>
                <SettingOutlined />
              </Tooltip>
            </Button>
          </Column>
        )}
      </Row>
      <Row
        style={{
          justifyContent: 'center',
          maxHeight: '35%',
        }}>
        <GraphTableComponent
          inspectionGroupingColumns={
            fdeInspectionScheduleConfigSwr.data?.[0]?.entry?.data
              ?.inspection_grouping_columns
          }
          resourceMapping={
            fdeInspectionScheduleConfigSwr.data?.[0]?.entry?.data?.resource_mapping
          }
          tabKey={SelectedTab}
          graphGranularity={graphGranularity}
          tabLabel={getLabelByKey(SelectedTab, tabOptions)}
          startDate={startDate}
          endDate={endDate}
          outageScheduleSWR={
            userFeatureFlags?.superUser ||
            userFeatureFlags?.[TabKey.inspectionScheduling]?.enableOutages
              ? OutageScheduleSWR
              : undefined
          }
          setFdeCustomerUnitSlug={props.setFdeCustomerUnitSlug}
          setAssetBaseballCardRefetchData={props.setAssetBaseballCardRefetchData}
          graphView={graphView}
          barMode={barMode}
          adjustedTargetLoad={calculateTargetLoad(weeklyTargetLoad, graphGranularity)}
          breakInHours={breakInInput}
          setStartGraphDate={setStartDate}
          setEndGraphDate={setEndDate}
          setGraphGranularity={setGraphGranularity}
        />
      </Row>

      {/* Outage Schedule Modal */}
      {(userFeatureFlags?.superUser ||
        inspectionSchedulingFeatureFlags?.enableOutages ||
        inspectionSchedulingFeatureFlags?.enableHours) && (
        <Modal
          title='Settings'
          open={isSettingsModalVisible}
          onCancel={() => {
            setIsSettingsModalVisible(false)
          }}
          footer={null}>
          <div
            style={{
              maxHeight: '70vh',
              overflowY: 'scroll',
              margin: '2rem 0rem 2rem 0rem',
            }}>
            <SiteSettings
              outageScheduleSwr={OutageScheduleSWR}
              fdeInspectionScheduleConfigSwr={fdeInspectionScheduleConfigSwr}
            />
          </div>
        </Modal>
      )}
    </PageContainer>
  )
}
