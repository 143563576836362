import React from 'react'
import { Modal, Radio, Space } from 'antd'

export interface ComplianceExportModalProps {
  open: boolean
  onClose: () => void
  selectedOption: 'table' | 'stats' | 'overdue'
  setOption: (option: 'table' | 'stats' | 'overdue') => void
  onOkCallback?: () => void
}

export const ComplianceExportModal: React.FC<ComplianceExportModalProps> = ({
  open,
  onClose,
  selectedOption,
  onOkCallback,
  setOption,
}) => {
  const radioOptions = [
    { label: 'Overdue Summary', value: 'stats' },
    { label: 'Overdue Assets', value: 'overdue' },
    { label: 'Table Data', value: 'table' },
  ]

  const generateOptions = (data: { label: string; value: string }[]) => {
    return data.map(({ label, value }) => {
      return <Radio value={value}>{label}</Radio>
    })
  }

  return (
    <Modal
      title='Select Report Format'
      open={open}
      onCancel={() => onClose()}
      onOk={() => {
        if (onOkCallback) {
          onOkCallback()
        }
        onClose()
      }}>
      <Radio.Group
        onChange={(event) => {
          setOption(event.target.value)
        }}
        value={selectedOption}>
        <Space direction='vertical'>{generateOptions(radioOptions)}</Space>
      </Radio.Group>
    </Modal>
  )
}
