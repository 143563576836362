import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

const Button = styled.button`
  background-color: ${asset_manager_theme.background.background_color6_5};
  border: none;
  border-radius: 4px;
  color: ${asset_manager_theme.text.c1};
  font-family: ${asset_manager_theme.text.font_family2};
  cursor: pointer;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  margin-left: 1.5rem;
  margin-bottom: 0.75rem;
  transition: 0.3s ease-out;
  &:hover {
    background-color: ${asset_manager_theme.background.background_color6};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export default Button
