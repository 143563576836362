import React, { useCallback, useContext } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { PriorityPill } from '../../../components/Common/PriorityPill'
import styled from 'styled-components'
import { RolodexPatchBatch } from '../../../util/RolodexInterface'
import { asset_manager_theme } from '../../../assets/themes'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'

const StyledDropDown = styled(Dropdown)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Apply your custom styles here
  div,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .anticon {
    margin-left: 5px; // Add more space if you prefer
  }
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
`

interface PlanRecPriorityDropDownProps {
  planRecId: string
  updateKey: string
  fieldValue: string
  rolodexType: string
  disabled?: boolean
  refetchData: () => void
}
export const PlanRecPriorityDropDown: React.FC<PlanRecPriorityDropDownProps> = ({
  planRecId,
  updateKey,
  fieldValue,
  rolodexType,
  disabled,
  refetchData,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()

  const menu = useCallback(
    (updateValue: (item: { key: string }) => void) => (
      <Menu onClick={updateValue}>
        <Menu.Item key='Top'>
          <PriorityPill text='Top'>Top</PriorityPill>
        </Menu.Item>
        <Menu.Item key='High'>
          <PriorityPill text='High'>High</PriorityPill>
        </Menu.Item>
        <Menu.Item key='Medium'>
          <PriorityPill text='Medium'>Medium</PriorityPill>
        </Menu.Item>
        <Menu.Item key='Low'>
          <PriorityPill text='Low'>Low</PriorityPill>
        </Menu.Item>
        <Menu.Item key='---'>
          <CenteredDiv>---</CenteredDiv>
        </Menu.Item>
      </Menu>
    ),
    []
  )

  const updateValue = async ({ key }: { key: string }) => {
    const formattedPriority = key === '---' ? null : key
    const patchBody = {
      id: planRecId,
      type: rolodexType,
      data: {
        [updateKey]: formattedPriority,
      },
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }

    RolodexPatchBatch([patchBody], auth0, siteData).then(() => {
      refetchData?.()
    })
  }

  return (
    <StyledDropDown overlay={menu(updateValue)} trigger={['click']} disabled={disabled}>
      <a onClick={(e) => e.preventDefault()}>
        {fieldValue && fieldValue !== '---' ? (
          <PriorityPill text={fieldValue}>{fieldValue}</PriorityPill>
        ) : (
          <CenteredDiv>---</CenteredDiv>
        )}
        <DownOutlined
          style={{ color: asset_manager_theme.background.background_color4 }}
        />
      </a>
    </StyledDropDown>
  )
}
