import React, { useMemo, useState } from 'react'
import { Row } from '../../../../components/Common/Grid'
import { Button, Card, Col, Collapse, Pagination, Tag } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { asset_manager_theme } from '../../../../assets/themes'
import { orderBy, partition, sortBy, sumBy } from 'lodash'
import { Asset, Plan } from '../../PlanModelingInterface'
import { AssetPlanSelection } from '../../PlanModelingTab'
import { calculateTCO, formatCurrency, maxCostPerYear } from './CostOptimizationUtil'

const { Panel } = Collapse

export const OptionsReview: React.FC<{
  sortedCombinations: Plan[][]
  assets: Asset[]
  selectedPlans: AssetPlanSelection
  onSelect: (plans: Plan[]) => void
  annualBudget?: number
}> = ({ sortedCombinations, assets, selectedPlans, onSelect, annualBudget }) => {
  const [minTCO, setMinTCO] = useState(Infinity)

  const [belowBudget, aboveBudget] = partition(sortedCombinations, (combo) =>
    annualBudget ? maxCostPerYear(combo, assets) < annualBudget : true
  )

  const isCurrentSelected = (combination: Plan[]): boolean => {
    const selectedPlanSlugs = Object.values(selectedPlans).map((plan) => plan.plan_slug)
    const combinationPlanSlugs = combination.map((plan) => plan.plan_slug)
    return combinationPlanSlugs.every((slug) => selectedPlanSlugs.includes(slug ?? ''))
  }

  const renderOption = (combination: Plan[], index: number, belowBudget: boolean) => {
    if (combination.length === 0) {
      return null
    }
    const tco = calculateTCO(combination)
    if (tco < minTCO) {
      setMinTCO(tco)
    }
    const isCurrentSelectedCombination = isCurrentSelected(combination)
    return (
      <Card
        key={index}
        style={{
          marginBottom: '16px',
          borderColor: asset_manager_theme.background.background_color2,
          opacity: annualBudget && !belowBudget ? 0.5 : 1,
        }}>
        <Row>
          <Col span={16}>
            <h4>Option {index + 1}</h4>
            <h2>{formatCurrency(tco)}</h2>
          </Col>
          <Col
            span={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}>
            <Row style={{ gap: '0.5rem' }}>
              {isCurrentSelectedCombination && (
                <Tag color='yellow' style={{ margin: '0px', height: '22px' }}>
                  Current Selection
                </Tag>
              )}
              {belowBudget && Boolean(annualBudget) && (
                <Tag color='cyan' style={{ margin: 0, height: '22px' }}>
                  Below Budget
                </Tag>
              )}
              {tco === minTCO && (
                <Tag color='green' style={{ margin: 0, height: '22px' }}>
                  Lowest TCO
                </Tag>
              )}
              {index === 0 && (
                <Tag
                  color='blue'
                  style={{
                    margin: 0,
                    height: '22px',
                  }}>
                  Recommended
                </Tag>
              )}
            </Row>
          </Col>
        </Row>
        <Collapse
          style={{
            border: 'transparent',
            boxShadow: 'none',
          }}>
          <Panel key={index} header={'Show Details'}>
            {orderBy(combination, (c) => c.total_plan_cost, 'desc').map((plan) => (
              <div key={plan.plan_slug}>
                <p>
                  <strong>{plan.asset_name}</strong> (
                  {formatCurrency(plan.total_plan_cost ?? '')})
                </p>
                {plan.plan_recs?.map((rec, idx) => (
                  <p key={idx}>
                    · {rec.scheduled_date}: {rec.recommendation}{' '}
                    {rec.repair_cost?.toString()
                      ? `( ${formatCurrency(rec.repair_cost)})`
                      : ''}
                  </p>
                ))}
              </div>
            ))}
            <p>
              <strong>
                Total Cost of Ownership: ${formatCurrency(calculateTCO(combination))}
              </strong>
            </p>
          </Panel>
        </Collapse>
        <Button
          type='primary'
          icon={<CheckOutlined />}
          onClick={() => onSelect(combination)}
          style={{
            position: 'absolute',
            bottom: '1.5rem',
            right: '1.5rem',
          }}>
          Select
        </Button>
      </Card>
    )
  }

  return (
    <Col
      span={18}
      style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        paddingRight: '2rem',
        paddingTop: '4rem',
      }}>
      {belowBudget.map((combo, idx) => renderOption(combo, idx, true))}
      {aboveBudget.length > 0 && (
        <Col>
          <h2 style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
            Plans Exceeding Annual Budget Requirements
          </h2>
          {aboveBudget.map((combo, idx) =>
            renderOption(combo, idx + belowBudget.length, false)
          )}
        </Col>
      )}
    </Col>
  )
}
