import { Input, Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface RenameModalProps {
  isRenameModalVisible: boolean
  onRename: () => void
  srcFilePath: string | null
  newName: string
  setNewName: React.Dispatch<React.SetStateAction<string>>
  onClose: () => void
}

// styled components
const FileInputWrapper = styled.div`
  display: flex;
`

const FileExtension = styled.span`
  border-left: none;
  padding: 4px 11px;
  display: flex;
  align-items: center;
`

export const RenameModal: React.FC<RenameModalProps> = ({
  isRenameModalVisible,
  onRename,
  srcFilePath,
  newName,
  setNewName,
  onClose,
}) => {
  // State to store prompt to remove/clear invlid characters.
  const [stripInvalidCharPromptVisible, setStripInvalidCharPromptVisible] =
    React.useState(false)
  // State for input field value
  const [inputValue, setInputValue] = React.useState('')
  const [shouldCallOnRename, setShouldCallOnRename] = React.useState(false)

  const [fileBaseName, setFileBaseName] = React.useState('')
  const [fileExtension, setFileExtension] = React.useState('')

  React.useEffect(() => {
    const fileName = inputValue
    const dotIndex = fileName.lastIndexOf('.')
    if (dotIndex > -1) {
      setFileBaseName(fileName.substring(0, dotIndex))
      setFileExtension(fileName.substring(dotIndex))
    } else {
      setFileBaseName(fileName)
      setFileExtension('')
    }
  }, [inputValue])

  const checkAndRename = () => {
    const newFileName = fileBaseName + fileExtension
    if (newFileName.includes('/')) {
      setStripInvalidCharPromptVisible(true)
    } else {
      setNewName(newFileName)
      setShouldCallOnRename(true)
    }
  }

  React.useEffect(() => {
    setInputValue(newName) // Reset input field when newName changes
  }, [newName])

  React.useEffect(() => {
    if (inputValue === newName && shouldCallOnRename) {
      onRename()
      setShouldCallOnRename(false)
    }
  }, [newName, inputValue, shouldCallOnRename])

  const stripAndRename = () => {
    const validName = inputValue.replace(/\//g, '') // Replace '/' with ''.
    setInputValue(validName)
    setStripInvalidCharPromptVisible(false)
  }

  const cancelStripInvalidChar = () => {
    setStripInvalidCharPromptVisible(false)
  }

  return (
    <>
      <Modal
        title='Rename'
        open={isRenameModalVisible}
        onOk={checkAndRename}
        onCancel={onClose}>
        <FileInputWrapper>
          <Input
            value={fileBaseName}
            onChange={(e) => setFileBaseName(e.target.value)}
            placeholder='Enter new file base name'
          />
          <FileExtension>{fileExtension}</FileExtension>
        </FileInputWrapper>
      </Modal>
      <Modal
        title='Invalid Character'
        open={stripInvalidCharPromptVisible}
        onOk={stripAndRename}
        onCancel={cancelStripInvalidChar}>
        <p>
          Invalid character '/' found in the name. Would you like to remove it and
          proceed?
        </p>
      </Modal>
    </>
  )
}
