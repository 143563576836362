import dayjs from 'dayjs'
import { Asset, Plan } from '../../PlanModelingInterface'
import { sumBy } from 'lodash'

export const maxCostPerYear = (plans: Plan[], assets: Asset[]): number => {
  const yearlyCosts: { [year: string]: number } = {}
  plans.forEach((plan) => {
    assets.forEach((asset) => {
      const assetPlan = asset.plans?.find((p) => p.plan_slug === plan.plan_slug)
      if (assetPlan) {
        assetPlan.plan_recs?.forEach((rec) => {
          if (!rec.scheduled_date) {
            return
          }
          const year = dayjs(rec.scheduled_date).get('year')
          if (!yearlyCosts[year]) {
            yearlyCosts[year] = 0
          }
          yearlyCosts[year] += rec.repair_cost ?? 0
        })
      }
    })
  })

  return Math.max(...Object.values(yearlyCosts))
}

export const calculateTCO = (combination: Plan[]): number =>
  sumBy(combination, (plan) => sumBy(plan.plan_recs, (rec) => rec.repair_cost ?? 0))

export const formatCurrency = (value: string | number) =>
  `$${parseFloat(value.toString()).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
