//this was directly copied from the forecast repo
import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Auth0Audience, isAuth0Authentication } from './../api/defines'
import { uniq } from 'lodash'

export interface AuthInfo {
  headers: HeadersInit | undefined
  userRole: string | undefined
  permissionTags: Record<string, string[]> | undefined
  accessibleFCUFields?: {
    [key: string]: string[]
  }
  is_Loading?: boolean
}
/**
 * A simple hook which returns required auth headers and permissions tags
 * (the latter are used for Rolodex queries)
 */
export const useAuthInfo: () => Readonly<AuthInfo> = () => {
  const auth0 = useAuth0()
  const [result, setResult] = React.useState<AuthInfo>({
    headers: undefined,
    userRole: undefined,
    permissionTags: undefined,
    accessibleFCUFields: undefined,
    is_Loading: true,
  })

  React.useMemo(() => {
    // If we're not using Auth0 authentication, we don't need an auth token or permissions tags
    if (!isAuth0Authentication()) {
      setResult({
        headers: { 'Content-Type': 'application/json' },
        userRole: undefined,
        accessibleFCUFields: undefined,
        permissionTags: {},
        is_Loading: false,
      })
      return
    }

    // Otherwise, grab the auth token and generate headers. Also derive permissions tags
    const options = { authorizationParams: { audience: Auth0Audience() } }
    auth0.getAccessTokenSilently(options).then((token) => {
      const base64Url = token.split('.')?.at(1) ?? ''
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      )
      const parsedJwt = JSON.parse(jsonPayload)
      const perms: string[] = parsedJwt.permissions ?? []
      const appData = parsedJwt['app_metadata'] ?? {}
      if (perms.find((p) => ['gecko:read', 'gecko:rolodex:edit'].includes(p)))
        setResult({
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          userRole: appData['asset_manager_user_type'] ?? 'gecko',
          permissionTags: {},
          is_Loading: false,
        })
      else {
        const rolodexPermissions = perms
          .filter((p) => p.startsWith('rolodex:'))
          .map((d) => d.split(':'))
        if (!rolodexPermissions || rolodexPermissions.length < 1)
          console.error('No permissions found, expecting 403 errors from Rolodex')
        else if (rolodexPermissions.some((d) => d.length !== 4))
          console.error(
            `Malformed permission found, expecting 403 errors from Rolodex: ${JSON.stringify(
              rolodexPermissions
            )}`
          )
        else if (
          // TODO Use RolodexPermission enum
          rolodexPermissions.some(
            (parts) => !['read', 'edit', 'admin', 'member'].includes(parts[3])
          )
        )
          console.error(
            `Unknown operation, expecting 403 errors from Rolodex: ${JSON.stringify(
              rolodexPermissions
            )}`
          )
        else {
          const groupedPermissions = rolodexPermissions.reduce((acc, parts) => {
            if (!acc[parts[1]]) {
              acc[parts[1]] = []
            }
            //dedupe in the reduce
            acc[parts[1]] = uniq([...acc[parts[1]], parts[2]])

            return acc
          }, {} as Record<string, string[]>)
          setResult({
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            userRole: appData['asset_manager_user_type'],
            accessibleFCUFields: appData['asset_manager_accessible_entries'],
            permissionTags: groupedPermissions,
            is_Loading: false,
          })
        }
      }
    })
  }, [auth0])

  return result
}
