import React, { useMemo } from 'react'
import { Button, Slider, Row, DatePicker, Col } from 'antd'
const RangePicker = DatePicker.RangePicker
import _ from 'lodash'
import { trackEvent } from '../helpers/mixpanel'

interface TableDateRangeFilterProps {
  setSelectedKeys: (selectedKeys: React.Key[]) => void
  selectedKeys: React.Key[]
  confirm: () => void
  clearFilters?: () => void
}

export const TableDateRangeFilter: React.FC<TableDateRangeFilterProps> = ({
  setSelectedKeys,
  confirm,
  clearFilters,
}) => {
  return (
    <div
      className='custom-filter-dropdown ant-table-filter-dropdown'
      style={{ minWidth: '20rem', padding: '0.5rem 1rem', inset: '0' }}>
      <Row style={{ paddingBottom: '1rem' }}>
        <Col span={24}>
          <RangePicker
            style={{ width: '100%' }}
            allowClear={false}
            onChange={(dates) => {
              const [min, max] = Array.from(dates ?? [])
              setSelectedKeys(
                dates
                  ? [
                      {
                        min,
                        max,
                      } as any as React.Key,
                    ]
                  : []
              )
            }}
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: 'right' }}>
        <Button
          size='small'
          onClick={() => {
            clearFilters?.()
            setSelectedKeys([])
            confirm()
          }}
          style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type='primary'
          size='small'
          onClick={() => confirm()}
          style={{ width: 90, marginLeft: '.5rem' }}>
          Apply
        </Button>
      </Row>
    </div>
  )
}
