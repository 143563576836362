import React, { FC, useState, useMemo, useContext } from 'react'
import { AssetCompareTable } from './AssetCompareTable'
import styled from 'styled-components'
import { Button, Divider } from 'antd'
import { useGetRepairCostTtlTraces } from '../../api/useFDEBackend'
import TraceView from './TraceView'
import { useGetComponentData, useGetSupportedAssets } from '../../util/RolodexInterface'
import { ROLODEX_KEY } from '../../util/OverviewConstants'
import { ComponentDataValues } from '../../util/DataSchema'
import { RolodexEntry } from '../../api/useRolodex'
import { ComponentWithSlug } from '../../util/AssetTableHelper'
import _ from 'lodash'
import { StopOutlined } from '@ant-design/icons'
import { ComparisonView } from './ComparisonView/ComparisonView'
import { SiteContext } from '../../contextProviders/siteContextProvider'

const AssetSelectionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PreviewTableArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PreviewGraphArea = styled.div`
  width: 60%;
`

const ComparisonViewArea = styled.div`
  width: 100%;
`

export const AssetCompareTab: FC = () => {
  const siteData = useContext(SiteContext)
  const [selectedCompareSlugs, setSelectedCompareSlugs] = useState<string[]>([])
  const [selectedPreviewSlug, setSelectedPreviewslug] = useState<string | null>(null)
  const previewTraceSwr = useGetRepairCostTtlTraces([selectedPreviewSlug ?? ''])

  //Supported Assets
  const supportedAssetsSwr = useGetSupportedAssets(siteData?.siteSlug ?? '')
  const supportedAssets: string[] = React.useMemo(
    () =>
      supportedAssetsSwr.data
        ? supportedAssetsSwr.data?.map(
            (rolodexEntry: { entry: RolodexEntry }) =>
              rolodexEntry.entry.data?.capex_optimizer?.supported_assets ?? []
          )[0]
        : [],
    [supportedAssetsSwr.data]
  )

  const componentSwr = useGetComponentData(supportedAssets)
  const componentData: ComponentWithSlug[] | undefined = useMemo(() => {
    return componentSwr.data
      ? componentSwr.data
          .map((rolodexEntry: { entry: RolodexEntry }) => {
            const entryData: ComponentDataValues = rolodexEntry.entry.data?.[ROLODEX_KEY]
            if (
              entryData &&
              rolodexEntry.entry.id &&
              rolodexEntry.entry.tags?.component
            ) {
              // going of tags rather than the id due to demo slugs not matching actual ones
              return {
                ...entryData,
                slug: rolodexEntry.entry.tags?.component,
                rolodexId: rolodexEntry.entry.id,
              }
            }
          })
          .filter((component): component is ComponentWithSlug => component !== undefined)
      : undefined
  }, [componentSwr.data])

  return (
    <div>
      <AssetSelectionArea>
        <PreviewTableArea>
          <AssetCompareTable
            components={componentData ?? []}
            isLoading={componentSwr.isLoading}
            selectedCompareSlugs={selectedCompareSlugs}
            setSelectedCompareSlugs={setSelectedCompareSlugs}
            selectedPreviewSlug={selectedPreviewSlug}
            setSelectedPreviewSlug={setSelectedPreviewslug}
          />
          <Button
            onClick={() => setSelectedCompareSlugs([])}
            disabled={selectedCompareSlugs.length <= 0}>
            <StopOutlined />
            Reset
          </Button>
        </PreviewTableArea>
        <Divider type='vertical' />
        <PreviewGraphArea>
          <TraceView
            traces={[previewTraceSwr.data?.[selectedPreviewSlug ?? '']]}
            isLoading={previewTraceSwr.isLoading}
            title={
              _.find(
                componentData ?? [],
                (component) => component.slug === selectedPreviewSlug
              )?.asset_name
            }
          />
        </PreviewGraphArea>
      </AssetSelectionArea>
      <Divider type='horizontal' />
      <ComparisonViewArea>
        <ComparisonView
          assetSlugs={selectedCompareSlugs}
          components={componentData ?? []}
        />
      </ComparisonViewArea>
    </div>
  )
}
