import { Input, Modal, Select } from 'antd'
import React from 'react'
import { TreeNode } from '../Types/FileTreeTypes'
import { trackEvent } from '../../../helpers/mixpanel'

interface UploadModalProps {
  uploadModalVisible: boolean
  setUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  selectedFiles: FileList | null
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>
  selectedUploadPath: string
  setSelectedUploadPath: React.Dispatch<React.SetStateAction<string>>
  directories: TreeNode[]
  onUpload: (
    e?: React.ChangeEvent<HTMLInputElement>,
    fileList?: FileList,
    modalPath?: string
  ) => void
  assetName?: string
}

export const UploadModal: React.FC<UploadModalProps> = ({
  uploadModalVisible,
  setUploadModalVisible,
  selectedFiles,
  setSelectedFiles,
  selectedUploadPath,
  setSelectedUploadPath,
  directories,
  onUpload,
  assetName,
}) => {
  return (
    <Modal
      open={uploadModalVisible}
      onCancel={() => setUploadModalVisible(false)}
      onOk={() => {
        if (selectedFiles) {
          onUpload(undefined, selectedFiles, selectedUploadPath)
          setUploadModalVisible(false)
          for (let i = 0; i < selectedFiles.length; i++) {
            trackEvent('Button Clicked', {
              buttonName: 'upload_file',
              assetDetails: {
                assetName: assetName,
              },
              fileDetails: {
                fileName: selectedFiles[i].name,
              },
            })
          }
        }
      }}
      okText='Upload'>
      <h2>Select target directory</h2>
      <Select
        onChange={(folder) => {
          setSelectedUploadPath(folder)
        }}
        style={{ width: 400, marginTop: '1rem' }}>
        <Select.Option value=''>Root</Select.Option>
        {directories.map((dir) => (
          <Select.Option key={dir.key} value={dir.key}>
            {dir.key}
          </Select.Option>
        ))}
      </Select>
      <Input
        type='file'
        multiple
        onChange={(e) => setSelectedFiles(e.target.files)}
        style={{ marginTop: '2rem' }}
      />
    </Modal>
  )
}
