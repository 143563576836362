/**
 * This file defines the styled components (essentially, a .css file) for the LifeExtensionOptionsTab component.
 *
 * I am not a fan of defining these in the component file because it can make that file extremely long and unwieldy;
 * however, If there is a better way/location to do this, or if you simply disagree, PLEASE let me (Cole) know.
 *
 */

import styled from 'styled-components'
import { Row } from '../../../components/Common/Grid'

export const LEOTabContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const LEOTabHeaderRow = styled(Row)`
  flex: 0 1 max-content;
  padding-inline: 1rem;
`

export const LEOTableContainer = styled(Row)`
  margin: 0rem;
  max-height: 70%;
`

export const LEOInspectionReportsContainer = styled.div`
  overflow-y: auto;
  height: 90%;
  padding-inline: 1rem;
`
