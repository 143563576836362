import styled from 'styled-components'
import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { asset_manager_theme } from '../../assets/themes'

const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

interface TruncatedTextWithTooltipProps {
  text?: string
  maxCharacters: number
}

const TruncatedTextWithTooltip: React.FC<TruncatedTextWithTooltipProps> = ({
  text,
  maxCharacters,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleMouseEnter = () => {
    if (text && text.length > maxCharacters) {
      setIsTooltipVisible(true)
    }
  }

  const handleMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  const truncatedText = text
    ? text.length > maxCharacters
      ? `${text.slice(0, maxCharacters)}...`
      : text
    : ''

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Tooltip
        title={isTooltipVisible ? text : ''}
        open={isTooltipVisible}
        color={asset_manager_theme.background.background_color10}>
        <TruncatedText>{truncatedText}</TruncatedText>
      </Tooltip>
    </div>
  )
}

export default TruncatedTextWithTooltip
