import React, { useContext, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { asset_manager_theme } from '../../../assets/themes'
import {
  LifeExtensionOptions,
  LifeExtensionOptionsRecs,
  LifeExtensionOptionsTableData,
} from '../RecommendationsInterface'
import { Checkbox, Table } from 'antd'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import {
  LifeExtensionPlanRecColumns,
  LifeExtensionRecColumns,
} from './LifeExtensionOptionsTableColumns'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { useRolodexEntries } from '../../../api/useRolodex'
import { TabKey } from '../../AppLayout'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'

const EDITABLE_SOURCES = new Set(['User Gen', 'Gecko: Forecast'])

const TableWrapper = styled.div`
  padding: 0.5em;
  width: 100%;
  height: 100%;
  overflow: auto;
  .table-row-tentative {
    opacity: 50%;
  }
`

const TableHeader = styled.th`
  color: ${asset_manager_theme.text.c4};
  margin: 0.5em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  //border-bottom: 1px solid ${asset_manager_theme.text.c4};
  font-weight: normal;
`

const TableRow = styled.tr`
  line-height: 1.8rem;
`

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
  font-family: ${asset_manager_theme.text.font_family2};
`

const TotalsRow = styled.tr`
  line-height: 1.8rem;
  border-collapse: collapse;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${asset_manager_theme.text.c3};
`

interface LifeExtensionOptionsTable {
  lifeExtensionOptions?: LifeExtensionOptionsRecs
  isAllActions: boolean
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>
  refetch: () => void
}

type OptionsTableColumn =
  | 'action'
  | 'deadline'
  | 'priority'
  | 'source'
  | 'workOrderNo'
  | 'cost'
  | 'cluster'
  | 'checkbox'
  | 'lifeExtension'
// | 'budget'
// | 'department'

// Functional component
const LifeExtensionOptionsTableRebased: React.FC<LifeExtensionOptionsTable> = ({
  lifeExtensionOptions,
  isAllActions,
  setCheckedList,
  refetch,
}) => {
  const userFeatureFlags = useContext(FeatureFlagContext)
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()

  const fdePlanningConfigSwr = useRolodexEntries({
    type: RolodexEntryType.PLANNING_CONFIG_TYPE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
    },
  })

  const lockOutEnabled = userFeatureFlags?.[TabKey.recommendations]?.read_only ?? false

  // Extract and sort keys
  const planRecSlugs = lifeExtensionOptions ? Object.keys(lifeExtensionOptions) : []

  const formattedPlanRecs = useMemo(() => {
    return (
      (lifeExtensionOptions
        ? planRecSlugs.sort((a, b) => {
            const itemA = lifeExtensionOptions[a]
            const itemB = lifeExtensionOptions[b]

            const scheduledA = itemA.scheduled_date
              ? new Date(itemA.scheduled_date).getTime()
              : Infinity
            const scheduledB = itemB.scheduled_date
              ? new Date(itemB.scheduled_date).getTime()
              : Infinity

            if (scheduledA !== scheduledB) {
              return scheduledA - scheduledB
            } else {
              // Safely access the timestamp, or use Infinity if not available
              return (
                (itemA.created_timestamp || Infinity) -
                (itemB.created_timestamp || Infinity)
              )
            }
          })
        : []
      ).map((d) => {
        return {
          ...lifeExtensionOptions?.[d],
          id: d,
          editable: EDITABLE_SOURCES.has(lifeExtensionOptions?.[d]?.source as string),
        }
      }) as LifeExtensionOptionsTableData[]
    ).filter((d) => d)
  }, [lifeExtensionOptions])

  const refetchCallBack = () => {
    refetch()
    fdePlanningConfigSwr.mutate()
  }

  const getSelectOptions = (field: keyof LifeExtensionOptions) => {
    return [
      ...new Set(
        Object.values(lifeExtensionOptions ?? {})
          .map((d) => {
            return d?.[field]
          })
          .filter((d): d is string => typeof d === 'string')
      ),
    ].map((d: string) => {
      return { label: d, value: d }
    })
  }

  const TableColumns = useMemo(() => {
    if (isAllActions) {
      return LifeExtensionRecColumns(refetch)
    }
    return LifeExtensionPlanRecColumns(
      fdePlanningConfigSwr,
      refetchCallBack,
      lockOutEnabled,
      getSelectOptions
    )
  }, [
    isAllActions,
    LifeExtensionPlanRecColumns,
    LifeExtensionRecColumns,
    lockOutEnabled,
    refetch,
    fdePlanningConfigSwr.data,
  ])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: LifeExtensionOptions[]) => {
      setCheckedList(selectedRowKeys as string[])
    },
    getCheckboxProps: (record: LifeExtensionOptions) => ({
      name: record.recommendation,
    }),
  }

  return (
    <TableWrapper>
      <Table
        rowClassName={(record) => (record.is_tentative ? 'table-row-tentative' : '')}
        dataSource={formattedPlanRecs}
        columns={TableColumns}
        rowKey={(record) => record.id}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </TableWrapper>
  )
}

export default LifeExtensionOptionsTableRebased
