import React from 'react'

import {
  MainTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../components/TableComponents'
import {
  InspectionCountTimelineData,
  InspectionCountTimelineDataValue,
} from './TimelineTab'
import { Spin } from 'antd'

interface TimelineTableProps {
  timelineData: InspectionCountTimelineData
  loading?: boolean
}

export const TimelineDataTable: React.FC<TimelineTableProps> = ({
  timelineData,
  loading,
}) => {
  return (
    <TableContainer key={`_timeline_table_container`}>
      <Spin spinning={!!loading}>
        <MainTable key={`_timeline_table_main`}>
          <TableHead key={`_timeline_table_head`}>
            <TableRow key={`_timeline_table_row_year`}>
              <TableHeadCell key={`_timeline_table_head_cell_year`}>Year</TableHeadCell>
              {Object.keys(timelineData).map((year) => {
                return (
                  <TableHeadCell key={`_timeline_table_cell_year_${year}`}>
                    {year}
                  </TableHeadCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody key={`_timeline_table_body`}>
            <TableRow key={`_timeline_table_row_internal`}>
              <TableCell key={`_timeline_table_cell_internal`}>Internal</TableCell>
              {Object.values(timelineData).map(
                (data: InspectionCountTimelineDataValue, index) => {
                  return (
                    <TableCell key={`_timeline_table_cell_internal_${index}`}>
                      {data.countInternal}
                    </TableCell>
                  )
                }
              )}
            </TableRow>
            <TableRow key={`_timeline_table_row_external`}>
              <TableCell key={`_timeline_table_cell_external`}>External</TableCell>
              {Object.values(timelineData).map(
                (data: InspectionCountTimelineDataValue, index) => {
                  return (
                    <TableCell key={`_timeline_table_cell_external_${index}`}>
                      {data.countExternal}
                    </TableCell>
                  )
                }
              )}
            </TableRow>
            <TableRow key={`_timeline_table_row_total`}>
              <TableCell key={`_timeline_table_cell_total`}>Total</TableCell>
              {Object.values(timelineData).map(
                (data: InspectionCountTimelineDataValue, index) => {
                  return (
                    <TableCell key={`_timeline_table_cell_total_${index}`}>
                      {data.countInternal + data.countExternal}
                    </TableCell>
                  )
                }
              )}
            </TableRow>
          </TableBody>
        </MainTable>
      </Spin>
    </TableContainer>
  )
}
