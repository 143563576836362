import { Button, Modal } from 'antd'
import { Document, Page } from 'react-pdf'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import React from 'react'
import { Row } from '../../Grid'

interface PdfPreviewModalProps {
  pdfData: string | null
  numPages: number | null
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  setNumPages: React.Dispatch<React.SetStateAction<number | null>>
  onClose: () => void
}

export const PdfPreviewModal: React.FC<PdfPreviewModalProps> = ({
  pdfData,
  numPages,
  pageNumber,
  setPageNumber,
  setNumPages,
  onClose,
}) => {
  return (
    <Modal open={true} onCancel={onClose} footer={null} bodyStyle={{ margin: '16px' }}>
      <Document file={pdfData} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <Page
          pageNumber={pageNumber}
          scale={0.8}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <Row style={{ gap: '0.5rem', marginTop: '1rem' }}>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
          <LeftOutlined />
        </Button>
        <Button
          disabled={!numPages || pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}>
          <RightOutlined />
        </Button>
      </Row>
    </Modal>
  )
}
