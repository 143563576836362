import styled from 'styled-components'
import { LoadingScreen } from 'gecko-ui'
import { asset_manager_theme } from '../assets/themes'
import { TwoColumnDivContainer, SecondColumn } from './Common/LayoutContainer'

const LoadingScreenOverrideWrapper = styled.div`
  div {
    background-color: ${asset_manager_theme.background.background_color6} !important;
    width: 100%;
  }
`

export const LoadingContent = () => {
  return (
    <TwoColumnDivContainer>
      <SecondColumn>
        {' '}
        <LoadingScreenOverrideWrapper>
          <LoadingScreen />
        </LoadingScreenOverrideWrapper>
      </SecondColumn>
    </TwoColumnDivContainer>
  )
}
