import React from 'react'
import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'
import { HEADER_HEIGHT } from '../../styles/LayoutVars'

interface PanelWrapProps {
  active: boolean
}

const PanelWrap = styled.div<PanelWrapProps>`
  position: fixed;
  top: ${HEADER_HEIGHT};
  bottom: 0;
  right: 0;
  width: 65%;
  height: calc(100vh - ${HEADER_HEIGHT});
  transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
  transition: 0.3s ease-out;
  box-shadow: ${({ active }) => (active ? '-10px 0 500px rgba(0, 0, 0, 0.55)' : 'none')};
  z-index: 4;
  overflow-y: auto;
`

const Panel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  background-color: ${asset_manager_theme.background.background_color8};
`

interface SlideProps {
  active: boolean
  children: React.ReactNode
  width?: string
  panelWrapstyle?: React.CSSProperties
  panelStyle?: React.CSSProperties
}

const SlidePanel: React.FC<SlideProps> = ({
  active,
  children,
  width,
  panelWrapstyle = {},
  panelStyle = {},
}) => {
  return (
    <PanelWrap style={{ ...panelWrapstyle, width: width || '65%' }} active={active}>
      <Panel style={panelStyle}>{children}</Panel>
    </PanelWrap>
  )
}

export default SlidePanel
