import { FC, useContext, useEffect, useMemo, useState } from 'react'
import RadarModule, { MarkerData } from '../../components/RadarModule/RadarModule'
import { PageContainer, Row, Column } from '../../components/Common/Grid'
import { TabHeader } from '../../components/Common/TabHeader'
import { Button } from 'antd'
import { AssetMappingModal } from '../../components/RadarModule/AssetMappingModal'
import { useGetFDECustomerUnitData } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { FDECustomerUnit } from '../AssetDatabaseTab/AssetDatabaseTabInterface'
import { useRolodexEntries } from '../../api/useRolodex' // Needed for mapbox styling
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import NumberLabelComponent from '../RecommendationsTab/RecommendationsKPIBar'
import {
  useGetModelingKPIData,
  useGetScheduledInspections,
  useGetPlanData,
} from '../../api/useFDEBackend'
import dayjs from 'dayjs'
import { createPlanModelingKPIData } from '../../components/helpers/PlanModelingHelpers'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'

interface RadarTabProps {
  setBaseballCardFdeCustomerUnitSlug: (slug: string | undefined) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
}

export const RadarTab: FC<RadarTabProps> = (props) => {
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const [_, __, planModelingDataSWR] = useGetPlanData(authInfo, siteData?.siteSlug)

  const [FDECustomerUnitData, setFDECustomerUnitData] = useState<FDECustomerUnit[]>([])
  const [FDESiteData, setFDESiteData] = useState<Record<any, any> | undefined>(undefined)
  const [unlocatedUnits, setUnlocatedUnits] = useState<FDECustomerUnit[]>([])

  const unitDataSwr = useGetFDECustomerUnitData(authInfo, siteData)

  const FDESiteBody = {
    type: RolodexEntryType.FDE_SITE,
    limit: 1,
    tags: {
      site: siteData?.siteSlug,
    },
  }

  const defaultStartDate = dayjs()

  const scheduledInspectionGraphDataSWR = useGetScheduledInspections(
    authInfo,
    siteData?.siteSlug,
    defaultStartDate.format('YYYY-MM-DD'),
    defaultStartDate.add(1, 'year').format('YYYY-MM-DD'),
    false,
    'month'
  )

  useEffect(() => {
    props.setAssetBaseballCardRefetchData({
      func: () => unitDataSwr.mutate(),
    })
  }, [])

  const kpiData = useMemo(() => {
    const processedPlanData =
      planModelingDataSWR.data?.plan_modeling.map((d) => {
        d.selectedPlan = d.plans?.[0]
        return d
      }) ?? []
    const planModellingKpiData = createPlanModelingKPIData(
      processedPlanData ?? [],
      processedPlanData ?? [],
      {},
      {}
    )
    const inspectionCounts =
      //sum external counts
      (
        Object.values(
          scheduledInspectionGraphDataSWR.data?.counts.external ?? {}
        ) as number[]
      ).reduce((acc, cur) => {
        return acc + cur
      }, 0) +
      //sum internel counts
      (
        Object.values(
          scheduledInspectionGraphDataSWR.data?.counts.internal ?? {}
        ) as number[]
      ).reduce((acc, cur) => {
        return acc + cur
      }, 0)

    const [numberOfAssetsInPlan, capitalSpend, maintenanceSpend, totalSpend] =
      planModellingKpiData
    const outKPIData = [
      {
        label: <div>Scheduled Inspections</div>,
        number: inspectionCounts,
        cardTitle: 'In Next Year',
        color: 'white',
        bold: true,
      },
      {
        ...numberOfAssetsInPlan,
        cardTitle: 'In 10-Year Plan',
        label: 'Number of Assets',
      },
      {
        ...capitalSpend,
        label: <div>Capital Spend</div>,
      },
      {
        ...maintenanceSpend,
        label: <div>Maintenance Spend</div>,
      },
      {
        ...totalSpend,
        label: <div>Total Spend</div>,
      },
    ]
    return outKPIData
  }, [planModelingDataSWR.data?.plan_modeling, scheduledInspectionGraphDataSWR.data])

  const FDESiteSWR = useRolodexEntries(FDESiteBody)

  useEffect(() => {
    if (!unitDataSwr.error && !unitDataSwr.isLoading && unitDataSwr.data) {
      setFDECustomerUnitData(unitDataSwr.data as FDECustomerUnit[])
      setUnlocatedUnits(
        unitDataSwr.data.filter(
          (unit: FDECustomerUnit) =>
            !unit.entry.data.latitude || !unit.entry.data.longitude
        )
      )
    }
  }, [unitDataSwr.data, unitDataSwr.error, unitDataSwr.isLoading])

  useEffect(() => {
    if (!FDESiteSWR.error && !FDESiteSWR.isLoading && FDESiteSWR.data) {
      if (!FDESiteSWR.data?.[0]?.entry?.data?.radar) {
        return
      }
      setFDESiteData(FDESiteSWR.data)
    }
  }, [FDESiteSWR.data, FDESiteSWR.error, FDESiteSWR.isLoading])

  const markers = FDECustomerUnitData.filter(
    (unit) => unit.entry.data.latitude && unit.entry.data.longitude
  ).map((unit) => ({
    longitude: unit.entry.data.longitude,
    latitude: unit.entry.data.latitude,
    title: unit.entry.data.equip_description,
    id: unit.entry.id,
    color: unit.entry.data.marker_color,
  }))

  const [tempMarker, setTempMarker] = useState<mapboxgl.Marker | null>(null)
  const [addingAsset, setAddingAsset] = useState(false)
  const [addAssetModalVisible, setAddAssetModalVisible] = useState(false)
  const toggleAddingAsset = () => setAddingAsset(!addingAsset)

  if (!FDESiteData?.[0]?.entry?.data?.radar) {
    return <div>No site data found</div>
  }

  return (
    <PageContainer style={{ maxWidth: '100%', maxHeight: '80vh' }}>
      <TabHeader style={{ maxHeight: 'none', justifyContent: 'space-between' }}>
        <Row>
          <Column>Radar</Column>{' '}
          <Column style={{ maxWidth: '10%' }}>
            {' '}
            <Button onClick={toggleAddingAsset}>
              {addingAsset ? 'Cancel Adding' : 'Add Asset'}
            </Button>
          </Column>
        </Row>
      </TabHeader>
      {(userFeatureFlags?.radar?.enableKPIs || userFeatureFlags?.superUser) && (
        <Row>
          <NumberLabelComponent input={kpiData} />
        </Row>
      )}
      <Row>
        {FDESiteData?.[0].entry.data.radar && (
          <RadarModule
            center={FDESiteData?.[0].entry.data.radar}
            style={{ height: '70vh', width: '90vw', borderRadius: '0.5rem' }}
            markers={markers as MarkerData[]}
            setBaseballCardFdeCustomerUnitSlug={props.setBaseballCardFdeCustomerUnitSlug}
            setAddAssetModalVisible={setAddAssetModalVisible}
            tempMarker={tempMarker}
            setTempMarker={setTempMarker}
            addingAsset={addingAsset}
          />
        )}
      </Row>
      <AssetMappingModal
        tempMarker={tempMarker}
        style={{ top: 200, left: -330 }}
        availableAssets={unlocatedUnits}
        setAddingAsset={setAddingAsset}
        assetModalVisible={addAssetModalVisible}
        setAssetModalVisible={setAddAssetModalVisible}
        refetchData={() => unitDataSwr.mutate()}
      />
    </PageContainer>
  )
}
