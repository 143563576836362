import { ButtonHTMLAttributes, FC } from 'react'
import { DoubleRightOutlined } from '@ant-design/icons'

import Button from './Button'
import styled from 'styled-components'

/**
 * A styled button which looks like 2 chevrons pointing right
 *
 * Note: this does not close anything by default, the close-function must be passed into the onClick prop
 * @param props
 * @constructor
 */
export const CloseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <ButtonOverride {...props}>
      <DoubleRightOutlined />
    </ButtonOverride>
  )
}

const ButtonOverride = styled(Button)`
  margin: 0 0.5rem 0 0.5rem;
`
