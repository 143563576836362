const { REACT_APP_ENVIRONMENT } = process.env

/*
DON'T PUT SENSITIVE VALUES IN THIS FILE
 */

const PROD_ENV = 'prod'
const LOCAL_ENV = 'local'

const AUTH0_CLIENT_ID_DEV = 'asmmm0979lBskKGlO2COA5yXoYv6XI0K'
const AUTH0_CLIENT_ID_PROD = 'QJ9ITuJjvlQ5AdwzVM0hVjjtXQuVh8DK'

export const Auth0ClientId = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_CLIENT_ID_PROD : AUTH0_CLIENT_ID_DEV
}

const AUTH0_URL_DEV = 'login.dev.geckorobotics.com'
const AUTH0_URL_PROD = 'login.geckorobotics.com'

export const Auth0Url = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_URL_PROD : AUTH0_URL_DEV
}

const FDE_BACKEND_URL_DEV = 'https://fde-backend.dev.cloud.geckorobotics.com/api/v1'
// const FDE_BACKEND_URL_LOCAL = 'http://0.0.0.0:8080/api/v1'
const FDE_BACKEND_URL_PROD = 'https://fde-backend.cloud.geckorobotics.com/api/v1'

const OBJECT_SERVICE_URL_DEV = 'https://object-service.dev.cloud.geckorobotics.com/api/v1'
const OBJECT_SERVICE_URL_PROD = 'https://object-service.cloud.geckorobotics.com/api/v1'

const PORTAL_SERVICE_URL_DEV = 'https://portal-service.dev.cloud.geckorobotics.com/api/v1'
const PORTAL_SERVICE_URL_PROD = 'https://portal-service.cloud.geckorobotics.com/api/v1'

const ROLODEX_URL_DEV = 'https://rolodex.dev.cloud.geckorobotics.com/api/v2'
const ROLODEX_URL_PROD = 'https://rolodex.cloud.geckorobotics.com/api/v2'
const ROLODEX_URL_LOCAL = 'http://localhost:8080/api/v2'

export const fdeBackendUrl = () => {
  switch (REACT_APP_ENVIRONMENT) {
    case PROD_ENV:
      return FDE_BACKEND_URL_PROD
    default:
      return FDE_BACKEND_URL_DEV
  }
}

export const objectServiceUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? OBJECT_SERVICE_URL_PROD
    : OBJECT_SERVICE_URL_DEV
}

export const portalServiceUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? PORTAL_SERVICE_URL_PROD
    : PORTAL_SERVICE_URL_DEV
}

export const rolodexUrl = () => {
  switch (REACT_APP_ENVIRONMENT) {
    case PROD_ENV:
      return ROLODEX_URL_PROD
    case LOCAL_ENV:
      return ROLODEX_URL_LOCAL
    default:
      return ROLODEX_URL_DEV
  }
}

const AUTH0_AUDIENCE_DEV = 'dev.cloud.geckorobotics.com'
const AUTH0_AUDIENCE_PROD = 'cloud.geckorobotics.com'

export const Auth0Audience = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_AUDIENCE_PROD : AUTH0_AUDIENCE_DEV
}
