import React, { useContext, useState } from 'react'
import { InputNumber, Button } from 'antd'
import styled from 'styled-components'
import { SiteContext } from '../../../contextProviders/siteContextProvider'
import { trackEvent } from '../../../components/helpers/mixpanel'
import { RolodexPatchBatch, PatchBody } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import { SWRResponse } from 'swr'
import { RolodexEntryType } from '../../../util/RolodexTypeConstants'
import { FeatureFlagContext } from '../../../contextProviders/featureFlagContextProvider'
import { TabKey } from '../../AppLayout'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
`

const LabelContainer = styled.div`
  align-self: flex-start;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center; // Aligns children (input and button) in the middle vertically
  gap: 1rem; // Space between input and button
`
interface InspectionHoursEditorProps {
  fdeInspectionScheduleConfigSwr: SWRResponse<any, any, any>
}

const InspectionHoursEditor: React.FC<InspectionHoursEditorProps> = ({
  fdeInspectionScheduleConfigSwr,
}) => {
  const [targetLoadInput, setTargetLoadInput] = useState<number | undefined>()
  const [breakInInput, setBreakInInput] = useState<number | undefined>()
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const userFeatureFlags = useContext(FeatureFlagContext)
  const lockOutEnabled =
    userFeatureFlags?.[TabKey.inspectionScheduling]?.read_only ?? false

  React.useEffect(() => {
    if (!fdeInspectionScheduleConfigSwr?.error && fdeInspectionScheduleConfigSwr?.data) {
      const loadedInspectionHours =
        fdeInspectionScheduleConfigSwr.data[0].entry.data.weekly_target_load
      const loadedBreakInHours =
        fdeInspectionScheduleConfigSwr.data[0].entry.data.break_in_hours

      setTargetLoadInput(loadedInspectionHours ?? undefined)
      setBreakInInput(loadedBreakInHours ?? undefined)
    }
  }, [fdeInspectionScheduleConfigSwr?.data, fdeInspectionScheduleConfigSwr?.error])

  const updateHandle = (value: number | undefined, key: string) => {
    const entryPatchBody: PatchBody = {
      id: `${siteData?.siteSlug}-inspection-schedule-config`,
      type: RolodexEntryType.INSPECTION_SCHEDULE_CONFIG_TYPE,
      tags: {
        organization: siteData?.orgSlug,
        site: siteData?.siteSlug,
      },
    }

    // Dynamically set the data or delete_data keys based on the value
    if (value !== undefined && value !== null) {
      entryPatchBody.data = {
        [key]: value,
      }
    } else {
      entryPatchBody.delete_data = [key]
    }

    trackEvent('Button Clicked', {
      buttonName: 'edit_' + key,
      pageName: 'Settings',
    })

    RolodexPatchBatch([entryPatchBody], auth0, siteData).then(() => {
      fdeInspectionScheduleConfigSwr?.mutate()
    })
  }

  return (
    <Container>
      <LabelContainer>
        <h4>Target Load</h4>
      </LabelContainer>
      <InputContainer>
        <InputNumber
          id='targetLoadInput'
          value={targetLoadInput}
          onChange={(value) => setTargetLoadInput(value ?? undefined)}
          style={{ width: '200px', marginBottom: '0.5rem' }}
          addonAfter='hrs / week'
          min={0}
        />
        <Button
          type='primary'
          disabled={lockOutEnabled} // CheckLockout
          onClick={() => updateHandle(targetLoadInput, 'weekly_target_load')}>
          Save
        </Button>
      </InputContainer>

      <LabelContainer>
        <h4>Break In Hours</h4>
      </LabelContainer>
      <InputContainer>
        <InputNumber
          id='breakInInput'
          value={breakInInput}
          onChange={(value) => setBreakInInput(value ?? undefined)}
          style={{ width: '200px' }}
          addonAfter='hrs / day'
          min={0}
        />
        <Button
          type='primary'
          disabled={lockOutEnabled} // CheckLockout
          onClick={() => updateHandle(breakInInput, 'break_in_hours')}>
          Save
        </Button>
      </InputContainer>
    </Container>
  )
}

export default InspectionHoursEditor
