export const checkUserFCUPermission = (
  row: Record<string, any>,
  accessibleFields: { [key: string]: Set<string> }
): boolean => {
  return Object.keys(accessibleFields ?? {}).reduce((acc, cur) => {
    const fields = accessibleFields[cur]
    const rowValue = row?.[cur]
    if (!fields) {
      return acc
    }
    return acc && fields.has(rowValue as string)
  }, true)
}
