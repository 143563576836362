import React, { useState, useEffect, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Select, Divider, Space, Input, Button } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { DatePicker } from 'antd'
import { RolodexLoadedEntry } from '../../api/useRolodex'
import { rolodex_batch_delete } from '../../api/useFDEBackend'

interface BlitzSelectorProps {
  allBlitzOptions?: {
    value: string
    label: string
  }[]
  values?: { [key: string]: RolodexLoadedEntry }
  createNewBlitz?: (
    blitzName: string,
    blitzQuarter: string,
    blitzID?: string
  ) => Promise<any>
  createNewBlitzInspection?: (blitzId: string) => Promise<any>
  deleteBlitzInspection?: (inspectionBlitzId: string[]) => Promise<any>
  refetch?: () => void
}

const BlitzSelector: React.FC<BlitzSelectorProps> = ({
  allBlitzOptions,
  values,
  createNewBlitz,
  createNewBlitzInspection,
  deleteBlitzInspection,
  refetch,
}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [newBlitzName, setNewBlitzName] = useState<string | null>('')
  const [newBlitzQuarter, setNewBlitzQuarter] = useState<Dayjs | null>(null)
  const selectValues = useMemo(() => {
    return Object.keys(values ?? {})
  }, [values])
  const [selectedBlitzes, setSelectedBlitzes] = useState<string[]>(selectValues || [])

  useEffect(() => {
    //Two Cases here:
    // selectedBlitzes.length > values.length:  created inspection for the new blitz
    // selectedBlitzes.length < values.length:  delete inspection for the blitz
    const newBlitzId = selectedBlitzes?.filter((d) => !selectValues?.includes(d))
    if (newBlitzId && newBlitzId.length === 1) {
      //additive case
      createNewBlitzInspection?.(newBlitzId[0]).then((d) => {
        refetch?.()
      })
    } else {
      //check if there is a blitz inspection that needs to be deleted
      const blitzToDelete = selectValues?.filter((d) => !selectedBlitzes?.includes(d))
      if (blitzToDelete && blitzToDelete?.length === 1 && deleteBlitzInspection) {
        //delete inspection
        const blitzId = blitzToDelete[0]
        const blitzInspectionId = values?.[blitzId]?.entry.id
        if (blitzInspectionId) {
          //delete inspection

          deleteBlitzInspection([blitzInspectionId]).then((d) => {
            refetch?.()
          })
        }
      }
    }
  }, [JSON.stringify(selectedBlitzes)])

  return (
    <Select
      style={{ width: 245 }}
      size='large'
      mode='tags'
      value={selectValues}
      open={selectOpen}
      options={allBlitzOptions}
      onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
      onChange={(value, option) => {
        setSelectedBlitzes(value)
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder='Enter Name'
              onChange={(value) => {
                setNewBlitzName(value.target.value)
              }}
              onKeyDown={(e) => {
                e.stopPropagation()
              }}
            />
            <DatePicker
              placeholder='Enter Quarter'
              picker='quarter'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              onChange={(value) => {
                setNewBlitzQuarter(value)
              }}
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            />
          </Space>
          <Button
            type='text'
            disabled={!newBlitzName || !newBlitzQuarter}
            onClick={async () => {
              if (
                createNewBlitz &&
                newBlitzName &&
                newBlitzQuarter &&
                newBlitzQuarter.isValid() &&
                createNewBlitzInspection
              ) {
                const newBlitzID = uuidv4()
                Promise.all([
                  createNewBlitz(
                    newBlitzName,
                    newBlitzQuarter.format('YYYY-MM'),
                    newBlitzID
                  ),
                  createNewBlitzInspection(newBlitzID),
                ]).then((d) => refetch?.())
              }
              setSelectOpen(false)
            }}>
            Add Blitz
          </Button>
        </>
      )}
    />
  )
}

export default BlitzSelector
