import styled from 'styled-components'
import { asset_manager_theme } from '../../../../assets/themes'
import { Checkbox } from 'antd'

interface RootDirectoryProps {
  isDragOver: boolean
}

export const StyledDirectoryComponents = styled.div<RootDirectoryProps>`
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 1.5rem;
  background-color: ${(props) =>
    props.isDragOver ? asset_manager_theme.background.background_color3 : 'transparent'};
  font-size: 1.2rem;
  font-weight: bold;
`

interface StyledNodeProps {
  isDragOver: boolean
  isFolder: boolean
}
export const StyledNode = styled.div<StyledNodeProps>`
  display: inline-block;
  background-color: ${(props) =>
    props.isDragOver && props.isFolder
      ? asset_manager_theme.background.background_color3
      : 'transparent'};
`

export const StyledInspectionPacketGenerationNode = styled.div`
  display: inline-block;
`

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`
