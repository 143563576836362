import React, { createContext } from 'react'
import { useParams } from 'react-router-dom'

import { useGetSiteData } from '../util/RolodexInterface'
import { useAuthInfo } from './useAuthInfo'
import { LoadingContent } from '../components/LoadingPageComponent'

interface SiteContextProviderInterface {
  children: JSX.Element | JSX.Element[]
}

export type SiteData =
  | {
      siteSlug: string
      orgSlug: string
      siteName: string
      demo: boolean
    }
  | undefined

export const SiteContext = createContext<SiteData>(undefined)

export const SiteContextProvider: React.FC<SiteContextProviderInterface> = ({
  children,
}) => {
  const auth0Info = useAuthInfo()
  const { siteId } = useParams()
  const [swrOutPut, siteData] = useGetSiteData(auth0Info, siteId)
  let contextData: SiteData = undefined

  if (siteId) {
    if (siteId === 'demo') {
      contextData = {
        siteSlug: 'heritage-berton-mill',
        orgSlug: 'heritage-industries',
        siteName: 'Heritage Mill',
        demo: true,
      }
    } else {
      if (siteData?.data?.name && siteId && siteData?.tags?.organization) {
        contextData = {
          siteSlug: siteId,
          siteName: siteData.data.name,
          orgSlug: siteData.tags.organization,
          demo: false,
        }
      }
    }
  }

  if (!swrOutPut || swrOutPut.isLoading) {
    return <LoadingContent />
  }
  return <SiteContext.Provider value={contextData}>{children}</SiteContext.Provider>
}
