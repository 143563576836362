import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadFile, UploadProps } from 'antd'
import { message, Upload } from 'antd'
import Button from '../Common/Button'
import { trackEvent } from '../helpers/mixpanel'

interface UploadButtonProps {
  assetName?: string
  pageName?: string
  subPageName?: string
  button_name?: string
  customRequest: (file: File) => void
  text?: string
  fileList?: UploadFile[]
  disabled?: boolean
}

const UploadButton: React.FC<UploadButtonProps> = ({
  assetName,
  pageName,
  subPageName,
  button_name,
  customRequest,
  text,
  fileList,
  disabled,
}) => {
  const props: UploadProps = {
    customRequest: (options) => {
      const file = options.file as File
      customRequest(file)
    },

    onChange(info) {
      // this creates a brief temporary banner, but status is broken from antd's end
      // when using a custom request
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <Upload {...props} fileList={fileList}>
      <Button
        disabled={disabled ?? false}
        style={{ marginLeft: '.5rem', fontSize: '.85rem' }}
        onClick={() => {
          trackEvent('Button Clicked', {
            buttonName: button_name || 'upload_file',
            pageName: pageName,
            subPageName: subPageName,
            assetDetails: {
              assetName: assetName,
            },
          })
        }}>
        {text || 'Upload Report'}
      </Button>
    </Upload>
  )
}

export default UploadButton
