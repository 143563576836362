import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { SiteContext, SiteData } from '../../contextProviders/siteContextProvider'
import { AuthInfo, useAuthInfo } from '../../contextProviders/useAuthInfo'
import { SWRResponse } from 'swr'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'

interface GroupSelectProps {
  recGroups: string[]
  planRecommendationSlug?: string
  fdePlanningConfigSwr: SWRResponse<any, any, any>
  refetchData?: () => void
  save?: boolean
  setGroup?: React.Dispatch<React.SetStateAction<string[]>>
  disabled?: boolean
  onChange?: () => void
}

interface GroupMap {
  [key: string]: any // TODO: Use a more specific type instead of any if possible
}

interface FDESitePatchBodyData {
  group_map: GroupMap
}

interface FDESitePatchBody {
  id: string
  type: string
  data: FDESitePatchBodyData
  tags: {
    site?: string
    organization?: string
  }
}

const updateGroup = (
  planRecommendationSlug: string,
  fdeSiteSlug: string,
  value: string[],
  siteData: SiteData,
  auth0: Readonly<AuthInfo>,
  refetchData?: () => void
) => {
  // API update logic here
  const planRecPatchBody = {
    id: planRecommendationSlug,
    type: RolodexEntryType.PLAN_REC,
    data: {
      cluster: value,
    },
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  }

  const FDESitePatchBody: FDESitePatchBody = {
    id: `${siteData?.siteSlug}-planning-config`,
    type: RolodexEntryType.PLANNING_CONFIG_TYPE,
    data: {
      group_map: {},
    },
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  }

  value.forEach((key) => {
    FDESitePatchBody.data.group_map[key] = {}
  })

  RolodexPatchBatch([planRecPatchBody, FDESitePatchBody], auth0, siteData).then(() => {
    refetchData?.()
  })
}
const GroupSelect: React.FC<GroupSelectProps> = ({
  recGroups,
  planRecommendationSlug,
  fdePlanningConfigSwr,
  refetchData,
  save = true,
  setGroup,
  onChange,
  disabled,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()

  const [groupMap, setGroupMap] = useState<Record<string, any>>({})
  const [normalizedGroups, setNormalizedGroups] = useState<string[]>(recGroups)

  useEffect(() => {
    setNormalizedGroups(typeof recGroups === 'string' ? [recGroups] : recGroups || [])
  }, [recGroups])

  // Get FDE site data
  useEffect(() => {
    if (!fdePlanningConfigSwr?.error && fdePlanningConfigSwr?.data) {
      const siteData = fdePlanningConfigSwr?.data[0]?.entry?.data
      if (siteData) {
        setGroupMap(siteData.group_map)
      }
    }
  }, [fdePlanningConfigSwr.data])

  useEffect(() => {
    if (!save && setGroup) {
      onChange?.()
    }
  }, [recGroups])

  const options = useMemo(() => {
    return groupMap
      ? Object.keys(groupMap).map((groupName) => ({ label: groupName, value: groupName }))
      : []
  }, [groupMap])

  const handleChange = useCallback(
    (value: string[]) => {
      setNormalizedGroups(value)
      const FDESiteSlug = `${siteData?.siteSlug}-planning-config`
      if (setGroup && FDESiteSlug) {
        // modified condition check
        setGroup(value)
      } else {
        if (!planRecommendationSlug || !FDESiteSlug) {
          return
        }
        updateGroup(
          planRecommendationSlug,
          FDESiteSlug, // Assuming this should be part of updateGroup params
          value,
          siteData,
          auth0,
          refetchData
        )
      }
    },
    [fdePlanningConfigSwr, planRecommendationSlug, siteData, auth0, refetchData, setGroup]
  )

  return (
    <Select
      disabled={disabled}
      mode='tags' // Enable multiple selection and tag creation
      style={{ width: '100%' }}
      placeholder='Select Groups'
      defaultValue={normalizedGroups}
      value={normalizedGroups}
      onChange={handleChange}
      options={options}
    />
  )
}

export default GroupSelect
