import React, { FC, useContext, useEffect, useState, useRef, useMemo, Key } from 'react'
import { Button, Modal, Input, Space, InputRef, Tooltip } from 'antd'
import _ from 'lodash'
import { FolderOpenOutlined, SearchOutlined } from '@ant-design/icons'
import type { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words'
import { RolodexPatchBatch, useGetFDECustomerUnitData } from '../../util/RolodexInterface'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
import { TableSliderFilter } from '../../components/Common/TableSliderFilter'
import { TableDateRangeFilter } from '../../components/Common/TableDateRangeFilter'
import BlitzSelector from './BlitzSelector'
import { v4 as uuidv4 } from 'uuid'
import { Column, PageContainer, Row } from '../../components/Common/Grid'
import { TabHeader } from '../../components/Common/TabHeader'
import BlitzTable from './BlitzTable'
import { Tabs } from 'antd'
import { ColumnsType, ColumnType } from 'antd/es/table'
import { defaultSortedColumn } from '../../api/tableHelpers'
import { asset_manager_theme } from '../../assets/themes'
import dayjs, { Dayjs } from 'dayjs'
import { toTitleCase } from '../../components/helpers/stringFilters'
import merge from 'deepmerge-json'
import { defaultSort } from '../../api/tableHelpers'
import { AMBIENT_TEMP_RANGE } from '../../util/constants'
import { FeatureFlagContext } from '../../contextProviders/featureFlagContextProvider'
import FileBrowser from '../../components/Common/FileBrowser/FileBrowser'
import { trackEvent } from '../../components/helpers/mixpanel'
import { useRolodexEntries, RolodexLoadedEntry, RolodexEntry } from '../../api/useRolodex'
import { RolodexEntryType } from '../../util/RolodexTypeConstants'
import { BlitzInterface, BlitzInspectionInterface } from './BlitzInterface'
import { rolodex_batch_delete } from '../../api/useFDEBackend'
import { convertQuarterDateToString } from './common'
import {
  AssetsDatabaseRowIndex,
  AssetsDatabaseTableRow,
  FDECustomerUnit,
  FDECustomerUnitData,
} from '../AssetDatabaseTab/AssetDatabaseTabInterface'
import AssetTable from '../AssetDatabaseTab/AssetTable'
import NumberLabelComponent from '../RecommendationsTab/RecommendationsKPIBar'
import {
  sortInspectionDatesFields,
  sortNumberFields,
} from '../AssetDatabaseTab/AssetDatabaseTab'

const RUG_SET = new Set(['Y', 'HTO'])

interface AssetDatabaseTabProps {
  setFdeCustomerUnitSlug: (slug: string | undefined) => void
  setAssetBaseballCardRefetchData: (refetch: { func: () => void }) => void
}

export const getBlitzInspectionIdsFromBlitzID = (
  blitzId?: string,
  blitzInspections?: RolodexLoadedEntry<RolodexEntry>[]
) => {
  return blitzInspections
    ?.filter((d) => d.entry.tags?.[RolodexEntryType.BLITZ_TYPE] === blitzId)
    ?.map((d) => d.entry.id)
}

const inspectionFields = [
  {
    key: 'next',
    label: 'Next',
    format: 'date',
  },
  {
    key: 'interval_months',
    label: 'Interval',
    format: 'number',
  },
  {
    key: 'last',
    label: 'Last',
    format: 'date',
  },
]

const RugBlitzPlanning: FC<AssetDatabaseTabProps> = (props) => {
  const authInfo = useAuthInfo()
  const siteData = useContext(SiteContext)

  const [selectedRow, setSelectedRow] = useState<AssetsDatabaseTableRow | null>(null)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [searchText, setSearchText] = useState<string | undefined>('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)

  const [isFileBrowserVisible, setIsFileBrowserVisible] = useState(false)
  const FileBrowserMemoized = React.memo(FileBrowser)

  const [currentActiveTabKey, setCurrentActiveTabKey] = useState<Key>('AllRugAssets')
  const [optionToDelete, setOptionToDelete] = useState<string | undefined>(undefined)

  const unitDataSwr = useGetFDECustomerUnitData(authInfo, siteData)
  const ruggableMappingSWR = useRolodexEntries(
    {
      ids: [`${siteData?.siteSlug}-ruggable-mapping`],
      type: RolodexEntryType.SITE_CONFIG_TYPE,
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    },
    undefined,
    !!siteData?.orgSlug
  )
  const rugBlitzesSWR = useRolodexEntries({
    type: RolodexEntryType.BLITZ_TYPE,
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  })

  const rugBlitzInspectionsSwr = useRolodexEntries({
    type: RolodexEntryType.BLITZ_INSPECTION,
    links: [RolodexEntryType.CUSTOMER_UNIT, RolodexEntryType.BLITZ_TYPE],
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  })

  const rugBlitzScheduleOptionsSWR = useRolodexEntries({
    type: RolodexEntryType.BLITZ_OPTION,
    tags: {
      site: siteData?.siteSlug,
      organization: siteData?.orgSlug,
    },
  })

  function convertToAssetsDatabaseTableRows(
    customerUnits: FDECustomerUnit[]
  ): AssetsDatabaseTableRow[] {
    return customerUnits.map((customerUnit) => {
      const {
        entry: { id },
        entry: { data, data: { overrides } = {} },
      } = customerUnit

      const mergedData: FDECustomerUnitData = merge(data, overrides)

      const assetsDatabaseTableRow: AssetsDatabaseTableRow = {
        id,
        ...mergedData,
      }
      if (!!ruggableMappingSWR.data) {
        const ruggableMapping = ruggableMappingSWR.data[0]?.entry?.data
        assetsDatabaseTableRow.ruggable = ruggableMapping?.[assetsDatabaseTableRow.id]
      }
      assetsDatabaseTableRow.is_rug_available =
        'component' in (customerUnit.entry.tags ?? {})
      return assetsDatabaseTableRow
    })
  }

  const handleDeleteOption = (blitzOptionId: string) => {
    const deleteArr = [blitzOptionId]
    const selectedBlitzOptionId = rugBlitzScheduleOptionsSWR.data?.find(
      (d) => d.entry.id === blitzOptionId
    )?.entry.data?.blitz_id
    if (selectedBlitzOptionId) {
      const blitzIdsToDelete = rugBlitzesSWR.data
        ?.filter((blitz) => blitz.entry.data?.blitz_option === selectedBlitzOptionId)
        ?.map((blitz) => blitz.entry.id)

      if (blitzIdsToDelete) {
        deleteArr.push(...blitzIdsToDelete)
        blitzIdsToDelete.forEach((blitzId) => {
          const blitzInspectionIdsToDelete = getBlitzInspectionIdsFromBlitzID(
            blitzId,
            rugBlitzInspectionsSwr.data
          )
          if (blitzInspectionIdsToDelete) {
            deleteArr.push(...blitzInspectionIdsToDelete)
          }
        })
        rolodex_batch_delete(authInfo, deleteArr).then((d) => {
          rugBlitzScheduleOptionsSWR.mutate()
          rugBlitzesSWR.mutate()
          rugBlitzInspectionsSwr.mutate()
        })
      }
    }
  }

  useEffect(() => {
    //need this useEffect so it doesn't infinitely rerender
    props.setAssetBaseballCardRefetchData({
      func: () => {
        unitDataSwr.mutate()
        rugBlitzScheduleOptionsSWR.mutate()
        rugBlitzesSWR.mutate()
        rugBlitzInspectionsSwr.mutate()
        ruggableMappingSWR.mutate()
      },
    })
  }, [])

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: AssetsDatabaseRowIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: AssetsDatabaseRowIndex
  ) => {
    confirm()
    clearFilters()
    handleSearch([], confirm, dataIndex)
  }
  const getColumnSearchProps = (
    dataIndex: AssetsDatabaseRowIndex,
    style: React.CSSProperties = {}
  ): ColumnType<AssetsDatabaseTableRow> => ({
    //blatantly copied from antd documentation: https://ant.design/components/table#components-table-demo-custom-filter-panel
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}>
            Close
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record?.[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) || false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex && searchText ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: asset_manager_theme.background.high_vis,
            padding: 0,
            color: '#000000',
          }}
          style={{
            ...style,
          }}
          searchWords={[searchText as string]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <p style={{ ...style }}>{text}</p>
      ),
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownVisible && !(event.target as HTMLElement).closest('.ant-dropdown')) {
        setDropdownVisible(false)
        setSelectedRow(null)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [dropdownVisible])

  const processedAssetDatabaseData: AssetsDatabaseTableRow[] = useMemo(
    () =>
      convertToAssetsDatabaseTableRows(
        (unitDataSwr.data ?? []) as FDECustomerUnit[]
      ).filter((d) => {
        return RUG_SET.has(d.ruggable?.toUpperCase() ?? '') && !d.decommissioned
      }),
    [unitDataSwr.data]
  )

  const checkTempRecordValue = (filterMax: number, filterMin: number) => {
    const { max, min } = AMBIENT_TEMP_RANGE
    //check if the ranges instersect anywhere
    return filterMax >= min && filterMin <= max
  }

  const createNewBlitz = (blitzOption: number) => {
    return (blitzName: string, blitzQuarter: string, blitzId?: string) => {
      const blitzPatchBody = {
        id: blitzId ?? uuidv4(),
        type: RolodexEntryType.BLITZ_TYPE,
        data: {
          blitz_name: blitzName,
          blitz_quarter: blitzQuarter,
          blitz_option: blitzOption,
        },
        tags: {
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
        },
      }
      return RolodexPatchBatch([blitzPatchBody], authInfo, siteData)
    }
  }

  const createNewBlitzInspection = (fcuSlug: string) => {
    return (blitzId: string) => {
      const blitzInspectionPatchBody = {
        id: uuidv4(),
        type: RolodexEntryType.BLITZ_INSPECTION,
        data: {
          requirements: [],
        },
        tags: {
          [RolodexEntryType.CUSTOMER_UNIT]: fcuSlug,
          [RolodexEntryType.BLITZ_TYPE]: blitzId,
          site: siteData?.siteSlug,
          organization: siteData?.orgSlug,
        },
      }
      return RolodexPatchBatch([blitzInspectionPatchBody], authInfo, siteData)
    }
  }

  const createNewOption = () => {
    const rugBlitzIds = rugBlitzScheduleOptionsSWR.data
      ?.map((blitz) => {
        return blitz.entry.data?.blitz_id
      })
      .filter(Boolean)
    const newBlitzId =
      rugBlitzIds?.length === 0 ? 1 : Math.max(...(rugBlitzIds as number[])) + 1
    const patchBody = {
      id: uuidv4(),
      type: RolodexEntryType.BLITZ_OPTION,
      data: {
        blitz_id: newBlitzId,
      },
      tags: {
        site: siteData?.siteSlug,
        organization: siteData?.orgSlug,
      },
    }
    return RolodexPatchBatch([patchBody], authInfo, siteData)
  }

  const deleteBlitzInspection = (blitzInspectionIds: string[]) => {
    return rolodex_batch_delete(authInfo, blitzInspectionIds)
  }

  const defaultSliderRangeFilter = (
    dataIndex: keyof AssetsDatabaseTableRow,
    tempFilter?: boolean
  ) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <TableSliderFilter
          data={processedAssetDatabaseData}
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value: boolean | React.Key, record: AssetsDatabaseTableRow) => {
        const { max, min } = value as any as { max: number; min: number }
        if (
          tempFilter &&
          (_.get(record, dataIndex) as string)?.toLowerCase()?.includes('amb')
        ) {
          return checkTempRecordValue(max, min)
        }

        const recordValue = parseInt(_.get(record, dataIndex) as string)
        return recordValue >= min && recordValue <= max
      },
    }
  }

  const defaultDateRangeFilter = (
    dataIndex: keyof AssetsDatabaseTableRow,
    removeOnstream?: boolean
  ) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <TableDateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),

      onFilter: (value: boolean | React.Key, record: AssetsDatabaseTableRow) => {
        const [min, max] = [
          (value as any as { min: Dayjs; max: Dayjs })?.min,
          (value as any as { min: Dayjs; max: Dayjs })?.max,
        ]
        const recordValue = _.get(record, dataIndex, undefined)
        const recordDateValue = dayjs(recordValue as string)
        if (removeOnstream && record?.onstream_exception) {
          //if onstream and we wanna remove onstreams from the filter then return false
          return false
        }
        if (recordValue && recordDateValue.isValid()) {
          //could use inbetween here but that threw errors
          return recordDateValue.isAfter(min) && recordDateValue.isBefore(max)
        }
        return false
      },
    }
  }

  const generateCategoricalColumn = <REC_T extends object>(
    title: string,
    key: Extract<keyof REC_T, string>,
    data: REC_T[],
    render?: (text: string, record: REC_T, index: number) => React.ReactNode,
    valueTransform?: (value: any, record?: REC_T) => string,
    sorter?: (a: REC_T, b: REC_T) => number,
    nullFilterName?: string
  ): ColumnType<REC_T> => {
    // Default behavior for transforming values, used if no valueTransform is provided
    const defaultTransform = (value: any) => String(value).toLowerCase()

    // Use the provided valueTransform function if available, otherwise use the default
    const transform = valueTransform || defaultTransform

    const shouldAddNoInfoFilter =
      title !== 'Onstream Exception' && title !== 'Decommissioned'

    // Create a map of original values to their transformed counterparts for filtering,
    // explicitly excluding null and undefined values.
    const valueMap = new Map<string, string>()
    data.forEach((item) => {
      const itemValue = item[key]
      if (itemValue !== null && itemValue !== undefined && itemValue !== '') {
        // Check for null or undefined
        const originalValue = String(itemValue) // Get the original string value
        const transformedValue = transform(originalValue) // Transform the value for comparison
        valueMap.set(transformedValue, originalValue) // Map transformed value back to original
      }
    })

    return {
      title,
      dataIndex: key,
      key: key,
      sorter: sorter ?? defaultSort(key),
      render,
      width: 'max-content',
      filters: [
        ...(shouldAddNoInfoFilter
          ? [{ text: nullFilterName || '---', value: 'NULL' }]
          : []),
        ...Array.from(valueMap, ([transformedValue, originalValue]) => ({
          text:
            originalValue == 'true' || originalValue == 'false'
              ? toTitleCase(originalValue)
              : originalValue, // Use the original value for display
          value: transformedValue, // Use the transformed value for comparison
        })),
      ],
      onFilter: (value, record) => {
        if (value === 'NULL') {
          return !record[key]
        }
        return transform(record[key]) === transform(value)
      },
    }
  }

  const distinctInspectionObjects = useMemo(() => {
    //generate the distinct method/ type combinations
    const methodTypeSet = new Set<string>()
    unitDataSwr.data?.forEach((d: FDECustomerUnit) => {
      Object.keys(d.entry.data.inspection_schedule || {}).forEach((key) => {
        Object.keys(
          d.entry.data.inspection_schedule?.[
            key as keyof typeof d.entry.data.inspection_schedule
          ] ?? {}
        ).forEach((inspectionKey) => {
          methodTypeSet.add(`${key}_${inspectionKey}`)
        })
      })
    })
    return Array.from(methodTypeSet)
  }, [unitDataSwr.data])

  const inspectionColumns = useMemo(() => {
    return distinctInspectionObjects.reduce((acc, curr) => {
      const [type, method] = curr.split('_')
      return [
        ...acc,
        ...inspectionFields.map((field) => {
          const fieldKey = field.key
          const fieldLabel = field.label
          return {
            title: toTitleCase(`${type} ${method} ${fieldLabel}`),
            dataIndex: ['inspection_schedule', type, method, fieldKey],
            key: `${method}_${type}_${fieldKey}`,
            ...(field.format === 'date'
              ? {
                  ...defaultDateRangeFilter(
                    ['inspection_schedule', type, method, fieldKey].join(
                      '.'
                    ) as keyof AssetsDatabaseTableRow
                  ),
                  sorter: (
                    row1: AssetsDatabaseTableRow,
                    row2: AssetsDatabaseTableRow
                  ) => {
                    return sortInspectionDatesFields(
                      row1,
                      row2,
                      ['inspection_schedule', type, method, fieldKey].join(
                        '.'
                      ) as keyof AssetsDatabaseTableRow
                    )
                  },
                  render: (text: string, record: AssetsDatabaseTableRow) => {
                    if (record?.onstream_exception && type === 'internal') {
                      //not sure how to get out of hardcoding internal here
                      return (
                        <Tooltip title={`Marked On-Stream on ${text}`}>
                          <div>On-Stream</div>
                        </Tooltip>
                      )
                    }
                    if (
                      (record?.['inspection_schedule'] as any)?.[type]?.[method] ===
                      undefined
                    ) {
                      //prone to change but atm if the method doesn't exist on a type it's not applicable
                      return 'N/A'
                    } else {
                      return text
                    }
                  },
                }
              : {
                  //if not a date format add the number filters and sorters
                  ...defaultSliderRangeFilter(
                    ['inspection_schedule', type, method, fieldKey].join(
                      '.'
                    ) as keyof AssetsDatabaseTableRow
                  ),
                  sorter: (
                    row1: AssetsDatabaseTableRow,
                    row2: AssetsDatabaseTableRow
                  ) => {
                    return sortNumberFields(
                      row1,
                      row2,
                      ['inspection_schedule', type, method, fieldKey].join(
                        '.'
                      ) as keyof AssetsDatabaseTableRow
                    )
                  },
                }),
          }
        }),
      ]
    }, [] as ColumnsType<AssetsDatabaseTableRow>)
  }, [distinctInspectionObjects])

  // Temporary implementation to filter inspection columns
  const filteredInspectionColumns = useMemo(() => {
    const filteredColumns: ColumnsType<AssetsDatabaseTableRow> = []
    inspectionColumns.forEach(function (column) {
      const keySplit = column.key?.toString().split('_') ?? []
      const lastKey = keySplit[keySplit?.length - 1]

      if (lastKey == 'next') {
        filteredColumns.push(column)
      }
    })
    return filteredColumns
  }, [inspectionColumns])

  const columns: ColumnsType<AssetsDatabaseTableRow> = [
    {
      ...defaultSortedColumn('Equip Description', 'equip_description'),
      width: 300,
      fixed: true,
      onCell: (record) => {
        return {
          onClick: () => {
            props.setFdeCustomerUnitSlug(record.id)
            trackEvent('Button Clicked', {
              buttonName: 'asset_baseball_card',
              pageName: 'Blitz Planning',
              assetDetails: {
                assetName: record.equip_description,
              },
            })
          },
        }
      },
      ...getColumnSearchProps('equip_description', {
        cursor: 'pointer',
        fontWeight: 'bold',
      }),
    },

    {
      title: 'Files',
      key: 'files',
      fixed: true,
      render: (row: AssetsDatabaseTableRow) => (
        <div>
          <Button
            onClick={() => {
              setSelectedRow(row)
              setIsFileBrowserVisible(true)
            }}
            icon={<FolderOpenOutlined />}
            style={{ border: 'none', backgroundColor: 'transparent' }}
          />
          {isFileBrowserVisible && selectedRow?.id === row.id && (
            <FileBrowserMemoized
              open={isFileBrowserVisible}
              onCancel={() => {
                setIsFileBrowserVisible(false)
              }}
              fdeCustomerUnitSlug={selectedRow?.id}
              assetName={selectedRow?.equip_description}
            />
          )}
        </div>
      ),
    },
    ...((rugBlitzScheduleOptionsSWR.data ?? [])
      ?.sort((a, b) => {
        return a?.entry?.data?.blitz_id - b.entry.data?.blitz_id
      })
      ?.map((d) => {
        const blitz_option_id = d.entry.data?.blitz_id
        if (blitz_option_id) {
          return {
            title: `Schedule ${blitz_option_id}`,
            render: (row: AssetsDatabaseTableRow) => {
              const rugBlitzSelectOptions = rugBlitzesSWR.data
                ?.filter((blitz) => blitz?.entry?.data?.blitz_option === blitz_option_id)
                ?.map((blitz) => ({
                  label: `${blitz.entry.data?.blitz_name} - ${convertQuarterDateToString(
                    dayjs(blitz.entry.data?.blitz_quarter).format('YYYY-Q')
                  )}`,
                  value: blitz.entry.id,
                }))

              const currentRugBlitzIds = new Set(
                rugBlitzSelectOptions?.map((d) => d.value)
              )
              const selectedBlitzes = rugBlitzInspectionsSwr.data
                ?.filter((inspection) => {
                  return (
                    inspection.entry?.tags?.[RolodexEntryType.CUSTOMER_UNIT] === row.id
                  )
                })
                .reduce((acc, curr) => {
                  if (
                    curr?.entry?.tags?.[RolodexEntryType.BLITZ_TYPE] &&
                    typeof curr?.entry?.tags?.[RolodexEntryType.BLITZ_TYPE] ===
                      'string' &&
                    currentRugBlitzIds.has(
                      curr?.entry?.tags?.[RolodexEntryType.BLITZ_TYPE]
                    )
                  ) {
                    acc[curr.entry.tags?.[RolodexEntryType.BLITZ_TYPE]] = curr
                  }
                  return acc
                }, {} as { [key: string]: RolodexLoadedEntry<RolodexEntry> })

              return (
                <BlitzSelector
                  values={selectedBlitzes}
                  allBlitzOptions={rugBlitzSelectOptions}
                  createNewBlitz={createNewBlitz(blitz_option_id)}
                  createNewBlitzInspection={createNewBlitzInspection(row.id)}
                  deleteBlitzInspection={deleteBlitzInspection}
                  refetch={() => {
                    rugBlitzesSWR.mutate()
                    rugBlitzInspectionsSwr.mutate()
                  }}
                />
              )
            },
          }
        }
      }) ?? []),

    // {
    //   ...defaultSortedColumn('EntryId', 'id'),
    //   width: 300,
    //   ...getColumnSearchProps('id'),
    // },

    generateCategoricalColumn('Equip Area', 'equip_area', processedAssetDatabaseData),
    {
      ...defaultSortedColumn('Area Description', 'area_description'),
      width: 'max-content',
      ...getColumnSearchProps('area_description'),
    },
    // {
    //   ...defaultSortedColumn('Legacy Equip ID', 'equip_id'),
    //   width: 'max-content',
    //   ...getColumnSearchProps('equip_id'),
    // },
    // {
    //   ...defaultSortedColumn(
    //     `${unitDataSwr.data?.[0]?.entry?.data?.erp_name.toUpperCase() ?? 'ERP'} ID`,
    //     'erp_id'
    //   ),
    //   width: 'max-content',
    //   ...getColumnSearchProps('erp_id'),
    // },
    // {
    //   ...defaultSortedColumn('National Board', 'national_board'),
    //   width: 'max-content',
    // },
    generateCategoricalColumn('Outage', 'outage_type', processedAssetDatabaseData),
    generateCategoricalColumn(
      'Risk Category',
      'risk_category',
      processedAssetDatabaseData
    ),
    { ...defaultSortedColumn('Type', 'type'), width: 'max-content' },
    { ...defaultSortedColumn('Year Built', 'year_built'), width: 'max-content' },
    { ...defaultSortedColumn('Orientation', 'orientation'), width: 'max-content' },
    {
      title: 'Dimensions - Height (ft)',
      dataIndex: ['dimensions', 'height_ft'],
      key: 'dimensions.height_ft',
      width: 'max-content',
      ...defaultSliderRangeFilter('dimensions.height_ft' as keyof AssetsDatabaseTableRow),
    },
    {
      title: 'Dimensions - Diameter (ft)',
      dataIndex: ['dimensions', 'diameter_ft'],
      key: 'dimensions.diameter_ft',
      width: 'max-content',
      ...defaultSliderRangeFilter(
        'dimensions.diameter_ft' as keyof AssetsDatabaseTableRow
      ),
    },
    generateCategoricalColumn(
      'RUG Available',
      'is_rug_available',
      processedAssetDatabaseData,
      (text) => toTitleCase(String(text || 'false')),
      (value) => toTitleCase(String(value || 'false'))
    ),
    // generateCategoricalColumn('Material', 'material', processedAssetDatabaseData),
    // generateCategoricalColumn(
    //   'Sub Material',
    //   'sub_material',
    //   processedAssetDatabaseData
    // ),
    // {
    //   ...generateCategoricalColumn('Ruggable', 'ruggable', processedAssetDatabaseData),
    //   defaultFilteredValue: ['Y', 'HTO'],
    // },
    // {
    //   title: 'Temp',
    //   dataIndex: 'temp',
    //   key: 'temp',
    //   width: 'max-content',
    //   ...defaultSliderRangeFilter('temp', true),
    // },
    // generateCategoricalColumn('Insulated', 'insulated', processedAssetDatabaseData),
    // {
    //   ...defaultSortedColumn('Contents', 'contents'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('pH', 'pH'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Pressure', 'pressure'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Stiffeners', 'stiffness'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Pending Repairs', 'pending_repairs'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Maintenance', 'maintenance'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Inspection Written', 'inspection_written'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Customer Corrosion Rate', 'customer_corrosion_rate'),
    //   width: 'max-content',
    // },
    // {
    //   ...defaultSortedColumn('Customer Remaining Life', 'customer_remaining_life'),
    //   width: 'max-content',
    // },
    ...filteredInspectionColumns,

    // {
    //   defaultFilteredValue: ['False'],
    //   ...generateCategoricalColumn(
    //     'Decommissioned',
    //     'decommissioned',
    //     processedAssetDatabaseData,
    //     (text) => toTitleCase(String(text)),
    //     (value) => toTitleCase(String(value))
    //   ),
    // },
    // generateCategoricalColumn(
    //   'Onstream Exception',
    //   'onstream_exception',
    //   processedAssetDatabaseData,
    //   (text) => toTitleCase(String(text || 'false')),
    //   (value) => toTitleCase(String(value || 'false'))
    // ),
  ] as ColumnType<AssetsDatabaseTableRow>[]

  const tabItems = [
    {
      key: 'AllRugAssets',
      label: 'All Rug Assets',
      children: (
        <div>
          {
            <Row style={{ marginBottom: '1.25rem' }}>
              <NumberLabelComponent
                input={[
                  {
                    label: 'RUG Eligible',
                    number: processedAssetDatabaseData.filter((d) => d.ruggable === 'Y')
                      .length,
                    color: 'white',
                    bold: true,
                  },
                  {
                    label: 'Outage RUG Eligible',
                    number: processedAssetDatabaseData.filter((d) => d.ruggable === 'HTO')
                      .length,
                    color: 'white',
                    bold: true,
                  },
                  {
                    label: 'RUG Eligible Scheduled',
                    number: 0,
                    color: asset_manager_theme.colors.high_vis,
                    bold: true,
                  },
                  {
                    label: 'RUG Completed',
                    number: processedAssetDatabaseData.filter((d) => d.is_rug_available)
                      .length,
                    color: asset_manager_theme.colors.green,
                    bold: true,
                  },
                  {
                    label: 'Unknown RUG Eligibility',
                    number: processedAssetDatabaseData.filter((d) => d.ruggable === '?')
                      .length,
                    color: asset_manager_theme.colors.orange,
                    bold: true,
                  },
                ]}
              />
            </Row>
          }
          <AssetTable
            data={processedAssetDatabaseData}
            columns={columns}
            dropdownVisible={dropdownVisible}
            loading={unitDataSwr.isLoading}
          />
        </div>
      ),
    },
    ...((rugBlitzScheduleOptionsSWR.data ?? [])
      ?.sort((a, b) => {
        return a?.entry?.data?.blitz_id - b.entry.data?.blitz_id
      })
      .map((d) => {
        return {
          key: d.entry.id,
          label: `Schedule ${d.entry.data?.blitz_id}`,
          children: (
            <BlitzTable
              blitzData={
                rugBlitzesSWR?.data?.filter((blitz) => {
                  return blitz.entry.data?.blitz_option === d.entry.data?.blitz_id
                }) as BlitzInterface[]
              }
              blitzInspectionData={
                rugBlitzInspectionsSwr.data as BlitzInspectionInterface[]
              }
              refetchBlitz={() => {
                rugBlitzesSWR.mutate()
                rugBlitzInspectionsSwr.mutate()
              }}
              setFdeCustomerUnitSlug={props.setFdeCustomerUnitSlug}
            />
          ),
        }
      }) ?? []),
  ]

  return (
    <PageContainer>
      <TabHeader>
        <Column>Rug Blitz Planning</Column>
        <Button
          onClick={() => {
            createNewOption().then(() => {
              rugBlitzScheduleOptionsSWR.mutate()
            })
          }}>
          {' '}
          Add Schedule
        </Button>
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={currentActiveTabKey === 'AllRugAssets'}
          onClick={() => {
            if (currentActiveTabKey === 'AllRugAssets') {
              return
            }
            setOptionToDelete(currentActiveTabKey as string)
          }}>
          Delete Schedule
        </Button>
      </TabHeader>
      <Tabs
        defaultActiveKey='AllRugAssets'
        items={tabItems}
        activeKey={currentActiveTabKey as string}
        onChange={(activeKey) => {
          setCurrentActiveTabKey(activeKey)
        }}
      />
      <Modal
        open={!!optionToDelete}
        title='Delete Blitz Option'
        onOk={() => {
          if (optionToDelete) {
            handleDeleteOption(optionToDelete)
            setCurrentActiveTabKey('AllRugAssets')
            setOptionToDelete(undefined)
          }
        }}
        onCancel={() => {
          setOptionToDelete(undefined)
        }}
      />
    </PageContainer>
  )
}

export default RugBlitzPlanning
