import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  margin-bottom: 5rem;
  width: 100%;
  .flex-item {
  }
`
