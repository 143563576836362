import useSWR, { SWRResponse } from 'swr'
import { useContext } from 'react'

import { rolodexUrl } from './helpers'
import { fileFetcher, getFetcher, postFetcher } from './fetchers'
import { useEffect, useState } from 'react'
import { AuthInfo, useAuthInfo } from '../contextProviders/useAuthInfo'
import { SiteContext } from '../contextProviders/siteContextProvider'

export interface RolodexEntry {
  id: string
  type: string
  portal?: Record<string, any>
  data?: Record<string, any>
  tags?: Record<string, string>
}

export interface RolodexLoadedEntry<T extends RolodexEntry = RolodexEntry> {
  entry: T
  links?: Record<string, RolodexEntry>
}

export interface FdeCustomerUnit {
  id: string
  type: string
  data: {
    slug?: string
    dimensions: {
      height_meters?: number
      diameter_meters?: number
    }
    equip_id: string
    erp_id: string
    equip_description?: string
    equip_area: string
    year_built?: number
    risk_category?: string
    inspection_schedule: {
      internal?: {
        last: string
        next: string
      }
      external?: {
        last: string
        next: string
      }
    }
  }
}

interface RolodexSwrParams<EntryType extends RolodexEntry = RolodexEntry> {
  noRefresh?: boolean
  onSuccess?: (data: RolodexLoadedEntry<EntryType>[] | undefined) => void
  onError?: (error: Error, value: string) => void
}

const getSWRConfig = <EntryType extends RolodexEntry = RolodexEntry>(
  params?: RolodexSwrParams<EntryType>
) =>
  params?.noRefresh ?? false
    ? {
        onSuccess:
          params?.onSuccess ??
          (() => {
            // do nothing
          }),
        onError:
          params?.onError ??
          (() => {
            // do nothing
          }),
        dedupingInterval: Number.MAX_VALUE,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    : {
        onSuccess:
          params?.onSuccess ??
          (() => {
            // do nothing
          }),
        onError:
          params?.onError ??
          (() => {
            // do nothing
          }),
      }

//------------------------------------------------------------------------------

/**
 * A hook for querying Rolodex entries
 * @param body The body of the query, containing IDs, tags,etc. to query for
 * @param params Optional parameters for configuring this query
 */
export const useRolodexEntries = <EntryType extends RolodexEntry>(
  body: Record<string, any> | undefined,
  params?: RolodexSwrParams<EntryType>,
  fetch = true //this is kinda stupid for the use swr hook it shouldn't fetch data until it's ready for it
): SWRResponse<RolodexLoadedEntry<EntryType>[]> => {
  const endpoint = body ? `${rolodexUrl()}/entries/load` : ''
  const auth0 = useAuthInfo()
  const siteData = useContext(SiteContext)
  const [extraTags, setExtraTags] = useState({})
  const [fetchReady, setIsFetchReady] = useState(false)
  useEffect(() => {
    if (siteData?.siteSlug && siteData.orgSlug) {
      setExtraTags({ site: siteData.siteSlug, organization: siteData.orgSlug })
    }
    if (auth0.is_Loading === false) {
      //wanna be explicity about the check here since if auth0 is undefined here it would be set to true
      setIsFetchReady(true)
    }
  }, [auth0.is_Loading, siteData])

  if (!!body && !Object.prototype.hasOwnProperty.call(body, 'limit'))
    body['limit'] = 10000
  if (!!body) body.tags = { ...extraTags, ...body.tags }
  const bodyData = JSON.stringify(body)

  return useSWR(
    fetchReady && fetch ? [endpoint, bodyData, auth0] : false,
    ([endpoint, bodyData, auth0]) => postFetcher(endpoint, bodyData, auth0),
    getSWRConfig(params)
  )
}

/**
 * A hook for querying downloading a large file from Rolodex
 * @param entryID The ID of the entry this file is attached to
 * @param filename The ID of the file to fetch
 * @param params Optional parameters for configuring this query
 */
export const useRolodexLargeFile = (
  entryID: string | undefined,
  filename: string | undefined,
  params?: RolodexSwrParams<any>
): {
  data: Blob | undefined
  isLoading: boolean
  isValidating: boolean
  error: Error | undefined
} => {
  const endpoint =
    !!entryID && !!filename
      ? `${rolodexUrl()}/files/${encodeURIComponent(entryID)}/${encodeURIComponent(
          filename
        )}`
      : ''
  const auth0 = useAuthInfo()

  type keyType = [string, AuthInfo]
  const gcpLinkQuery = useSWR<any, Error, keyType>(
    [endpoint, auth0],
    ([endpoint, auth0]) => getFetcher(endpoint, auth0),
    getSWRConfig(params)
  )

  const gcpLink = gcpLinkQuery.data
  const fileDownload = useSWR([gcpLink, auth0], ([endpoint, auth0]) =>
    fileFetcher(endpoint, auth0)
  )

  return !gcpLink ? gcpLinkQuery : fileDownload
}

/**
 * A hook for querying the list of files associated with a Rolodex entry
 * @param body The body of
 * @param params
 */
export const useRolodexFilesList = (
  body: Record<string, any> | undefined,
  params?: RolodexSwrParams
): {
  data: any[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: Error | undefined
} => {
  const endpoint = body ? `${rolodexUrl()}/files/list` : ''
  const auth0 = useAuthInfo()

  if (!!body && !Object.prototype.hasOwnProperty.call(body, 'limit'))
    body['limit'] = 10000
  const bodyData = JSON.stringify(body)

  type keyType = [string, string, AuthInfo]
  return useSWR<RolodexLoadedEntry[], Error, keyType>(
    [endpoint, bodyData, auth0],
    ([endpoint, bodyData, auth0]) => postFetcher(endpoint, bodyData, auth0),
    getSWRConfig(params)
  )
}
