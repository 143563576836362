import typia from 'typia'
import { inspectionStatus } from '../AssetDatabaseTab/AssetDatabaseTabInterface'

export interface InspectionEntry {
  entry: {
    id: string
    type: string
    data: RawScheduledInspection
    tags?: {
      site?: string
      organization?: string
    }
  }
}

export interface RawScheduledInspection extends RawInspectionMetadata {
  completed: boolean
  scheduled_date: string
  next_deadline: string
}

export interface MergedInspectionSchedule extends RawScheduledInspection {
  status: inspectionStatus
}

export interface OutOfPeriodCounts {
  pre_count: number
  post_count: number
  pre_data: {
    earliest_date: string
    latest_date: string
  }
  post_data: {
    earliest_date: string
    latest_date: string
  }
}

export interface DueGraphDataResponse {
  graph_data: DueGraphData
  out_of_period_counts: OutOfPeriodCounts
}
export interface DueGraphData {
  [key: string]: InspectionGraphCategoryData
}

export interface InspectionGraphCategoryData {
  dates: string[]
  counts: number[]
  hours: number[]
}

export interface RawInspectionMetadata {
  asset_name: string
  asset_tag?: string
  component_name?: string
  component_type?: string
  inspection_type: string
  area: string
  inspection_method: string
  outage_type: string
  inspection_interval: number
  current_deadline?: string
  inspection_tags?: string[]
  cost?: number
  priority?: 'High' | 'Medium' | 'Low' | 'Top'
  previous_inspection?: string
  inspectionId?: string
  latest?: boolean
  fdeCustomerUnitSlug: string
  estimated_hours?: number
}

export interface ScheduledInspection {
  rowId: string
  assetName: string
  assetTag: string
  componentName?: string
  componentType?: string
  componentData?: ScheduledInspectionComponentData[]
  inspectionType: string
  area: string
  inspectionMethod: string
  outageType: string
  interval: number
  scheduledDate: string
  deadline: string
  prevInspection?: string
  fdeCustomerUnitSlug: string
  inspectionTags: string[]
  priority: 'High' | 'Medium' | 'Low' | 'Top' | '---'
  inspectionId: string
  cost?: number
  completed: boolean
  currentDeadline: string
  latest?: boolean
  estimatedHours?: number
  ruggable?: string
  resource?: string
}

export type ScheduledInspectionComponentData = Omit<ScheduledInspection, 'componentData'>

export const isScheduledInspection = (data: any) => {
  return data
}
