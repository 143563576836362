/**
 * A function that converts a string in camelCase to title case.
 * @param input string in camelCase
 * @returns A new string in title case
 */
export function convertCamelCaseToTitleCase(input: string): string {
  const withSpaces = input.replace(/([A-Z])/g, ' $1')

  const titleCased = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)

  return titleCased.trim()
}
