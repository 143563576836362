import styled from 'styled-components'
import { asset_manager_theme } from '../../assets/themes'

interface PriorityPillProps {
  text: string | undefined
}

export const PriorityPill = styled.div<PriorityPillProps>`
  width: 4rem;
  font-size: 0.75rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${asset_manager_theme.text.font_family2};
  text-align: center;
  border-radius: 6px;
  margin: 0 auto;

  background-color: ${({ text }) => {
    if (text === 'Top') return asset_manager_theme.colors.high_vis
    if (text === 'High') return asset_manager_theme.colors.red_dark
    if (text === 'Medium') return asset_manager_theme.colors.orange_dark
    if (text === 'Low') return asset_manager_theme.colors.yellow_dark
    return 'inherit'
  }};

  color: ${({ text }) => {
    if (text === 'Top') return asset_manager_theme.colors.yellow_dark
    if (text === 'High') return asset_manager_theme.colors.red
    if (text === 'Medium') return asset_manager_theme.colors.orange
    if (text === 'Low') return asset_manager_theme.colors.yellow
    return 'inherit'
  }};
`
