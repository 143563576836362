import React, { useEffect, useContext, useState } from 'react'
import { Input as InputString } from 'antd'
import _ from 'lodash'
import { SiteContext } from '../../contextProviders/siteContextProvider'
import { useDebounce } from 'react-use'
import { RolodexPatchBatch } from '../../util/RolodexInterface'
import { useAuthInfo } from '../../contextProviders/useAuthInfo'
const { TextArea } = InputString

interface EditableTextAreaElementProps {
  value: string | undefined
  objectId: string
  field: string
  refetchData?: () => void
  rolodexType: string
  disabled?: boolean
}

export const EditableTextAreaElement: React.FC<EditableTextAreaElementProps> = ({
  value,
  objectId,
  field,
  refetchData,
  rolodexType,
  disabled,
}) => {
  const siteData = useContext(SiteContext)
  const auth0 = useAuthInfo()
  const [editedValue, setEditedValue] = useState<string | undefined>(value)

  useEffect(() => {
    setEditedValue(value)
  }, [value])

  useDebounce(
    () => {
      if (editedValue !== value && siteData) {
        //only worth patching when they're different
        const patchBody = {
          id: objectId,
          type: rolodexType,
          data: {
            [field]: editedValue ?? null,
          },
          tags: {
            site: siteData.siteSlug,
            organization: siteData.orgSlug,
          },
        }
        RolodexPatchBatch([patchBody], auth0, siteData).then((d) => {
          refetchData?.()
        })
      }
    },
    500,
    [editedValue]
  )

  return (
    <TextArea
      key={objectId}
      defaultValue={value}
      disabled={disabled}
      value={editedValue}
      onChange={(e) => {
        setEditedValue(e.target.value)
      }}
    />
  )
}
