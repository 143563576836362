/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Data } from 'plotly.js'
import { FC, useMemo } from 'react'

import { GeckoTheme } from 'gecko-ui'

import {
  OVERVIEW_BAR_COMPLIANT_LABEL,
  OVERVIEW_BAR_EXT_NON_COMPLIANT_LABEL,
  OVERVIEW_BAR_INT_NON_COMPLIANT_LABEL,
  OVERVIEW_BAR_NON_COMPLIANT_LABEL,
} from '../../util/OverviewConstants'
import { AreaToOverviewCounts } from './OverviewTab'
import { useResizeDetector } from 'react-resize-detector'
import Plot from 'react-plotly.js'
import { asset_manager_theme } from '../../assets/themes'

interface OverviewBarProps {
  overviewData: AreaToOverviewCounts
}

const OverviewBar: FC<OverviewBarProps> = (props) => {
  const traces = useMemo(() => {
    // x-values for all bar-traces
    const xValues: string[] = Object.keys(props.overviewData)

    const fullyCompliantY: number[] = []
    const externallyNonCompliantY: number[] = []
    const internallyNonCompliantY: number[] = []
    const nonCompliantY: number[] = []
    for (const area of xValues) {
      fullyCompliantY.push(props.overviewData[area]?.fully_compliant ?? 0)
      externallyNonCompliantY.push(
        props.overviewData[area]?.externally_non_compliant +
          props.overviewData[area]?.non_compliant
      )
      internallyNonCompliantY.push(
        props.overviewData[area]?.internally_non_compliant +
          props.overviewData[area]?.non_compliant
      )
      nonCompliantY.push(props.overviewData[area]?.non_compliant ?? 0)
    }
    return [
      {
        type: 'bar',
        x: xValues,
        y: fullyCompliantY,
        name: OVERVIEW_BAR_COMPLIANT_LABEL,
        marker: {
          color: asset_manager_theme.colors.green,
        },
      },
      {
        type: 'bar',
        x: xValues,
        y: externallyNonCompliantY,
        name: OVERVIEW_BAR_EXT_NON_COMPLIANT_LABEL,
        marker: {
          color: asset_manager_theme.colors.orange,
        },
      },
      {
        type: 'bar',
        x: xValues,
        y: internallyNonCompliantY,
        name: OVERVIEW_BAR_INT_NON_COMPLIANT_LABEL,
        marker: {
          color: asset_manager_theme.colors.yellow,
        },
      },
      {
        type: 'bar',
        x: xValues,
        y: nonCompliantY,
        name: OVERVIEW_BAR_NON_COMPLIANT_LABEL,
        marker: {
          color: asset_manager_theme.colors.red,
        },
      },
    ] as Data[]
  }, [props.overviewData])

  const { ref, width, height } = useResizeDetector()

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <div style={{ width, height, paddingTop: '.5rem' }}>
        <Plot
          data={traces}
          layout={{
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            width,
            height: 320,
            font: {
              color: 'rgba(255,255,255,1)',
            },
            margin: { t: 0, b: 70 },
            xaxis: {
              tickfont: {
                family: GeckoTheme.fontFamily.manrope[0],
                size: 11.2,
              },
              tickangle: 30,
            },
            yaxis: {
              showgrid: false,
            },
            autosize: true,
          }}
          config={{
            displayModeBar: false,
            responsive: true,
          }}
          useResizeHandler={true}
          onLegendClick={() => false}
        />
      </div>
    </div>
  )
}

export default OverviewBar
